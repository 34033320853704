import React, {useEffect, useState} from 'react';
import {
	labels,
	urls,
	currentResource
} from '../../../util/uiconstants';
import ChooseAcceptDeclineDelegate from './acceptdeclinedelegaterequest';
import ChooseNewOrExistingWorkspace from '../EngagementWorkflowsNew/chooseneworexisting';
import CreateWorkspace from './createworkspace';
import SelectWorkspace from './selectworkspace';
import SelectEngagement from './selectengagement';
import DeclinePackage from './declinepackage';
import DelegatePackage from './delegatepackage';
import styled from 'styled-components';
import {CopyObjectGroupType} from '../../../util/enumerations';
import * as QueryString from 'query-string';
import {useHistory} from 'react-router-dom';
import WizardWrapper from '../Common/WizardWrapper';
import {useDispatch, useSelector} from 'react-redux';
import env from '../../../util/env';
import Loader from '@ey/loader';
import {getCopyRequest} from '../../../actions/copyhubactions';

const ImportDataPackageWizardLoader = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const copyRequest = useSelector((store) => store.copyHub.copyRequest);

	const params = QueryString.parse(location.search);
	let datapackagetype = copyRequest && copyRequest.copyobjectgrouptypeid;
	let enableNewWrkspc = copyRequest && copyRequest.enablecopyengtonewworkspace;
	if (enableNewWrkspc == null || enableNewWrkspc == undefined) {
		enableNewWrkspc = true;
	}

	const [activeStepIndex, setActiveStepIndex] = useState(
		params.engagementid ? 1 : 0
	);
	const [step2NextChng, setstep2NextChng] = useState(1);
	const [step3FinishChng, setstep3FinishChng] = useState(1);
	const [step2FinishChng, setstep2FinishChng] = useState(1);
	const [canGoToNextStep, setcanGoToNextStep] = useState(false);
	const [isLoading, setisLoading] = useState(false);
	const [isChkImportForReviewOnly, setisChkImportForReviewOnly] =
		useState(false);
	const [domain, setDomain] = useState({
		domainAccept: true,
		domainDecline: false,
		domainDelegate: false,
		domainAcceptDelegateDecline: 0, //0:accept , 1: delegate , 2: decline
		domainChooseExisting: true,
		domainWorkspaceId: -1,
		domainClientId: -1
	});

	const [wizardSteps, setWizardSteps] = useState([
		{
			stepName: labels.step01,
			stepDescription: labels.chooseaction,
			active: true
		},
		{
			stepName: labels.step02,
			stepDescription: domain.domainAccept
				? datapackagetype == CopyObjectGroupType.Engagement ||
				  datapackagetype == CopyObjectGroupType.V2Engagement ||
				  datapackagetype == CopyObjectGroupType.V2EngagementRollforwardCopy ||
				  datapackagetype == CopyObjectGroupType.EngagementByEntity
					? labels.choosenewexistingstepheader
					: labels.selectworkspace
				: domain.domainDecline
				? labels.decline
				: labels.delegate,
			active: false
		},
		{
			stepName: labels.step03,
			stepDescription:
				datapackagetype == CopyObjectGroupType.Engagement ||
				datapackagetype == CopyObjectGroupType.V2Engagement ||
				datapackagetype == CopyObjectGroupType.V2EngagementRollforwardCopy ||
				datapackagetype == CopyObjectGroupType.EngagementByEntity
					? domain.domainChooseExisting
						? labels.selectexistingworkspace
						: labels.importtonewworkspace
					: labels.selectengagement,
			active: false
		}
	]);

	useEffect(() => {
		datapackagetype = copyRequest && copyRequest.copyobjectgrouptypeid;

		domain.domainAcceptDelegateDecline === 0
			? setWizardSteps([
					{
						stepName: labels.step01,
						stepDescription: labels.chooseaction,
						active: true
					},
					{
						stepName: labels.step02,
						stepDescription: domain.domainAccept
							? datapackagetype == CopyObjectGroupType.Engagement ||
							  datapackagetype == CopyObjectGroupType.V2Engagement ||
							  datapackagetype == CopyObjectGroupType.V2EngagementRollforwardCopy ||
							  datapackagetype == CopyObjectGroupType.EngagementByEntity
								? labels.choosenewexistingstepheader
								: labels.selectworkspace
							: domain.domainDecline
							? labels.decline
							: labels.delegate,
						active: false
					},
					{
						stepName: labels.step03,
						stepDescription:
							datapackagetype == CopyObjectGroupType.Engagement ||
							datapackagetype == CopyObjectGroupType.V2Engagement ||
							datapackagetype == CopyObjectGroupType.V2EngagementRollforwardCopy ||
							datapackagetype == CopyObjectGroupType.EngagementByEntity
								? domain.domainChooseExisting
									? labels.selectexistingworkspace
									: labels.importtonewworkspace
								: labels.selectengagement,
						active: false
					}
			  ])
			: setWizardSteps([
					{
						stepName: labels.step01,
						stepDescription: labels.chooseaction,
						active: true
					},
					{
						stepName: labels.step02,
						stepDescription: domain.domainAccept
							? labels.selectworkspace
							: domain.domainDecline
							? labels.decline
							: labels.delegate,
						active: false
					}
			  ]);
	}, [domain.domainAcceptDelegateDecline, copyRequest]);

	useEffect(() => {
		getCopyRequestFunction(params.copyRequestId);
	}, []);

	useEffect(() => {
		let wizardLoaderName = labels.import;

		if (copyRequest) {
			wizardLoaderName =
				labels.import +
				' ' +
				currentResource.copyObjectGroupType[
					copyRequest.copyobjectgrouptypeid
				];
		}
		
		setwizLoaderName(wizardLoaderName);
	}, [copyRequest]);

	useEffect(() => {
		// If the "selecting new or create workspace" state is changed, update the step labels as needed.
		setWizardSteps((prev) => {
			return prev.map((step) => {
				return step.stepName === labels.step03
					? {
							...step,
							stepDescription: domain.domainChooseExisting
								? labels.selectexistingworkspacestepheader
								: labels.createworkspacestepheader
					  }
					: step;
			});
		});
	}, [domain.domainChooseExisting]);

	const oldPath = props.location?.state?.oldPath
		? props.location?.state?.oldPath
		: '';

	const [wizLoaderName, setwizLoaderName] = useState(
		labels.importengagementheader
	);

	const mode = {
		importdp: 'importdp'
	};

	const buttons = [
		{
			label: activeStepIndex == 0 ? labels.cancel : labels.back, // Change label based on active step.
			onClick: () => {
				switch (activeStepIndex) {
					case 0:
						navigateBack();
						break;
					case 1:
						setActiveStepIndex((prev) => prev - 1);
						break;
					case wizardSteps.length - 1:
						setActiveStepIndex((prev) => prev - 1);
						break;
				}
			},
			isVisible: true,
			disabled: false
		},
		{
			label:
				activeStepIndex === wizardSteps.length - 1
					? labels.finish
					: labels.continue,
			onClick: () => {
				switch (activeStepIndex) {
					case 0:
						resetDomainValues([
							'domainClientId',
							'domainClientName',
							'domainWorkspaceId',
							'domainWorkspaceName'
						]);
						setActiveStepIndex((prev) => prev + 1);
						break;
					case 1:
						if (domain.domainAcceptDelegateDecline === 0) {
							if (
								datapackagetype == CopyObjectGroupType.Engagement ||
								datapackagetype == CopyObjectGroupType.V2Engagement ||
								datapackagetype == CopyObjectGroupType.V2EngagementRollforwardCopy ||
								datapackagetype == CopyObjectGroupType.EngagementByEntity
							) {
								resetDomainValues([
									'domainClientId',
									'domainClientName',
									'domainWorkspaceId',
									'domainWorkspaceName'
								]);
								setActiveStepIndex((prev) => prev + 1);
							} else {
								canGoToNextStep
									? setActiveStepIndex((prev) => prev + 1)
									: setstep2NextChng((prev) => prev + 1);
							}
						} else {
							setstep2FinishChng((prev) => prev + 1);
						}
						break;
					case wizardSteps.length - 1:
						setstep3FinishChng((prev) => prev + 1);
						break;
				}
			},
			isVisible: true,
			disabled: false
		}
	];

	const navigateBack = () => {
		history.push(oldPath);
	};

	const updateWizardData = (fieldName, fieldValue) => {
		setDomain((prev) => {
			return {
				...prev,
				[fieldName]: fieldValue
			};
		});
	};

	const getWizardData = (domainFieldName) => {
		return domain[domainFieldName];
	};

	const resetDomainValues = (valuesArray) => {
		valuesArray.forEach((valueName) => {
			updateWizardData(valueName, undefined);
		});
	};

	const getCopyRequestUrl =
		env.getURL('serviceUrl') +
		'/' +
		urls.GROUPAUDITENGAGEMENTS_URL +
		'?phase=ImportDataPackageRequest&filters=CopyRequestID eq ';

	const getCopyRequestFunction = async (id) => {
		try {
			setisLoading(true);
			await dispatch(getCopyRequest(getCopyRequestUrl + id, id));
		} finally {
			setisLoading(false);
		}
	};

	const getCheckImportForReview = (isChecked) => {
		setisChkImportForReviewOnly(isChecked);
	};

	const isStep2Valid = (isComponentDataValid) => {
		if (activeStepIndex == 1) {
			if (isComponentDataValid == undefined || isComponentDataValid == null)
				setcanGoToNextStep(false);
			else setcanGoToNextStep(isComponentDataValid);
		}
	};
	const isStep3Valid = (isComponentDataValid) => {
		if (activeStepIndex == 2) {
			if (isComponentDataValid == undefined || isComponentDataValid == null)
				setcanGoToNextStep(false);
			else setcanGoToNextStep(isComponentDataValid);
		}
	};

	const step1 = () => {
		return (
			<ChooseAcceptDeclineDelegate
				updateWizardData={updateWizardData}
				getDependencyData={getWizardData}
				importForReviewCheck={getCheckImportForReview}
			/>
		);
	};

	const step2 = (copyHubRequestID) => {
		return domain.domainAcceptDelegateDecline === 0 ? (
			domain.domainAccept ? (
				datapackagetype == CopyObjectGroupType.Engagement ||
				datapackagetype == CopyObjectGroupType.V2Engagement ||
				datapackagetype == CopyObjectGroupType.V2EngagementRollforwardCopy ||
				datapackagetype == CopyObjectGroupType.EngagementByEntity ? (
					<ChooseNewOrExistingWorkspace
						updateWizardData={updateWizardData}
						getDependencyData={getWizardData}
						mode={mode.importdp}
						enableCopyToNewWrkspc={enableNewWrkspc}
					/>
				) : (
					<SelectWorkspace
						isComponentValid={isStep2Valid}
						updateWizardData={updateWizardData}
						nextClicked={step2NextChng}
						getDependencyData={getWizardData}
						isChkImportForReviewOnly={isChkImportForReviewOnly}
					/>
				)
			) : domain.domainDecline ? (
				<DeclinePackage
					isComponentValid={isStep2Valid}
					updateWizardData={updateWizardData}
					getDependencyData={getWizardData}
					onFinishClick={step2FinishChng}
				/>
			) : (
				<DelegatePackage
					copyRequestId={copyHubRequestID}
					isComponentValid={isStep2Valid}
					updateWizardData={updateWizardData}
					getDependencyData={getWizardData}
					onFinishClick={step2FinishChng}
				/>
			)
		) : domain.domainAccept ? (
			<SelectWorkspace
				isComponentValid={isStep2Valid}
				updateWizardData={updateWizardData}
				nextClicked={step2NextChng}
				getDependencyData={getWizardData}
				closeWizard={navigateBack}
				isChkImportForReviewOnly={isChkImportForReviewOnly}
			/>
		) : domain.domainDecline ? (
			<DeclinePackage
				isComponentValid={isStep2Valid}
				updateWizardData={updateWizardData}
				getDependencyData={getWizardData}
				onFinishClick={step2FinishChng}
				closeWizard={navigateBack}
			/>
		) : (
			<DelegatePackage
				copyRequestId={copyHubRequestID}
				isComponentValid={isStep2Valid}
				updateWizardData={updateWizardData}
				getDependencyData={getWizardData}
				onFinishClick={step2FinishChng}
				closeWizard={navigateBack}
			/>
		);
	};
	const step3 = () => {
		return datapackagetype == CopyObjectGroupType.Engagement ||
			datapackagetype == CopyObjectGroupType.V2Engagement ||
			datapackagetype == CopyObjectGroupType.V2EngagementRollforwardCopy ||
			datapackagetype == CopyObjectGroupType.EngagementByEntity ? (
			domain.domainChooseExisting ? (
				<SelectWorkspace
					isComponentValid={isStep2Valid}
					updateWizardData={updateWizardData}
					nextClicked={step2NextChng}
					getDependencyData={getWizardData}
					closeWizard={navigateBack}
					onFinishClick={step3FinishChng}
					isChkImportForReviewOnly={isChkImportForReviewOnly}
				/>
			) : (
				<CreateWorkspace
					isComponentValid={isStep3Valid}
					updateWizardData={updateWizardData}
					onFinishClick={step3FinishChng}
					getDependencyData={getWizardData}
					closeWizard={navigateBack}
					isChkImportForReviewOnly={isChkImportForReviewOnly}
				/>
			)
		) : (
			<SelectEngagement
				isComponentValid={isStep3Valid}
				updateWizardData={updateWizardData}
				onFinishClick={step3FinishChng}
				getDependencyData={getWizardData}
			/>
		);
	};

	return (
		<>
			{isLoading ? (
				<Loader view="fullscreen" />
			) : (
				<StyledWizardWrapper>
					<WizardWrapper
						wizLoaderName={wizLoaderName}
						currentStepIndex={activeStepIndex}
						wizardSteps={wizardSteps}
						setWizardSteps={setWizardSteps}
						buttons={buttons}
						theme={
							document
								.getElementsByTagName('body')[0]
								.getAttribute('motif-theme') === 'light'
								? null
								: 'dark'
						}
						stepContent={[step1(), step2(params.copyRequestId), step3()]}
					/>
				</StyledWizardWrapper>
			)}
		</>
	);
};

export default ImportDataPackageWizardLoader;

const StyledWizardWrapper = styled.section`
	width: 100%;
	padding: 0 var(--px-40);
	header h5 {
		font-size: var(--px-20);
		font-weight: bold;
		width: 100%;
		padding: var(--px-15) 0;
	}
	.componentBodyWrapper {
		height: calc(100vh - var(--px-280));
		position:relative;
	}
`;
