import React from 'react';
import {useHistory} from 'react-router-dom';
import MenuItem from '@ey/motif-react-wrapper/MenuItem';
import MotifIcon from '@ey/motif-react-wrapper/Icon';
import {
	IconoirDollarCircle,
	IconoirCustomOversightProduct,
	IconoirHelpCircle,
	IconoirSettings,
	IconoirShieldCheck,
	IconoirJournal
} from '@ey-xd/motif-icon';
import {labels} from '../../../util/uiconstants';
import env from '../../../util/env';
import Utility from '../../../util/utils';

const MoreMenuOptions = (props) => {
	const {featureConfiguration, userSettings} = props;

	const history = useHistory();

	const onUserSettingsClick = () => {
		window.dispatchEvent(new CustomEvent('closeMenu', {}));
		history.push('/usersettings');
	};

	let paansConfiguration = env.getPaansConfiguration();

	if (paansConfiguration) {
		const userLanguageCode = Utility.GetUserLanguageCode(
			userSettings.userlanguageid
		);
		paansConfiguration.paansUrl =
			paansConfiguration.paansUrl + `&l=${userLanguageCode}`;
	}

	return (
		<>
			{featureConfiguration?.iseycanvaseconomicsenabled && (
				<MenuItem>
					<a href={featureConfiguration?.eycanvaseconomicsurl || ''}>
						<MotifIcon src={IconoirDollarCircle} />
						<span>{labels.eyCanvasEconomicsTooltip}</span>
					</a>
				</MenuItem>
			)}
			{featureConfiguration?.iseyoversightenabled && (
				<MenuItem>
					<a href={featureConfiguration?.eycanvasoversighturl || ''}>
						<MotifIcon
							src={IconoirCustomOversightProduct}
							className="oversight-icon"
						/>
						<span>{labels.eyOversightTooltip}</span>
					</a>
				</MenuItem>
			)}
			<MenuItem>
				<a href={env.getHelpWebUrl()} target="_blank">
					<MotifIcon src={IconoirHelpCircle} />
					<span>{labels.eyGeneralHelp}</span>
				</a>
			</MenuItem>
			<MenuItem>
				<a onClick={onUserSettingsClick}>
					<MotifIcon src={IconoirSettings} />
					<span>{labels.usersettings}</span>
				</a>
			</MenuItem>
			{paansConfiguration?.paansEnabled &&
				paansConfiguration.privacyNoticePolicyTypeId && (
					<MenuItem>
						<a
							href={
								paansConfiguration?.paansUrl +
								`&pt=${paansConfiguration?.privacyNoticePolicyTypeId}`
							}
							target="_blank"
						>
							<MotifIcon src={IconoirShieldCheck} />
							<span>{labels.eyPrivacyNoticeTooltip}</span>
						</a>
					</MenuItem>
				)}
			{paansConfiguration?.paansEnabled &&
				paansConfiguration?.termsOfServicePolicyTypeId && (
					<MenuItem>
						<a
							href={
								paansConfiguration?.paansUrl +
								`&pt=${paansConfiguration?.termsOfServicePolicyTypeId}`
							}
							target="_blank"
						>
							<MotifIcon src={IconoirJournal} />
							<span>{labels.eyTermsOfServiceTooltip}</span>
						</a>
					</MenuItem>
				)}{' '}
		</>
	);
};

export default MoreMenuOptions;
