/* eslint-disable prettier */

import {
	Addon
} from 'react-bootstrap/lib/InputGroup';

/**
 * Created by calhosh on 4/14/2017.
* HE resource file
 */
export const labels = {
	defaultDropdown: "תפריט נפתח",
	status: "סטטוס",
	language: "שפה",
	channel: "ערוץ",
	header: "כותרת עליונה",
	headerName: "שם כותרת עליונה",
	sectionCustomLabelLabel: "Custom Label",
	sectionNamePlaceHolder: "הזן כותרת מקטע",
	showRelatedObjectLabel: "הצג אובייקטים קשורים",
	errorBanner: "{ 0} שגיאה/ות",
	engagements: "התקשרויות",
	selectedengagements: "תיק ביקורת",
	addEngagement: "הוסף תיק ביקורת",
	archives: "תיקים בארכיב",
	userInitialsTitle: "אפשרויות משתמש",
	clickForOptions: "לחץ לקבלת אפשרויות נוספות",
	comingDue: "לקראת תאריך היעד",
	whatsNew: "מה חדש",
	auditMetrics: "מדדי (metrics) ביקורת",
	id: "ID",
	archived: "אורכב",
	myarchives: "התיקים בארכיב שלי ",
	allarchives: "כל התיקים בארכיב",
	logout: "התנתק",
	norecords: "לא נמצאו רשומות",
	nomatches: "לא נמצאו התאמות",
	notemplates: "אין תבניות זמינות עבור האזור שנבחר",
	pleasetryagain: "אנא נסה שנית",
	firstpage: "הדף הראשון",
	previous: "הקודם",
	next: "הבא",
	prevpage: "הדף הקודם",
	nextpage: "הדף הבא",
	lastpage: "הדף האחרון",
	allengagements: "כל ההתקשרויות",
	searchPlaceholder: "חיפוש",
	searchEngagementsPlaceholder: "חפש תיקי ביקורת",
	clearSearch: "נקה חיפוש",
	searchTextPlaceHolder: "חיפוש",
	show: "הצג",
	manageaccess: "ניהול גישה",
	restore: "שוחזר",
	rollforward: "גלגל קדימה",
	viewaccesslist: "הצגת רשימת הגישה",
	teammembers: "חברי הצוות",
	name: "שם",
	initials: "ראשי תיבות",
	role: "תפקיד",
	usersettings: "הגדרות משתמש",
	usersettingsnewengagements: "ההגדרות חלות על התקשרויות חדשות שנוצרו",
	usersettingsallengagements: "ההגדרות חלות על כל סביבות העבודה וההתקשרויות",
	enterfirstname: "הצג שם (פרטי)",
	enterlastname: "הצג שם (משפחה)",
	firstname: "שם פרטי",
	lastname: "שם משפחה",
	enter: "הזן",
	select: "בחר ...",
	email: "כתובת הדואל",
	contact: "מספר טלפון",
	accesslist: "רשימת הגישה",
	engagementsettings: "הגדרות ההתקשרות",
	displayname: "הצג שם",
	signoffinitials: "ראשי תיבות לצרכי sign-off",
	primaryoffice: "משרד ראשי",
	roles: "תפקיד",
	phonenumber: "מספר טלפון",
	globalapplicationsettings: "הגדרות יישומים כלליות",
	displaylanguage: "שפת התצוגה",
	preferreddateformat: "תבנית תאריך מועדפת",
	preferrednumberformat: "תבנית מספר מועדפת",
	save: "שמור",
	cancel: "בטל",
	isRequired: "נדרש {0}",
	arcretrymessage: 'אירעה שגיאה בתהליך האירכוב. תאריך הרישום של הארכיב הוא כעת {0}. EY Canvas מנסה שוב באופן אוטומטי לארכב את סביבת העבודה שלך, אנא המתן חמש עשרה שעות מרגע הגשת הארכיב להשלמת תהליך השחזור האוטומטי לפני שהינך פונה לתמיכה הטכנית (Help Desk). אם התמיכה הטכנית (Help Desk) יכולים לפתור את הבעיה מבלי להפעיל מחדש את תיק הביקורת, תאריך הארכיון ימשיך להיות {0}. אם התמיכה הטכנית (Help Desk) יהיו צריכים להפעיל מחדש את תיק הביקורת כדי שהצוות ינקוט בפעולות מתקנות, תאריך הארכיב יאופס למועד בו הצוות יגיש את הארכיב שוב. אם הנ"ל יגרום לארכיב מאוחר, על הצוות לכלול תיעוד בתיק הביקורת, תוך התייחסות למספר הפנייה לתמיכה הטכנית(Help Desk), הבעיה והצעדים שננקטו כדי לפתור אותה בהתאם למדיניות האירכוב המקומית.',
    ArchiveRetryLink: "ניסיון חוזר לארכב",
	workspacelist: "רשימת סביבות העבודה",
	engagementslist: "רשימת התקשרויות",
	accessrequested: "נתבקשה גישה",
	noaccess: "אין גישה",
	independenceOnlyNoAccessMessage: "תפקידך בהתקשרות היה 'אי תלות בלבד'. צור קשר עם חבר צוות בתיק הביקורת בכדי להעניק זכויות לשחזור או לגלגול קדימה של ההתקשרות.",
	accessrejected: "גישה נדחתה",
	requestaccess: "בקשת גישה",
	allarchiveinitialmsg: "הזן את שם הלקוח, שם סביבת העבודה או שם ההתקשרות כדי להתחיל",
	noengagementsforusermessage: "אין לך התקשרויות פעילות. לחץ על צור התקשרות כדי להתחיל.",
	noengagementstoimportto: "אין לך תיקי ביקורת שבאפשרותך לייבא.",
	favorites: "מועדפים",
	portfolioview: "כל ההתקשרויות v2",
	reviewengagement: "סקירת התקשרויות",
	noreviewengagementsmessage: "אין לך כל התקשרויות לסקירה.",
	noreviewengagementsmessagecontact: "אין לך התקשרויות מסוג סקירה. אם אתה זקוק לגישה להתקשרות כזו, פנה לחבר צוות אחר.",
	nofavoriteengagementsmsg1: "אין לך שום התקשרויות מועדפות.",
	nofavoriteengagementsmsg2: "בכדי להוסיף התקשרויות לתצוגה זו, גש לחלון 'כל ההתקשרויות'.",
	nofavoriteengagementsmsg3: "ולחץ על סמל ה'מועדפים' ב- engagement card. ",
	markasfavorite: "סמן כמועדף",
	unmarkfromfavorites: "הסר מהמועדפים",
	selectworkspacenoclientworkspaceexists: "אין לך סביבות עבודה קיימות. לחץ על חזור אחורה ובחר באפשרות סביבת עבודה חדשה משלב 1.",
	importpkgselectworkspacenoclientworkspaceexists: "אין לך סביבות עבודה קיימות. לחץ על 'חזור' ובחר 'צור סביבת עבודה חדשה' בשלב הקודם.",
    expirydatelabel: "תיק הביקורת זה ימחק ב",
	expirydatetodaytomorrowlabel: "תיק הביקורת ימחק",
	/*Reporting Hub*/
	reportingHub: "דוחות",
	reportingHubTitle: "EY Canvas דוחות של",
	selectEngagement: "בחר התקשרות בכדי להציג דוחות",
	connectVPN: "ניתן לגשת לדוחות רק כשאתה מחובר ישירות לרשת של EY או באמצעות גישה מרחוק",
	reportsNotLoaded: "לא ניתן לטעון דוחות. רענן את הדף ונסה שנית. במידה והבעיה נמשכת, פנה אל התמיכה הטכנית.",
	searchByEngagementNameOrClientId: "בחר התקשרות",
	dataExportLabel: "יצוא נתונים",
	summaryScreensLabel: "מסכי סיכום",
	projectManagementLabel: "ניהול פרויקטים",
	engagement: "שם ההתקשרות",
	reportingNotAvailable: "דיווחים אינם זמינים באתר שנבחר. פנה למומחה Canvas בצוות שלך לתמיכה.",
	client: "לקוח",
	workspace: "סביבת עבודה",
	serviceline: "מחלקה",
	subserviceline: "תת מחלקה",
	engagementstartdate: "תאריך סיום שנה",
	engagementenddate: "תאריך סיום של התקופה המבוקרת/נסקרת",
	deleteafter: "מחק אחרי",
	auditorsreportdate: "תאריך הדוח",
	createengagementquestionlabel: "צור התקשרות",
	choosenewexistingquestionheader: "האם ליצור התקשרות בסביבת עבודה חדשה או קיימת?",
	createengagementheader: "צור התקשרות",
	restoreengagementheader: "שחזור תיק הביקורת",
	createworkspacestepheader: "צור סביבת עבודה חדשה",
	selectexistingworkspacestepheader: "בחר סביבת עבודה קיימת",
	workspacenotelibilefornewengagement: "סביבת עבודה לא מתאימה להתקשרויות חדשות",
	choosenewexistingstepheader: "סביבת עבודה חדשה או קיימת",
	editengagementwizheader: "עריכת התקשרות",
	removeengagementwizheader: "הסרת תיק הביקורת",
	editworkspacewizheader: "עריכת סביבת עבודה",
	editworkspacestepfooter: "לחץ על 'שמור וסגור' בכדי לשמור שינויים בסביבת העבודה או לחץ על 'שמור והמשך' בכדי להמשיך לערוך.",
	editwizheader: "ערוך",
	newworkspace: "סביבת עבודה חדשה",
	existingworkspace: "סביבת עבודה קיימת",
	back: "חזור",
	continue: "המשך",
	saveclose: "שמור וסגור",
	savecontinue: "שמור והמשך",
	finish: "סיים",
	step01: "שלב 01",
	step02: "שלב 02",
	step03: "שלב 03",
	step04: "שלב 04",
	step05: "שלב 05",
	engagementname: "שם ההתקשרות",
	confirmdecline: "אשר דחייה. לא ניתן לבטל פעולה זו.",
	confirmdeclinevalidation: "אשר כי ברצונך לדחות.",
	responderror: "כבר הגבת לזימון זה, רענן את הדף ונסה שנית.",
	engagementcode: "קוד התקשרות",
	engagementcodename: "שם קוד ההתקשרות",
	selectEngagementHeader: "בחר סביבת עבודה קיימת",

	selectworkspaceclient: "בחר לקוח",
	selectclient: "בחר לקוח כדי להציג סביבות עבודה קשורות",
	createnewworkspace: "צור סביבת עבודה חדשה",
	editworkspace: "עריכת סביבת עבודה",
	clientcode: "קוד לקוח",
	clientname: "שם הלקוח",
	invalidclientcode: "קוד לקוח לא חוקי",
	workspacename: 'שם סביבת העבודה',
	location: "אתר ",
	workspacecodenameplaceholder: "הזן קוד לקוח כדי לאתר את שם הלקוח",
	engagementcodenameplaceholder: "הזן קוד התקשרות כדי לאתר את שם הקוד",
	isprimary: "צוות ראשי",
	addengagementcode: "הוסף קוד התקשרות",
	engagementnamerequired: "נדרש לציין את שם ההתקשרות",
	primaryofficerequired: "נדרש לציין את המשרד הראשי",
	languagerequired: "נדרש לציין את השפה",
	servicelinerequired: "נדרש לציין את שם המחלקה (SL)",
	subservicelinerequired: "נדרש לציין את שם תת-המחלקה (SSL)",
	engagementcodevalidationmessage: "קוד התקשרות אינו תואם לקוד הלקוח",
	engagementcoderequired: "נדרש קוד התקשרות",
	yearenddate: "תאריך סיום שנה",
	periodenddate: "תאריך סיום התקופה",
	reportdate: "תאריך הדוח",
	complete: "השלים",
	end: "סוף",
	started: "התחלה",
	notstarted: "טרם החל",
	inprogress: "בתהליך",
	inreview: "בסקירה",
	taskstatus: "סטטוס המשימה",
	confirmlabel: "אשר",
	submitlabel: "שלח",
	editengagementquestionlabel: "עריכת התקשרות",
	removeengagementquestionlabel: "הסרת תיק הביקורת",
	removeengaementconfirmationmessage1: "האם אתה בטוח שברצונך להסיר את ההתקשרות הבאה מרשימת תיקי הביקורת שלך? אם תסיר את תיק הביקורת כאמור לא תהיה לך עוד גישה אליו. ",
	removeengagementerrormessage: "אין אפשרות להסיר את תיק הביקורת מסביבת העבודה כיוון שאתה המשתמש הפעיל האחרון. הוסף חבר צוות אחר או מחק את תיק הביקורת. ",
	deleteengagement: "מחק התקשרות",
	deletreviewcopyoption: "מחק",
	deleteengagementconfirmationmessage: "האם אתה בטוח שברצונך להגיש בקשה למחיקת תיק הביקורת ?",
	deleteengagementconfirmationmessage1: "לגבי התקשרויות שהסתיימו או כאלה שבהן איננו מוציאים דוח, צוותי הביקורת צריכים להתייעץ עם המחלקה המקצועית בכדי לקבוע אם נדרש ארכיב.",
	deleteengagementconfirmationmessageoneuser: "האם אתה בטוח שברצונך למחוק את תיק הביקורת?",
	deleteengagementconfirmationmessageoneuser1: "דרישות הארכיב ב",
	deleteengagementconfirmationmessageoneuser2: "DOC+ARC 3.1",
	deleteengagementconfirmationmessageoneuser3: " יש לשקול לפני המחיקה. לדוגמה, לגבי התקשרויות שהסתיימו או כאלה שבהן איננו מוציאים דוח, צוותי הביקורת צריכים להתייעץ עם המחלקה המקצועית בכדי לקבוע אם נדרש ארכיב.",
	deleteengagementmarkeddelete: "תיק הביקורת סומן למחיקה",
	deleteengagementoption: "מחק,",
	deleteengagementreasonlabel: "הזן את סיבת המחיקה",
	deleteengagementreasonquestion: "מדוע ההתקשרות הזו נמחקת?",
	deleteengagementreasonrequired: "נדרש הסבר. בחר אפשרות או הזן סיבה למעלה.",
	deleteengagementcommentreasonrequired: "נדרש סיבה. בחר סיבה למעלה.",
	deleteengagementcommentrequiredlabel: "הזן סיבה למחיקה",
	deletereasontrainingengagementlabel: "סביבת העבודה היא לצרכי תרגול",
	deletereasonrestoredorcopiedlabel: "תיק הביקורת משוחזר או מועתק לצורך עיון בלבד",
	deletereasoncreatedbymistakelabel: "נוצר בטעות",
	deletereasonotherlabel: "אחרים",
	deletereasonterminedbyclientlabel: "הופסקה התקשרות כאשר לא בוצעה עבודה. אם בוצעה עבודה בהתקשרות זו, יש לאחסן אותה בארכיון.",
	markeddeleteconfirm: "אשר מחיקה",
	deleterequestbannertext1: "בקשת מחיקה ",
	deleterequestbannertext2: "לפני",
	markeddeleteconfirmmessage: "האם אתה בטוח שברצונך למחוק את תיק הביקורת?",
	markeddeleteconfirmmessage1: "אם תאשר את המחיקה, לא ניתן יהיה לבטל פעולה זו.",
	markeddeletereasonlabel: "סיבה למחיקה",
	engagementlistplaceholder: "בחירת התקשרות",
	engagementrequired: "התקשרות נדרשת",
	restoreconfirmationstepheader: "אישור",
	restoreconfirmationheader: "האם אתה בטוח שברצונך לשחזר את ההתקשרות?",
	selectarchiveheader: "בחר קובץ מהארכיב",
	restoreengagementstepheader: "שחזור תיק הביקורת",
	chooserollfwdhdr: "גלגל קדימה (rollforward) את ההתקשרות לסביבת עבודה חדשה או קיימת",
	rollfwdstephdrext: "גלגל קדימה (rollforward) את ההתקשרות לסביבת עבודה קיימת",
	rollfwdstephdrnew: "גלגל קדימה (rollforward) את ההתקשרות לסביבת עבודה חדשה",
	rollfwdhdrqstn: "האם לגלגל קדימה (rollforward) את ההתקשרות לסביבת עבודה חדשה או קיימת?",
	rollforwardengagementstepheader: "גלגול לשנה הבאה של תיק הביקורת",
	genericerror1: "משהו השתבש, אנא נסה שוב או צור קשר עם צוות התמיכה",
	restoreengagementmaxcopies: "ההתקשרות חרגה מהמספר המירבי של עותקים משוחזרים ({0}). בקש גישה לעותק קיים.",
	restoreengagementengcopies: "ישנם {0} עותקים משוחזרים זמינים של תיק הביקורת:",
	restoreengagementengcopy: "יש {0} העתק משוחזר זמין של תיק הביקורת:",
	restoreengagementengnocopies: "אין עותקים משוחזרים אחרים זמינים של תיק הביקורת.",
	globalexceptionrestoreengagementengcopies: "ישנם {0} עותקים משוחזרים זמינים של התקשרות זו. סמן (Check) את התיבה למעלה כדי לשחזר בחריגה ממדיניות הגלובל (global exception).",
	globalexception: "בחריגה ממדיניות הגלובל (Global exception).",
	globalexceptioncheckerror: "בדוק את האפשרות לחרוג ממדיניות הגלובל (global exception) כדי להמשיך.",
	restoreengagementrestoredon: "שוחזר ב",
	restoreengagementusers: "משתמשים",
	restoreengagementuser: "המשתמש",
	at: "ב",
	arcfileuserunavailable: "המשתמש אינו זמין",

	restorehdrqstn: "האם לשחזר את תיק הביקורת לסביבת עבודה חדשה או קיימת?",
	chooserestorehdr: "שחזור סביבת עבודה חדשה או קיימת",
	restorestephdrext: "שחזור סביבת עבודה קיימת",
	restorestephdrnew: "שחזור סביבת עבודה חדשה",

	close: "Close",
	confirm: "אשר",
	settings: "הגדרות",
	expand: "הרחב",
	collapse: "צמצם",
	contextMenuEdit: "ערוך",
	contextMenuEditEngagement: "עריכת התקשרות",
	contextMenuRemoveEngagement: "הסרת תיק הביקורת",

	contextMenuDeleteEngagement: "מחק התקשרות",
	contextMenuEditWorkspace: "עריכת סביבת עבודה",
	contextMenuDelete: "מחק,",
	contextMenuOverrideArchiveDate: "שנה את תאריך היעד של הארכיב",
	na: "לא רלוונטי",
	archivesubmittedby: "נשלח על ידי",
	archivedateandtime: "תאריך ושעה",
	notifications: "הודעות",
	nonotificationmsg: "אין לך הודעות.",
	notifcenter: "מרכז ההודעות",
	notifitemspendingact: "פריטים הממתינים לפעולה",
	accept: "קבל,",
	reject: "דחה,",
	acceptall: "קבל הכל",
	rejectall: "דחה הכל",
	notifEngInvite: "מוזמנים לתיק הביקורת",
	notifengaccesreq: "בקשת גישה לתיק הביקורת",
	keyevents: "אירועי מפתח",
	milestones: "סיכום CRA,",
	importselecttemplateheader: "אנא בחר את התבנית שבה ברצונך להשתמש",
	importtemplateheader: "אנא בחר את האזור שברצונך לייבא ממנו",
	import: "ייבוא",
	importregionstepheader: "בחירת אזור",
	importtemplatestepheader: "בחר תבנית",
	importtemplatemsg: "בחר אזור להמשך",
	importselecttemplatemsg: "בחר תבנית כדי להמשיך",
	importengagementheader: "ייבא תבנית",
	importhdrqstn: "יבא אל סביבת עבודה חדשה או קיימת ",
	importstephdrext: "יבא אל סביבת עבודה קיימת",
	importstephdrnew: "יבא אל סביבת עבודה חדשה",
	chooseimporthdr: "יבא לסביבת עבודה חדשה או קיימת",
	selectexistingworkspacemsg: "בחר סביבת עבודה כדי להמשיך",
	selectaclienttocontinuemsg: "להמשך, בחר לקוח",
	selectengagementmsg: "בחר תיק ביקורת כדי לקבל את חבילת הדיווח",
	selectgaengagementmsg: "עלייך לבחור התקשרות בכדי לאשר את בקשת הקישור.",
	arearegion: "Area/Region",
	importdatapackagequestionlabel: "מה ברצונך לעשות?",
	sentby: "נשלח על-ידי",
	on: "על",
	by: "על ידי",
	view: "הצג",
	sourceengagement: "מקור תיק הביקורת",
	package: "חבילת דיווח",
	decline: "לדחות",
	delegate: "העבר משימה",
	chooseaction: "בחר פעולה",
	selectworkspace: "בחר סביבת עבודה",
	declinepackage: "הבקשה נדחתה",
	delegatepackage: "העבר חבילת דיווח",
	selectengagement: "בחר תיק ביקורת",
	datapackagerequest: "בקשה לחבילת דיווח",
	respond: "לחץ כאן כדי להגיב",
	deletionrequestfor: "התקבלה בקשת מחיקה עבור",
	invalidengagementcode: "קוד תיק הביקורת לא חוקי",
	noNotifications: "אין הודעות זמינות",
	bellIconTitle: "מרכז ההודעות",
	restoredengagement: "תיק הביקורת שוחזר",
	profiledeliveryinprogress: "הצגת פרופיל בתהליך",
	completeprofileheader: "השלם פרופיל התקשרות",
	completeindependenceheader: "השלם נהלי אי תלות",
	independencecompletedheader: "בחינת אי התלות הושלמה",
	retry: "הבקשה נכשלה. נסה לטעון שוב את הדף.",
	errorDate: "שגיאה",
	invitationDate: "תאריך ההזמנה",
	engCreationFailedWarning: "יצירת ההתקשרות נכשלה. רענן את הדף ונסה שנית. במידה והבעיה נמשכת, פנה לתמיכה הטכנית. ",
	deletedEngagementWarning: "אם ההתקשרות הייתה בשימוש ונמחקה בטעות, פנה לתמיכה הטכנית באופן מיידי.",
	searchEngagementWarning: "חפש אחר תיקי ביקורת אלה בכדי לנקוט פעולה נוספת.",
	archiveOverrideWarning: "שינוי תקופת הארכיב אינה מותרת על ידי ה ARC. תאריך היעד של הארכיון הוחזר לערך ברירת המחדל עבור המיקום שלך.",
	archiveOverrideInProgress: "שינוי תאריך היעד של הארכיב כפוף לאישור ה- ARC",
	archiveOverrideAccepted: "שינוי תאריך היעד של הארכיב הושלם",
	archiveOverrideRejected: "שינוי תקופת הארכיב אינה מותרת על ידי ה ARC. תאריך היעד של הארכיון הוחזר לערך ברירת המחדל עבור האתר שלך.",
	scopeName: "שם הסקופ (Scope Name)",
	shortName: "שם קצר",
	invited: "הוזמן",
	invitedOn: "הוזמן ל",
	instructions: "הוראות",
	updates: "עדכונים",
	viewinstructions: "הצג הנחיות",
	viewinstructionsupdates: "הצג עדכונים",
	more: "עוד,",
	addmembers: "הוסף חברים",
	statusChangeDate: "הסטטוס השתנה",
	deletedBy: "נמחק על-ידי ",
	deletedDate: "נמחק",
	deactivatedBy: "בוטלה על-ידי",
	deactivatedDate: "Deactivated",
	requestUserName: "התבקש על-ידי",
	requestDate: "התבקש",
	archiveDate: "אורכב",
	receivedFrom: "התקבל מ",
	received: "התקבל",
	clear: "Clear",
	clearAll: "נקה הכל",
	type: "סוג",
	engagementhasbeendeleted: "ההתקשרות שנבחרה נמחקה על-ידי משתמש אחר. רענן את הדף ונסה שוב.",
	independenceOnlyRejectInvite: {
		engagementIsNotAvailable: "תיק הביקורת אינו זמין. רענן את הדף ונסה שוב. אם הבעיה נמשכת, צור קשר עם התמיכה הטכנית.",
		failedToRejectInvite: "דחיית ההזמנה נכשלה. רענן את הדף ונסה שוב. אם הבעיה נמשכת, צור קשר עם התמיכה הטכנית.",
		succesfulRejection: "ההזמנה נדחתה בהצלחה.",
		loadingLabel: "דוחה הזמנה...",
	},
	manageTeam: "ניהול צוות",
	childinprogress: "הקמת תיק הביקורת מתבצעת",
	// SeeMore component
	showMore: "הצג עוד",
	showLess: "הצג פחות",
	showMoreEllipsis: "הצג עוד ...",
	showLessEllipsis: "הצג פחות ...",
	accepted: "התקבל",
	acceptedOn: "התקבל ב:",
	rejected: "נדחה,",
	deniedBy: "נדחה על-ידי ",
	engagementstatusrestored: "שוחזר",
	engagementstatusmarkedfordeletion: "מסומן למחיקה",
	delegaterequired: "נדרשת הקצאה",
	username: "שם משתמש",
	noresults: "לא נמצאו תוצאות, אנא נסה שנית",
	importdatapackageconcurrencymessage: "סטטוס הבקשה להעתקה השתנה. רענן את הדף ונסה שנית. ",
	selectdelegateuser: "בחר למי להקצות את המשימה",
	eyusersearchplaceholder: "הזן שם או דואר אלקטרוני לחיפוש",
	optional: "(אופציונלי)",
	engagementAlerts: "התראות תיק הביקורת",
	engagementMetrics: "מדדי תיק הביקורת",
	overdue: "תאריך היעד חלף,",
	/*day labels*/
	mon: "שני",
	tues: "שלישי",
	wed: "רביעי",
	thurs: "חמישי",
	fri: "שישי",
	sat: "שבת",
	sun: "ראשון",
	canvas: 'Canvas',
	overrideArchiveDateModalHeader: "שנה את תאריך היעד של הארכיב",
	overrideArchiveDateModalMessage: "האם אתה בטוח שברצונך לשנות את תאריך היעד של הארכיב?",
	overrideArchiveDateModalComboPlaceholder: "שנה את תאריך היעד של הארכיב",
	createdOn: "נוצר ב",
	expiresOn: "בתוקף עד",
	archiveDeadlineDate: "תאריך יעד לארכיב",
	defaultOverriden: "שינוי ברירת המחדל",
	selectexistingworkspace: "בחר סביבת עבודה קיימת",
	importtonewworkspace: "יבא אל סביבת עבודה חדשה",
	groupaudit: "בקשת קישור לביקורת הקבוצה",
	gaacceptdeclinedelegatequestionlabel: "מה אתה רוצה לעשות עם בקשת הקישור?",
	gaacceptselectengagementquestionlabel: "בחר התקשרות שברצונך לקשר",
	gadelegatequestionlabel: "העבר את בקשת הקישור לביקורת הקבוצה",
	gadeclinequestionlabel: "דחה בקשת קישור לביקורת קבוצה",
	garegioncomponent: "האזור / הישות המדווחת (component)",
	gascope: "היקף",
	gamxTransfer: "העבר מה- GAMx",
	closeNotificationCenter: "סגור את מרכז ההודעות",
	contextMenucrp: "צור עותק לסקירה",
	workingoffline: "עבודה במצב לא מקוון",
	trainingoffline: "עבודה במצב לא מקוון (תרגול)",
	training: "תרגול",
	crpdescription: "צור עותק של ההתקשרות למבקרים חיצוניים.",
	crpeditdescritpion: "ערוך את שם תיק הביקורת או את תפוגתו. תיקי הביקורת שפג תוקפם יימחקו באופן אוטומטי.",
	expiry: "תוקף",
	enteremail: 'הזן כתובת דוא"ל',
    create: "צור",
	metricsPlaceholderText: "אין נתונים זמינים עבור מסגרת הזמן שנבחרה",
	deleteReviewCopy: "מחק את עותק הסקירה",
	deleteReviewCopyMsg: "האם הינך בטוח שברצונך למחוק עותק סקירה זה? לאחר המחיקה, תיק הביקורת כבר לא יהיה זמין ולא ניתן יהיה לשחזר אותו.",
	crpNotAvailable: "לא ניתן ליצור עותק סקירה לתיק ביקורת זה, הואיל ופורטל הגישה החיצונית של Canvas אינו זמין בשלב זה. לקבלת מידע נוסף, פנה לתמיכה הטכנית.",
	externalmember: "חברי צוות חיצוניים",
	internalmember: "חברי צוות פנימיים",
	add: "הוסף",
	crpEditMembers: "ערוך",
	invalidEmail: 'הזן כתובת דוא"ל חוקית',
	emailNotFound: 'כתובות הדוא"ל לא נמצאו',
    dupExternalUser: "חבר צוות חיצוני כבר נוסף",
	noExternalUser: "לא נוספו חברי צוות חיצוניים",
	expiresIn: "פג תוקף",
	days: "ימים",
	today: "היום,",
	tomorrow: "מחר",
	reviewengagementname: "סקירת שם תיק הביקורת",
	enternameoremail: 'הזן שם או כתובת דוא"ל',
    options: "אפשרויות",
	userNotFound: "המשתמש לא נמצא",
	remove: "הסר",
	crpEditHeader: "ערוך עותק סקירה",
	globalexceptionrestore: "Global Exception Restore",
	expired: "פג תוקף",
	softwareUpdate: "עדכוני תוכנה",
	updateNow: "עדכן כעת",
	updateMsg: "עדכוני תוכנה זמינים עבור EY Canvas. בחר באפשרות עדכן כעת כדי להוריד ולהתקין את העדכונים. הדף יתרענן לאחד מכן.",
	preferredlandingpageview: "תצוגה מועדפת של דף הבית (landing page)",
	preferredlandingpagefilter: "סינון (filter) מועדף של דף הבית (landing page)",
	engagementlist: "רשימת תיקי ביקורת",
	workspacelistusersettings: "רשימת סביבות העבודה",
	engagementsclientstoload: "מספר תיקי הביקורת / לקוחות שיש לטעון בכל כניסה ליישום",
	favoriteengagements: "תיקי ביקורת מועדפים",
	preferredEngagementDashboard: "הגדרות לוח מחוונים של מעורבות",
	preferredEngagementDashboardTeamMembers: "חברי הצוות",
	preferredEngagementDashboardExecutives: "בכירים",
	linkedHelixProjects: "מקושר ל- EY Helix",
	loadingLabel: "טוען ...",
	loadingErrorText: "אירעה שגיאה בטעינת ציר הזמן. אנא רענן את הדף ונסה שנית.",
	selectYear: "בחר שנה (חובה)",
	clientNameOrId: "שם או מזהה לקוח",
	engagementNameOrId: "שם או מזהה תיק הביקורת",
	engagementCodeOrCodeName: "קוד ההתקשרות או שם הקוד",
	searchArchives: "חפש",
	clearSearchArchives: "נקה חיפוש",
	searchArchivesMessage: "בכדי להתחיל, בחר את השנה שבה תיק הביקורת הועבר לארכיב. לאחר מכן, חפש לפי אחת או יותר מהאפשרויות המוצגות להלן:",
	titleUpdateProfile: "מידע",
	msgUpdateProfileLn1: "עדכנת את שפת תיק הביקורת. כדי לקבל תוכן בשפה זו, עבור אל עדכוני תוכן של תיק הביקורת ובחר עדכון תוכן כללי.",
	sessionTimerMsg1: "בגלל חוסר פעילות, משך זמן העבודה הנוכחי שלך עומד להסתיים.",
	sessionTimerMsg2: "למען שמירה על אבטחת המידע, משך זמן העבודה ב- EY Canvas מסתיים אוטומטית לאחר 30 דקות של חוסר פעילות.",
	sessionTimerMsg3: "משך זמן העבודה הנוכחי שלך יפוג בעוד {0} דקות. ",
	sessionTimerMsg4: "אם ברצונך להישאר מחובר, אנא לחץ על",
	sessionTimerHeader: "התנתקות אוטומטית להגנת המידע",
	theme: "נושא",
	light: "בהיר",
	dark: "כהה",
	chooseDisplayTheme: "בחר ערכת נושא לתצוגה כברירת מחדל.",
	searchByCountry: "בחר מדינה",
	primaryEngagementLabel: "תיק ביקורת ראשי",
	componentEngagementLabel: "תיק ביקורת ישות מדווחת",
	regionalEngagementLabel: "תיק ביקורת אזורי (Regional)",
	linkedLabel: "מקושר",
	eyCanvasEconomicsTooltip: "כלכלת EY Canvas",
	eyOversightTooltip: "פיקוח על קנבס EY",
	eyPrivacyNoticeTooltip: "הודעת פרטיות",
	eyTermsOfServiceTooltip: "תנאי השירות",
	eyGeneralHelp: "עזרה כללית",

	preferrednegativenumberformat: "פורמט מועדף להצגת מספר שלילי",

	protectedEngagementMessage: "מנע הדפסה, העתקה או שכפול אחר של מסמכים",
	documentRrestriction: "הגבלת מסמכים:",
	on: "מופעל",
	off: "כבוי",
	protectedEngagementMessageOn: "- לא ניתן להדפיס, להעתיק או לשכפל מסמכים בהתקשרות זו",
	protectedEngagementMessageOff: "- ניתן להדפיס, להעתיק או לשכפל מסמכים בהתקשרות זו",

	/* Dashboard labels*/
	homeLabel: "בית",
	manageAccessHeader: "ניהול גישה",
	sharepointWorkspacesHeader: "סביבות עבודה של SharePoint",
	showInactiveUserslabel: "הצג משתמשים לא פעילים",
	showExpiredMessageslabel: "הצג הודעות שפג תוקפן",
	addUserButtonLabel: "הוסף משתמש",
	searchUserText: "חפש",
	nameHeader: "שם",
	dataCenterNameHeader: "שם מרכז הנתונים (data center)",
	numberOfWorkSpacesHeader: "מספר סביבות העבודה שהוקצו",
	workspaceName: "שם סביבת העבודה",
	documentCount: "ספירת מסמכים",
	percentCapacity: "אחוז קיבולת",
	emailHeader: "דואל",
	actionHeader: "פעולה",
	manageRolesLabel: "ניהול תפקידים",
	deactivateUserlabel: "השבת את המשתמש",
	manageRolesTableModule: "מודול",
	manageRolesTableRoles: "תפקידים",
	manageRolesTableServiceLine: "מחלקה",
	manageRolesTableCountries: "מדינות",
	manageAccessAssignRole: "הקצה תפקיד",
	manageAccessNoRolesAdded: "לא נוספו תפקידים",
	manageAccessDeleteRoleModalHeader: "מחק תפקיד",
	manageAccessDeleteRoleButton: "מחק",
	noRoleResults: "אין תוצאות",
	confirmAssignRoleButton: "Assign,",
	permissionGroupName: "שם הקבוצה",
	permissionGroupDescription: "תיאור קבוצה",
	activeMembers: "חברים פעילים",
	permissionGroupMembersTitle: "כותרת",
	permissionGroupMembersPhone: "טלפון",
	permissionGroupMembersGui: "GUI",
	permissionGroupMembersUserId: "מזהה משתמש",
	permissionGroupMembersAdStatus: "סטטוס AD",
	permissionGroupAddMember: "הוסף חבר",
	permissionGroupUserDeleteLabel: "מחק",
	permissionGroupUserDeleteHeader: "מחק חבר",
	permissionGroupDetails: "פרטי קבוצות ההרשאות ",
	cache: "מפתח מטמון (cache key)",
	cacheSelection: "בחר מיקום מטמון (cache)",
	engagementID: "מזהה תיק הביקורת",
	cacheKey: "מפתח מטמון (cache key)",
	validationMessage: "שדה חובה",
	getCache: "קבל מטמון (Get cache)",
	clearResult: "נקה תוצאות",
	removeCache: "הסר מטמון (cache)",
	requiredField: "שדה חובה",
	ticketNumber: "מספר קריאה (חובה)",
	confirmationMessage: "נא להזין מספר קריאה עבור פעולה זו",
	confirmButtonLabel: "אשר",
	cancelButtonLabel: "בטל",
	modalHeaderLabel: "הסר מטמון (cache)",
	adminModuleinvalidEngagementId: "מזהה תיק הביקורת לא חוקי",
	adminModuleInvalidCacheKey: "מפתח המטמון (Cache key) מכיל תווים לא חוקיים",
	clearResults: "נקה תוצאות",
	getDocuments: "קבל מסמכים",
	documentsHeader: "מסמכים",
	validTicketNumber: "נא להזין מספר קריאה תקף",

	egagementsPageDescription: "חפש התקשרויות בהתבסס על קריטריון אחד או יותר בכדי לעדכן את נתוני ההתקשרות להזמין חברי צוות חדשים לעדכן את הסטטוס וההרשאות של חברי הצוות הקיימים.",
	engagementSearchCriteria: "מזהה מדינה או מזהה תיק ביקורת נדרשים לצורך ביצוע החיפוש",
	engagementsHeader: "תיק ביקורת",
	engagementIdSearchPlaceholder: "מזהה תיק הביקורת",
	engagementNameSearchPlaceholder: "שם ההתקשרות",
	workSpaceSearchPlaceholder: "שם או מזהה של סביבת העבודה",
	clientSearchPlaceholder: "שם לקוח או קוד",
	engagementCodeSearchPlaceholder: "קוד ההתקשרות או שם הקוד",
	userSearchPlaceholder: "ממשק משתמש, אימייל או UserID",
	countrySearchPlaceholder: "מדינה",
	engagementTableHeader: "תיק ביקורת",
	nameTableHeader: "שם",
	workspaceIdTableHeader: "סביבת עבודה",
	clientTableHeader: "לקוח",
	engagementCodeTableHeader: "קוד התקשרות",
	yearEndTableHeader: "סוף שנה",
	engagementdeleteDate: "תאריך מחיקת ההתקשרות",
	archivalguid: "GUID לארכיון",
	archivalLogId: "מספר לוג של הארכיב",
	archivalFileguid: "פרטי תיק ארכיב GUID",
	profile: "הפרופיל הושלם",
	independence: "אי תלות",
	archiveRetry: "ניסיון חוזר לארכב",
	engagementdetails: "פרטי התקשרות",
	engagementsdetails: "פרטי התקשרות",
	statusTableHeader: "סטטוס",
	attributesTableHeader: "מאפיינים",
	attributesProfileComplete: "הפרופיל הושלם",
	attributesIndpendenceApplicable: "יישום אי תלות",
	engagementPagePlaceholder: "חפש התקשרויות למעלה התוצאות יוצגו כאן",
	noEngagementFound: "לא נמצאה התקשרות",
	emptySearchFieldError: "לפחות שדה חיפוש אחד צריך להיות בעל ערך.",
	largeCountrySelectionError: "אנא בחר 10 מדינות או פחות.",
	saveEngagement: "שמור",
	undoEditEngagement: "אתחול",
	engagementUpdateSuccess: "השינויים נשמרו בהצלחה",
	archiveRetrySuccessMessage: "הארכיב הופעל בהצלחה עבור התקשרות זו. אנא בדוק את הסטטוס מאוחר יותר כדי לאמת אם הארכיב הצליח.",
	welcome: "ברוך הבא ל",
	welcomeadmin: "כלי ניהול אדמין",
	engagementCard: "תיקי הביקורת",
	messagingCard: "הודעות",
	templatesCard: "טמפלייטים",
	permissionGroupCard: "קבוצות הרשאות",
	manageAccessCard: "ניהול גישה",
	sharepointWorkspacesCard: "סביבות עבודה של SharePoint",
	databaseLocatorCard: "סנכרון מסד נתונים",
	importForReviewTooltip: "סביבת העבודה ב- Canvas תעבור אופטימיזציה לבדיקה וחלק מהתכונות והפונקציות לא יהיו זמינות.",
	importForReviewChecboxText: "ייבוא ​​למטרות סקירה בלבד",
	clientCode: "קוד לקוח",
	primaryEngagementCode: "קוד מעורבות ראשי",
	invalidEngagementTitle: "קוד לקוח או התקשרות לא חוקי",
	invalidEngagementMessage: "יש לאמת את קוד הלקוח ו/או קוד ההתקשרות לפני שתוכל לגשת למעורבות זו. לחץ על המשך כדי לעדכן ולאמת את הקודים.",
	insufficientPermission: "אין מספיק הרשאות עבור מודול זה",
	noPermission: "אין רשות",
	noAdminRights: "אין לך זכויות לכלי זה.",

	//Engagement Users Lables
	engmtUserTableUserNameHeader: "שם משתמש",
	engmtUserTableEmailAddressHeader: 'כתובת הדוא"ל',
    engmtUserTableRoleHeader: "Role",
	engmtUserTableGUIeHeader: "משתמש",
	engmtUserTableStatuseHeader: "סטטוס",
	engmtUserTableIndependenceStatuseHeader: "אי תלות",
	engmtUserTableConfidentialityHeader: "סודיות",
	engmtUserTableADStatusHeader: "סטטוס AD",
	engmtUserTablePermissionsHeader: "הרשאות",
	engmtUserTableActionHeader: "פעולה",
	engagementUsersTitle: "משתמשי מעורבות",
	engagementInviteMember: "הזמן חבר צוות",
	engagementInviteUser: "הזמן משתמש",
	engagementRoles: "Role,",
	engagementRolesRequired: "נדרש תפקיד בתיק הביקורת",
	engagementInviteMemberSuccessMessage: "חבר צוות הוזמן לתיק הביקורת בהצלחה",
	engagementInviteMemberDescription: 'חפש משתמשים לפי שם פרטי, שם משפחה או כתובת דוא"ל למעלה.התוצאות יוצגו כאן.',
    engagementUsersTitleEdit: "ערוך",
	engagementUpdatePermissionToolTip: "אין לך מספיק הרשאות לעדכן את המעורבות.",
	engagementRetryArchivePermissionToolTip: "אין לך הרשאות מספיקות כדי לנסות שוב לארכב",
	usersEditPermissionToolTip: "אין לך הרשאה לבצע פעולה זו. צור קשר עם מנהל המערכת כדי לבקש רשות.",
	usersPermissionToolTip: "אין לך הרשאות מספיקות לעדכן את משתמש המעורבות.",
	invalidArchivalGuidMessage: "GUID לא חוקי של הארכיב",
	invalidArchivalFileGuidMessage: "GUID לא חוקי של קובץ הארכיב",
	invalidArchivalLogId: "מזהה לוג לא חוקי לארכיב",
	datePickerLabel: "בחר תאריך",
	updateEngagement: "עדכן את תיק הביקורת",

	invalidEngagementMessage: "יש לאמת את קוד הלקוח ו/או קוד ההתקשרות לפני שתוכל לגשת למעורבות זו. לחץ על המשך כדי לעדכן ולאמת את הקודים.",
	invalidEngagementId: "מזהה ההתקשרות אינו חוקי",
	serverErrorMessage: "שגיאת שרת תחזור מאוחר יותר",
	engagementDetailsNoPermission: "אין לך הרשאה מספקת כדי להציג את פרטי ההתקשרות.",
	engagementNotFound: "לא נמצאו פרטי התקשרות",
	pageNotFound: "הדף לא נמצא",
	assignRoleModalMessage: "בחר מודול ופונקציה ספציפית שתופעל עבור המשתמש",
	selectModule: "בחר מודול",
	selectRole: "בחר תפקיד",
	serviceLine: "מחלקה",
	countries: "מדינות",
	confirmAssignRoleButton: "Assign,",
	addUser: "הוסף משתמש",
	addAdminUserHeader: "הזן שם פרטי שם משפחה או כתובת דואר אלקטרוני של המשתמש ולחץ על Enter כדי לחפש",
	addMemberDescription: 'חפש משתמשים לפי שם פרטי שם משפחה או כתובת דוא"ל למעלה.התוצאות יוצגו כאן.',
    seachInputRequired: "חפש קלט שנדרש",
	searchUsers: "חיפוש משתמשים",
	noResultsFound: "לא נמצאו תוצאות",
	usersToBeAdded: "יש להוסיף משתמשים",
	addUserSuccessMessage: "משתמש אדמין נוסף בהצלחה.",
	addMemberSuccessMessage: "חבר נוסף לקבוצה בהצלחה",
	deactivateWarning: "האם אתה בטוח שברצונך להשבית את המשתמש?",
	deactivateButton: "השבת (deactivate)",
	deactivateModalTitle: "השבת את המשתמש",
	generateReport: 'הפקת דו"ח',
    generateValidationReport: "הפק דוח אימות (Validation Report)",

	templateManagement: "טמפלייטים",
	templateName: "שם התבנית",
	templateAreaRegion: "Area/Region",
	templateSize: "גודל",
	templateCreatedBy: "נוצר על ידי",
	templateCreatedDate: "תאריך יצירה",
	templateCountOfEngagement: "תיקי הביקורת",
	templateStatus: "סטטוס",
	templatePublished: "יצא לאור",
	templateUnpublished: "לא פורסם",
	templateSearchText: "הזן טקסט חיפוש והקש Enter",
	templateInsufficientPermissions: "אין מספיק הרשאות למודול זה",
	templateMultipleArea: "Multiple,",
	templateEdit: "ערוך תבנית",
	templateEditSave: "שמור,",
	templateEditCancel: "בטל,",
	templateEditSuccessMessage: "התבנית עודכנה בהצלחה.",
	templateDeleteConfirmationMessage: "האם אתה בטוח שברצונך למחוק את התבנית?",
	templateDelete: "מחק תבנית",
	templateEditDelete: "מחק",

	messagingMessageTitle: "כותרת ההודעה",
	messagingDescriptionTitle: "תיאור ההודעה",
	messagingStartsOn: "מתחיל ב (UTC)",
	messagingEndsOn: "מסתיים ב (UTC)",
	messagingAffectedDataCenters: "מרכזי הנתונים שהושפעו",
	messagingStatus: "סטטוס",
	messagingCreatedBy: "נוצר על ידי",
	messagingCreatedDate: "תאריך יצירה",
	messagingDataCenterSystemWide: "מערכת רחבה",
	messagingDataCenterMultiple: "Multiple",
	messagingDataCenterSingle: "יחיד",
	messageName: "כותרת ההודעה",
	messageStartsOn: "מתחיל ב",
	messageEndsOn: "מסתיים ב",
	messageDateTime: "בחר תאריך ושעה",
	messageAllTimeInUTC: "כל הזמנים הם ב-UTC",
	messageTargetAudience: "קהל יעד",
	messageDataCenters: "מרכזי נתונים",
	messageSelectADate: "בחר תאריך",
	messageAllDataCenters: "כל מרכזי הנתונים",
	messageKendoDescription: "שימוש בעיצוב יקטין את אורך התיאור המרבי ל- 4,000 תווים",
	messageSelectDataCenter: "בחר מרכז נתונים",
	messageFieldRequired: "שדה חובה",
	messageStartTimeError: "תאריך ושעה התחלה לא יכולים להיות פחות מהשעה הנוכחית",
	messageEndTimeError1: "תאריך ושעה סיום לא אמורים להיות לפני התאריך והשעה הנוכחיים",
	messageEndTimeError2: "תאריך ושעה סיום אמורים להיות גדולים מתאריך ושעה התחלה",
	messageDataCenterError: "בחר לפחות מרכז נתונים אחד",
	messageSubmitValidationError: "חסרים שדות חובה. אנא גלול ווודא כי כל השדות הנדרשים מולאו.",
	messageChangesValidationError: "לא בוצעו שינויים. ערוך לפחות שדה אחד כדי לשמור.",
	currentutc: "תאריך ושעה UTC נוכחיים:",
	messageCurrentUTCTime: " {0} (בפורמט של 24 שעות)",
	createMessage: "צור הודעה",
	createMessagePermissions: "אין מספיק הרשאות עבור מודול זה",
	createMessageSuccess: "ההודעה נוצרה בהצלחה",
	editMessage: "ערוך הודעה",
	editMessageSuccess: "ההודעה נערכה בהצלחה",
	editHoverText: "ערוך",

	messagingModalDeleteTitle: "למחוק הודעה",
	messagingModalDeleteMessage: "האם אתה בטוח שברצונך למחוק את ההודעה?",
	messagingModalDeleteButton: "מחק",
	messagingModalDeleteConfirm: "אשר",
	messagingModalDeleteCancel: "בטל",
	messagingModalDeletedSuccessfully: "ההודעה נמחקה בהצלחה",

	// databaselocator
	databaseLocatorSuccessMessage: "סנכרון מאתר מסד הנתונים הוצב בתור",

	// documents module
	docListHeaderDocId: "מזהה מסמך",
	docListHeaderDocVersion: "גִרְסָה",
	docListHeaderDocName: "שם המסמך",
	docListHeaderFileSize: "גודל הקובץ",
	docListHeaderContentUri: "URI של תוכן",
	docListHeaderFileExists: "הקובץ קיים",
	docListNoResults: "לא נמצאו מסמכים",
	docListTotalDocuments: "סך המסמכים:",
	docListDocIdSearchLabel: "מזהה מסמך",
	docListExportBtn: "ייצא",

	// Internal user roles abbr
	picRole: "PIC",
	adminRole: "Admin",
	readOnlyRoleForThirdPartyUser: "לקריאה בלבד",
	legacy: "ללא הגבלה",
	permissionLabel: "הרשאה",
	permission: "הרשאות",
	active: "פעיל",
	inactive: "לא פעיל",
	EngagementUserStatus: "סטטוס",
	IndependenceStatus: "אי תלות",
	Confidentiality: "סודיות",
	EditUserPermissionsMainTitle: "אתה יכול לערוך את ההרשאות שלך",
	EditUserPermissionsInstructions: "זה תמיד יהיה הרשאות עריכה של אנשים אחרים לא שלך.",
	PermissionSaveButtonText: "שמור,",
	PermissionCancelButtonText: "בטל,",
	UnknownCountry: "לא ידוע",
	engagementNoPermissionMesage: "אין לך הרשאות מספיקות למודול המעורבות.",
	cacheRemoveSuccessMessage: "המטמון (cache) הוסר בהצלחה",
	cacheKeyNotFound: "לא נמצאה תוצאה עבור מפתח מטמון (cache key)",

	// Portfolio labels
	informationText: "ברוך שובך",
	filterEngagements: {
		all: "כל ההתקשרויות",
		favorite: "תיקי ביקורת מועדפים",
		review: "התקשרויות סקירה "
	},
	filterEngagementsLabel: "סינון תיקי ביקורת",
	engv2noActiveEngagements: 'אין לך תיקי ביקורת פעילים כלשהם. לחץ על כפתור "הוסף תיק ביקורת" בכדי להתחיל.',
	engv2noFavoriteEngagements: "אין לך תיקי ביקורת מועדפים. בכדי להוסיף התקשרות לתצוגה זו, נווט אל 'כל ההתקשרויות' ולחץ על סמל המועדפים בפינה הימנית התחתונה של כרטיסי ההתקשרויות.",
	engv2noReviewEngagements: "אין לך התקשרויות מסוג סקירה. אם אתה זקוק לגישה להתקשרות כזו, פנה לחבר צוות אחר.",

	// Engagement Progress Labels
	engagementProgressStrategyPhaseLabel: "שלב ההיקף והאסטרטגיה",
	engagementProgressExecutionPhaseLabel: "שלב הביצוע",
	engagementProgressConclusionPhaseLabel: "שלב הסיכום והמסקנות",
	engagementProgressCompletionPercentage: "לְהַשְׁלִים",
	engagementProgressCompleted: "לְהַשְׁלִים",
	engagementProgressNotAvailable: "התקדמות תיק הביקורת תהיה זמינה כאשר מרכז הנתונים המקומי שלך ישודרג",

	// Engagement archive countdown indicator
	EngagementArchiveCountdownIndicator: {
		reportreleasedate: "תאריך פרסום הדוח הוא <b>{0}</b>",
		archivedeadlinedate: "תאריך המועד האחרון לביצוע הארכיב הוא <b>{0}</b>",
		today: "היום,",
		tommorow: "מחר",
		overdue: "תאריך היעד חלף,",
		reportreleasedays: "עד לתאריך פרסום הדוח (RRD)",
		archivedeadlinedays: "עד לתאריך היעד של הארכיב",
		days: "ימים"
	},
	postArchive: "אחרי הארכיב",

	adminTool: "כלי ניהול אדמין",
	characters: "תווים",
	placeholder: "שומר מקום (Placeholder)",
	ofLabel: "של",
	kendoTextLimitMessage: "חרגת ממגבלת התווים המקסימלית",
	kendoRequiredMessage: "נדרש",
	engv2Errors: {
		noReviewEngagementsPermission: "אין לך הרשאה לסקור תיקי ביקורת. צור קשר עם חבר צוות תיק הביקורת אם אתה זקוק לגישה.",
	},
	timeTracking: 'Time Tracker',
	timeTrackingToolMessage: "הכלי האופציונלי הזה נוצר במיוחד כדי לעזור לך לנהל טוב יותר את הזמן שלך בזמן שאתה עובד על תיקי הביקורת שלך.",
	timeTrackingToolMessage1: "ברגע שתתחיל להזין זמן, תוכל למצוא סיכום של נתונים שנאספו עד 14 ימים. נתונים מעל 14 ימים יוסרו אוטומטית.",
	timeTrackingToolUpdatedMessage: "כלי אופציונלי זה עוזר לתעד איך אתה מקצה את הזמן שלך. הזמן שנרשם נשמר למשך שבועיים לכל היותר ולא יועבר לארכיון.",
	timeTrackerErrorFallBackMessage: "לא ניתן לטעון את סיכום הזמן. רענן את הדף ונסה שוב. אם הבעיה נמשכת, פנה לתמיכה הטכנית.",
	serviceGateway: "שער שירות (Service Gateway)",
	reportinghubDisableMessage: "Spotfire תופסק ביוני 2024. הדוחות החדשים של EY Canvas נגישים מההתקשרות שלך עם EY Canvas. לאחר גרסה 1 של 2024, ניתן לגשת אליו מהתפריט עוד. הקפד לעדכן את כל הדיווחים המותאמים אישית או האוטומציות התלויות בדוחות האלה לגרסה החדשה לפני יוני 2024.",
	mobileVersionLabels: {
		canvas: "Canvas",
		noEngagementFound: "אין לך התקשרויות פעילות. גש אל ה- Canvas במחשב שלך כדי להתחיל.",
		searchEngagementPlaceholder: "לחפש....",
		search: "חפש",
		independenceIncomplete: "הצהרת אי התלות לא מלאה",
		profileIncomplete: "פרופיל לא שלם",
		allEngagements: "כל ההתקשרויות",
		noMatchesFound: "לא נמצאו התאמות. אנא נסה שנית.",
		inValidUserLogin: "גש למחשב שלך כדי להשלים את הגדרות המשתמש",
		pagination: {
			pageLabel: "עמוד",
			ofLabel: "שֶׁל"
		}
	},
	independenceOnlyStatus: "אי תלות בלבד",
	toggleView: "החלף תצוגה",

	// Adobe Analytics
	aaCookieConsentTitle: "ברוכים הבאים אל",
	aaCookieContentPrompt: "האם ברצונך לאפשר קבצי Cookie?",
	aaCookieConsentExplanation: '<p>בנוסף לעוגיות הנחוצות בהחלט להפעלת אתר זה, אנו משתמשים בסוגי ה- Cookies הבאים בכדי לשפר את חווייתך ואת השירותים שלנו: <strong>קבצי Cookie פונקציונליים</strong> לשיפור חווייתך (למשל זכירת הגדרות), <strong>קבצי Cookie של ביצועים </strong> למדידת הביצועים של האתר ושיפור החוויה שלך, <strong>קובצי Cookie של פרסום/פילוח</strong>, המוגדרים על ידי צדדים שלישיים שאיתם אנו מבצעים קמפיינים פרסומיים ומאפשרים לנו לספק לך פרסומות רלוונטיות עבורך.</p><p>עיין ב<a target="_blank" href="https://www.ey.com/en_us/cookie-policy">מדיניות קובצי ה-cookie</a> שלנו למידע נוסף.</p>',
	aaCookieConsentExplanationWithDoNotTrack: '<p>In addition to cookies that are strictly necessary to operate this website, we use the following types of cookies to improve your experience and our services: <strong>Functional cookies</strong> to enhance your experience (e.g. remember settings), <strong>Performance cookies</strong> to measure the website\'s performance and improve your experience, <strong>Advertising/Targeting cookies</strong>, which are set by third parties with whom we execute advertising campaigns and allow us to provide you with advertisements relevant to you.</p><p>We have detected that you have enabled the Do Not Track setting in your browser; as a result, Advertising/Targeting cookies are automatically disabled.</p><p>Review our <a target="_blank" href="https://www.ey.com/en_us/cookie-policy">cookie policy</a> for more information.</p>',
	aaCookieConsentDeclineOptionalAction: "אני דוחה קבצי Cookie אופציונליים",
	aaCookieConsentAcceptAllAction: "אני מאשר את כל קבצי ה- Cookie",
	aaCookieConsentCustomizeAction: "התאמה אישית של קבצי Cookie",
	aaCookieConsentCustomizeURL: 'https://www.ey.com/en_us/cookie-settings',

	// Cookie Settings
	cookieSettings: {
		title: "הגדרות קבצי cookie",
		explanation: "אנא ספק את הסכמתך לשימוש בקבצי cookie ב-ey.com ובפלטפורמת My EY. בחר אחד או יותר מסוגי קבצי ה- cookie המפורטים להלן, ולאחר מכן שמור את הבחירות שלך. עיין ברשימה למטה לפרטים על סוגי קבצי ה- cookies ומטרתם.",
		emptyCookieListNotice: "קובצי Cookie מקטגוריה זו אינם משמשים באפליקציה זו",
		nameTableHeader: "שם קובץ ה cookie",
		providerTableHeader: "ספק קובץ ה cookie",
		purposeTableHeader: "מטרת קובץ ה cookie",
		typeTableHeader: "סוג קובץ ה cookie",
		durationTableHeader: "משך קובץ ה cookie",
		formSubmit: "שמור את הבחירה שלי",
		requiredCookieListTitle: "קבצי cookies חובה",
		functionalCookieListTitle: "קבצי cookies פונקציונאליים",
		functionalCookieAcceptance: "אני מקבל את קבצי ה- cookies הפונקציונליים להלן",
		functionalCookieExplanation: "קבצי cookies פונקציונאליים, המאפשרים לנו לשפר את חווייתך (למשל על ידי זכירת כל ההגדרות שבחרת).",
		performanceCookieListTitle: "קבצי Cookies של ביצועים",
		performanceCookieAcceptance: "אני מקבל את קבצי ה- Cookies של הביצועים שלהלן",
		performanceCookieExplanation: "קבצי cookie של ביצועים, שעוזרות לנו למדוד את ביצועי האתר ולשפר את החוויה שלך. בשימוש בקבצי cookie של ביצועים איננו אוגרים נתונים אישיים כלשהם, אלא רק משתמשים במידע שנאסף באמצעות קבצי cookie אלה בצורה מצטברת ואנונימית.",
		advertisingCookieListTitle: "פילוח קבצי cookie",
		advertisingCookieAcceptance: "אני מקבל את קבצי ה cookie לפרסום/פילוח להלן",
		advertisingCookieExplanation: "קובצי Cookie של פרסום/פילוח, בהן אנו משתמשים כדי לעקוב אחר פעילות המשתמשים והפעלות כדי שנוכל לספק שירות מותאם אישית יותר, ו(במקרה של קובצי Cookie של פרסום) המוגדרים על ידי צדדים שלישיים שאיתם אנו מבצעים קמפיינים פרסומיים ומאפשרים לנו לספק לך פרסומות רלוונטיות עבורך.",
		doNotTrackNotice: "זיהינו שהפעלת את ההגדרה 'אל תעקוב' בדפדפן שלך; כתוצאה מכך,  קובצי Cookie של פרסום/פילוח מושבתים באופן אוטומטי.",
	},

	//Copyright
	copyrightMessage: "זכויות יוצרים © <year> כל הזכויות שמורות",
	addWorkspace: 'Add workspace'
};

export const errors = {
	// GenericErrors Codes
	'0005': "המתנה לפעולה",
	'0006': "הפעולהה נכשלה",
	'0008': "תיאור אינו יכול להכיל את התווים הבאים: \\",
    '0010': "Arc Call נכשל",

	0: "הבקשה נכשלה",
	928: "גישה לא מורשית-אין לך גישה לאפשרות שנבחרה. פנה לתמיכה הטכנית לקבלת הוראות לקבלת גישה, אם מורשה.",

	1009: "שרת הנתונים לא נמצא",
	1010: "מרכז הנתונים לא נמצא",
	1011: "לא הוגדר מרכז נתונים עבור מדינה זו ",
	1013: "לא נמצא מספר מדינה או מספר התקשרות",
	1014: "לא נמצא מרכז נתונים ארצי",
	1016: "מיפוי איתור מסדי נתונים נכשל",
	1017: "oDataQuery אינו יכול להיות null או ריק",
	1018: "odataquery אינו מכיל שדות",
	1019: "מרכז הנתונים האוניברסלי לא נמצא",
	1020: "המשתמש אינו קיים במערכת",
	1021: "דיווח אינו זמין באתר שנבחר. פנה למומחה Canvas בצוות שלך לתמיכה.",

	// Scope & Strategy 1021 - 1200
	1026: "השורה לא נמצאה",
	1030: "שם הלקוח לא נמצא",

	// Restore Engagement
	1101: "אין אפשרות לשחזר את תיק הביקורת למדינה שנבחרה. רענן את הדף ונסה שוב.",

	// Execution   1201-1400
	1219: "השיטה לא נמצאה",
	1234: "מספר התפקיד אינו יכול להיות null או ריק",
	1260: "הפריט שעליו אתה עובד נמחק על-ידי משתמש אחר.",
	1300: "אובייקט בקשה לא חוקי",
	1301: "תפקיד התקשרות לא חוקי",
	1302: "שפת משתמש לא חוקית",
	1303: "פרטי המשתמש בהתקשרות לא נמצאו",
	1304: "אתר לא חוקי",
	1305: "שם ההתקשרות לא נמצא",
	1306: "שם ההתקשרות חורג מהאורך המירבי (255 תווים)",
	1307: "לא נמצא תאריך סיום התקופה המבוקרת/הנסקרת",
	1308: "תאריך לא חוקי",
	1309: "תאריך דוח רואי החשבון המבקר לא נמצא",
	1310: "תאריך היעד לארכיב של ההתקשרות לא נמצא",
	1311: "תאריך היעד לארכיב ההתקשרות אינו יכול להיות לפני התאריך הנוכחי",
	1312: "להתקשרות יש קודי התקשרות כפולים",
	1313: "האתר לא נמצא",
	1314: "שם סביבת העבודה לא נמצא",
	1315: "שם סביבת העבודה חורג מהאורך המירבי (255 תווים)",
	1316: "קוד לקוח חורג מהאורך המירבי (50 תווים)",
	1317: "שם הלקוח חורג מהאורך המירבי (255 תווים)",
	1318: "ערך לא חוקי של הסמן המציין האם סביבת העבודה היא לצרכי תרגול",
	1319: "לא נמצא הסמן המציין האם סביבת העבודה היא לצרכי תרגול",
	1320: "לא נמצא  הסמן  המציין האם הלקוח קיים",
	1321: "ערך לא חוקי של  הסמן המציין האם הלקוח קיים",
	1322: "מספר לקוח לא חוקי",
	1323: "קודי ההתקשרות לא נמצאו",
	1324: "מספר ההתקשרות לא נמצא",
	1325: "מספר משרד ראשי לא חוקי",
	1052: "מזהה קו שירות לא חוקי",
	1053: "מזהה תת מחלקה (SSL ID) לא חוקי",
	1326: "מספר המדינה לא נמצא",
	1327: "מספר סביבת עבודה לא חוקי",
	1328: "סטטוס לא חוקי של חבר בצוות ההתקשרות",
	1329: "סטטוס ההתקשרות לא חוקי",
	1330: "ההתקשרות נמחקה כבר על-ידי חבר צוות אחר",
	1331: "תאריך סיום השנה לא נמצא",
	1339: "חריגה ממספר מירבי של התקשרויות ",
	1340: "פרטי המיקום לא נמצאו",
	1341: "נתונים לעדכון  של ההתקשרות לא התקבלו כראוי",
	1342: "לא נמצאו פרטים של התקשרות עבור ההתקשרות הנוכחית",
	1343: "לא נמצא סיכום של נתוני ההתקשרות להתקשרות הנוכחית",
	1344: "התקשרות אינה קיימת עבור המספר שנבחר",
	1359: "מספר  של מרכז הנתונים עבור אחת מההתקשרויות הפעילות אינו חוקי",
	1363: "יצירת התקשרות נכשלה ב- Universal",
	1364: "יצירת התקשרות נכשלה ב-LDC",
	1373: "בחר סביבת עבודה קיימת או צור סביבת עבודה חדשה",
	1374: "שם ההתקשרות כבר קיים. נא הזן שם התקשרות חדש כדי לייבא את התבנית המתאימה.",
	1375: "לא ניתן לעדכן ימי תפוגה שגויים.",
	1379: "התוכן עובר רענון כעת. נסה שוב מאוחר יותר. אם השגיאה נמשכת זמן ממושך אנא פנה לתמיכה הטכנית של IT (IT Help Desk).",
	1380: "לא ניתן היה להשלים את הניסיון הנוסף לארכיב. רענן את הדף ונסה שוב. אם הבעיה ממשיכה, פנה לתמיכה הטכנית.",
	1381: "יצירת תיק ביקורת RF / RS נכשלה עקב אי התאמה במזהה המדינה בסביבת העבודה וההתקשרות.",
	1382: "יצירת תיק ביקורת RF / RS נכשלה עקב אי התאמה במשרד הראשי בסביבת העבודה וההתקשרות.",
	1384: "שחזור או גלגול קדימה כבר בתהליך עבור תיק הביקורת הזה. בבקשה נסה שוב מאוחר יותר.",
	1386: "סביבת עבודה לא מתאימה להתקשרויות חדשות. רענן את הדף נסה שוב. אם הבעיה נמשכת, פנה לתמיכה הטכנית.",
	1385: "לא ניתן ליצור תיק ביקורת במיקום שנבחר. רענן את הדף ונסה שוב. אם הבעיה נמשכת, פנה לתמיכה הטכנית.",
	1389: "לא ניתן לעדכן  סקירת התקשרות  שפג תוקפה.",
	// Invite Team Members (1401 - 1500)
	1403: "הודעת הפתיחה של ההתקשרות עולה על האורך המרבי (200 תווים)",
	1406: "לא נמצא ריכוז של התקשרויות",
	1424: "פרטי ההתקשרות או פרטי המשתמש לא נמצאו",
	1425: "לא נמצאו פרטי התקשרות",

	// User details Error Codes (1426 - 1434)
	1426: "נדרש להזין שם פרטי",
	1427: "נדרשלהזין שם משפחה",
	1428: "נדרש ראשי תיבות",
	1429: "נדרש להזיו משרד ראשי",
	1430: "נדרש מספר טלפון",
	1431: "נדרש להזין שפה",
	1432: "נדרש  להזין תבנית תאריך מועדפת",
	1433: "נדרש להזין תבנית מספר מועדפת",
	1434: "פרטי המשתמש אינם יכולים להיות null או ריקים",
	1436: "זוהה קונפליקט. אם הבעיה נמשכת, פנה אל התמיכה הטכנית.",
	1438: "רשימת המשתמשים אינו יכול להיות ריק.",
	1448: "המדינה שנבחרה לא עודכנה לגרסה האחרונה של EY Canvas. התייעץ עם EY Atlas לגבי ציר הזמן של הפריסה ונסה שוב לאחר שהעדכון התרחש. אם הבעיה נמשכת פנה לדלפק העזרה.",

	1502: "אין אפשרות להשלים את הפעולה בשלב זה. רענן את הדף ונסה שוב. אם הבעיה נמשכת, פנה אל התמיכה הטכנית.",
	1504: "אין אפשרות להשלים את הפעולה בשלב זה. רענן את הדף ונסה שוב. אם הבעיה נמשכת, פנה אל התמיכה הטכנית.",
	1505: "קלט לא חוקי",

	// Common control errors (1601 - 1700)
	1601: "אחוז לא חוקי",
	18000: "שגיאת עיבוד ARC: אין אפשרות לבצע את הפעולה המבוקשת מאחר ש-ARC עובר תחזוקה. אנא נסו שוב במועד מאוחר יותר. ",
	18306: "שגיאת עיבוד ARC: טופס ARC הקשור לארכיב ה Canvas לא נמצא",
	18307: "שגיאת עיבוד ARC: ההתקשרות נמחקה ב-ARC. לא ניתן להמשיך באפשרות הנבחרת. צור קשר עם התמיכה הטכנית (ARC Admin) אם אתה זקוק לסיוע נוסף.",
	18309: "שגיאת עיבוד ARC: אין אפשרות לבצע את הפעולה המבוקשת מאחר שההתקשרות המאורכבת נמצאת תחת הגבלות רגולטוריות. צור קשר עם התמיכה הטכנית (ARC Admin) אם אתה זקוק לסיוע נוסף.",
	18320: "שגיאת עיבוד ARC: טופס ARC נמצא במצב השהייה (מושהה) ואין אפשרות לשנות זאת.",
	18327: "שגיאת עיבוד ARC: טופס ARC נמצא במצב השהייה למחיקה ואין אפשרות לשנות זאת.",
	18328: "שגיאת עיבוד ARC: אין אפשרות לבצע את הפעולה המבוקשת מאחר וההתקשרות מסומנת למחיקה ב-ARC. צור קשר עם התמיכה הטכנית (ARC Admin) אם אתה זקוק לסיוע נוסף.",
	18329: "שגיאת עיבוד ARC: התקשרות זו אינה נגישה לשותפים. צור קשר עם התמיכה הטכנית (ARC Admin) אם אתה זקוק לסיוע נוסף.",
	18330: "שגיאת עיבוד ARC: אין אפשרות לבצע את הפעולה המבוקשת מאחר שההתקשרויות נעולות עקב הגבלות רגולטוריות. צור קשר עם התמיכה הטכנית (ARC Admin) אם אתה זקוק לסיוע נוסף.",
	2027: "בקשה שגויה",
	2046: "לא מורשה",
	2047: "אסור",
	2048: "הרשומה לא נמצאה",
	2049: "הישות המבוקשת גדולה מדי",
	2052: "לא קביל",
	2053: "אירעה שגיאה בעת אחזור נתונים. רענן את הדף ונסה שוב. אם הבעיה נמשכת, פנה אל התמיכה הטכנית.",
	2056: "אירעה שגיאה כללית",
	2057: "השירות אינו זמין",

	// Roll Forward Error Codes (2301-2400)
	2301: "במהלך הגלגול קדימה הקובץ בארכיב לא נמצא",
	2302: "במהלך הגלגול קדימה נכשלה קבלת הקריאה מהארכיב",
	2303: "במהלך הגלגול קדימה עלה כי הקובץ  שעלה מהארכיב ריק",
	2304: "במהלך הגלגול קדימה של הקובץ המאוחזר בארכיב יש כתובת URL ריקה במאגר קבצים",
	2305: "במהלך הגלגול קדימה הקובץ המאוחזר בארכיב כולל ערך סולמית ריק",
	2306: "המשתמש אינו מורשה לבצע פעולה זו",
	2307: "אירעה חריגה מהמספר המותר של עותקים משוחזרים",

	// Link Error Codes
	2568: "הקישור אינו זמין עוד משום שאינך מוגדר עוד כאיש קשר . אנא רענן את הדף.",
	3005: "הצוות הראשי כבר מחק את הישות המוחזקת (component) מההתקשרות של הקבוצה",
	2571: "בקשת הקישור עודכנה. נא רענן את הדף.",

	// Error for v1 engagement not supported features
	4001: "ההתקשרות שנבחרה נוצרה בגירסה ישנה יותר של EY Canvas ולכן בקשת הגישה אינה זמינה עד שישודרג מרכז הנתונים המקומי לגירסה החדשה ביותר. לקבלת מידע נוסף, פנה לצוות התמיכה הטכנית.",
	4002: "ההתקשרות שנבחרה נוצרה בגירסה ישנה יותר של EY Canvas ולכן בקשת הגישה אינה זמינה עד שישודרג מרכז הנתונים המקומי לגירסה החדשה ביותר. לקבלת מידע נוסף, פנה לצוות התמיכה הטכנית.",
	4214: "לא ניתן לייבא את התבנית לסביבת עבודה במיקום הנבחר. בחר מיקום אחר ונסה שוב",

	// Common framework error
	4205: "פרטי בקשת ההעתקה לא נמצאו",
	401000: "אותר שינוי ברשת, אנא טען מחדש את הדף כדי להמשיך",

	// CopyHub concurrency scenarios
	4217: "הסטטוס של בקשת חבילת הדיווח השתנתה. רענן את הדף ונסה שוב.",
	4219: "העתקים בין גרסאות שונות של EY Canvas אינם נתמכים במהדורה זו בשל שיפורים שנעשו בתהליך ההעתקה. עיין ב- EY Atlas לפרטים על לוחות הזמנים בהטמעה (deployment schedule). לאחר שדרוג מרכז הנתונים שלך, ניתן לבצע עותק.",
	4220: "גרסת התבנית אינה נתמכת עבור מדינה זו.",
	4221: "גרסת התבנית אינה נתמכת עבור מדינה זו.",
	4222: "לא ניתן להשלים את הייבוא עד שמיקום היעד ישודרג לגרסה העדכנית ביותר של EY Canvas. בחר מיקום אחר כדי להמשיך. פנה לתמיכה הטכנית אם הבעיה נמשכת.",

	// Error for Mobility API
	4501: "אין משימות זמינות בהתקשרות",
	4502: "אין משימות לביצוע זמינות בהתקשרות",

	// Counrty API version error
	5001: "לאתר שנבחר יש גירסה ישנה יותר של ה- EY Canvas ולכן ההתקשרות אינה יכולה להיות מגולגלת לסביבת עבודה חדשה עד שישודרג מרכז הנתונים המקומי לגירסה החדשה ביותר של EY Canvas. פנה לתמיכה הטכנית שלך לקבלת מידע נוסף ",

	//CRP checks
	5101: "לא ניתן ליצור עותק סקירה לתיק ביקורת זה, הואיל ופורטל הגישה החיצונית של Canvas אינו זמין בשלב זה. לקבלת מידע נוסף, פנה לתמיכה הטכנית.",
	5102: "יצירת עותק CRP מתבצע עבור התקשרות זו. אנא נסה שוב מאוחר יותר",
	5103: "לא ניתן לערוך עותק סקירה לתיק ביקורת זה, הואיל ופורטל הגישה החיצונית של Canvas אינו זמין בשלב זה. לקבלת מידע נוסף, פנה לתמיכה הטכנית.",
	5104: "הגבלת מסמכים אינה זמינה להתקשרות זו. בטל את הסימון באפשרות ונסה שוב.",

	18100: "קו שירות (SL) לא נמצא.",
	18101: "לא נמצא קו שירות משני (SSL).",

	//RBAC realted error messages.
	935: "אין די הרשאות לביצוע פעולה זו. פנה למנהל תיק הביקורת ובקש הרשאה לנקוט בפעולה זו.",
	8001: "לא ניתן היה להשלים את הפעולה כעת. רענן את הדף ונסה שנית. אם הבעיה נמשכת, פנה אל התמיכה הטכנית. ",
	336699: "לא ניתן לטעון את סיכום הזמן. רענן את הדף ונסה שוב. אם הבעיה נמשכת, פנה לתמיכה הטכנית.",

	//Concurrency errors
	7821: "כבר הגבת להזמנה זו, רענן את הדף ונסה שוב.",

	/*Information Security */
	200001: "הפעולה שננקטה נאסרה על ידי EY Information Security. רענן את הדף ונסה שוב. אם הבעיה נמשכת פנה לתמיכה הטכנית."
};

export const adminErrors = {
	1332: "שגיאה בעת הפעלת ניסיון חוזר לארכיב",
	1501: "המשתמש אינו פעיל. רענן את הדף ונסה שוב",
	156: "אין מספיק הרשאות למודול זה",
	70740: "לא ניתן לשמור שינויים. תיק הביקורת חייב לכלול לפחות חבר צוות פעיל אחד בעל הרשאות גישה לנהל את תיק הביקורת ועומד בדרישות אי התלות. אם הבעיה נמשכת, פנה לתמיכה הטכנית (Help Desk).",
	70790: "הדומיין אינו מורשה. נסה שוב ובמידה הבעיה נמשכת, פנה אל התמיכה הטכנית. ",
	70800: "המשתמש לא יכול להזמין משתמש צד שלישי",
	70810: "נבחרו תפקידי RBAC לא חוקיים/לא פעילים עבור משתמש צד שלישי.",
	70820: "בחר תפקיד RBAC חוקי עבור משתמש צד שלישי.",
	70830: "בחר תפקיד לקריאה בלבד עבור משתמש צד שלישי."
};

export const actionTypes = {
	1: "הודעת מערכת",
	2: "מוזמנים לתיק הביקורת",
	3: "גישה נדחתה",
	4: "בקשות למחיקת תיק ביקורת",
	5: "שגיאה ביצירת התקשרות",
	6: "בקשת גישה לתיק הביקורת",
	7: "התקשרויות שנמחקו",
	8: "תיקי ביקורת שאורכבו",
	9: "הפעל התקשרויות לא פעילות",
	10: "תאריך היעד של הארכיב השתנה",
	11: "בקשות לקישור סביבות עבודה של ישויות מוחזקות בביקורת קבוצה",
	12: "התקבלו הנחיות לביקורת קבוצה",
	13: "עותקים שהתקבלו",
	14: "ההתקשרות מתבצעת"
};
export const timephaseType = {
	'-1': "תאריך סיום של התקופה המבוקרת/נסקרת",
	'-2': "תאריך הדיווח",
	'-3': "תאריך יעד לארכיב",
	'1': "היקף",
	'2': "אסטרטגיה",
	'3': "Walkthroughs",
	'4': "טסטים על בקרות בתקופות ביניים",
	'5': "בדיקות מבססות בתקופות ביניים",
	'6': "Walkthroughs (תהליכי סוף השנה)",
	'7': "טסטים על בקרות בסוף שנה",
	'8': "בדיקות מבססות בסוף שנה",
	'9': "מסקנה",
	'10': "לא רלוונטי",
	'11': "ביצוע הביקורת",
	'12': "פעילויות איכות בשלב ההיקף והאסטרטגיה",
	'13': "תכנון ואסטרטגיה אחרים",
	'14': "CRA",
	'15': "הערכת סיכונים ומהותיות",
	'16': "גיבוש סופי של היקף ואסטרטגית הביקורת ",
	'17': "Post-interim project management"
};
export const dataExport = [
	"טופס ארכיב",
	"טפסי Canvas",
	"שיתוף פעולה",
	"Control Forms",
	"מסמכים",
	"משימות",
	"חברי הצוות",
	"מאזן בוחן"
];
export const dataExportReportNames = {
	'טופס ארכיב': 'Archive Form',
	'טפסי Canvas': 'Canvas Form Report',
	'שיתוף פעולה': 'Collaboration',
	'Control Forms': 'Control Report',
	'מסמכים': 'All Documents Report',
	'משימות': 'All Tasks Report',
	'חברי הצוות': 'Team Members',
	'מאזן בוחן': 'Numbers Report'
};
export const projectManagement = [
	"Collaboration Details",
	"כרטיס מידע דיגיטלי",
	"אבני הדרך",
	"הערות סקירה",
	"בדיקות אימות"
];
export const projectManagementReportNames = {
	'Collaboration Details': 'Client Collaboration Dashboard',
	'אבני הדרך': 'Milestone Report',
	'הערות סקירה': 'Review Notes Report',
	'בדיקות אימות': 'Validation Checks Report'
};
export const summaryScreenDefaults = [
	"דוח של תוכנית הביקורת",
	"תוכנית ביקורת עם ראיות קשורות",
	"כלי סקירה של בקרות (CRT)",
	"סיכום CRA",
	"ממצאים",
	"IT App & SO סיכום",
	"SCOT סיכום",
	"SEM"
];
export const summaryScreenDefaultsReportNames = {
	'דוח של תוכנית הביקורת': 'Audit Plan Report',
	'תוכנית ביקורת עם ראיות קשורות': 'Audit Plan with Related Evidence',
	'כלי סקירה של בקרות (CRT)': 'Controls Review Tool (CRT)',
	'סיכום CRA': 'CRA Summary',
	'ממצאים': 'Findings',
	'IT App & SO סיכום': 'ITSO CRT',
	'SCOT סיכום': 'SCOT Summary',
	'SEM': 'SEM'
};
export const summaryScreenInterim = [
	"דוח  של תוכנית הביקורת",
	"תוכנית ביקורת עם ראיות קשורות"
];
export const summaryScreenInterimReportNames = {
	'דוח  של תוכנית הביקורת': 'Audit Plan Report',
	'תוכנית ביקורת עם ראיות קשורות': 'Audit Plan with Related Evidence'
};
export const summaryScreenGroupAudit = [
	"דוח של תוכנית הביקורת",
	"תוכנית ביקורת עם ראיות קשורות",
	"כלי סקירה של בקרות (CRT)",
	"CRA סיכום",
	"ממצאים",
	"סיכום של הנחיות לחברות בקבוצה",
	"דוח של הקבוצה",
	"סיכום של מבנה הקבוצה",
	"IT App & SO סיכום",
	"SCOT סיכום",
	"SEM"
];
export const summaryScreenGroupAuditReportNames = {
	'דוח של תוכנית הביקורת': 'Audit Plan Report',
	'תוכנית ביקורת עם ראיות קשורות': 'Audit Plan with Related Evidence',
	'כלי סקירה של בקרות (CRT)': 'Controls Review Tool (CRT)',
	'CRA סיכום': 'CRA Summary',
	'ממצאים': 'Findings',
	'סיכום של הנחיות לחברות בקבוצה': 'Group Instruction Summary',
	'דוח של הקבוצה': 'Group Report',
	'סיכום של מבנה הקבוצה': 'Group Structure Summary',
	'IT App & SO סיכום': 'ITSO CRT',
	'SCOT סיכום': 'SCOT Summary',
	'SEM': 'SEM'
};
export const summaryScreenGroupAuditInterim = [
	"Group Instruction סיכום",
	"Group Structure סיכום",
	"דוח של תוכנית הביקורת",
	"תוכנית ביקורת עם ראיות קשורות"
];
export const summaryScreenGroupAuditInterimReportNames = {
	'Group Instruction סיכום': 'Group Instruction Summary',
	'Group Structure סיכום': 'Group Structure Summary',
	'דוח של תוכנית הביקורת': 'Audit Plan Report',
	'תוכנית ביקורת עם ראיות קשורות': 'Audit Plan with Related Evidence'
};
export const rolelistitems = [{
	id: 8,
	label: "מתמחה"
},
{
	id: 7,
	label: "סניור"
},
{
	id: 6,
	label: "מנג'ר"
},
{
	id: 5,
	label: "סניור מנג'ר"
},
{
	id: 3,
	label: "ED"
},
{
	id: 4,
	label: "שותף"
},
{
	id: 11,
	label: "שותף אחר"
},
{
	id: 2,
	label: "השותף האחראי על תיק הביקורת"
},
{
	id: 1,
	label: "השותף האחראי על תיק הביקורת"
},
{
	id: 10,
	label: "שותף מלווה EQR"
},
{
	id: 17,
	label: "סוקר AQR"
},
{
	id: 16,
	label: "יועץ משפטי"
},
{
	id: 12,
	label: "GDS - Staff"
},
{
	id: 30,
	label: "GDS - Senior"
},
{
	id: 31,
	label: "GDS - Manager"
}
];
export const roleType = {
	'11': "שותף אחר",
	'24': "אחר (למשל בדיקה נאותות)",
	'15': "שירותי תמיכה ניהוליים",
	'18': "ML צוות הביקורת של אתר הישות המדווחת (CT)",
	'17': "סוקר AQR",
	'1': "השותף האחראי על תיק הביקורת",
	'2': "השותף האחראי על תיק הביקורת",
	'9': "מתמחה",
	'13': "יעוץ (FAIT, TAS, Human Capital או אחר)",
	'14': "TAX",
	'27': "תעשייה",
	'10': "שותף מלווה EQR",
	'25': "משרד",
	'8': "מתמחה",
	'20': "צוות הלקוח",
	'19': "Client Supervisor",
	'5': "סניור מנג'ר",
	'16': "יועץ כללי",
	'26': "אזור",
	'23': "רגולטור",
	'22': "צוות ביקורת פנימית",
	'3': "ED",
	'21': "אחראי על הביקורת הפנימית",
	'6': "מנג'ר",
	'4': "שותף",
	'7': "סניור",
	'12': "GTH",
	'28': "National",
	'29': "Global",
	'12': "GDS - Staff",
	'30': "GDS - Senior",
	'31': "GDS - Manager"
};

export const copyObjectGroupType = {
	1: "תיק ביקורת",
	2: "סעיפים,",
	3: "SCOTs",
	4: "יישומי IT ולשכות שירות (IT Applications & Service Organizations)",
	5: "בקשות",
	6: "ראיות",
	7: "מספרים",
	8: "בקשות ומשימות חיצוניות",
	9: "קבצים זמניים",
	10: "ראיית ביקורת מתקופה קודמת",
	11: "קבצים שנמחקו לאחרונה",
	12: "קבצים היסטוריים",
	13: "תבנית תיק הביקורת",
	14: "שחזר עותק של GCO",
	15: "העתק של סקירה חיצונית",
	16: "סקירה חיצונית של תיק ביקורת פעיל",
	17: "טפסי Canvas",
	20: "ראיות",
	21: "תיק ביקורת",
	22: "סעיפים",
	23: "SCOTs",
	24: "גלגול קדימה של ההתקשרות",
	27: "עדויות חיצוניות",
	28: "Guided Workflows",
	29: 'Engagement by Entity'
};

export const EngagementStatusText = [
	"לא מוגדר",
	"פעיל",
	"מושהה",
	"אורכב",
	"נמחק",
	"מתבצע איחסון בארכיון",
	"הופעל מחדש",
	"שוחזר",
	"סמן למחיקה",
	"שגיאת ארכיב",
	"שגיאה בגילגול קדימה (rollforward)",
	"שגיאה בהפעלה מחדש",
	"שגיאה בשיחזור",
	"גלגול קדימה (rollforward) מתבצע",
	"הפעלה מחדש מתבצעת",
	"השחזור מתבצע",
	"המרת ה GAMX מתבצעת",
	"שגיאה בהמרת ה GAMX",
	"יצירת הטרנזקציה מושהת",
	"יצירת הטרנזקציה נכשלה",
	"עדכון הטרנזקציה מושהה",
	"עדכון הטרנזקציה נכשל",
	"העתקה מתבצעת",
	"שגיאה בהעתקה",
	"שגיאה בהעתקת תבנית",
	"סקירה חיצונית של תיק הביקורת בתהליך ",
	"שגיאה בסקירה חיצונית של תיק הביקורת",
	"סקירה חיצונית של תיק הביקורת"
];
export const overdueIssues = {
	partnerInvitationPending: "הזמנת השותף האחראי על הביקורת (PIC) בהמתנה",
	eqrInvitationPending: "הזמנת השותף המלווה (EQR) בהמתנה",
	invalidEngagementCode: "קוד תיק הביקורת לא חוקי",
	overdueArchiveDeadline: "תאריך יעד הארכיב עבר",
	upcomingArchiveDeadline: "תאריך יעד הארכיב מתקרב",
	contentDeliveryInProgress: "מסירת התוכן מתבצעת",
	overdueMilestones: "תאריך יעד סיום שלבים בביקורת (Milestones overdue)",
	overduePartnerTasks: "תאריך היעד למשימות השותף האחראי על הביקורת (Partner Tasks) חלף",
	overdueEqrTasks: "תאריך היעד למשימות השותף המלווה (EQR Tasks) חלף"
};
export const engagementMetricNames = [{
	propName: 'preparer',
	value: "להכין"
},
{
	propName: 'forMyReview',
	value: "לסקירה"
},
{
	propName: 'upcomingReview',
	value: "הסקירה הקרובה"
},
{
	propName: 'reviewNotesAssigned',
	value: "הערות שנכתבו עבורי"
},
{
	propName: 'reviewNotesAuthored',
	value: "הערות שנכתבו על ידי"
},
{
	propName: 'clientRequests',
	value: "בקשות"
},
{
	propName: 'timephases',
	value: "שלבי זמן"
},
{
	propName: 'groupTasks',
	value: "משימות ברמת הקבוצה"
},
{
	propName: 'groupDeliverables',
	value: "תוצרים של הקבוצה"
},
{
	propName: 'automationDocuments',
	value: "אוטומציה"
}
];
export const metricFilterComingDue = [{
	value: 1,
	label: "תאריך היעד מחר"
},
{
	value: 3,
	label: "תאריך היעד בעוד 3 ימים"
},
{
	value: 5,
	label: "תאריך היעד בעוד 5 ימים",
	isDefault: true
},
{
	value: 10,
	label: "תאריך היעד בעוד 10 ימים"
},
{
	value: 30,
	label: "תאריך היעד בעוד 30 ימים",
	isMax: true
}
];
export const metricFilterWhatsNew = [{
	value: 1,
	label: "מה חדש ביום האחרון"
},
{
	value: 3,
	label: "מה חדש ב- 3 הימים האחרונים"
},
{
	value: 5,
	label: "מה חדש ב- 5 הימים האחרונים",
	isDefault: true
},
{
	value: 10,
	label: "מה חדש ב- 10 הימים האחרונים",
	isMax: true
}
];

export const type1Alerts = [
	"hasPartnerInvited",
	"hasEqrInvited",
	"hasValidEngagementCode",
	"overdueArchiveDeadline"
];

export const engagementStatuses = {
	1: "פעיל",
	2: "מושהה",
	3: "אורכב",
	4: "נמחק",
	5: "מתבצע איחסון בארכיון",
	6: "הופעל מחדש",
	7: "שוחזר",
	8: "מסומן למחיקה",
	9: "שגיאת ארכיב",
	10: "שגיאה בגילגול קדימה (rollforward)",
	11: "שגיאה בהפעלה מחדש",
	12: "שגיאה בשיחזור",
	13: "גלגול קדימה (rollforward) מתבצע",
	14: "מתבצעת הפעלה מחדש",
	15: "השחזור מתבצע",
	16: "המרת ה GAMX מתבצעת",
	17: "שגיאה בהמרת ה GAMX",
	18: "יצירת הטרנזקציה מושהת",
	19: "יצירת הטרנזקציה נכשלה",
	20: "עדכון הטרנזקציה מושהה",
	21: "עדכון הטרנזקציה נכשל",
	22: "העתקה מתבצעת",
	23: "שגיאה בהעתקה",
	24: "שגיאה בהעתקת תבנית",
	25: "סקירה חיצונית של תיק הביקורת בתהליך",
	26: "שגיאה בסקירה חיצונית של תיק הביקורת",
	27: "סקירה חיצונית של תיק הביקורת",
	28: "התאמה מקומית כדי להבדיל מהארכיב האוניבסלי",
	29: "התאמה מקומית כדי להבדיל מהארכיב האוניבסלי",
	30: "מסירת התוכן מתבצעת",
	31: "שגיאה בהעברת התוכן",
	32: "העברת התוכן נכשלה",
	33: "Canvas External Access Portal V2",
	34: "Canvas External Access Portal V2 בתהליך",
	35: "שגיאת Canvas External Access Portal V2",
	100: "פיצול מרכז נתונים"
};

export const overrideArchiveDeadlineDateOptions = [{
	value: 0,
	label: "ברירת מחדל"
},
{
	value: 10,
	label: "10 ימים"
},
{
	value: 14,
	label: "14 ימים"
},
{
	value: 20,
	label: "20 ימים"
},
{
	value: 21,
	label: "21 ימים"
},
{
	value: 25,
	label: "25 ימים"
},
{
	value: 30,
	label: "30 ימים"
},
{
	value: 45,
	label: "45 ימים"
}
];
export const ExternalReviewCopyExpiryDays = [{
	value: 30,
	label: "30 ימים"
},
{
	value: 60,
	label: "60 ימים"
},
{
	value: 90,
	label: "90 ימים"
},
{
	value: 180,
	label: "180 ימים"
}
];

export const serviceLines = [{
	servicelineid: 1,
	servicelinename: "ביקורת"
},
{
	servicelineid: 3,
	servicelinename: "אסטרטגיה ועסקאות"
},
{
	servicelineid: 2,
	servicelinename: "התייעצות"
},
{
	servicelineid: 4,
	servicelinename: "TAX"
},
{
	servicelineid: 5,
	servicelinename: "יועץ משפטי (GCO)"
}
];

export const subServiceLines = [{
	subservicelineid: 1,
	servicelineid: 1,
	subservicelinename: "ביקורת"
},
{
	subservicelineid: 2,
	servicelineid: 1,
	subservicelinename: "FIDS"
},
{
	subservicelineid: 3,
	servicelineid: 2,
	subservicelinename: "ITRA מערכות מידע"
},
{
	subservicelineid: 9,
	servicelineid: 1,
	subservicelinename: "FAAS"
},
{
	subservicelineid: 10,
	servicelineid: 1,
	subservicelinename: "ACR"
},
{
	subservicelineid: 11,
	servicelineid: 1,
	subservicelinename: "CCaSS"
},
{
	subservicelineid: 4,
	servicelineid: 3,
	subservicelinename: "שינוי הוני"
},
{
	subservicelineid: 5,
	servicelineid: 3,
	subservicelinename: "תמיכה בעסקה"
},
{
	subservicelineid: 6,
	servicelineid: 2,
	subservicelinename: "שיפור ביצועים"
},
{
	subservicelineid: 7,
	servicelineid: 2,
	subservicelinename: "סיכון"
},
{
	subservicelineid: 7,
	servicelineid: 23,
	subservicelinename: "ייעוץ עסקי"
},
{
	subservicelineid: 24,
	servicelineid: 6,
	subservicelinename: "אחרים"
},
{
	subservicelineid: 8,
	servicelineid: 2,
	subservicelinename: "אחרים"
},
{
	subservicelineid: 12,
	servicelineid: 4,
	subservicelinename: "BTS"
},
{
	subservicelineid: 13,
	servicelineid: 4,
	subservicelinename: "הון אנושי"
},
{
	subservicelineid: 14,
	servicelineid: 4,
	subservicelinename: "חוק"
},
{
	subservicelineid: 15,
	servicelineid: 4,
	subservicelinename: "מס עקיף"
},
{
	subservicelineid: 16,
	servicelineid: 4,
	subservicelinename: "GCR"
},
{
	subservicelineid: 17,
	servicelineid: 4,
	subservicelinename: "מיסוי עסקה"
},
{
	subservicelineid: 18,
	servicelineid: 4,
	subservicelinename: "ITS"
},
{
	subservicelineid: 19,
	servicelineid: 5,
	subservicelinename: "יועץ משפטי (GCO)"
},
{
	subservicelineid: 20,
	servicelineid: 2,
	subservicelinename: "ייעוץ עסקי"
},
{
	subservicelineid: 21,
	servicelineid: 2,
	subservicelinename: "ייעוץ טכנולוגי"
},
{
	subservicelineid: 22,
	servicelineid: 3,
	subservicelinename: "עסקאות ומימון חברות"
},
{
	subservicelineid: 23,
	servicelineid: 3,
	subservicelinename: "EY Parthenon"
},
{
	subservicelineid: 25,
	servicelineid: 1,
	subservicelinename: "סיכון טכנולוג"
}
];

export const modules = [{
	id: 1,
	name: "ניהול גישה"
},
{
	id: 2,
	name: "תיקי הביקורת"
},
{
	id: 3,
	name: "קבוצות הרשאות"
},
{
	id: 4,
	name: "טמפלייטים"
},
{
	id: 5,
	name: "הודעות"
},
{
	id: 6,
	name: "מטמון (cache)"
},
{
	id: 7,
	name: "מאתר מסד הנתונים"
},
{
	id: 8,
	name: "מסמכים"
},
{
	id: 9,
	name: "סביבות עבודה של SharePoint"
}
];

export const roles = [{
	id: 17,
	ModuleId: 1,
	name: "לקריאה בלבד"
},
{
	id: 18,
	ModuleId: 1,
	name: "הוסף/הסר משתמש והרשאות"
},
{
	id: 19,
	ModuleId: 2,
	name: "לקריאה בלבד"
},
{
	id: 20,
	ModuleId: 2,
	name: "עדכן את המעורבות"
},
{
	id: 21,
	ModuleId: 2,
	name: "הזמן חבר צוות"
},
{
	id: 22,
	ModuleId: 2,
	name: "עדכן משתמש מעורבות"
},
{
	id: 23,
	ModuleId: 3,
	name: "לקריאה בלבד"
},
{
	id: 24,
	ModuleId: 3,
	name: "הוסף/הסר חבר"
},
{
	id: 25,
	ModuleId: 3,
	name: "Sync DatabaseLocator"
},
{
	id: 26,
	ModuleId: 4,
	name: "לקריאה בלבד"
},
{
	id: 27,
	ModuleId: 4,
	name: "עדכון תבנית"
},
{
	id: 28,
	ModuleId: 4,
	name: "מחק תבנית"
},
{
	id: 29,
	ModuleId: 5,
	name: "לקריאה בלבד"
},
{
	id: 30,
	ModuleId: 5,
	name: "צור/עדכן הודעה"
},
{
	id: 31,
	ModuleId: 5,
	name: "למחוק הודעה"
},
{
	id: 32,
	ModuleId: 6,
	name: "קרא ומחק מטמון (cache)"
},
{
	id: 33,
	ModuleId: 8,
	name: "גישה למסמכים"
},
{
	id: 34,
	ModuleId: 2,
	name: "ניסיון חוזר לארכב"
},
{
	id: 38,
	ModuleId: 9,
	name: "לקריאה בלבד"
},
{
	id: 39,
	ModuleId: 9,
	name: "הוסף סביבות עבודה"
},
{
	id: 40,
	ModuleId: 9,
	name: "מחק סביבות עבודה"
}
];

export const countries = {
	1: "מיוט",
	2: "איי בתולה בריטיים",
	3: "סְפָרַד",
	4: "בליז",
	5: "פרו",
	6: "סלובקיה",
	7: "ונצואלה",
	8: "נורווגיה",
	9: "איי פוקלנד (מלווינאס)",
	10: "מוזמביק",
	11: "סין היבשתית",
	12: "סודן",
	13: "ישראל",
	14: "בלגיה",
	15: "ערב הסעודית",
	16: "גיברלטר",
	17: "גואם",
	18: "איי נורפוק",
	19: "זמביה",
	20: "איחוד",
	21: "אזרבייג'ן",
	22: "סנט הלנה",
	23: "איראן",
	24: "מונקו",
	25: "סנט פייר ומיקלון",
	26: "ניו זילנד",
	27: "איי קוק",
	28: "סנט לוסיה",
	29: "זימבבואה",
	30: "עִירַאק",
	31: "טונגה",
	32: "סמואה האמריקנית",
	33: "המלדיביים",
	34: "מָרוֹקוֹ",
	35: "תקנים בינלאומיים לביקורת (ISA)",
	36: "אלבניה",
	37: "אפגניסטן",
	38: "גמביה",
	39: "בורקינה פאסו",
	40: "טוקלאו",
	41: "לוב",
	42: "קנדה",
	43: "איחוד האמירויות הערביות",
	44: "הרפובליקה העממית הדמוקרטית של קוריאה",
	45: "מונטסראט",
	46: "גרינלנד",
	47: "רואנדה",
	48: "פיג'י",
	49: "ג'יבוטי",
	50: "בוצואנה",
	51: "כווית",
	52: "מדגסקר",
	53: "האי מאן",
	54: "הונגריה",
	55: "נמיביה",
	56: "מלטה",
	57: "ג'רזי",
	58: "תאילנד",
	59: "סנט קיטס ונוויס",
	60: "בהוטן",
	61: "פנמה",
	62: "סומליה",
	63: "בחריין",
	64: "בוסניה והרצגובינה",
	65: "צָרְפַת",
	66: "קוריאה, הרפובליקה של",
	67: "אִיסלַנד",
	68: "פּוֹרטוּגָל",
	69: "תוניסיה",
	70: "גאנה",
	71: "קמרון",
	72: "יָוָן",
	73: "הטריטוריות הדרומיות של צרפת",
	74: "איי הרד ואיי מקדונלד",
	75: "אנדורה",
	76: "לוקסמבורג",
	77: "סמואה",
	78: "אנגווילה",
	79: "הולנד",
	80: "גינאה-ביסאו",
	81: "ניקרגואה",
	82: "פרגוואי",
	83: "אנטיגואה וברבודה",
	84: "תקן דיווח כספי בינלאומי (IFRS)",
	85: "ניז'ר",
	86: "מִצְרַיִם",
	87: "מדינת הוותיקן",
	88: "לטביה",
	89: "קַפרִיסִין",
	90: 'האיים הקטנים של ארה"ב',
    91: "רוסיה ישויות משפטיות אחרות",
	92: "וינסנט הקדוש ו ה - גרנידיים",
	93: "גרנזי",
	94: "בורונדי",
	95: "קובה",
	96: "גיניאה המשוונית",
	97: "טריטוריית האוקיינוס ​​ההודי הבריטי",
	98: "שבדיה",
	99: "אוגנדה",
	100: "צפון מקדוניה",
	101: "סווזילנד",
	102: "אל סלבדור",
	103: "קירגיזסטן",
	104: "אירלנד",
	105: "קזחסטן",
	106: "הונדורס",
	107: "אורוגוואי",
	108: "גאורגיה",
	109: "טרינידד וטובגו",
	110: "הרשות הפלסטינית",
	111: "מרטיניק",
	112: "איי אלנד",
	113: "פולינזיה הצרפתית",
	114: "חוף שנהב",
	115: "מונטנגרו",
	116: "דרום אפריקה",
	117: "דרום ג'ורג'יה ואיי סנדוויץ' הדרומיים",
	118: "תֵימָן",
	119: "הונג קונג",
	120: "קניה",
	121: "צ'אד",
	122: "קולומביה",
	123: "קוסטה ריקה",
	124: "אנגולה",
	125: "ליטא",
	126: "סוּריָה",
	127: "מלזיה",
	128: "סיירה לאון",
	129: "סרביה",
	130: "פּוֹלִין",
	131: "סורינאם",
	132: "האיטי",
	133: "נאורו",
	134: "סאו טומה ופרינסיפה",
	135: "סבאלברד ויאן מאין",
	136: "סינגפור",
	137: "מולדובה",
	138: "טייוואן",
	139: "סנגל",
	140: "גאבון",
	141: "מקסיקו",
	142: "איי סיישל",
	143: "מדינות הפדרציה של מיקרונזיה של",
	144: "אלג'יריה",
	145: "אִיטַלִיָה",
	146: "סן מרינו",
	147: "ליבריה",
	148: "בְּרָזִיל",
	149: "קרואטיה",
	150: "איי פרו",
	151: "פלאו",
	152: "פינלנד",
	153: "פיליפינים",
	154: "ג'מייקה",
	155: "גיאנה הצרפתית",
	156: "קייפ ורדה",
	157: "מיאנמר",
	158: "לסוטו",
	159: 'איי הבתולה של ארה"ב',
    160: "איי קיימן",
	161: "ניואה",
	162: "ללכת",
	163: "בלארוס",
	164: "דומיניקה",
	165: "אִינדוֹנֵזִיָה",
	166: "אוזבקיסטן",
	167: "ניגריה",
	168: "וואליס ופוטונה",
	169: "ברבדוס",
	170: "סרי לנקה",
	171: "הממלכה המאוחדת",
	172: "אקוודור",
	173: "גוואדלופ",
	174: "לאוס",
	175: "יַרדֵן",
	176: "איי שלמה",
	177: "מזרח טימור",
	178: "לבנון",
	179: "הרפובליקה המרכז - אפריקאית",
	180: "הוֹדוּ",
	181: "אי חג המולד",
	182: "ונואטו",
	183: "ברוניי",
	184: "בנגלדש",
	185: "אנטארקטיקה",
	186: "בוליביה",
	187: "טורקיה",
	188: "איי בהאמה",
	189: "קומורו",
	190: "סהרה המערבית",
	191: "הרפובליקה הצ'כית",
	192: "אוקראינה",
	193: "אסטוניה",
	194: "בולגריה",
	195: "מאוריטניה",
	196: "קונגו הרפובליקה הדמוקרטית של",
	197: "ליכטנשטיין",
	198: "פיטקרן",
	199: "דנמרק",
	200: "איי מרשל",
	201: "יפן",
	202: "אוֹסְטְרֵיָה",
	203: "עומאן",
	204: "מונגוליה",
	205: "טג'יקיסטן",
	206: "שוויץ",
	207: "גואטמלה",
	208: "אריתריאה",
	209: "נפאל",
	210: "מלי",
	211: "סלובניה",
	212: "איי מריאנה הצפוניים",
	213: "(לא ישים)",
	214: "ארובה",
	215: "קונגו",
	216: "קטאר",
	217: "גינאה",
	218: "ארצות הברית",
	219: "אֶתִיוֹפִּיָה",
	220: "אחרים",
	221: "גיאנה",
	222: "גֶרמָנִיָה",
	223: "ברמודה",
	224: "איי טורקס וקאיקוס",
	225: "אוֹסטְרַלִיָה",
	226: "קיריבטי",
	227: "פוארטו ריקו",
	228: "פקיסטן",
	229: "מאוריציוס",
	230: "מלאווי",
	231: "טורקמניסטן",
	232: "קמבודיה",
	233: "צ'ילה",
	234: "קלדוניה החדשה",
	235: "פפואה גינאה החדשה",
	236: "האי בובה",
	237: "טובאלו",
	238: "קוראסאו",
	239: "הרפובליקה הדומיניקנית",
	240: "וייטנאם",
	241: "איי קוקוס (קילינג).",
	242: "גרנדה",
	243: "טנזניה",
	244: "ארגנטינה",
	245: "מקאו",
	246: "בנין",
	247: "רומניה",
	248: "אַרְמֶנִיָה",
	249: "Global",
	250: "IFRS לחברות SMEs",
	251: 'GAAP בארה"ב',
    252: "מסגרת דיווח כספי של AICPA עבור גופים קטנים ובינוניים",
	253: "דרום סודן",
	254: "קוסובו",
	255: "ארצות הברית - ממשלה",
	256: "רוסיה OOO EY & OOO EY Vneshaudit",
	257: "ארמניה-רוסיהDC",
	258: "בלארוס-רוסיהDC",
	259: "אוזבקיסטן-רוסיהDC"
};

export const common = {
	selected: "המשתמש שנבחר",
	cancel: "בטל",
	checkbox: {
		selectAllOn: "בחר הכל",
		selectAllOff: "בטל את הבחירה בכולם"
	},
	clear: "Clear",
	close: "Close",
	pagination: {
		show: "‏הצג",
		iconHover: {
			first: "הדף הראשון",
			previous: "הדף הקודם",
			next: "הדף הבא",
			last: "הדף האחרון"
		}
	},
	engv2Pagination: {
		show: "‏הצג",
		iconHover: {
			first: "הדף הראשון",
			previous: "הדף הקודם",
			next: "הדף הבא",
			last: "הדף האחרון"
		}
	},
	search: {
		clear: "נקה חיפוש",
		buttonTitle: "חפש",
		placeholder: "חפש",
		noResultsPlaceholder: "לא נמצאו תוצאות"
	},
	table: {
		remove: "הסר",
		noDataPlaceholder: "אין נתונים זמינים",
		noResultsPlaceholder: "לא נמצאו תוצאות"
	},
	toggleSwitch: {
		no: "לא",
		yes: "כן"
	},
	wizard: {
		next: "המשך",
		back: "חזור",
		finish: "סיים"
	}
};

export const ConfidentialityTypes = [{
	id: 0,
	Name: "ברירת מחדל"
},
{
	id: 10,
	Name: "Low"
},
{
	id: 12,
	Name: "Moderate"
},
{
	id: 11,
	Name: "High"
}
];

export const internalUserRoles = [{
	roleKey: 3,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "הרשאות",
	roleNameUI: "העברת תיעוד ההתקשרות לארכיון",
	displayName: "העברת תיעוד ההתקשרות לארכיון",
	roleDisplayOrder: 2,
	tooltipText: "יכולת לארכב את תיק הביקורת"
},
{
	roleKey: 4,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "הרשאות",
	roleNameUI: "ניהול תיק הביקורת (Administer engagement)",
	displayName: "ניהול תיק הביקורת (Administer engagement)",
	roleDisplayOrder: 1,
	tooltipText: "יכולת להזמין חברי צוות פנימיים וחיצוניים ולהקצות הרשאות לחברי צוות פנימיים"
},
{
	roleKey: 5,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "הרשאות",
	roleNameUI: "עדכן תוכן",
	displayName: "עדכן תוכן",
	roleDisplayOrder: 6,
	tooltipText: "אפשרות לערוך את הפרופיל ולקבל עדכון תוכן"
},
{
	roleKey: 7,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "הרשאות",
	roleNameUI: "צור בקשת שירות",
	displayName: "ניהול בקשות שירות",
	roleDisplayOrder: 4,
	tooltipText: "יכולת ליצור בקשות שירות ולסמן בקשות שירות כמושלמות"
},
{
	roleKey: 8,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "הרשאות",
	roleNameUI: "ניהול ביקורת קבוצה",
	displayName: "ניהול ביקורת קבוצה",
	roleDisplayOrder: 5,
	tooltipText: "קבל או דחה הנחיות לקבוצה שהתקבלו מצוות הביקורת הראשי (Primary team) או האזורי (Regional Team)."
},
{
	roleKey: 9,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "הרשאות",
	roleNameUI: "העתק, גלגל קדימה, שחזר תיקי ביקורת",
	displayName: "העתק, גלגל קדימה, שחזר תיקי ביקורת",
	roleDisplayOrder: 3,
	tooltipText: "יכולת להעתיק את ההתקשרות, ולגלגל קדימה ולשחזר את הארכיון שלו"
},
{
	roleKey: 0,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "סודיות",
	roleNameUI: "ברירת מחדל",
	displayName: "ללא (ברירת מחדל)",
	roleDisplayOrder: 1,
	tooltipText: "תפקיד ברירת מחדל למסמכים סודיים"
},
{
	roleKey: 10,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "סודיות",
	roleNameUI: "Low",
	displayName: "Low",
	roleDisplayOrder: 8,
	tooltipText: "Low Confidential Documents Role"
},
{
	roleKey: 11,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "סודיות",
	roleNameUI: "High",
	displayName: "High",
	roleDisplayOrder: 10,
	tooltipText: "High Confidential Documents Role"
},
{
	roleKey: 12,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "סודיות",
	roleNameUI: "Moderate",
	displayName: "Moderate",
	roleDisplayOrder: 9,
	tooltipText: "Moderate Confidential Documents Role"
},
// {
// roleKey: 13,
// sectionId:1,
// groupDisplayOrder: 200,
// groupName:"Confidentiality",
// roleNameUI: "Confidential",
// displayName: "Confidential",
// roleDisplayOrder: 11,
// tooltipText: 'Strictly Confidential Documents Role'
// },
{
	roleKey: 14,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "מגבלות",
	roleNameUI: "לקריאה בלבד",
	displayName: "לקריאה בלבד",
	roleDisplayOrder: 12,
	tooltipText: "יש להשתמש באפשרות זו רק כדי להעניק לסוקרים חיצוניים גישה לתיק הביקורת. יש להשיג את האישורים וההרשאות מהתאימות מה PPD."
},
{
	roleKey: 16,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "מגבלות",
	roleNameUI: "סקירה בלבד",
	displayName: "סקירה בלבד",
	roleDisplayOrder: 13,
	tooltipText: "סקירה מעניקה למשתמש זה רק את היכולת להציג מידע במעורבות ב-Canvas, להירשם ולהשתמש בפונקציונליות הערות הביקורת. יש להשתמש בזה כאשר משתמשי EY אחרים צריכים לבדוק את המעורבות הזו (למשל, צוות הביקורת הראשי של הקבוצה) אך אינם יכולים לבצע עריכות.",
},
{
	roleKey: 37,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "מגבלות",
	roleNameUI: "אי תלות בלבד",
	displayName: "אי תלות בלבד",
	roleDisplayOrder: 14,
	tooltipText: "אי תלות רק מעניק למשתמש זה את היכולת למלא את הצהרת אי התלות שלו עבור ההתקשרות, אך לא תאפשר למשתמש לגשת באופן מלא לתיק הביקורת ב EY Canvas. יש להשתמש בזה כאשר משתמש ביצע עבודה עבור ההתקשרות, אך אין לו צורך בגישה ל-EY Canvas.",
},
{
	roleKey: 6,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "הרשאות",
	roleNameUI: "בטל עריכה",
	displayName: "מסמכים שהוחזרו (Roll-back documents)",
	roleDisplayOrder: 7,
	tooltipText: "יכולת לבטל מסמכים אם נעשו שינויים בטעות לאחר תאריך שחרור הדוח ולהסיר אותם מטופס 440 Canvas."
},
{
	roleKey: 35,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "הרשאות",
	roleNameUI: "נהל שיתוף של הפעלת EY Canvas FIT",
	displayName: "ניהול תהליכי עבודה מודרכים (guided workflow)",
	roleDisplayOrder: 14,
	tooltipText: "יכולת לשתף תהליכי עבודה מודרכים (Guided Workflows) בין התקשרויות של EY Canvas"
},
{
	roleKey: 36,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "הרשאות",
	roleNameUI: "ייבוא נתוני EY helix",
	displayName: "ייבוא נתוני EY helix",
	roleDisplayOrder: 15,
	tooltipText: "יכולת לייבא נתונים מ-EY Helix"
}
];

export const restoreengagementexpirydates = [{
	value: 3,
	label: "3 חודשים"
},
{
	value: 6,
	label: "6 חודשים"
},
{
	value: 9,
	label: "9 חודשים"
},
{
	value: 12,
	label: "12 חודשים"
}
];

export const messageStatus = {
	0: "נוכחי",
	1: "מתוזמן",
	2: "לא בתוקף"
};

export const kendoLabels = {
	alignLeft: "הצדק שמאלה",
	alignRight: "הצדק ימינה",
	alignCenter: "הסבר מרכז",
	alignFull: "הסבר מלא",
	addComment: "הוסף מענה",
	addColumnBefore: "הוסף עמודה משמאל",
	addColumnAfter: "הוסף עמודה מימין",
	addRowAbove: "הוסף שורה למעלה",
	addRowBelow: "הוסף שורה למטה",
	bulletList: "הוסף רשימה לא מסודרת",
	bold: "מודגש",
	backColor: "הדגשה",
	createLink: "הכנס היפר-קישור",
	createTable: "צור טבלה",
	cleanFormatting: "עיצוב נקי",
	deleteRow: "מחק שורה",
	deleteColumn: "מחק עמודה",
	deleteTable: "מחק טבלה",
	fontSizeInherit: "גודל גופן",
	foreColor: "צבע",
	format: "פורמט",
	fontSize: "גודל גופן",
	hyperlink: "הכנס קישור אינטרנט",
	italic: "נטוי",
	indent: "להרחיק",
	insertTableHint: "צור טבלה של {0} על {1}",
	"hyperlink-dialog-content-address": "כתובת אתר אינטרנט",
	"hyperlink-dialog-title": "הכנס היפר-קישור",
	"hyperlink-dialog-content-title": "כותרת",
	"hyperlink-dialog-content-newwindow": "תפתח קישור בחלון חדש",
	"hyperlink-dialog-cancel": "בטל",
	"hyperlink-dialog-insert": "הכנס",
	outdent: "Outdent",
	orderedList: "הכנס רשימה מסודרת",
	print: "הדפס",
	pdf: "ייצוא ל-pdf",
	redo: "לבצע שוב",
	strikethrough: "חוצה",
	subscript: "מנוי",
	superscript: "כתב-על (Superscript)",
	underline: "קו תחתון להדגשה",
	undo: "לבטל"
};

export const kendoFormatOptions = [{
	text: "פסקה",
	value: "p"
},
{
	text: "כותרת 1",
	value: "h1"
},
{
	text: "כותרת 2",
	value: "h2"
},
{
	text: "כותרת 3",
	value: "h3"
},
{
	text: "כותרת 4",
	value: "h4"
},
{
	text: "כותרת 5",
	value: "h5"
},
{
	text: "כותרת 6",
	value: "h6"
}
];

export const kendoFontSize = [{
	text: '8',
	value: "8px"
},
{
	text: '9',
	value: "9px"
},
{
	text: '10',
	value: "10px"
},
{
	text: '11',
	value: "11px"
},
{
	text: '12',
	value: "12px"
},
{
	text: '14',
	value: "14px"
},
{
	text: '16',
	value: "16px"
},
{
	text: '18',
	value: "18px"
},
{
	text: '20',
	value: "20px"
},
{
	text: '22',
	value: "22px"
},
{
	text: '24',
	value: "24px"
},
{
	text: '26',
	value: "26px"
},
{
	text: '28',
	value: "28px"
},
{
	text: '36',
	value: "36px"
},
{
	text: '48',
	value: "48px"
},
{
	text: '72',
	value: "72px"
}
];

export const cacheOptionValue = [{
	value: 1,
	label: "LDC"
},
{
	value: 2,
	label: "אוניברסלי"
}
]
