/* eslint-disable prettier */

import {
	Addon
} from 'react-bootstrap/lib/InputGroup';

/**
 * Created by calhosh on 4/14/2017.
 * English resource file
 */
export const labels = {
	defaultDropdown: "下拉菜单",
	status: "状态",
	language: "语言",
	channel: "频道",
	header: "标题",
	headerName: "标题名称",
	sectionCustomLabelLabel: "自定义标签",
	sectionNamePlaceHolder: "输入章节标题",
	showRelatedObjectLabel: "显示相关对象",
	errorBanner: "{0} 错误",
	engagements: "项目",
	selectedengagements: "项目",
	addEngagement: "添加项目",
	archives: "存档",
	userInitialsTitle: "用户选项",
	clickForOptions: "点击查看更多选项",
	comingDue: "即将到期",
	whatsNew: "新请求",
	auditMetrics: "审计指标",
	id: "ID",
	archived: "已存档",
	myarchives: "我的存档",
	allarchives: "所有存档",
	logout: "签出",
	norecords: "未找到记录",
	nomatches: "未找到匹配",
	notemplates: "选定区域/地区无可用模板",
	pleasetryagain: "请重试",
	firstpage: "第一页",
	previous: "上一页",
	next: "下一页",
	prevpage: "上一页",
	nextpage: "下一页",
	lastpage: "最后一页",
	allengagements: "所有项目",
	searchPlaceholder: "搜索",
	searchEngagementsPlaceholder: "搜索项目",
	clearSearch: "清除搜索",
	searchTextPlaceHolder: "搜索",
	show: "显示",
	manageaccess: "管理访问",
	restore: "还原",
	rollforward: "后推",
	viewaccesslist: "查看访问列表",
	teammembers: "项目组成员",
	name: "名称",
	initials: "首字母缩写",
	role: "职能",
	usersettings: "用户设置",
	usersettingsnewengagements: "设置应用于新创建的项目",
	usersettingsallengagements: "设置应用于所有工作区和项目",
	enterfirstname: "显示姓名（名字）",
	enterlastname: "显示姓名（姓氏）",
	firstname: "名字",
	lastname: "姓氏",
	enter: "输入",
	select: "选择……",
	email: "邮箱地址",
	contact: "电话号码",
	accesslist: "访问列表",
	engagementsettings: "项目设置",
	displayname: "显示名称",
	signoffinitials: "签名首字母缩写",
	primaryoffice: "主要办事处",
	roles: "职能",
	phonenumber: "电话号码",
	globalapplicationsettings: "全球应用设置",
	displaylanguage: "显示语言",
	preferreddateformat: "首选日期格式",
	preferrednumberformat: "首选数字格式",
	save: "保存",
	cancel: "取消",
	isRequired: "必须输入{0}",
	arcretrymessage: "归档流程中发生错误。归档的记录日期当前为{0}。EY Canvas将自动重试您的归档，请在提交此归档后十五小时内等待自动恢复流程完成后，然后再联系服务台。如果帮助台可以解决而无需重新激活项目，归档日期将继续为{0}。如果帮助台必须重新激活项目，以便项目组采取纠正措施，则归档日期将重置为项目再次提交归档时的日期。如果这会导致归档时间过晚，项目组应在项目中加入文档记录，参考服务台故障单编号、问题以及根据本地归档政策采取的解决步骤。",
    ArchiveRetryLink: "存档重试",
	workspacelist: "工作区列表",
	engagementslist: "项目列表",
	accessrequested: "已请求访问",
	noaccess: "禁止访问",
	independenceOnlyNoAccessMessage: "您在项目中的角色是“仅独立性”。请联系项目成员以授予恢复或后推项目的权利。",
	accessrejected: "拒绝访问",
	requestaccess: "请求访问",
	allarchiveinitialmsg: "输入要开始的项目的客户名称、工作区名称或项目名称",
	noengagementsforusermessage: "你没有任何正在进行的项目。单击“创建项目”开始。",
	noengagementstoimportto: "没有可导入项目。",
	favorites: "收藏夹",
	portfolioview: "所有项目v2",
	reviewengagement: "复核项目",
	noreviewengagementsmessage: "您没有任何复核项目。",
	noreviewengagementsmessagecontact: "您没有任何审阅项目。如需获取，请联系项目组成员。",
	nofavoriteengagementsmsg1: "你的收藏夹中没有任何项目。",
	nofavoriteengagementsmsg2: "若要将项目添加到此视图，请转到“所有项目”",
	nofavoriteengagementsmsg3: "然后单击项目卡上的“收藏夹”图标。",
	markasfavorite: "标记为收藏",
	unmarkfromfavorites: "从收藏夹移除",
	selectworkspacenoclientworkspaceexists: "你没有任何现有的工作区。单击“返回”，然后从步骤1中选择“新建工作区”。",
	importpkgselectworkspacenoclientworkspaceexists: "您没有任何现有工作区。单击上一步，然后从上一步中选择“创建新工作区”。",
	expirydatelabel: "该项目将被删除",
	expirydatetodaytomorrowlabel: "该项目将被删除",
	/*Reporting Hub*/
	reportingHub: "报告",
	reportingHubTitle: "安永Canvas报告",
	selectEngagement: "选择要查看报告的项目",
	connectVPN: "仅当连接到EY Network或EY Remote Connect时才能访问报告",
	reportsNotLoaded: "无法加载报告。请刷新页面并重试。如果问题持续存在，请联系帮助台。",
	searchByEngagementNameOrClientId: "选择项目",
	dataExportLabel: "数据导出",
	summaryScreensLabel: "汇总页面",
	projectManagementLabel: "项目管理",
	engagement: "项目名称",
	reportingNotAvailable: "报告在所选位置不可用。请联系你当地的安永Canvas Champion 来了解进一步的详细信息",
	client: "客户",
	workspace: "工作区",
	serviceline: "服务线",
	subserviceline: "子服务线",
	engagementstartdate: "年末日期",
	engagementenddate: "所涵盖期间的结束日期",
	deleteafter: "……后删除",
	auditorsreportdate: "报告日期",
	createengagementquestionlabel: "创建一个项目",
	choosenewexistingquestionheader: "在一个新的或现有的工作区创建项目？",
	createengagementheader: "创建项目",
	restoreengagementheader: "恢复项目",
	createworkspacestepheader: "创建新工作区",
	selectexistingworkspacestepheader: "选择现有工作区",
	workspacenotelibilefornewengagement: "工作区不符合新项目条件",
	choosenewexistingstepheader: "新的或现有的工作区",
	editengagementwizheader: "编辑项目",
	removeengagementwizheader: "移除项目",
	editworkspacewizheader: "编辑工作区",
	editworkspacestepfooter: "点击“保存并关闭”，以保存对工作区的变更，或点击“保存并继续”以编辑项目。",
	editwizheader: "编辑",
	newworkspace: "新工作区",
	existingworkspace: "现有工作区",
	back: "返回",
	continue: "继续",
	saveclose: "保存并关闭",
	savecontinue: "保存并继续",
	finish: "完成",
	step01: "步骤01",
	step02: "步骤02",
	step03: "步骤03",
	step04: "步骤04",
	step05: "步骤05",
	engagementname: "项目名称",
	confirmdecline: "确认拒绝。该操作无法撤消。",
	confirmdeclinevalidation: "确认选择拒绝",
	responderror: "您已经回复了此邀请，请刷新页面重试。",
	engagementcode: "项目代码",
	engagementcodename: "项目代码名称",
	selectEngagementHeader: "选择一个现有工作区",

	selectworkspaceclient: "选择客户",
	selectclient: "选择一个客户，以查看相关工作区",
	createnewworkspace: "创建新的工作区",
	editworkspace: "编辑工作区",
	clientcode: "客户代码",
	clientname: "客户名称",
	invalidclientcode: "无效的客户代码",
	workspacename: '工作区名称',
	location: "位置",
	workspacecodenameplaceholder: "输入客户代码以查找客户名称",
	engagementcodenameplaceholder: "输入业务代码以查找代码名称",
	isprimary: "主要",
	addengagementcode: "添加项目代码",
	engagementnamerequired: "必须输入项目名称",
	primaryofficerequired: "必须输入主要办事处",
	languagerequired: "必须输入语言",
	servicelinerequired: "必须输入服务线",
	subservicelinerequired: "必须输入子服务线",
	engagementcodevalidationmessage: "项目代码与客户代码不匹配",
	engagementcoderequired: "必须输入项目代码",
	yearenddate: "年度结束日",
	periodenddate: "期间结束日",
	reportdate: "报告日",
	complete: "完成",
	end: "结束",
	started: "已开始",
	notstarted: "未开始",
	inprogress: "进行中",
	inreview: "复核中",
	taskstatus: "任务状态",
	confirmlabel: "确认",
	submitlabel: "提交",
	editengagementquestionlabel: "编辑项目",
	removeengagementquestionlabel: "移除项目",
	removeengaementconfirmationmessage1: "是否确认从项目列表中删除以下项目？如删除，则永久失去对该项目的访问权限。 ",
	removeengagementerrormessage: "你是最后一位活跃用户，无法从工作区删除项目。添加其他项目成员或删除项目。 ",
	deleteengagement: "删除项目",
	deletreviewcopyoption: "删除",
	deleteengagementconfirmationmessage: "是否确定提交请求以删除以下项目? ",
	deleteengagementconfirmationmessage1: "对于终止的项目或我们不发布报告的项目，项目组应咨询专业业务部以确定是否需要存档。",
	deleteengagementconfirmationmessageoneuser: "是否确定删除以下项目? ",
	deleteengagementconfirmationmessageoneuser1: "文档和存档3.1中的归档要求",
	deleteengagementconfirmationmessageoneuser2: "DOC+ARC 3.1",
	deleteengagementconfirmationmessageoneuser3: " 应在删除前考虑。例如，对于终止的项目或我们不发布报告的项目，可能需要咨询专业业务部以确定是否需要存档。",
	deleteengagementmarkeddelete: "项目已标记为删除",
	deleteengagementoption: "已删除",
	deleteengagementreasonlabel: "输入删除原因",
	deleteengagementreasonquestion: "为什么将此项目删除？",
	deleteengagementreasonrequired: "原因为必填项。选择上述一个选项或输入一个原因。",
	deleteengagementcommentreasonrequired: "原因为必填项。从上述原因中选择一项。",
	deleteengagementcommentrequiredlabel: "请输入删除原因",
	deletereasontrainingengagementlabel: "培训项目",
	deletereasonrestoredorcopiedlabel: "恢复项目或复制的项目，仅供参考",
	deletereasoncreatedbymistakelabel: "错误创建的项目",
	deletereasonotherlabel: "其他项目",
	deletereasonterminedbyclientlabel: "未执行任何工作的已终止项目。如果在此项目中执行了工作，则应将其存档。",
	markeddeleteconfirm: "确认删除",
	deleterequestbannertext1: "删除请求自 ",
	deleterequestbannertext2: "以前",
	markeddeleteconfirmmessage: "是否确定删除以下项目? ",
	markeddeleteconfirmmessage1: "如果确认删除，此操作无法撤销。",
	markeddeletereasonlabel: "删除原因",
	engagementlistplaceholder: "选择项目",
	engagementrequired: "必须输入项目",
	restoreconfirmationstepheader: "确认",
	restoreconfirmationheader: "是否确认还原项目？",
	selectarchiveheader: "选择已存档文件",
	restoreengagementstepheader: "恢复项目",
	chooserollfwdhdr: "后推至新的或已存在工作区",
	rollfwdstephdrext: "后推至已存在工作区",
	rollfwdstephdrnew: "后推至新工作区",
	rollfwdhdrqstn: "是否后推项目至新的或已存在工作区？",
	rollforwardengagementstepheader: "后推项目",
	genericerror1: "发生错误，请重试或联系工作组",
	restoreengagementmaxcopies: "项目超过了已恢复副本的最大数量({0})。请求访问现有副本。",
	restoreengagementengcopies: "该项目有{0}个可用的已恢复副本：",
	restoreengagementengcopy: "该项目有{0}个可用的已恢复副本：",
	restoreengagementengnocopies: "该项目没有其他可用的已恢复副本。",
	globalexceptionrestoreengagementengcopies: "本项目可用的已恢复副本为{0}.查看上方的复选框以恢复全局异常.",
	globalexception: "全局异常",
	globalexceptioncheckerror: "查看全局异常选项以继续.",
	restoreengagementrestoredon: "恢复于",
	restoreengagementusers: "用户",
	restoreengagementuser: "用户",
	at: "于",
	arcfileuserunavailable: "用户不可用",

	restorehdrqstn: "将项目恢复至新工作区还是现有工作区？",
	chooserestorehdr: "恢复至新工作区或现有工作区",
	restorestephdrext: "恢复至现有工作区",
	restorestephdrnew: "恢复至新工作区",

	close: "关闭",
	confirm: "确认",
	settings: "设置",
	expand: "展开",
	collapse: "收起",
	contextMenuEdit: "编辑",
	contextMenuEditEngagement: "编辑项目",
	contextMenuRemoveEngagement: "移除项目",

	contextMenuDeleteEngagement: "删除项目",
	contextMenuEditWorkspace: "编辑工作区",
	contextMenuDelete: "删除",
	contextMenuOverrideArchiveDate: "改写存档截止日",
	na: "不适用",
	archivesubmittedby: "提交人",
	archivedateandtime: "日期和时间",
	notifications: "通知",
	nonotificationmsg: "你没有收到任何通知。",
	notifcenter: "通知中心",
	notifitemspendingact: "待处理项",
	accept: "接受",
	reject: "拒绝",
	acceptall: "接受所有",
	rejectall: "拒绝所有",
	notifEngInvite: "项目邀请",
	notifengaccesreq: "项目访问请求",
	keyevents: "关键事项",
	milestones: "里程碑",
	importselecttemplateheader: "请选择想要使用的模板",
	importtemplateheader: "请选择想要从中导入的区域/地区",
	import: "导入",
	importregionstepheader: "选择区域/地区",
	importtemplatestepheader: "选择模板",
	importtemplatemsg: "选择区域/地区以继续",
	importselecttemplatemsg: "选择模板以继续",
	importengagementheader: "导入模板",
	importhdrqstn: "导入到新工作区或现有工作区",
	importstephdrext: "导入到现有工作区",
	importstephdrnew: "导入到新工作区",
	chooseimporthdr: "导入到新的或现有工作区",
	selectexistingworkspacemsg: "选择工作区以继续",
	selectaclienttocontinuemsg: "选择一个客户以继续",
	selectengagementmsg: "选择一个项目以接受数据包",
	selectgaengagementmsg: "您必须选择一个项目以接受链接请求",
	arearegion: "区域/地区",
	importdatapackagequestionlabel: "想要进行什么操作？",
	sentby: "发送人",
	on: "开启",
	by: "由",
	view: "查看",
	sourceengagement: "项目源",
	package: "资料包",
	decline: "拒绝",
	delegate: "代理",
	chooseaction: "选择操作",
	selectworkspace: "选择工作区",
	declinepackage: "拒绝请求",
	delegatepackage: "代理资料包",
	selectengagement: "选择项目",
	datapackagerequest: "数据包请求",
	respond: "请点击此处进行回复",
	deletionrequestfor: "收到的删除请求",
	invalidengagementcode: "无效的项目编码",
	noNotifications: "无可用通知",
	bellIconTitle: "通知中心",
	restoredengagement: "恢复项目",
	profiledeliveryinprogress: "配置信息提交中",
	completeprofileheader: "填写资料",
	completeindependenceheader: "完成独立性确认",
	independencecompletedheader: "独立性已完成",
	retry: "重试",
	errorDate: "错误",
	invitationDate: "邀请日期",
	engCreationFailedWarning: "项目创建失败。请刷新页面并重试。如果问题持续存在，请联系帮助台。",
	deletedEngagementWarning: "如项目在使用中，被错误删除，请立即联系帮助台。",
	searchEngagementWarning: "搜索这些项目，采取进一步操作。",
	archiveOverrideWarning: "选定的存档覆盖期间未经ARC批准。存档截止日期已还原为你所在地区的默认值。",
	archiveOverrideInProgress: "存档逾越待ARC批准",
	archiveOverrideAccepted: "存档逾越完成",
	archiveOverrideRejected: "ARC不允许该选定的存档逾越期间。存档截止日已恢复为您所在地区的默认值。",
	scopeName: "范围名称",
	shortName: "缩写名称",
	invited: "已邀请",
	invitedOn: "邀请时间",
	instructions: "指引",
	updates: "更新",
	viewinstructions: "查看指引",
	viewinstructionsupdates: "查看更新",
	more: "更多",
	addmembers: "添加成员",
	statusChangeDate: "状态已更改",
	deletedBy: "删除人 ",
	deletedDate: "已删除",
	deactivatedBy: "停用人",
	deactivatedDate: "已停用",
	requestUserName: "请求人",
	requestDate: "已请求",
	archiveDate: "已存档",
	receivedFrom: "接受自",
	received: "已接收",
	clear: "清除",
	clearAll: "清除全部",
	type: "类型",
	engagementhasbeendeleted: "选择项目已被其他用户删除。刷新页面并重试",
	independenceOnlyRejectInvite: {
		engagementIsNotAvailable: "项目不可用。请刷新页面重试。如问题仍然存在，请联系帮助台。",
		failedToRejectInvite: "无法拒绝邀请。请刷新页面重试。如问题仍然存在，请联系帮助台。",
		succesfulRejection: "邀请被成功拒绝。",
		loadingLabel: "正在拒绝邀请……",
	},
	manageTeam: "管理小组",
	childinprogress: "项目正在设立中",
	// SeeMore component
	showMore: "显示更多",
	showLess: "显示更少",
	showMoreEllipsis: "显示更多……",
	showLessEllipsis: "显示更少……",
	accepted: "已接受",
	acceptedOn: "接受日期：",
	rejected: "已拒绝",
	deniedBy: "拒绝人 ",
	engagementstatusrestored: "已恢复",
	engagementstatusmarkedfordeletion: "标记为删除",
	delegaterequired: "须授权",
	username: "用户名",
	noresults: "未找到结果，请重试",
	importdatapackageconcurrencymessage: "复制请求状态已变更。刷新页面并重试",
	selectdelegateuser: "选择授权人",
	eyusersearchplaceholder: "输入姓名或邮件进行搜索",
	optional: "（可选）",
	engagementAlerts: "项目通知",
	engagementMetrics: "项目统计",
	overdue: "逾期",
	/*day labels*/
	mon: "星期一",
	tues: "星期二",
	wed: "星期三",
	thurs: "星期四",
	fri: "星期五",
	sat: "星期六",
	sun: "星期日",
	canvas: "EY Canvas",
	overrideArchiveDateModalHeader: "逾越存档截止日",
	overrideArchiveDateModalMessage: "是否确定要逾越存档截止日？",
	overrideArchiveDateModalComboPlaceholder: "逾越存档截止日",
	createdOn: "创建于",
	expiresOn: "到期",
	archiveDeadlineDate: "存档截止日",
	defaultOverriden: "默认覆盖",
	selectexistingworkspace: "选定现有工作区",
	importtonewworkspace: "导入到新工作区",
	groupaudit: "集团审计链接请求",
	gaacceptdeclinedelegatequestionlabel: "您要如何处理此链接请求？",
	gaacceptselectengagementquestionlabel: "选定一个项目链接",
	gadelegatequestionlabel: "委托集团审计链接请求",
	gadeclinequestionlabel: "拒绝集团审计链接请求",
	garegioncomponent: "地区/组成部分",
	gascope: "范围",
	gamxTransfer: "从GAMx传输",
	closeNotificationCenter: "关闭通知中心",
	contextMenucrp: "创建复核副本",
	workingoffline: "离线工作",
	trainingoffline: "离线工作（培训）",
	training: "培训",
	crpdescription: "为外部复核人创建项目副本。",
	crpeditdescritpion: "编辑项目名称或项目有效期。逾期项目将被自动删除。",
	expiry: "有效期",
	enteremail: "输入邮件地址",
	create: "创建",
	metricsPlaceholderText: "在选定时间范围内无可用数据",
	deleteReviewCopy: "删除复核副本",
	deleteReviewCopyMsg: "是否确定删除该复核副本？一旦删除，则无法获取该项目，且删除后不可恢复。",
	crpNotAvailable: "由于目前无可用的Canvas外部访问端口，因此无法针对此项目创建复核副本。请联系帮助台以获取进一步信息。",
	externalmember: "外部成员",
	internalmember: "内部成员",
	add: "添加",
	crpEditMembers: "编辑",
	invalidEmail: "输入有效邮件地址",
	emailNotFound: "未找到邮件地址",
	dupExternalUser: "已添加外部成员",
	noExternalUser: "未添加任何外部成员。",
	expiresIn: "到期",
	days: "天数",
	today: "今天",
	tomorrow: "明天",
	reviewengagementname: "复核项目名称",
	enternameoremail: "输入名称或邮件地址",
	options: "选项",
	userNotFound: "未找到用户",
	remove: "删除",
	crpEditHeader: "编辑复核副本",
	globalexceptionrestore: "全球异常恢复",
	expired: "已到期",
	softwareUpdate: "软件更新",
	updateNow: "立即更新",
	updateMsg: "EY Canvas有软件更新。选择立即更新，下载并安装更新。页面将被刷新。",
	preferredlandingpageview: "首选登陆页面视图",
    preferredlandingpagefilter: "首选登陆页面筛选",
	engagementlist: "项目列表",
	workspacelistusersettings: "工作区列表",
	engagementsclientstoload: "每次访问应用程序时要加载的项目/客户数量。",
	favoriteengagements: "最喜欢的项目",
	preferredEngagementDashboard: "项目仪表盘设定",
	preferredEngagementDashboardTeamMembers: "项目组成员",
	preferredEngagementDashboardExecutives: "主管人员",
	linkedHelixProjects: "链接至EY Helix",
	loadingLabel: "正在加载...",
	loadingErrorText: "加载时间表时出现错误。请刷新页面并重试。",
	selectYear: "选择年份（必选）",
	clientNameOrId: "客户名称或客户ID",
	engagementNameOrId: "项目名称或项目ID",
	engagementCodeOrCodeName: "项目代码或代码名称",
	searchArchives: "搜索",
	clearSearchArchives: "清空搜索",
	searchArchivesMessage: "第一步，首先选择项目的存档年份。然后按以下一个或多个选项进行搜索：",
	titleUpdateProfile: "信息",
	msgUpdateProfileLn1: "你已更新项目语言。请前往项目内容更新页，并选择全球内容更新，以接收以此语言编制的内容。",
	sessionTimerMsg1: "由于长时间未进行操作，您当前的工作会话即将自动退出。",
	sessionTimerMsg2: "为了您的使用安全起见，EY Canvas会话将在您在系统中不操作时间到达30分钟后自动结束。",
	sessionTimerMsg3: "当前会话将在{0}分钟后自动退出。",
	sessionTimerMsg4: "如果您希望继续保持登录状态，请点击继续。 ",
	sessionTimerHeader: "自动注销以保护您的安全",
	theme: "主题",
	light: "浅色",
	dark: "深色",
	chooseDisplayTheme: "选择默认主题。",
	searchByCountry: "选择国家",
	primaryEngagementLabel: "主审项目",
	componentEngagementLabel: "组成部分项目",
	regionalEngagementLabel: "地区项目",
	linkedLabel: "已关联",
	eyCanvasEconomicsTooltip: "EY Canvas经济",
	eyOversightTooltip: "EY Canvas Oversight",
	eyPrivacyNoticeTooltip: "隐私通知",
	eyTermsOfServiceTooltip: "服务条款",
	eyGeneralHelp: "一般帮助",

	preferrednegativenumberformat: "首选负数格式",

	protectedEngagementMessage: "防止文档被打印、复印或以其他形式复制",
	documentRrestriction: "文档限制： ",
	on: "开启 ",
	off: "关闭 ",
	protectedEngagementMessageOn: "- 不得打印、复印或以其他方式复制本项目文档",
	protectedEngagementMessageOff: "- 可以打印、复印或以其他方式复制本项目文档",

	/* Dashboard labels*/
	homeLabel: "主页",
	manageAccessHeader: "管理访问权限",
	sharepointWorkspacesHeader: "SharePoint工作区",
	showInactiveUserslabel: "显示不活跃用户",
	showExpiredMessageslabel: "显示过期邮件",
	addUserButtonLabel: "添加用户",
	searchUserText: "搜索",
	nameHeader: "姓名",
	dataCenterNameHeader: "数据中心名称",
	numberOfWorkSpacesHeader: "已设置的工作区数量",
	workspaceName: "工作区名称",
	documentCount: "文档数量",
	percentCapacity: "容量百分比",
	emailHeader: "邮箱",
	actionHeader: "操作",
	manageRolesLabel: "管理角色",
	deactivateUserlabel: "被停用的用户",
	manageRolesTableModule: "模块",
	manageRolesTableRoles: "角色",
	manageRolesTableServiceLine: "业务线",
	manageRolesTableCountries: "国家",
	manageAccessAssignRole: "分配角色",
	manageAccessNoRolesAdded: "没有添加角色",
	manageAccessDeleteRoleModalHeader: "删除角色",
	manageAccessDeleteRoleButton: "删除",
	noRoleResults: "无结果",
	confirmAssignRoleButton: "分配",
	permissionGroupName: "集团名称",
	permissionGroupDescription: "集团说明",
	activeMembers: "活跃用户",
	permissionGroupMembersTitle: "标题",
	permissionGroupMembersPhone: "电话",
	permissionGroupMembersGui: "GUI",
	permissionGroupMembersUserId: "用户ID",
	permissionGroupMembersAdStatus: "AD状态",
	permissionGroupAddMember: "添加成员",
	permissionGroupUserDeleteLabel: "删除",
	permissionGroupUserDeleteHeader: "删除成员",
	permissionGroupDetails: "权限分组详情",
	cache: "缓存密钥",
	cacheSelection: "选择缓存位置",
	engagementID: "项目ID",
	cacheKey: "缓存密钥",
	validationMessage: "必填字段",
	getCache: "获得缓存",
	clearResult: "清空结果",
	removeCache: "删除缓存",
	requiredField: "必填字段",
	ticketNumber: "工单号（必填）",
	confirmationMessage: "请为此操作输入工单号",
	confirmButtonLabel: "确认",
	cancelButtonLabel: "取消",
	modalHeaderLabel: "删除缓存",
	adminModuleinvalidEngagementId: "无效项目ID",
	adminModuleInvalidCacheKey: "缓存密钥中存在无效字符",
	clearResults: "清除结果",
	getDocuments: "获取文档",
	documentsHeader: "文档",
	validTicketNumber: "请输入有效的工单号",

	egagementsPageDescription: "根据一项或多项标准搜索项目，以更新项目数据、邀请新项目组成员、更新现有项目组成员状态和权限。",
	engagementSearchCriteria: "搜索时必须填写国家或项目ID",
	engagementsHeader: "项目",
	engagementIdSearchPlaceholder: "项目ID",
	engagementNameSearchPlaceholder: "项目名称",
	workSpaceSearchPlaceholder: "Workspace名称或ID",
	clientSearchPlaceholder: "客户姓名或代码",
	engagementCodeSearchPlaceholder: "项目代码或代码名称",
	userSearchPlaceholder: "用户GUI、邮箱或用户ID",
	countrySearchPlaceholder: "国家",
	engagementTableHeader: "项目",
	nameTableHeader: "名称",
	workspaceIdTableHeader: "Workspace",
	clientTableHeader: "客户",
	engagementCodeTableHeader: "项目代码",
	yearEndTableHeader: "年末",
	engagementdeleteDate: "项目删除日期",
	archivalguid: "归档GUID",
	archivalLogId: "归档日志ID",
	archivalFileguid: "归档文件GUID",
	profile: "配置文档完成",
	independence: "独立性",
	archiveRetry: "重新尝试归档",
	engagementdetails: "项目详细信息",
	engagementsdetails: "项目详细信息",
	statusTableHeader: "状态",
	attributesTableHeader: "属性",
	attributesProfileComplete: "配置文档完成",
	attributesIndpendenceApplicable: "独立性适用情况",
	engagementPagePlaceholder: "搜索以上项目，搜索结果将在此处显示",
	noEngagementFound: "没有发现项目",
	emptySearchFieldError: "至少填写一个搜索栏。",
	largeCountrySelectionError: "选择的国家应不超过10个。",
	saveEngagement: "保存",
	undoEditEngagement: "重置",
	engagementUpdateSuccess: "已成功保存更改",
	archiveRetrySuccessMessage: "已为此项目成功启动归档。请稍后检查状态，以验证是否归档成功。",
	welcome: "欢迎使用 ",
	welcomeadmin: "管理工具",
	engagementCard: "项目",
	messagingCard: "消息传递",
	templatesCard: "模板",
	permissionGroupCard: "权限集团",
	manageAccessCard: "管理访问权限",
	sharepointWorkspacesCard: "SharePoint工作区",
	databaseLocatorCard: "同步数据库定位器",
	importForReviewTooltip: "将对项目进行优化以供复核，一些特性和功能将无法使用。",
	importForReviewChecboxText: "仅出于复核目的导入",
	clientCode: "客户代码",
	primaryEngagementCode: "主要项目代码",
	invalidEngagementTitle: "无效客户或项目代码",
	invalidEngagementMessage: "在你访问此项目前，需要验证客户代码和/或项目代码。点击继续，以更新并验证代码。",
	insufficientPermission: "此模块的权限不足",
	noPermission: "没有权限",
	noAdminRights: "你没有访问此工具的权限。",



	//Engagement Users Lables
	engmtUserTableUserNameHeader: "用户名",
	engmtUserTableEmailAddressHeader: "邮件地址",
	engmtUserTableRoleHeader: "角色",
	engmtUserTableGUIeHeader: "GUI",
	engmtUserTableStatuseHeader: "状态",
	engmtUserTableIndependenceStatuseHeader: "独立性",
	engmtUserTableConfidentialityHeader: "机密性",
	engmtUserTableADStatusHeader: "AD状态",
	engmtUserTablePermissionsHeader: "权限",
	engmtUserTableActionHeader: "操作",
	engagementUsersTitle: "项目用户",
	engagementInviteMember: "邀请项目组成员",
	engagementInviteUser: "邀请用户",
	engagementRoles: "角色",
	engagementRolesRequired: "项目角色为必填项",
	engagementInviteMemberSuccessMessage: "已成功将成员邀请至项目",
	engagementInviteMemberDescription: "按上方名字、姓氏或电子邮件地址搜索用户。搜索结果见此处。",
	engagementUsersTitleEdit: "编辑",
	engagementUpdatePermissionToolTip: "你没有项目更新权限。",
	engagementRetryArchivePermissionToolTip: "您的权限不足，无法重新尝试归档。",
	usersEditPermissionToolTip: "你没有执行此操作所需的权限。请联系管理员申请权限。",
	usersPermissionToolTip: "你没有项目用户更新权限。",
	invalidArchivalGuidMessage: "归档GUID无效",
	invalidArchivalFileGuidMessage: "归档文档GUID无效",
	invalidArchivalLogId: "归档日志ID无效",
	datePickerLabel: "选择日期",
	updateEngagement: "更新项目",

	invalidEngagementMessage: "在你访问此项目前，需要验证客户代码和/或项目代码。点击继续，以更新并验证代码。",
	invalidEngagementId: "项目ID无效",
	serverErrorMessage: "服务器错误，请稍后重试",
	engagementDetailsNoPermission: "你没有查看项目详细信息的权限。",
	engagementNotFound: "没有找到项目详细信息",
	pageNotFound: "没有找到页面",
	assignRoleModalMessage: "选择要为用户启用的模块和特定功能",
	selectModule: "选择模块",
	selectRole: "选择角色",
	serviceLine: "业务线",
	countries: "国家",
	confirmAssignRoleButton: "分配",
	addUser: "添加用户",
	addAdminUserHeader: "输入用户名字、姓氏或邮箱，点击回车搜索。",
	addMemberDescription: "在上面使用名字、姓氏或电子邮件地址搜索用户。搜索结果将展示在此处。",
	seachInputRequired: "搜索输入项为必填",
	searchUsers: "搜索用户",
	noResultsFound: "没有找到结果",
	usersToBeAdded: "待添加用户",
	addUserSuccessMessage: "管理员用户添加成功",
	addMemberSuccessMessage: "已成功将成员添加至分组。",
	deactivateWarning: "是否确定禁用以下用户?",
	deactivateButton: "禁用",
	deactivateModalTitle: "禁用用户",
	generateReport: "生成报告",
	generateValidationReport: "生成验证报告",

	templateManagement: "模板",
	templateName: "模板名称",
	templateAreaRegion: "区域/地区",
	templateSize: "大小",
	templateCreatedBy: "创建人",
	templateCreatedDate: "创建日期",
	templateCountOfEngagement: "项目",
	templateStatus: "状态",
	templatePublished: "已发布",
	templateUnpublished: "未发布",
	templateSearchText: "输入搜索内容，点击回车",
	templateInsufficientPermissions: "访问此模块的权限不足",
	templateMultipleArea: "多个",
	templateEdit: "编辑模板",
	templateEditSave: "保存",
	templateEditCancel: "取消",
	templateEditSuccessMessage: "模板更新成功。",
	templateDeleteConfirmationMessage: "是否确定删除模板?",
	templateDelete: "删除模板",
	templateEditDelete: "删除",

	messagingMessageTitle: "消息标题",
	messagingDescriptionTitle: "消息说明",
	messagingStartsOn: "开始时间（世界标准时间）",
	messagingEndsOn: "结束时间（世界标准时间）",
	messagingAffectedDataCenters: "受影响的数据中心",
	messagingStatus: "状态",
	messagingCreatedBy: "创建人",
	messagingCreatedDate: "创建日期",
	messagingDataCenterSystemWide: "系统层面",
	messagingDataCenterMultiple: "多个",
	messagingDataCenterSingle: "单个",
	messageName: "消息标题",
	messageStartsOn: "开始时间",
	messageEndsOn: "结束时间",
	messageDateTime: "选择日期和时间",
	messageAllTimeInUTC: "所有时间均采用世界标准时间（UTC）",
	messageTargetAudience: "目标受众",
	messageDataCenters: "数据中心",
	messageSelectADate: "选择日期",
	messageAllDataCenters: "所有数据中心",
	messageKendoDescription: "使用格式化会降低4,000字符的最大描述长度。",
	messageSelectDataCenter: "选择数据中心",
	messageFieldRequired: "必填字段",
	messageStartTimeError: "开始日期和时间不得早于当前日期和时间",
	messageEndTimeError1: "结束日期和时间不得早于当前日期和时间",
	messageEndTimeError2: "结束日期和时间应晚于开始日期和时间",
	messageDataCenterError: "至少选择一个数据中心",
	messageSubmitValidationError: "有漏填的必填信息栏。请滚动页面，确保所有必填的信息栏均已填写完成。",
	messageChangesValidationError: "未进行任何变更。需至少编辑一个信息栏以保存。",
	currentutc: "当前UTC日期和时间：",
	messageCurrentUTCTime: " {0}（24小时格式）",
	createMessage: "创建消息",
	createMessagePermissions: "访问此模块的权限不足",
	createMessageSuccess: "成功创建消息",
	editMessage: "编辑消息",
	editMessageSuccess: "消息编辑成功",
	editHoverText: "编辑",

	messagingModalDeleteTitle: "删除消息",
	messagingModalDeleteMessage: "是否确定要删除消息？",
	messagingModalDeleteButton: "删除",
	messagingModalDeleteConfirm: "确认",
	messagingModalDeleteCancel: "取消",
	messagingModalDeletedSuccessfully: "消息删除成功",

	// databaselocator
	databaseLocatorSuccessMessage: "数据库定位器同步已排队",

	// documents module
	docListHeaderDocId: "文件ID",
	docListHeaderDocVersion: "版本",
	docListHeaderDocName: "文件名称",
	docListHeaderFileSize: "文件大小",
	docListHeaderContentUri: "内容URI",
	docListHeaderFileExists: "文件已存在",
	docListNoResults: "未找到文件",
	docListTotalDocuments: "总文件数：",
	docListDocIdSearchLabel: "文件ID",
	docListExportBtn: "导出",

	// Internal user roles abbr
	picRole: "PIC",
	adminRole: "管理员",
	readOnlyRoleForThirdPartyUser: "只读",
	legacy: "不受限制",
	permissionLabel: "权限",
	permission: "权限",
	active: "活跃",
	inactive: "非活跃",
	EngagementUserStatus: "状态",
	IndependenceStatus: "独立性",
	Confidentiality: "机密性",
	EditUserPermissionsMainTitle: "你可以编辑权限",
	EditUserPermissionsInstructions: "你编辑的是其他人的权限，而不是自己的权限。",
	PermissionSaveButtonText: "保存",
	PermissionCancelButtonText: "取消",
	UnknownCountry: "未知",
	engagementNoPermissionMesage: "你没有项目模块访问权限。",
	cacheRemoveSuccessMessage: "缓存已成功删除",
	cacheKeyNotFound: "未找到该缓存密钥的结果",

	// Portfolio labels
	informationText: "欢迎回来，",
	filterEngagements: {
		all: "所有项目",
		favorite: "收藏项目",
		review: "复核项目"
	},
	filterEngagementsLabel: "筛选项目",
	engv2noActiveEngagements: "您没有任何活跃项目。点击“添加项目”按钮开始。",
	engv2noFavoriteEngagements: "您没有任何收藏项目。若要将项目添加到此视图，请前往到所有项目并点击项目卡右下角的收藏图标。",
	engv2noReviewEngagements: "您没有任何复核项目。如果您需要权限，请联系项目组成员。",

	// Engagement Progress Labels
	engagementProgressStrategyPhaseLabel: "范围和策略阶段",
	engagementProgressExecutionPhaseLabel: "执行阶段",
	engagementProgressConclusionPhaseLabel: "总结阶段",
	engagementProgressCompletionPercentage: "完成",
	engagementProgressCompleted: "完成",
	engagementProgressNotAvailable: "本地数据中心升级后，可查看项目进度",

	// Engagement archive countdown indicator
	EngagementArchiveCountdownIndicator: {
		reportreleasedate: "报告发布日为<b>{0}</b>",
		archivedeadlinedate: "归档截止日为<b>{0}</b>",
		today: "今天",
		tommorow: "明天",
		overdue: "逾期",
		reportreleasedays: "距离报告发布日还有",
		archivedeadlinedays: "距离存档截止日还有",
		days: "天数"
	},
	postArchive: "发布归档",

	adminTool: "管理工具",
	characters: "字符",
	placeholder: "占位符",
	ofLabel: "的",
	kendoTextLimitMessage: "超过最大字符数限制",
	kendoRequiredMessage: "必填",
	engv2Errors: {
		noReviewEngagementsPermission: "您没有项目复核权限。如果您需要权限，请联系项目组成员。 ",
	},
	timeTracking: 'Time Tracker',
	timeTrackingToolMessage: "此可选工具是特别为帮助您在项目中更好地管理时间而创建。",
	timeTrackingToolMessage1: "开始记录时间后，你可以查询14天内所收集的数据汇总表。超过14天的数据将自动删除。",
	timeTrackingToolUpdatedMessage: "此可选工具可以帮助您记录工时。记录的工时最多保留两周，并且不会存档。",
	timeTrackerErrorFallBackMessage: "无法加载时间汇总表。请刷新页面并重试。如果问题仍然存在，请联系帮助台。",
	serviceGateway: "服务网关",
	reportinghubDisableMessage: "Spotfire将于2024年6月停止使用。新的EY Canvas报告可从您的EY Canvas项目中获取。2024年R1上线之后，可以从更多（More）菜单获取报告。请务必在2024年6月之前将依赖于这些报告的所有定制报告或自动化程序更新到新版本。",
	mobileVersionLabels: {
		canvas: "Canvas",
		noEngagementFound: "您没有任何活跃项目。通过您的计算机访问Canvas。",
		searchEngagementPlaceholder: "搜索……",
		search: "搜索",
		independenceIncomplete: "未确认独立性",
		profileIncomplete: "配置文件不完整",
		allEngagements: "所有项目",
		noMatchesFound: "未找到匹配项。请重试。",
		inValidUserLogin: "访问您的计算机，完成用户设置",
		pagination: {
			pageLabel: "页码",
			ofLabel: "共"
		}
	},
	independenceOnlyStatus: "仅适用于独立性",
	toggleView: "切换视图",

	// Adobe Analytics
	aaCookieConsentTitle: "欢迎访问",
	aaCookieContentPrompt: "是否允许使用cookie？",
	aaCookieConsentExplanation: "<p>除了运营本网站所需的cookie外，我们还使用以下类型的cookie来改善您的用户体验和我们的服务：<strong>功能cookie</strong>，提升用户体验（如记住设置），<strong>性能cookie</strong>，监测网站性能，改善用户体验，<strong>广告/定向cookie</strong>，由第三方设置，我们与该第三方共同执行广告活动，为您提供与您相关的广告信息。</p><p>您可查看我们的<a target=“_blank”href=“https://www.ey.com/en_us/cookie-policy“>cookie策略</a>了解更多信息</p>",
	aaCookieConsentExplanationWithDoNotTrack: "<p>除了运营本网站所需的cookie外，我们还使用以下类型的cookie来改善您的用户体验和我们的服务：<strong>功能cookie</strong>，提升用户体验（如记住设置），<strong>性能cookie</strong>，监测网站性能，改善用户体验，<strong>广告/定向cookie</strong>，由第三方设置，我们与该第三方共同执行广告活动，为您提供与您相关的广告信息。</p><p>我们已检测到您的浏览器开启了“请勿追踪”的设置；因此，广告/定向cookie将自动禁用。</p><p>您可查看我们的<a target=“_blank”href=“https://www.ey.com/en_us/cookie-policy“>cookie策略</a>了解更多信息</p>",
	aaCookieConsentDeclineOptionalAction: "我拒绝可选cookie",
	aaCookieConsentAcceptAllAction: "我接受所有cookie",
	aaCookieConsentCustomizeAction: "自定义cookie",
	aaCookieConsentCustomizeURL: 'https://www.ey.com/en_us/cookie-settings',

	// Cookie Settings
	cookieSettings: {
		title: "cookie设置",
		explanation: "请您在ey.com和“我的安永”平台上提供cookie使用许可。请选择下面列出的一种或多种cookie类型，然后点击保存。有关cookie类型及其用途的详细信息，请参阅下面的列表。",
		emptyCookieListNotice: "此应用程序中未使用此类别的cookie",
		nameTableHeader: "cookie名称",
		providerTableHeader: "cookie提供商",
		purposeTableHeader: "cookie用途",
		typeTableHeader: "cookie类型",
		durationTableHeader: "保存我的选择",
		formSubmit: "必需cookie",
		requiredCookieListTitle: "功能cookie",
		functionalCookieListTitle: "我接受以下功能cookie",
		functionalCookieAcceptance: "功能cookie，可以帮助我们增强您的用户体验（例如，通过记住您选择的任何设置）。",
		functionalCookieExplanation: "功能cookie，可以帮助我们增强您的用户体验（例如，通过记住您选择的任何设置）。",
		performanceCookieListTitle: "性能cookie",
		performanceCookieAcceptance: "我接受以下性能cookie",
		performanceCookieExplanation: "性能Cookie，可以帮助我们监测网站的性能并改善您的用户体验。在使用性能cookie时，我们不存储任何个人数据，仅使用通过这些cookie以汇总和匿名形式收集的信息。",
		advertisingCookieListTitle: "定向cookie",
		advertisingCookieAcceptance: "我接受以下广告/定向cookie",
		advertisingCookieExplanation: "广告/定向cookie，可以帮助我们跟踪用户活动和会话，以便提供更个性化的服务。 （在广告cookie的情况下）cookie由第三方设置，我们与该第三方共同执行广告活动，为您提供与您相关的广告信息。",
		doNotTrackNotice: "我们已检测到您的浏览器开启了“请勿追踪”的设置；因此，广告/定向cookie将自动禁用。",
	},

	//Copyright
	copyrightMessage: "版权©<year>版权所有",
	addWorkspace: 'Add workspace'
};

export const errors = {
	// GenericErrors Codes
	'0005': "交易待执行",
	'0006': "失败交易",
	'0008': "描述不得包含以下任何字符：\\",
	'0010': "Arc指令执行失败",

	0: "请求失败",
	928: "未经授权的访问-你无权访问所选选项。如果获得授权，请联系帮助台以获取有关如何访问的说明。",

	1009: "未找到数据服务器",
	1010: "未找到数据中心",
	1011: "尚未配置此国家/地区的数据中心",
	1013: "未找到国家ID或项目ID",
	1014: "未找到国家数据中心",
	1016: "数据库定位匹配失败",
	1017: "oDataQuery不得为零或空",
	1018: "oDataQuery不包含任何信息栏",
	1019: "未找到通用数据中心",
	1020: "用户不存在于该系统中",
	1021: "报告在所选位置不可用。请联系你当地的安永Canvsa Champion了解进一步详细信息。",

	// Scope & Strategy 1021 - 1200
	1026: "未找到行",
	1030: "未找到客户名称",

	// Restore Engagement
	1101: "无法将项目恢复至所选择国家。请刷新页面并重试。",

	// Execution   1201-1400
	1219: "未找到方法",
	1234: "职能ID不能为零或空",
	1260: "你正在处理的项目已被其他用户删除。",
	1300: "无效的请求目标",
	1301: "无效的项目职能",
	1302: "无效的用户语言",
	1303: "未找到项目用户详细信息",
	1304: "无效位置",
	1305: "未找到项目名称",
	1306: "项目名称超过最大字符长度（255个字符）",
	1307: "未找到所涵盖期间的结束日期",
	1308: "无效日期",
	1309: "未找到审计报告日期",
	1310: "未找到项目存档截止日期",
	1311: "项目存档截止日期不得早于今天",
	1312: "项目有重复的项目代码",
	1313: "未找到位置",
	1314: "未找到工作区名称",
	1315: "工作区名称超过最大字符长度（255个字符）",
	1316: "客户代码称超过最大字符长度（50个字符）",
	1317: "客户名称超过最大字符长度（255个字符）",
	1318: "指示工作区是否用于培训的标示值无效",
	1319: "未找到指示工作区用于培训的标示",
	1320: "未找到指示客户是否已验证的标示",
	1321: "指示客户是否已验证的标示值无效",
	1322: "无效客户ID",
	1323: "未找到项目代码",
	1324: "未找到项目ID",
	1325: "无效的主审办公室ID",
	1052: "无效业务线ID",
	1053: "无效子业务线ID",
	1326: "未找到国家/地区ID",
	1327: "未找到工作区ID",
	1328: "项目组成员状态无效",
	1329: "项目状态无效",
	1330: "其他工作组成员已删除项目",
	1331: "未发现年终日期",
	1339: "超过允许的最大项目数量",
	1340: "未找到位置详细信息",
	1341: "未正确接收需要更新的项目数据",
	1342: "未找到当前项目的项目详细信息",
	1343: "未找到当前项目的项目汇总数据",
	1344: "选中ID的项目不存在",
	1359: "其中一个正在进行的项目的数据中心ID无效",
	1363: "Universal里的项目创建失败",
	1364: "LDC里的项目创建失败",
	1373: "选择现有工作区或创建新工作区",
	1374: "项目名称已经存在。请输入新的项目名称以导入模板。",
	1375: "无法更新不正确的过期天数。",
	1379: "内容刷新中。请稍后再试。如问题长时间未解决，请联系IT帮助台。",
	1380: "无法完成重新归档。刷新页面，然后重试。如果问题仍然存在，请联系帮助台。",
	1381: "创建/后推/还原项目失败，因为Workspace与项目之间的国家ID不匹配。",
	1382: "创建/后推/还原项目失败，因为Workspace与项目之间的主要办事处不匹配。",
	1384: "此项目的恢复或后推已在进行中。请稍后重试。",
	1386: "工作区不适用于新项目。请刷新页面并重试。如果问题仍然存在，请联系帮助台。",
	1385: "无法在所选位置创建项目。请刷新页面并重试。如果问题仍然存在，请联系帮助台。",
	1389: "无法更新过期的审阅项目。",
	// Invite Team Members (1401 - 1500)
	1403: "项目欢迎信息超过最大字符长度（200个字符）",
	1406: "未找到项目集合",
	1424: "未找到项目或用户详细信息",
	1425: "未找到项目信息",

	// User details Error Codes (1426 - 1434)
	1426: "必须输入名字",
	1427: "必须输入姓氏",
	1428: "必须输入首字母缩写",
	1429: "必须输入主要办事处",
	1430: "必须输入首选电话号码",
	1431: "必须输入语言",
	1432: "必须输入首选日期格式",
	1433: "必须输入首选数字格式",
	1434: "用户详细信息不得为零或空",
	1436: "发现冲突。如果问题仍然存在，请联系帮助台。",
	1438: "用户集合不得为空",
	1448: "所选国家没有更新到最新版EY Canvas。请咨询EY Atlas了解部署安排，并在更新完成后重试。如果问题仍然存在，请联系帮助台。",

	1502: "此时无法完成操作。刷新页面并重试。如果问题仍然存在，请联系帮助台。",
	1504: "此时无法完成操作。刷新页面并重试。如果问题仍然存在，请联系帮助台。",
	1505: "输入值无效",

	// Common control errors (1601 - 1700)
	1601: "百分比无效",
	18000: "ARC处理错误：由于ARC正在进行维护，无法执行请求的操作。请稍后再试。",
	18306: "ARC处理错误：找不到与Canvas存档GUID相关的ARC表格",
	18307: "ARC处理错误：该项目已在ARC中删除。不得继续处理选中的选项。如果需要进一步帮助，请联系你的ARC管理员。",
	18309: "ARC处理错误：由于存档的项目受管理限制,无法执行请求的操作。如果需要进一步帮助，请联系你的ARC管理员",
	18320: "ARC处理错误：ACR表格处于保留（暂停）状态，无法修改。",
	18327: "ARC处理错误：ARC处于保留状态以进行删除，无法修改。",
	18328: "ARC处理错误：由于项目已在ARC中标记为删除，无法执行请求的操作。如果需要进一步帮助，请联系你的ARC管理员。",
	18329: "ARC处理错误：合伙人无法访问此项目。如果需要进一步帮助，请联系你的ARC管理员。",
	18330: "ARC处理错误：由于监管限制，项目被锁定，因此无法执行请求的操作。如果需要进一步帮助，请联系你的ARC管理员。",
	2027: "错误的请求",
	2046: "未授权",
	2047: "禁止访问",
	2048: "未找到记录",
	2049: "请求实体规模过大",
	2052: "不可接受",
	2053: "检索数据时出错。刷新页面并重试。如果问题仍然存在，请联系帮助台。",
	2056: "发生一般性错误",
	2057: "服务不可用",

	// Roll Forward Error Codes (2301-2400)
	2301: "在后推过程中，未找到存档文件",
	2302: "在后推过程中获取存档参考的指令执行失败",
	2303: "在后推过程中，返回的存档文件具有空的唯一标识符",
	2304: "在后推过程中，返回的存档文件具有空的文件存储URL",
	2305: "在后推过程中，返回的存档文件具有空散列值",
	2306: "用户无权执行此操作",
	2307: "已超过允许的还原副本数",

	// Link Error Codes
	2568: "因为你不是当前联系人，链接不再可用。请刷新页面。",
	3005: "主审小组已从集团项目中删除组成部分",
	2571: "链接请求已更新。请刷新页面。",

	// Error for v1 engagement not supported features
	4001: "选中的项目是在旧版本的EY Canvas中创建的，在选中的位置本地数据中心升级到最新版本的EY Canvas之前无法还原。请与你当地的帮助台联系以获取更多信息。",
	4002: "选中的项目是在旧版本的EY Canvas中创建的，在本地数据中心升级到最新版本之前，请求访问不可用。请与你当地的帮助台联系以获取更多信息。",
	4214: "无法将模板导入选中位置中的工作区。请选择其他位置，然后重试",

	// Common framework error
	4205: "未找到复制请求详细信息",
	401000: "检测到网络更改，请重新加载页面以继续",

	// CopyHub concurrency scenarios
	4217: "数据包请求的状态已更改。刷新页面并重试。",
	4219: "由于复制流程升级中，此版本不支持在不同版本的EY Canvas之间复制。查看EY Atlas可了解升级安排的详细信息。你可以在数据中心升级完成后进行复制。",
	4220: "该国不支持使用本模板。",
	4221: "该国不支持使用本模板。",
	4222: "只有目标地区升级到最新版EY Canvas后，才能完成导入。选择其他地区继续。如果问题仍然存在，请联系帮助台。",

	// Error for Mobility API
	4501: "项目中没有可用的任务",
	4502: "项目中没有待办任务",

	// Counrty API version error
	5001: "选中的位置存在于旧版本的EY Canvas中，在选中的位置本地数据中心升级到最新版本的EY Canvas之前，无法将项目后推到新的工作区。请与你当地的帮助台联系以获取更多信息",

	//CRP checks
	5101: "由于目前无可用的Canvas外部访问端口，因此无法针对此项目创建复核副本。请联系帮助台以获取进一步信息。",
	5102: "该项目正在进行CRP复制。请稍后重试。",
	5103: "由于目前无可用的Canvas外部访问端口，因此无法针对此项目编辑复核副本。请联系帮助台以获取进一步信息。",
	5104: "本项目无法启用文档限制功能。请取消勾选选项并重试。",

	18100: "未找到业务线。",
	18101: "未找到子业务线。",

	//RBAC realted error messages.
	935: "权限不足，无法执行此操作。请与项目管理员联系，并请求权限以执行此操作。",
	8001: "当前无法完成此操作。请刷新页面并重试。如问题仍然存在，请联系帮助台。",
	336699: "无法加载时间汇总表。请刷新页面并重试。如果问题仍然存在，请联系帮助台。",

	//Concurrency errors
	7821: "您已经回复了此邀请，请刷新页面重试。",

	/*Information Security */
	200001: "安永信息安全部禁止执行该操作。请刷新页面并重试。如问题仍然存在，请联系帮助台。"
};

export const adminErrors = {
	1332: "重新尝试归档触发时出现错误",
	1501: "用户不活跃请刷新页面并重试",
	156: "访问此模块的权限不足",
	70740: "无法保存更改。项目必须至少具有一位活跃成员，该成员具有项目管理权限并已经解决了独立性。如仍有问题，请联系帮助台。",
	70790: "域名无权限。如仍有问题，请联系帮助台。",
	70800: "用户无效，无法邀请第三方用户。",
	70810: "为第三方用户选定的RBAC角色无效/不活跃。",
	70820: "为第三方用户选择有效的RBAC角色。",
	70830: "为第三方用户选择有效的只读角色。"
};

export const actionTypes = {
	1: "系统消息",
	2: "项目邀请",
	3: "已拒绝访问",
	4: "项目删除请求",
	5: "项目创建出错",
	6: "项目访问请求",
	7: "删除项目",
	8: "已存档项目",
	9: "停用项目",
	10: "逾越存档截止日",
	11: "集团链接请求",
	12: "已收到集团指引",
	13: "已收到副本",
	14: "进行中项目"
};
export const timephaseType = {
	'-1': "所涵盖期间的结束日期",
	'-2': "报告日期",
	'-3': "存档截止日期",
	'1': "范围",
	'2': "策略",
	'3': "穿行测试",
	'4': "中期控制测试",
	'5': "中期实质性测试",
	'6': "穿行测试（年终流程）",
	'7': "年终控制测试",
	'8': "年终实质性测试",
	'9': "结论",
	'10': "不适用",
	'11': "执行",
	'12': "范围和策略质量活动",
	'13': "其他范围和策略",
	'14': "综合风险评估",
	'15': "风险评估和重要性",
	'16': "最终确定范围和策略",
	'17': "中期审计后项目管理"
};
export const dataExport = [
	"存档表格",
	"Canvas表格",
	"协作",
	"控制表格",
	"文档",
	"任务",
	"项目组成员",
	"试算表"
];
export const dataExportReportNames = {
	'存档表格': 'Archive Form',
	'Canvas表格': 'Canvas Form Report',
	'协作': 'Collaboration',
	'控制表格': 'Control Report',
	'文档': 'All Documents Report',
	'任务': 'All Tasks Report',
	'项目组成员': 'Team Members',
	'试算表': '数据报告'
};
export const projectManagement = [
	"协作详细信息",
	"数字记分卡",
	"里程碑",
	"复核注释",
	"验证检查"
];
export const projectManagementReportNames = {
	'协作详细信息': 'Client Collaboration Dashboard',
	'里程碑': 'Milestone Report',
	'复核注释': 'Review Notes Report',
	'验证检查': 'Validation Checks Report'
};
export const summaryScreenDefaults = [
	"审计计划报告",
	"有相关证据的审计计划",
	"控制复核工具（CRT）",
	"综合风险评估汇总表",
	"发现",
	"IT App & SO 汇总表",
	"重大交易类别汇总表",
	"SEM"
];
export const summaryScreenDefaultsReportNames = {
	'审计计划报告': 'Audit Plan Report',
	'有相关证据的审计计划': 'Audit Plan with Related Evidence',
	'控制复核工具（CRT）': 'Controls Review Tool (CRT)',
	'综合风险评估汇总表': 'CRA Summary',
	'发现': 'Findings',
	'IT App & SO 汇总表': 'ITSO CRT',
	'重大交易类别汇总表': 'SCOT Summary',
	'SEM': 'SEM'
};
export const summaryScreenInterim = [
	"工作计划报告",
	"有相关证据的工作计划"
];
export const summaryScreenInterimReportNames = {
	'工作计划报告': 'Audit Plan Report',
	'有相关证据的工作计划': 'Audit Plan with Related Evidence'
};
export const summaryScreenGroupAudit = [
	"审计计划报告",
	"有相关证据的审计计划",
	"控制复核工具（CRT）",
	"综合风险评估汇总表",
	"发现",
	"集团指令汇总表",
	"集团报告",
	"集团结构汇总表",
	"IT App & SO 汇总表",
	"重大交易类别汇总表",
	"SEM"
];
export const summaryScreenGroupAuditReportNames = {
	'审计计划报告': 'Audit Plan Report',
	'有相关证据的审计计划': 'Audit Plan with Related Evidence',
	'控制复核工具（CRT）': 'Controls Review Tool (CRT)',
	'综合风险评估汇总表': 'CRA Summary',
	'发现': 'Findings',
	'集团指令汇总表': 'Group Instruction Summary',
	'集团报告': 'Group Report',
	'集团结构汇总表': 'Group Structure Summary',
	'IT App & SO 汇总表': 'ITSO CRT',
	'重大交易类别汇总表': 'SCOT Summary',
	'SEM': 'SEM'
};
export const summaryScreenGroupAuditInterim = [
	"集团指令汇总表",
	"集团结构汇总表",
	"工作计划报告",
	"有相关证据的工作计划"
];
export const summaryScreenGroupAuditInterimReportNames = {
	'集团指令汇总表': 'Group Instruction Summary',
	'集团结构汇总表': 'Group Structure Summary',
	'工作计划报告': 'Audit Plan Report',
	'有相关证据的工作计划': 'Audit Plan with Related Evidence'
};
export const rolelistitems = [{
	id: 8,
	label: "审计员"
},
{
	id: 7,
	label: "高级审计员"
},
{
	id: 6,
	label: "经理"
},
{
	id: 5,
	label: "高级经理"
},
{
	id: 3,
	label: "执行总监"
},
{
	id: 4,
	label: "负责人"
},
{
	id: 11,
	label: "其他合伙人"
},
{
	id: 2,
	label: "项目合伙人"
},
{
	id: 1,
	label: "项目负责合伙人"
},
{
	id: 10,
	label: "项目质量复核人"
},
{
	id: 17,
	label: "审计质量复核人"
},
{
	id: 16,
	label: "总法律顾问"
},
{
	id: 12,
	label: "GDS - 审计员"
},
{
	id: 30,
	label: "GDS - 高级审计员"
},
{
	id: 31,
	label: "GDS - 经理"
}
];
export const roleType = {
	'11': "其他合伙人",
	'24': "其他（例如，尽职调查复核）",
	'15': "负责人支持服务",
	'18': "ML组成部分小组",
	'17': "审计质量复核人",
	'1': "项目负责合伙人",
	'2': "项目合伙人",
	'9': "实习生",
	'13': "咨询（财务审计信息技术、财务交易咨询服务、人力资本或其他）",
	'14': "税务",
	'27': "行业",
	'10': "项目质量复核人",
	'25': "办事处",
	'8': "审计员",
	'20': "客户员工",
	'19': "客户主管",
	'5': "高级经理",
	'16': "总法律顾问",
	'26': "地区",
	'23': "监管机构",
	'22': "内部审计审计员",
	'3': "执行总监",
	'21': "内部审计主管",
	'6': "经理",
	'4': "负责人",
	'7': "高级审计员",
	'12': "全球人才中心",
	'28': "全国",
	'29': "全球",
	'12': "GDS - 审计员",
	'30': "GDS - 高级审计员",
	'31': "GDS - 经理"
};

export const copyObjectGroupType = {
	1: "项目",
	2: "账户",
	3: "重大交易类别",
	4: "IT应用程序和服务机构",
	5: "请求",
	6: "证据",
	7: "数量",
	8: "请求和外部任务",
	9: "临时文件",
	10: "前期证据",
	11: "近期删除文件",
	12: "历史文件",
	13: "项目模板",
	14: "恢复GCO副本",
	15: "外部复核副本",
	16: "活跃项目外部复核",
	17: "Canvas表格",
	20: "证据",
	21: "项目",
	22: "账户",
	23: "重大交易类别",
	24: "项目后推",
	27: "外部证据",
	28: "引导式工作流程",
	29: 'Engagement by Entity'
};

export const EngagementStatusText = [
	"未定义",
	"活跃",
	"已停用",
	"已存档",
	"已删除",
	"存档中",
	"重新激活",
	"已恢复",
	"为删除而作标记",
	"存档出错",
	"后推出错",
	"重新激活出错",
	"恢复出错",
	"后推中",
	"重新激活中",
	"恢复中",
	"Gamx迁移中",
	"Gamx迁移出错",
	"创建交易待处理",
	"创建交易失败",
	"更新交易待处理",
	"更新交易失败",
	"复制中",
	"复制出错",
	"复制模板出错",
	"外部复核项目进行中",
	"外部复核项目出错",
	"外部复核项目"
];
export const overdueIssues = {
	partnerInvitationPending: "合伙人邀请待处理",
	eqrInvitationPending: "项目质量复核人邀请待处理",
	invalidEngagementCode: "无效的项目代码",
	overdueArchiveDeadline: "超过存档截止日期",
	upcomingArchiveDeadline: "即将到期的存档截止日期",
	contentDeliveryInProgress: "内容传送中",
	overdueMilestones: "逾期的审计小组里程碑",
	overduePartnerTasks: "合伙人任务逾期",
	overdueEqrTasks: "项目质量复核任务逾期"
};
export const engagementMetricNames = [{
	propName: 'preparer',
	value: "编制"
},
{
	propName: 'forMyReview',
	value: "复核"
},
{
	propName: 'upcomingReview',
	value: "下一项复核"
},
{
	propName: 'reviewNotesAssigned',
	value: "我收到的复核注释"
},
{
	propName: 'reviewNotesAuthored',
	value: "我发出的复核注释"
},
{
	propName: 'clientRequests',
	value: "请求"
},
{
	propName: 'timephases',
	value: "时间段"
},
{
	propName: 'groupTasks',
	value: "集团任务"
},
{
	propName: 'groupDeliverables',
	value: "集团可交付任务"
},
{
	propName: 'automationDocuments',
	value: "自动化"
}
];
export const metricFilterComingDue = [{
	value: 1,
	label: "明天到期"
},
{
	value: 3,
	label: "3天后到期"
},
{
	value: 5,
	label: "5天后到期",
	isDefault: true
},
{
	value: 10,
	label: "10天后到期"
},
{
	value: 30,
	label: "30天后到期",
	isMax: true
}
];
export const metricFilterWhatsNew = [{
	value: 1,
	label: "昨天有什么新变化"
},
{
	value: 3,
	label: "最近3天有什么新变化"
},
{
	value: 5,
	label: "最近5天有什么新变化",
	isDefault: true
},
{
	value: 10,
	label: "最近10天有什么新变化",
	isMax: true
}
];

export const type1Alerts = [
	"hasPartnerInvited",
	"hasEqrInvited",
	"hasValidEngagementCode",
	"overdueArchiveDeadline"
];

export const engagementStatuses = {
	1: "活跃",
	2: "已停用",
	3: "已存档",
	4: "已删除",
	5: "存档中",
	6: "重新激活",
	7: "已恢复",
	8: "为删除而作标记",
	9: "存档出错",
	10: "后推出错",
	11: "重新激活出错",
	12: "恢复出错",
	13: "后推中",
	14: "重新激活中",
	15: "恢复中",
	16: "Gamx迁移中",
	17: "Gamx迁移出错",
	18: "创建交易待处理",
	19: "创建交易失败",
	20: "更新交易待处理",
	21: "更新交易失败",
	22: "复制中",
	23: "复制出错",
	24: "复制模板出错",
	25: "外部复核项目进行中",
	26: "外部复核项目出错",
	27: "外部复核项目",
	28: "从本土化到全球化",
	29: "从本土化到全球化(已存档)",
	30: "内容传送中",
	31: "内容传送出错",
	32: "内容传送失败",
	33: "Canvas外部访问端口V2",
	34: "Canvas外部访问端口V2进行中",
	35: "Canvas外部访问端口V2错误",
	100: "数据中心分拆"
};

export const overrideArchiveDeadlineDateOptions = [{
	value: 0,
	label: "默认"
},
{
	value: 10,
	label: "10 天"
},
{
	value: 14,
	label: "14 天"
},
{
	value: 20,
	label: "20 天"
},
{
	value: 21,
	label: "21 天"
},
{
	value: 25,
	label: "25 天"
},
{
	value: 30,
	label: "30 天"
},
{
	value: 45,
	label: "45 天"
}
];
export const ExternalReviewCopyExpiryDays = [{
	value: 30,
	label: "30 天"
},
{
	value: 60,
	label: "60 天"
},
{
	value: 90,
	label: "90 天"
},
{
	value: 180,
	label: "180 天"
}
];

export const serviceLines = [{
	servicelineid: 1,
	servicelinename: "审计"
},
{
	servicelineid: 3,
	servicelinename: "策略和交易"
},
{
	servicelineid: 2,
	servicelinename: "咨询"
},
{
	servicelineid: 4,
	servicelinename: "税务"
},
{
	servicelineid: 5,
	servicelinename: "法律顾问办公室"
}
];

export const subServiceLines = [{
	subservicelineid: 1,
	servicelineid: 1,
	subservicelinename: "审计"
},
{
	subservicelineid: 2,
	servicelineid: 1,
	subservicelinename: "FIDS"
},
{
	subservicelineid: 3,
	servicelineid: 2,
	subservicelinename: "信息技术风险咨询"
},
{
	subservicelineid: 9,
	servicelineid: 1,
	subservicelinename: "财务会计咨询服务"
},
{
	subservicelineid: 10,
	servicelineid: 1,
	subservicelinename: "会计合规及报告"
},
{
	subservicelineid: 11,
	servicelineid: 1,
	subservicelinename: "气候变化与可持续发展服务"
},
{
	subservicelineid: 4,
	servicelineid: 3,
	subservicelinename: "资本转换"
},
{
	subservicelineid: 5,
	servicelineid: 3,
	subservicelinename: "交易支持"
},
{
	subservicelineid: 6,
	servicelineid: 2,
	subservicelinename: "业绩改进"
},
{
	subservicelineid: 7,
	servicelineid: 2,
	subservicelinename: "风险"
},
{
	subservicelineid: 7,
	servicelineid: 23,
	subservicelinename: "业务咨询"
},
{
	subservicelineid: 24,
	servicelineid: 6,
	subservicelinename: "其他"
},
{
	subservicelineid: 8,
	servicelineid: 2,
	subservicelinename: "其他"
},
{
	subservicelineid: 12,
	servicelineid: 4,
	subservicelinename: "企业税务服务"
},
{
	subservicelineid: 13,
	servicelineid: 4,
	subservicelinename: "人力资本"
},
{
	subservicelineid: 14,
	servicelineid: 4,
	subservicelinename: "法律"
},
{
	subservicelineid: 15,
	servicelineid: 4,
	subservicelinename: "间接税"
},
{
	subservicelineid: 16,
	servicelineid: 4,
	subservicelinename: "全球合规及报告"
},
{
	subservicelineid: 17,
	servicelineid: 4,
	subservicelinename: "交易税务"
},
{
	subservicelineid: 18,
	servicelineid: 4,
	subservicelinename: "国际税务服务"
},
{
	subservicelineid: 19,
	servicelineid: 5,
	subservicelinename: "法律顾问办公室"
},
{
	subservicelineid: 20,
	servicelineid: 2,
	subservicelinename: "业务咨询"
},
{
	subservicelineid: 21,
	servicelineid: 2,
	subservicelinename: "技术咨询"
},
{
	subservicelineid: 22,
	servicelineid: 3,
	subservicelinename: "交易和公司金融"
},
{
	subservicelineid: 23,
	servicelineid: 3,
	subservicelinename: "EY Parthenon"
},
{
	subservicelineid: 25,
	servicelineid: 1,
	subservicelinename: "技术风险"
}
];

export const modules = [{
	id: 1,
	name: "管理访问权限"
},
{
	id: 2,
	name: "项目"
},
{
	id: 3,
	name: "权限集团"
},
{
	id: 4,
	name: "模板"
},
{
	id: 5,
	name: "发信"
},
{
	id: 6,
	name: "缓存"
},
{
	id: 7,
	name: "数据库定位器"
},
{
	id: 8,
	name: "文档"
},
{
	id: 9,
	name: "SharePoint工作区"
}
];

export const roles = [{
	id: 17,
	ModuleId: 1,
	name: "只读"
},
{
	id: 18,
	ModuleId: 1,
	name: "添加/删除用户和权限"
},
{
	id: 19,
	ModuleId: 2,
	name: "只读"
},
{
	id: 20,
	ModuleId: 2,
	name: "更新项目"
},
{
	id: 21,
	ModuleId: 2,
	name: "邀请项目组成员"
},
{
	id: 22,
	ModuleId: 2,
	name: "更新项目用户"
},
{
	id: 23,
	ModuleId: 3,
	name: "只读"
},
{
	id: 24,
	ModuleId: 3,
	name: "添加/删除成员"
},
{
	id: 25,
	ModuleId: 3,
	name: "同步数据库定位器"
},
{
	id: 26,
	ModuleId: 4,
	name: "只读"
},
{
	id: 27,
	ModuleId: 4,
	name: "更新模板"
},
{
	id: 28,
	ModuleId: 4,
	name: "删除模板"
},
{
	id: 29,
	ModuleId: 5,
	name: "只读"
},
{
	id: 30,
	ModuleId: 5,
	name: "创建/更新消息"
},
{
	id: 31,
	ModuleId: 5,
	name: "删除消息"
},
{
	id: 32,
	ModuleId: 6,
	name: "阅读并删除缓存"
},
{
	id: 33,
	ModuleId: 8,
	name: "访问文档"
},
{
	id: 34,
	ModuleId: 2,
	name: "重新尝试归档"
},
{
	id: 38,
	ModuleId: 9,
	name: "只读"
},
{
	id: 39,
	ModuleId: 9,
	name: "添加工作区"
},
{
	id: 40,
	ModuleId: 9,
	name: "删除工作区"
}
];

export const countries = {
	1: "马约特岛",
	2: "英属维尔京群岛",
	3: "西班牙",
	4: "伯利兹",
	5: "秘鲁",
	6: "斯洛伐克",
	7: "委内瑞拉",
	8: "挪威",
	9: "福克兰群岛（马尔维纳斯）",
	10: "莫桑比克",
	11: "中国大陆",
	12: "苏丹",
	13: "以色列",
	14: "比利时",
	15: "沙特阿拉伯",
	16: "直布罗陀",
	17: "关岛",
	18: "诺福克群岛",
	19: "赞比亚",
	20: "留尼汪",
	21: "阿塞拜疆",
	22: "圣赫勒拿岛",
	23: "伊朗",
	24: "摩纳哥",
	25: "圣皮埃尔和密克隆群岛",
	26: "新西兰",
	27: "库克群岛",
	28: "圣卢西亚岛",
	29: "津巴布韦",
	30: "伊拉克",
	31: "汤加",
	32: "美属萨摩亚",
	33: "马尔代夫",
	34: "摩洛哥",
	35: "国际审计准则（ISA）",
	36: "阿尔巴尼亚",
	37: "阿富汗",
	38: "冈比亚",
	39: "布基纳法索",
	40: "托克劳",
	41: "利比亚",
	42: "加拿大",
	43: "阿拉伯联合酋长国",
	44: "朝鲜",
	45: "蒙特塞拉特",
	46: "格陵兰",
	47: "卢旺达",
	48: "斐济",
	49: "吉布提",
	50: "博茨瓦纳",
	51: "科威特",
	52: "马达加斯加",
	53: "马恩岛",
	54: "匈牙利",
	55: "纳米比亚",
	56: "马耳他",
	57: "泽西岛",
	58: "泰国",
	59: "圣基茨和尼维斯",
	60: "不丹",
	61: "巴拿马",
	62: "索马里",
	63: "巴林",
	64: "波斯尼亚和黑塞哥维那",
	65: "法国",
	66: "韩国",
	67: "冰岛",
	68: "葡萄牙",
	69: "突尼斯",
	70: "加纳",
	71: "喀麦隆",
	72: "希腊",
	73: "法属南部领地",
	74: "赫德岛和麦克唐纳群岛",
	75: "安道尔",
	76: "卢森堡",
	77: "萨摩亚",
	78: "安圭拉岛",
	79: "荷兰",
	80: "几内亚比绍",
	81: "尼加拉瓜",
	82: "巴拉圭",
	83: "安提瓜和巴布达",
	84: "国际财务报告准则（IFRS）",
	85: "尼日尔",
	86: "埃及",
	87: "梵蒂冈",
	88: "拉脱维亚",
	89: "塞浦路斯",
	90: "美国本土外小岛屿",
	91: "俄罗斯其他法律实体",
	92: "圣文森特和格林纳丁斯",
	93: "根西岛",
	94: "布隆迪",
	95: "古巴",
	96: "赤道几内亚",
	97: "英属印度洋领地",
	98: "瑞典",
	99: "乌干达",
	100: "北马其顿",
	101: "斯威士兰",
	102: "萨尔瓦多",
	103: "吉尔吉斯斯坦",
	104: "爱尔兰",
	105: "哈萨克斯坦",
	106: "洪都拉斯",
	107: "乌拉圭",
	108: "格鲁吉亚",
	109: "特立尼达和多巴哥",
	110: "巴勒斯坦",
	111: "马提尼克岛",
	112: "奥兰群岛",
	113: "法属波利尼西亚",
	114: "科特迪瓦",
	115: "黑山共和国",
	116: "南非",
	117: "南乔治亚岛和南桑威奇群岛",
	118: "也门",
	119: "中国香港",
	120: "肯尼亚",
	121: "乍得",
	122: "哥伦比亚",
	123: "哥斯达黎加",
	124: "安哥拉",
	125: "立陶宛",
	126: "叙利亚",
	127: "马来西亚",
	128: "塞拉利昂",
	129: "塞尔维亚",
	130: "波兰",
	131: "苏里南",
	132: "海地",
	133: "瑙鲁",
	134: "圣多美和普林西比",
	135: "斯瓦尔巴和扬·马延",
	136: "新加坡",
	137: "摩尔多瓦",
	138: "中国台湾",
	139: "塞内加尔",
	140: "加蓬",
	141: "墨西哥",
	142: "塞舌尔",
	143: "密克罗尼西亚联邦",
	144: "阿尔及利亚",
	145: "意大利",
	146: "圣马力诺",
	147: "利比里亚",
	148: "巴西",
	149: "克罗地亚",
	150: "法罗群岛",
	151: "帕劳",
	152: "芬兰",
	153: "菲律宾",
	154: "牙买加",
	155: "法属圭亚那",
	156: "佛得角",
	157: "缅甸",
	158: "莱索托",
	159: "美属维尔京群岛",
	160: "开曼群岛",
	161: "纽埃",
	162: "多哥",
	163: "白俄罗斯",
	164: "多米尼加",
	165: "印度尼西亚",
	166: "乌兹别克斯坦",
	167: "尼日利亚",
	168: "瓦利斯和富图纳群岛",
	169: "巴巴多斯",
	170: "斯里兰卡",
	171: "英国",
	172: "厄瓜多尔",
	173: "瓜德罗普岛",
	174: "老挝",
	175: "约旦",
	176: "所罗门群岛",
	177: "东帝汶",
	178: "黎巴嫩",
	179: "中非共和国",
	180: "印度",
	181: "圣诞岛",
	182: "瓦努阿图",
	183: "文莱",
	184: "孟加拉国",
	185: "南极洲",
	186: "玻利维亚",
	187: "土耳其",
	188: "巴哈马",
	189: "科摩罗",
	190: "西撒哈拉",
	191: "捷克共和国",
	192: "乌克兰",
	193: "爱沙尼亚",
	194: "保加利亚",
	195: "毛里塔尼亚",
	196: "刚果民主共和国",
	197: "列支敦士登",
	198: "皮特凯恩",
	199: "丹麦",
	200: "马绍尔群岛",
	201: "日本",
	202: "奥地利",
	203: "阿曼",
	204: "蒙古国",
	205: "塔吉克斯坦",
	206: "瑞士",
	207: "危地马拉",
	208: "厄立特里亚",
	209: "尼泊尔",
	210: "马里",
	211: "斯洛文尼亚",
	212: "北马里亚纳群岛",
	213: "（不适用）",
	214: "阿鲁巴",
	215: "刚果",
	216: "卡塔尔",
	217: "几内亚",
	218: "美国",
	219: "埃塞俄比亚",
	220: "其他",
	221: "圭亚那",
	222: "德国",
	223: "百慕大",
	224: "特克斯和凯科斯群岛",
	225: "澳大利亚",
	226: "基里巴斯",
	227: "波多黎各",
	228: "巴基斯坦",
	229: "毛里求斯",
	230: "马拉维",
	231: "土库曼斯坦",
	232: "柬埔寨",
	233: "智利",
	234: "新喀里多尼亚",
	235: "巴布亚新几内亚",
	236: "布维岛",
	237: "图瓦卢",
	238: "库拉索",
	239: "多米尼加共和国",
	240: "越南",
	241: "科科斯（基林）群岛",
	242: "格林纳达",
	243: "坦桑尼亚",
	244: "阿根廷",
	245: "中国澳门",
	246: "贝宁",
	247: "罗马尼亚",
	248: "亚美尼亚",
	249: "全球",
	250: "中小企业国际财务报告准则",
	251: "美国一般公认会计准则",
	252: "AICPA中小企业财务报告框架",
	253: "南苏丹",
	254: "科索沃",
	255: "美国-政府",
	256: "俄罗斯安永和安永Vneshaudit",
	257: "美国-俄罗斯DC",
	258: "白俄罗斯-俄罗斯DC",
	259: "乌兹别克斯坦-俄罗斯DC"
};

export const common = {
	selected: "已选定",
	cancel: "取消",
	checkbox: {
		selectAllOn: "选择全部",
		selectAllOff: "全部取消选择"
	},
	clear: "清空",
	close: "关闭",
	pagination: {
		show: "显示",
		iconHover: {
			first: "第一页",
			previous: "上一页",
			next: "下一页",
			last: "最后一页"
		}
	},
	engv2Pagination: {
		show: "显示",
		iconHover: {
			first: "首页",
			previous: "上一页",
			next: "下一页",
			last: "最后一页"
		}
	},
	search: {
		clear: "清空搜索",
		buttonTitle: "搜索",
		placeholder: "搜索",
		noResultsPlaceholder: "未发现结果"
	},
	table: {
		remove: "删除",
		noDataPlaceholder: "无可用数据",
		noResultsPlaceholder: "未发现结果"
	},
	toggleSwitch: {
		no: "否",
		yes: "是"
	},
	wizard: {
		next: "继续",
		back: "返回",
		finish: "完成"
	}
};

export const ConfidentialityTypes = [{
	id: 0,
	Name: "默认"
},
{
	id: 10,
	Name: "低"
},
{
	id: 12,
	Name: "中"
},
{
	id: 11,
	Name: "高"
}
];

export const internalUserRoles = [{
	roleKey: 3,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "权限",
	roleNameUI: "归档项目",
	displayName: "归档项目",
	roleDisplayOrder: 2,
	tooltipText: "能够归档项目"
},
{
	roleKey: 4,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "权限",
	roleNameUI: "管理项目",
	displayName: "管理项目",
	roleDisplayOrder: 1,
	tooltipText: "能够邀请内部和外部成员并分配权限给内部成员"
},
{
	roleKey: 5,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "权限",
	roleNameUI: "更新内容",
	displayName: "更新内容",
	roleDisplayOrder: 6,
	tooltipText: "能够编辑配置并接受内容更新"
},
{
	roleKey: 7,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "权限",
	roleNameUI: "创建服务请求",
	displayName: "管理服务请求",
	roleDisplayOrder: 4,
	tooltipText: "创建服务请求并将服务请求标记为已完成的权限"
},
{
	roleKey: 8,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "权限",
	roleNameUI: "管理分组",
	displayName: "管理分组",
	roleDisplayOrder: 5,
	tooltipText: "接受或拒绝主审小组或地区小组发送的集团指引"
},
{
	roleKey: 9,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "权限",
	roleNameUI: "复制、后推并恢复项目",
	displayName: "复制、后推并恢复项目",
	roleDisplayOrder: 3,
	tooltipText: "能够复制项目、后推和恢复其存档"
},
{
	roleKey: 0,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "机密性",
	roleNameUI: "默认",
	displayName: "无（默认）",
	roleDisplayOrder: 1,
	tooltipText: "默认机密性文档角色"
},
{
	roleKey: 10,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "机密性",
	roleNameUI: "低",
	displayName: "低",
	roleDisplayOrder: 8,
	tooltipText: "低机密性文档角色"
},
{
	roleKey: 11,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "机密性",
	roleNameUI: "高",
	displayName: "高",
	roleDisplayOrder: 10,
	tooltipText: "高机密性文档角色"
},
{
	roleKey: 12,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "机密性",
	roleNameUI: "中",
	displayName: "中",
	roleDisplayOrder: 9,
	tooltipText: "中等机密性文档角色"
},
// {
// roleKey: 13,
// sectionId:1,
// groupDisplayOrder: 200,
// groupName:"Confidentiality",
// roleNameUI: "Confidential",
// displayName: "Confidential",
// roleDisplayOrder: 11,
// tooltipText: 'Strictly Confidential Documents Role'
// },
{
	roleKey: 14,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "限制",
	roleNameUI: "只读",
	displayName: "只读",
	roleDisplayOrder: 12,
	tooltipText: "此功能应仅用于向第三方复核人授予本EY Canvas项目的权限。应从专业业务部获得适当的批准和授权。"
},
{
	roleKey: 16,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "限制",
	roleNameUI: "仅复核",
	displayName: "仅复核",
	roleDisplayOrder: 13,
	tooltipText: "“复核”仅赋予用户查看Canvas项目、签名中的信息和使用复核附注功能的能力。当其他安永用户需要复核此项目（如集团审计主审小组）但无法编辑时，应使用该功能。",
},
{
	roleKey: 37,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "限制",
	roleNameUI: "仅适用于独立性",
	displayName: "仅适用于独立性",
	roleDisplayOrder: 14,
	tooltipText: "仅适用于独立性选项为用户提供了可以完成项目独立性表格，但无法完全访问EY Canvas项目的权限。当用户为项目执行了工作但不需要拥有对EY Canvas的访问权限时，可以使用此选项。",
},
{
	roleKey: 6,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "权限",
	roleNameUI: "撤销编辑",
	displayName: "回滚文档",
	roleDisplayOrder: 7,
	tooltipText: "如果在报告发布日期之后意外进行了更改，则可以回滚文档并将其从Canvas表格440中删除。"
},
{
	roleKey: 35,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "权限",
	roleNameUI: "管理EY Canvas FIT促进工具共享情况",
	displayName: "管理员工作流指导",
	roleDisplayOrder: 14,
	tooltipText: "能够在EY Canvas项目之间共享工作流指导"
},
{
	roleKey: 36,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "权限",
	roleNameUI: "导入EY Helix数据",
	displayName: "导入EY Helix数据",
	roleDisplayOrder: 15,
	tooltipText: "能够导入EY Helix数据"
}
];

export const restoreengagementexpirydates = [{
	value: 3,
	label: "3个月"
},
{
	value: 6,
	label: "6个月"
},
{
	value: 9,
	label: "9个月"
},
{
	value: 12,
	label: "12个月"
}
];

export const messageStatus = {
	0: "当前",
	1: "预定",
	2: "已到期"
};

export const kendoLabels = {
	alignLeft: "左对齐",
	alignRight: "右对齐",
	alignCenter: "居中对齐",
	alignFull: "两端对齐",
	addComment: "添加批注",
	addColumnBefore: "左侧添加列",
	addColumnAfter: "右侧添加列",
	addRowAbove: "上方添加行",
	addRowBelow: "下方添加行",
	bulletList: "插入无序列表",
	bold: "加粗",
	backColor: "高亮",
	createLink: "插入超链接",
	createTable: "创建表格",
	cleanFormatting: "清除格式",
	deleteRow: "删除行",
	deleteColumn: "删除列",
	deleteTable: "删除表格",
	fontSizeInherit: "字体大小",
	foreColor: "颜色",
	format: "格式",
	fontSize: "字体大小",
	hyperlink: "插入网页链接",
	italic: "斜体",
	indent: "缩进",
	insertTableHint: "创建一个{0}x{1}表格",
	"hyperlink-dialog-content-address": "网址",
	"hyperlink-dialog-title": "插入超链接",
	"hyperlink-dialog-content-title": "标题",
	"hyperlink-dialog-content-newwindow": "在新窗口中打开链接",
	"hyperlink-dialog-cancel": "取消",
	"hyperlink-dialog-insert": "插入",
	outdent: "减少缩进",
	orderedList: "插入有序列表",
	print: "打印",
	pdf: "导出为pdf格式",
	redo: "恢复",
	strikethrough: "删除线",
	subscript: "下标",
	superscript: "上标",
	underline: "下划线",
	undo: "撤消"
};

export const kendoFormatOptions = [{
	text: "段落",
	value: "p"
},
{
	text: "标题1",
	value: "h1"
},
{
	text: "标题2",
	value: "h2"
},
{
	text: "标题3",
	value: "h3"
},
{
	text: "标题4",
	value: "h4"
},
{
	text: "标题5",
	value: "h5"
},
{
	text: "标题6",
	value: "h6"
}
];

export const kendoFontSize = [{
	text: '8',
	value: "8px"
},
{
	text: '9',
	value: "9px"
},
{
	text: '10',
	value: "10px"
},
{
	text: '11',
	value: "11px"
},
{
	text: '12',
	value: "12px"
},
{
	text: '14',
	value: "14px"
},
{
	text: '16',
	value: "16px"
},
{
	text: '18',
	value: "18px"
},
{
	text: '20',
	value: "20px"
},
{
	text: '22',
	value: "22px"
},
{
	text: '24',
	value: "24px"
},
{
	text: '26',
	value: "26px"
},
{
	text: '28',
	value: "28px"
},
{
	text: '36',
	value: "36px"
},
{
	text: '48',
	value: "48px"
},
{
	text: '72',
	value: "72px"
}
];

export const cacheOptionValue = [{
	value: 1,
	label: "LDC"
},
{
	value: 2,
	label: "广泛适用"
}
]
