import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import WorkSpacesDetails from './workspacesdetails';
import { labels } from '../../../../util/uiconstants';
import EllipsesControl from '@ey/ellipses-control';
import MotifIcon from '@ey/motif-react-wrapper/Icon';
import { IconoirPlusCircle } from '@ey-xd/motif-icon';
import MotifButton from '@ey/motif-react-wrapper/Button';
import MotifMenu from '@ey/motif-react-wrapper/Menu';
import AddWorkspaceModal from '../addworkspacemodal';

function WorkspacesWrapper(props) {
	const [dataCenter, setDatacenter] = useState({});
	const [dataCenterList, setDatacenterList] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [showWorkspaceModal, setShowWorkspaceModal] = useState(false);

	useEffect(() => {
		setDatacenterList(props?.dataCenterListForNavigation);
	}, [props?.dataCenterListForNavigation]);

	useEffect(() => {
		if (props?.currentDatacenter) {
			setDatacenter(props?.currentDatacenter);
		}
	}, [props?.currentDatacenter]);

	const findCurrentDatacenterIndex = () => {
		if (props.getCurrentIndex && _.isFunction(props.getCurrentIndex)) {
			return props.getCurrentIndex(dataCenter);
		} else {
			const index = dataCenterList?.findIndex((element) => {
				return element.dataCenterId === dataCenter.dataCenterId;
			});
			return index;
		}
	};

	const openPreviousDatacenter = () => {
		setIsLoading(true);
		var currentIndex = findCurrentDatacenterIndex();
		if (currentIndex < 0 && dataCenterList.length > 0) {
			setDatacenter(dataCenterList[0]);
		} else {
			if (props.getPrevDatacenter && _.isFunction(props.getPrevDatacenter)) {
				setDatacenter(props.getPrevDatacenter(currentIndex - 1));
			} else {
				setDatacenter(dataCenterList[currentIndex - 1]);
			}
		}
	};

	const openNextDatacenter = () => {
		setIsLoading(true);
		var currentIndex = findCurrentDatacenterIndex();
		if (props.getNextDatacenter && _.isFunction(props.getNextDatacenter)) {
			setDatacenter(props.getNextDatacenter(currentIndex + 1));
		} else {
			setDatacenter(dataCenterList[currentIndex + 1]);
		}
	};

	const previousButtonDisabled = () => {
		var currentIndex = findCurrentDatacenterIndex();
		if (currentIndex <= 0) {
			return true;
		}
		return false;
	};

	const nextButtonDisabled = () => {
		var currentIndex = findCurrentDatacenterIndex();

		let maxIndex = props?.maxDatacenterListIndex
			? props.maxDatacenterListIndex
			: dataCenterList?.length - 1;
		if (currentIndex == maxIndex) {
			return true;
		}
		if (currentIndex < 0) {
			return true;
		}
		return false;
	};

	const onModalClose = (e) => {
		if (props.onClose) {
			props.onClose(e);
		}
	};

	const loadWorkSpacesDetails = () => {
		return (
			<>
				{dataCenter.dataCenterId && (
					<WorkSpacesDetails
						dataCenterId={dataCenter.dataCenterId}
						setIsLoading={setIsLoading}
					/>
				)}
			</>
		);
	};

	const ellipsesControlRender = (text) => {
		return (
			<section className="roleType">
				<EllipsesControl
					content={text}
					tooltip={text}
					displayId={''}
					noOfLines={1}
					ellipsisClass="columnName"
				/>
			</section>
		);
	};

	return (
		<MotifMenu
			className={'workspace_menu'}
			show={props.show}
			onClose={onModalClose}
			title={ellipsesControlRender(dataCenter.dataCenterName)}
			closeButtonProps={{ title: labels.close }}
		>
			{!isLoading && <section className="header">
				<MotifButton
					className="add-workspace"
					variant="text-alt"
					size="medium"
					onClick={() => { setShowWorkspaceModal(true) }}
				>
					<MotifIcon
						className="icon-container"
						iconFunction={IconoirPlusCircle}
						size="20"
					/>
					{labels.addWorkspace}
				</MotifButton>
			</section>}

			<section>{loadWorkSpacesDetails()}</section>

			{!isLoading && (
				<>
					<hr />
					<section className="footer_button">
						<MotifButton
							className='previousBtn'
							variant="secondary"
							onClick={openPreviousDatacenter}
							disabled={previousButtonDisabled()}
							stepper="prev"
						>
							{labels.previous}
						</MotifButton>

						<MotifButton
							variant="primary"
							onClick={openNextDatacenter}
							disabled={nextButtonDisabled()}
							stepper="next"
						>
							{labels.next}
						</MotifButton>
					</section>
				</>
			)}

			<section>
				{showWorkspaceModal && <AddWorkspaceModal
					showModal={showWorkspaceModal}
					setShowModal={setShowWorkspaceModal}
					dataCenterList={dataCenterList}
					defaultDatacenterId={dataCenter?.dataCenterId}
				/>}
			</section>
		</MotifMenu>
	);
}

export default WorkspacesWrapper;
