/**
 * Created by rastwe on 3/1/2017.
 * Traditionally, the root reducer file is named index.js
 *
 * This rootReducer config object is passed to Redux.createStore() in
 * configurestore.{env}.js.
 */

import {
	ArcEngagementInfoReducer,
	RestoreEngagementReducer,
	TemplateEngagementInfoReducer
} from './restoreengagementreducer';
import {ArcFilesReducer, CrpReducer} from './crpreducer';
import {
	EngagementMetaDataReducer,
	MyEngagementsReducer,
	setSuccessfulToastMessage
} from './engagementsreducer';
import {
	UserPermissionsReducer,
	UserSettingsReducer
} from './usersettingsreducer';
import {
	adminUserReducer,
	adminUserRolesReducer
} from './Admin/ManageAccess/adminusersreducer';
import {
	workSpaceDetailsReducer,
	dataCenterDetailsReducer
} from './Admin/sharepointworkspaces/sharepointworkspacesreducer';
import {
	countriesReducer,
	dataCentersReducer,
	engagementRolesReducer,
	modulesReducer,
	regionsReducer,
	rolesReducer,
	subServiceLinesReducer
} from './admin/master/masterreducer';
import {
	engagementDetailsReducer,
	engagementSearchParametersReducer,
	engagementStatusReducer,
	engagementUserStatusesReducer,
	engagementUsersReducer,
	engagementsReducer,
	getEmployeeADStatusReducer,
	independenceStatusesReducer,
	rbacUserRolesReducer
} from './Admin/Engagements/engagementsReducer';
import {getMessageByIdReducer, messagingReducer} from './Admin/messaging/messagingReducer';
import {
	permissionGroupReducer,
	permissionGroupUsersReducer
} from './Admin/permissiongroups/permissiongroupsreducer';

import CountriesDetailsReducer from './CountriesDetailsReducer';
import CreateEditWorkspaceReducer from './createeditworkspace';
import DashboardReducer from './dashboardreducer';
import {FeatureConfigurationReducer} from './featureconfigurationreducer';
import IndividualWorkspaceDataReducer from './individualWorkspaceData';
import LoadingIndicatorReducer from './loadingindicatorreducer';
import {MyReviewEngagementsReducer} from './reviewengagementsreducer';
import SearchEngagementReducer from './searchReducer';
import {UserPreferencesReducer} from './admin/userpreferences/userpreferencesreducer';
import VersionUpgradeStateReducer from './versionreducer';
import {adminEngagementDocumentsReducer} from './Admin/documents/documentsreducer';
import appOnlineStateReducer from './appOnlineState';
import archives from './archivesreducer';
import {cacheReducer} from './Admin/cache/cacheReducer';
import clientWorkspaces from './clientworkspacesreducer';
import clientengagement from './engagementcodenamereducer';
import {combineReducers} from 'redux';
import copyHubReducer from './copyhubreducer';
import {downloadReportReducer} from './Admin/report/reportreducer';
import engagementErrorReducer from './engagementerrorreducer';
import engagementOverviewReducer from './engagementOverviewReducer';
import engagementTemplatesReducer from './admin/template/engagementTemplatesReducer';
import errorReducer from './errorreducer';
import eyusers from './eyusersreducer';
import favoriteEngagements from './favoriteengagementsreducer';
import favoriteWorkspaces from './favoriteworkspacesreducer';
import genericGet from './genericreducer';
import knowledgeTemplate from './knowledgetemplatereducer';
import languages from './languagesreducer';
import modalStateReducer from './modalstatereducer';
import myWorkspaces from './workspacesreducer';
import myreviewworkspacesreducer from './reviewworksapcereducer';
import notifications from './notificationsreducer';
import primaryoffices from './primaryofficesreducer';
import requestaccess from './requestactionreducer';
import rollForwardEngagement from './rollforwardengagementreducer';
import saveEngagement from './saveengagementreducer';
import selectTemplate from './selecttemplatereducer';
import servicelines from './servicelinesreducer';
import singleEngagement from './singleengagementreducer';
import spotfireDataCenter from './spotfiredatacenterurlreducer';
import successMessageReducer from './Admin/successmessagereducer';
import timeline from './timelinereducer';
import {timeoutReducer} from './timeoutreducer';
import {userContextReducer} from './usercontextreducer';
import userSearchReducer from './Admin/UserSearch/usersearchreducer';
import viewAccessList from './viewaccesslistreducer';
import {MyEngagementsV2Reducer} from './engagementsV2reducer';

/* All of the application's reducers will be combined into this
 * rootReducer. Be careful of what you name these objects! The names
 * you choose will be referred to through the state object every
 * time you access it. So, "languages" below will end up being referred
 * to as state.languages.
 * Also note that we're using the ES6 shorthand property name here
 * for "languages: languages".
 *
 * All combineReducers() does is generate a function that calls your
 * reducers with slices of state according to their keys.
 * Ref: http://redux.js.org/docs/api/combineReducers.html
 */
const rootReducer = combineReducers({
	apponlinestate: appOnlineStateReducer,
	appVersion: VersionUpgradeStateReducer,
	myEngagements: MyEngagementsReducer,
	myEngagementsV2: MyEngagementsV2Reducer,
	engagementMetaData: EngagementMetaDataReducer,
	myWorkspaces,
	reviewEngagements: MyReviewEngagementsReducer,
	reviewWorkspaces: myreviewworkspacesreducer,
	userSettings: UserSettingsReducer,
	userPermissions: UserPermissionsReducer,
	workSpaceDetails: workSpaceDetailsReducer,
	dataCentersSharepointWorkspaces: dataCenterDetailsReducer,
	archives,
	viewAccessList,
	requestaccess,
	errors: errorReducer,
	dashboard: DashboardReducer,
	favoriteEngagements,
	favoriteWorkspaces,
	countriesDetails: CountriesDetailsReducer,
	createEditWorkspace: CreateEditWorkspaceReducer,
	individualWorkspaceData: IndividualWorkspaceDataReducer,
	spotfireDataCenter,
	primaryoffices,
	servicelines,
	clientengagement,
	saveEngagement,
	clientWorkspaces,
	timeline,
	restoreEngagement: RestoreEngagementReducer,
	arcEngagementInfo: ArcEngagementInfoReducer,
	templateEngInfo: TemplateEngagementInfoReducer,
	rollForwardEngagement,
	knowledgeTemplate,
	selectTemplate,
	genericGet,
	copyHub: copyHubReducer,
	notifications,
	eyusers,
	isLoading: LoadingIndicatorReducer,
	singleEngagement,
	myEngDashboard: DashboardReducer,
	languages,
	arcFiles: ArcFilesReducer,
	crp: CrpReducer,
	searchEngagementState: SearchEngagementReducer,
	sessionTimer: timeoutReducer,
	userContext: userContextReducer,
	featureConfiguration: FeatureConfigurationReducer,
	adminUsers: adminUserReducer,
	adminUserRoles: adminUserRolesReducer,
	engagements: engagementsReducer,
	countries: countriesReducer,
	userPreferences: UserPreferencesReducer,
	engagementUsers: engagementUsersReducer,
	engagementDetails: engagementDetailsReducer,
	status: engagementStatusReducer,
	subServiceLines: subServiceLinesReducer,
	engagementUserStatuses: engagementUserStatusesReducer,
	independenceStatuses: independenceStatusesReducer,
	employeeADStatuses: getEmployeeADStatusReducer,
	userSearcheResults: userSearchReducer,
	modules: modulesReducer,
	roles: rolesReducer,
	isModalOpen: modalStateReducer,
	permissionGroup: permissionGroupReducer,
	rbacRoles: rbacUserRolesReducer,
	engagementTemplates: engagementTemplatesReducer,
	regions: regionsReducer,
	engagementRoles: engagementRolesReducer,
	permissionGroupUsers: permissionGroupUsersReducer,
	successMessages: successMessageReducer,
	engagementSearchParameters: engagementSearchParametersReducer,
	cache: cacheReducer,
	messaging: messagingReducer,
	downloadReport: downloadReportReducer,
	dataCenters: dataCentersReducer,
	messageDetails: getMessageByIdReducer,
	adminEngagementDocumentsList: adminEngagementDocumentsReducer,
	engagementError: engagementErrorReducer,
	engagementOverview: engagementOverviewReducer,
	successfulToastMessage: setSuccessfulToastMessage
});

export default rootReducer;
