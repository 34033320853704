import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

const Loaderdiv = styled.div`
    padding:0 4rem;
	background: ${(props) => props.theme.color[`grey50`]};
	max-width: 2048px;
	margin: 0 auto;
    .menuNavLoader{
        width:100%;
        height:2rem;
        border-bottom: 0.1rem solid ${(props) => props.theme.color[`grey235`]};
    }

    .pageFilterLoader{
        width:100%;
        height:2rem;
    }
`;

const StyledSkeletonLoader = styled.section`
	border: 0.1rem solid ${(props) => props.theme.color[`grey300`]};
	background: ${(props) => props.theme.color[`white`]};
	border-left: 0.5rem solid ${(props) => props.theme.color[`blue300`]};
	padding: 2rem 2rem 0;
	margin-bottom: 2rem;
	.bottomBar {
		height: 3rem;
		background: ${(props) => props.theme.color[`grey200`]};
		margin: 2rem -2rem 0;
	}
`;

const StyledSkeleton = styled(Skeleton)`
	margin: 0 2rem;
`;

const generateEngagements = function () {
	var engs = [];

	for (var i = 0; i < 10; i++) {
		engs.push(
			<StyledSkeletonLoader key={i}>
				<Skeleton circle={false} height={20} width={200} />
				<StyledSkeleton circle={false} count={1} height={20} />
				<Skeleton circle={false} height={20} width={200} />
				<section className="bottomBar"></section>
			</StyledSkeletonLoader>
		);
	}

	return engs;
};

const IndexLoader = () => {
	return (
		<Loaderdiv>
			<section className="menuNavLoader"></section>
			<section className="pageFilterLoader"></section>
			{generateEngagements()}
		</Loaderdiv>
	);
};

export default IndexLoader;
