import React from 'react';
import Input from '@ey/motif-react-wrapper/Input';
import FormField from '@ey/motif-react-wrapper/FormField';
import ErrorMessage from '@ey/motif-react-wrapper/ErrorMessage';
import Label from '@ey/motif-react-wrapper/Label';
import { labels } from '../../../util/uiconstants';
import styled from 'styled-components';

// All
const TicketFieldWrapper = styled.section`

`;

function TicketField(props) {
    const {
        ticketNumber,
        onTicketChanged,
        showValidation,
        hideDescription,
        hideLabel,
        placeholderText
    } = props;

    return (
        <TicketFieldWrapper className='ticketdetail'>
            {!hideDescription && <h5>{labels.confirmationMessage}</h5>}
            <FormField>
                {!hideLabel && <Label>{labels.ticketNumberLabel}</Label>}
                <Input
                    value={ticketNumber}
                    maxLength={100}
                    clearButtonTitle={labels.clear}
                    onChange={onTicketChanged}
                    placeholder={placeholderText}
                />
                {ticketNumber?.trim().length === 0 && showValidation && (
                    <ErrorMessage>
                        {labels.validationMessage}
                    </ErrorMessage>
                )}
            </FormField>
        </TicketFieldWrapper>
    );
}

export default TicketField;
