import React from 'react';
import {labels} from '../../../util/uiconstants';
import AdminDashboard from '../dashboard/Dashboard';
import ManageAccess from '../ManageAccess/users/manageaccess';
import ManageRoles from '../ManageAccess/users/manageroles';
import EngagementsView from '../engagements/engagementlayout/EngagementsView';
import SelectedEngagementDetails from '../engagements/engagement/selectedengagementbase';
import {HttpStatusCodes, currentResource} from '../../../util/uiconstants';
import PermissionGroup from '../permissiongroups/permissiongroupsbase';
import PermissionGroupUserList from '../permissiongroups/permissiongroupuserlist';
import TemplateManagement from '../template/templatelayout/templatetable';
import CacheDetails from '../cache/cachedetails';
import Messaging from '../messaging/messaginglayout';
import DocumentsBase from '../documents/documentsbase';
import DataCenterDetails from '../sharepointworkspaces/datacenterdetails';
import {
	IconoirFolder,
	IconoirGoogleDocs,
	IconoirCustomUserAdmin,
	IconoirGroup,
	IconoirMessageText,
	IconoirCustomUserManage,
	IconoirUserBadgeCheck,
	IconoirRefreshDouble,
	IconoirSuitcase,
	IconoirMultiplePages,
	IconoirCustomDocumentCompleted
} from '@ey-xd/motif-icon';
export function getAdminRoutes() {
	var adminRoutes = [
		{
			path: '/admin/manageaccess/userroles/:userid',
			component: <ManageRoles />,
			moduleName: labels.manageAccessHeader,
			iconName: IconoirUserBadgeCheck,
			breadCrumb: [
				{
					label: 'Home',
					link: '/admin'
				},
				{
					label: labels.manageAccessHeader,
					link: '/admin/manageaccess'
				},
				{
					isDynamicLabel: 1,
					label: 'UserName'
				}
			]
		},
		{
			path: '/admin/engagements/:engagementId',
			component: <SelectedEngagementDetails />,
			moduleName: labels.engagementdetails,
			iconName: IconoirFolder,
			breadCrumb: [
				{
					label: 'Home',
					link: '/admin'
				},
				{
					label: labels.engagements,
					link: '/admin/engagements'
				},
				{
					isDynamicLabel: 1,
					label: 'Engagement Details'
				}
			]
		},
		{
			path: '/admin/engagements',
			component: <EngagementsView />,
			moduleName: labels.engagements,
			iconName: IconoirFolder,
			breadCrumb: [
				{
					label: 'Home',
					link: '/admin'
				},
				{
					label: labels.engagements,
					link: '/admin/engagements'
				}
			]
		},
		{
			path: '/admin/manageaccess',
			component: <ManageAccess />,
			moduleName: labels.manageAccessHeader,
			iconName: IconoirUserBadgeCheck,
			breadCrumb: [
				{
					label: 'Home',
					link: '/admin'
				},
				{
					label: labels.manageAccessHeader,
					link: '/admin/manageaccess'
				}
			]
		},
		{
			path: '/admin/permissiongroups/:activedirectorygroupid',
			component: <PermissionGroupUserList />,
			moduleName: labels.permissionGroupDetails,
			iconName: IconoirGroup,
			breadCrumb: [
				{
					label: 'Home',
					link: '/admin'
				},
				{
					label: labels.permissionGroupCard,
					link: '/admin/permissiongroups'
				},
				{
					isDynamicLabel: 1,
					label: 'Permission Group Users'
				}
			]
		},
		{
			path: '/admin/permissiongroups',
			component: <PermissionGroup />,
			moduleName: labels.permissionGroupCard,
			iconName: IconoirGroup,
			breadCrumb: [
				{
					label: 'Home',
					link: '/admin'
				},
				{
					label: labels.permissionGroupCard,
					link: '/admin/permissiongroups'
				}
			]
		},
		{
			path: '/admin/template',
			component: <TemplateManagement />,
			moduleName: labels.templateManagement,
			iconName: IconoirGoogleDocs,
			breadCrumb: [
				{
					label: 'Home',
					link: '/admin'
				},
				{
					label: labels.templateManagement,
					link: '/admin/template'
				}
			]
		},
		{
			path: '/admin/cache',
			component: <CacheDetails />,
			moduleName: 'Cache',
			iconName: IconoirRefreshDouble,
			breadCrumb: [
				{
					label: 'Home',
					link: '/admin'
				},
				{
					label: 'Cache',
					link: '/admin/cache'
				}
			]
		},
		{
			path: '/admin/messaging',
			component: <Messaging />,
			moduleName: 'Messaging',
			iconName: IconoirMessageText,
			breadCrumb: [
				{
					label: 'Home',
					link: '/admin'
				},
				{
					label: 'Messaging',
					link: '/admin/messaging'
				}
			]
		},
		{
			path: '/admin/documents',
			component: < DocumentsBase/>,
			moduleName: labels.documentsHeader,
			iconName: IconoirGoogleDocs,
			breadCrumb: [
				{
					label: 'Home',
					link: '/admin'
				},
				{
					label: labels.documentsHeader,
					link: '/admin/documents'
				}
			]
		},
		{
			path: '/admin/sharepointworkspaces',
			component: <DataCenterDetails/>,
			moduleName: labels.sharepointWorkspacesHeader,
			iconName: IconoirSuitcase,
			breadCrumb: [
				{
					label: 'Home',
					link: '/admin'
				},
				{
					label: labels.sharepointWorkspacesHeader,
					link: '/admin/sharepointworkspaces'
				}
			]
		},
		{
			path: '/admin',
			component: <AdminDashboard />,
			breadCrumb: []
		}
	];

	return adminRoutes;
}

export function getErrorMessage(result) {
	let displayError = undefined;
	let isError = result?.response?.data?.errors;
	if (isError) {
		let keys = Object.keys(result?.response?.data?.errors);
		if (keys && keys.length) {
			let error = result?.response?.data?.errors[keys[0]];
			if (error) {
				displayError = error[0];
			}
		}
	}

	return displayError ? displayError : '';
}

export function getErrorMessageFromResponse(responseMessage) {
	let error;
	let errorMessage = '';
	let message = '';
	try {
		// responseMessage is an API middleware error object
		if (
			responseMessage &&
			responseMessage.data &&
			responseMessage.data.errors
		) {
			let errorList = responseMessage.data.errors;

			// TODO: Handle multiple error messages in the collection
			if (errorList.Error && Array.isArray(errorList.Error)) {
				errorMessage = errorList.Error[0];
				error = errorMessage.substr(0, errorMessage.indexOf(' -'));
			}
		} else if (responseMessage && responseMessage.indexOf(' - ') > 0) {
			/* errorMessage is a string with the following format: "errCode - errMessage" */
			errorMessage = responseMessage;
			error = errorMessage.substr(0, errorMessage.indexOf(' -'));
		}
	} catch (err) {
		message = err;
	}
	if (error && error.length > 0 && !Array.isArray(error)) {
		if (!error.ErrorCode) {
			var errorValue = Number(error);
			if (!isNaN(errorValue)) {
				let localizedMessage = currentResource.adminErrors[errorValue];
				return localizedMessage
					? error + ': ' + localizedMessage
					: errorMessage;
			}
		}
	}
	return responseMessage;
}

export function getStatusCode(result) {
	return result?.response?.data?.status;
}

export function getLocalizedAPIError(error) {
	if (getStatusCode(error) == HttpStatusCodes.Forbidden) {
		return labels.insufficientPermission;
	}
	let displayError = getErrorMessage(error);

	let errorMessage = getErrorMessageFromResponse(displayError);
	if (!errorMessage) {
		return 'Unknown error - Please try again or after sometime';
	}

	return errorMessage;
}

/**
 * Compares two arrays, both arrays have to contain the same value type
 * EX: arrayA = ['a', 'b'] && arrayB = ['c', 'b']
 * @param {any} arrayA - first array to compare
 * @param {any} arrayB - second array to compare
 * @returns {boolean} true if equal, otherwise false
 * */
export function compareArray(arrayA, arrayB) {
	if (arrayA.length === arrayB.length) {
		return arrayA.every(element => arrayB.includes(element));
	}
	return false;
}