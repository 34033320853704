import React, {useState} from 'react';
import {useEffect} from 'react';
import env from '../../../../util/env';
import {useSelector, useDispatch} from 'react-redux';
import Table from '@ey/motif-react-wrapper/Table';
import EllipsesControl from '@ey/ellipses-control';
import Loader from '../../../Common/Loaders/loadingindicator';
import {
	labels,
	sharepointWorkspacesCapacityLimit
} from '../../../../util/uiconstants';
import AccessDeniedMessage from '../../../Common/AccessDeniedMessage/AccessDeniedMessage';
import Wrapper from '../Styles/sharepointWorkspacesbasestyle';
import {clearErrors} from '../../../../actions/erroractions';
import {setIsModalOpen} from '../../../../actions/modalstateactions';
import {getWorkspacesDetails} from '../../../../actions/admin/sharepointworkspacesactions';
import styled from 'styled-components';

function WorkSpacesDetails(props) {
	const userPermissions = useSelector((store) => store.userPermissions);
	const workSpaceDetails = useSelector((store) => store.workSpaceDetails);
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	useEffect(() => {
		clearError(true);
	}, []);

	useEffect(() => {
		setLoading(true);
		//Todo :modify the  request url when the api created and pass the data accordingly
		let workSpaceDetailsUrl = env.getURL('serviceUrlV2') + 
			`admin/datacenters/${props.dataCenterId}?includeSharePointWorkspaces=true`;

		dispatch(getWorkspacesDetails(workSpaceDetailsUrl, props.dataCenterId))
			.finally(() => {
				setLoading(false);
				props.setIsLoading(false);
			});
	}, [props.dataCenterId]);

	const headerCellRender = (params) => {
		return (
			<StyledHeader className="header motif-body2-default-regular">
				<section>{params.label}</section>
			</StyledHeader>
		);
	};

	const columnDefs = [
		{
			minWidth: 50,
			width: 50,
			flex: 6,
			headerComponentParams: {
				label: labels.workspaceName
			},
			headerComponent: (params) => {
				return headerCellRender(params);
			},
			field: 'WorkspaceName',
			cellRenderer: (params) => workSpaceNameCellRenderer(params),
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			minWidth: 25,
			width: 25,
			flex: 4,
			headerComponentParams: {
				label: labels.documentCount
			},
			headerComponent: (params) => {
				return headerCellRender(params);
			},
			field: 'DocumentCount',
			cellRenderer: (params) => documentCountCellRenderer(params),
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			minWidth: 25,
			width: 25,
			flex: 4,
			headerComponentParams: {
				label: labels.percentCapacity
			},
			headerComponent: (params) => {
				return headerCellRender(params);
			},
			field: 'PercentageCapacity',
			cellRenderer: (params) => percentageCapacityCellRenderer(params),
			lockPosition: true,
			suppressSizeToFit: true
		}
	];

	const getRowStyle = (params) => {
		if (params.node.rowIndex % 2 === 0) {
			return {background: 'var(--neutrals-00white)'};
		} else {
			return {background: 'var(--ui-background-secondary)'};
		}
	};

	const workSpaceNameCellRenderer = (e) => {
		return (
			<>
				<section className="roleType motif-body2-default-light">
					<section>
						<EllipsesControl
							content={e.data.WorkspaceName}
							tooltip={e.data.WorkspaceName}
							displayId={''}
							noOfLines={1}
							ellipsisClass="columnName"
						/>
					</section>
				</section>
			</>
		);
	};

	const percentageCapacityCellRenderer = (e) => {
		let className = 'roleType motif-body2-default-light ';
		className =
			className +
			(e.data.PercentageCapacity > sharepointWorkspacesCapacityLimit
				? 'exceedLimit'
				: '');
		return (
			<>
				<section className={className}>
					{JSON.stringify(e.data.PercentageCapacity)}%
				</section>
			</>
		);
	};

	const documentCountCellRenderer = (e) => {
		return (
			<>
				<section className="roleType motif-body2-default-light">
					<section>{JSON.stringify(e.data.DocumentCount)}</section>
				</section>
			</>
		);
	};

	const clearError = (isModelOpen) => {
		dispatch(clearErrors()).then(() => {
			dispatch(setIsModalOpen(isModelOpen));
		});
	};

	function hasPermissions() {
		let sharepointWorkSpacesPermission = userPermissions?.permissions;
		return (
			sharepointWorkSpacesPermission &&
			(sharepointWorkSpacesPermission.canReadCollaborationSites ||
				sharepointWorkSpacesPermission.canCreateUpdateCollaborationSites ||
				sharepointWorkSpacesPermission.canDeleteCollaborationSites)
		);
	}

	return (
		<Wrapper>
			{loading == true ? (
				<Loader show view="blockView" styleName="loadingstyle" />
			) : (
				<section className="mainwrapper">
					{hasPermissions() ? (
						workSpaceDetails.length > 0 ? (
							<section>
								<section className="workspace_menuTable mainResponsiveWrapper">
									<section className="managepermissionTable">
										<Table
											defaultColDef={{
												flex: 1,
												sortable: false,
												filter: false,
												resizable: false,
												lockPosition: true
											}}
											onGridReady={(params) => {
												params.api.sizeColumnsToFit();
											}}
											getRowStyle={getRowStyle}
											rowHeight={32}
											enableCellTextSelection
											suppressRowClickSelection
											suppressCellSelection
											columnDefs={columnDefs}
											rowSelection="multiple"
											rowData={workSpaceDetails}
										/>
									</section>
								</section>
							</section>
						) : (
							<section className="norecord">{labels.noResultsFound}</section>
						)
					) : (
						<section className="message">
							<AccessDeniedMessage
								permissionMesage={labels.insufficientPermission}
							/>
						</section>
					)}
				</section>
			)}
		</Wrapper>
	);
}

export default WorkSpacesDetails;

const StyledHeader = styled.section`
	&.header {
		width: 100%;
		display: flex;
		color: var(--neutrals-900);
	}
`;
