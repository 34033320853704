import React, { useState } from 'react';
import Modal from '../../Common/Modal/Modal';
import { labels } from '../../../util/uiconstants';
import Utility from '../../../util/utils';
import MotifFormField from '@ey/motif-react-wrapper/FormField';
import MotifSelect from '@ey/motif-react-wrapper/Select';
import MotifOption from '@ey/motif-react-wrapper/Option';
import TicketField from '../common/ticketfield';
import EllipsesControl from '@ey/ellipses-control';

const AddWorkspaceModal = (props) => {
    const {
        showModal,
        setShowModal,
        dataCenterList,
        defaultDatacenterId
    } = props;

    const [dataCenterId, setDataCenterId] = useState(defaultDatacenterId ?? '');
    const [ticketNumber, setTicketNumber] = useState('');

    return (
        <>
            <Modal
                modalsize="medium"
                role="dialog"
                title={labels.addWorkspaceModalTitle}
                closeTitle={labels.cancel}
                closeBtnTitle={labels.cancel}
                confirmBtnTitle={labels.save}
                confirmBtnToolTip={labels.save}
                show={showModal}
                onOkClick={() => { }}
                onHide={() => setShowModal(false)}
            >
                <div>{Utility.createRichTextElement(labels.addWorkspaceModalDescription)}</div>
                <div>
                    <MotifFormField>
                        <MotifSelect
                            ariaLabelledBy="select-datacenter-label"
                            onChange={(id) => { setDataCenterId(id) }}
                            placeholder={labels.datacenterPlaceholder}
                            value={`${dataCenterId}`}
                            //visibleOptions={6}
                        >
                            {dataCenterList?.map((dc) => (
                                <MotifOption key={dc.dataCenterId} value={`${dc.dataCenterId}`} label={dc.dataCenterName}>
                                    {<EllipsesControl
                                        content={dc.dataCenterName}
                                        tooltip={dc.dataCenterName}
                                        noOfLines={1}
                                    />}
                                </MotifOption>
                            ))}
                        </MotifSelect>
                    </MotifFormField>
                </div>
                <div>
                    <TicketField
                        ticketNumber={ticketNumber}
                        onTicketChanged={(e) => { setTicketNumber(e.target.value ?? '') }}
                        //showValidation={false}
                        hideDescription={true}
                        hideLabel={true}
                        placeholderText={labels.ticketNumberLabel}
                    />
                </div>
            </Modal>
        </>
    )
};
export default AddWorkspaceModal;