import {FormComboBox, FormRadio} from '@ey/form';
import React, {useEffect, useState} from 'react';
import {labels, standardStrings, urls} from '../../../util/uiconstants';
import {useDispatch, useSelector} from 'react-redux';

import {CopyObjectGroupType} from '../../../util/enumerations';
import Loader from '@ey/loader';
import {Radio} from 'react-bootstrap';
import {SVGICONS} from '@ey/icons';
import env from '../../../util/env';
import {getClientAndWorkspaceDetails} from '../../../actions/workspacesactions';
import {getNotifications} from '../../../actions/notificationsactions';
import {patchCopyRequest} from '../../../actions/copyhubactions';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';

const SelectWorkspace = (props) => {
	const {
		onFinishClick,
		onComponentDisplayed,
		nextClicked,
		isGroupAudit,
		isComponentValid,
		isChkImportForReviewOnly,
		closeWizard,
		updateWizardData,
		getDependencyData
	} = props;

	const clientData = useSelector((store) => store.clientWorkspaces || {});
	const copyRequest = useSelector((store) => store.copyHub.copyRequest || {});
	const dispatch = useDispatch();

	const [selectedClientId, setSelectedClientId] = useState(
		getDependencyData('domainClientId') || -1
	);
	const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(
		getDependencyData('domainWorkspaceId') || -1
	);
	const [clientWorkspaces, setClientWorkspaces] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [clientName, setClientName] = useState(
		getDependencyData('domainClientName') || ''
	);
	const [workspaceName, setWorkspaceName] = useState(
		getDependencyData('domainWorkspaceName') || ''
	);
	const [showValidation, setShowValidation] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const [disableClient, setDisableClient] = useState(false);

	let errorMessage = labels.selectexistingworkspacemsg;
	let workspacesurl =
		env.getURL('serviceUrl') +
		'/home.json?phase=GetAllClientsAndWorkspaces&filters=sourceEngagementId eq ' +
		copyRequest.sourceengagementid +
		",sourceEngagementVersion eq '" +
		copyRequest.sourceengagementversion +
		"'";
	let getNotificationsUrl =
		env.getURL('serviceUrl') + '/' + urls.LANDINGPAGENOTIFICATION_URL;
	let groupauditworkspacesurl =
		env.getURL('serviceUrl') + '/home.json?phase=GetGroupAuditEngagements';

	useEffect(() => {
		setIsLoading(true);
		if (isGroupAudit) {
			dispatch(getClientAndWorkspaceDetails(groupauditworkspacesurl))
				.then(() => {
					setIsLoading(false);
					updateWizardFromComponent();
				})
				.catch(() => {
					setIsLoading(false);
				});
		} else {
			dispatch(getClientAndWorkspaceDetails(workspacesurl))
				.then(() => {
					setIsLoading(false);
					updateWizardFromComponent();
				})
				.catch(() => {
					setIsLoading(false);
				});
		}
	}, []);

	useEffect(() => {
		setComponentValid();
	}, [onComponentDisplayed]);

	useEffect(() => {
		if (selectedClientId < 1 || selectedWorkspaceId < 1) {
			setShowValidation(true);
		}
	}, [nextClicked]);

	useEffect(() => {
		if (
			copyRequest.copyobjectgrouptypeid == CopyObjectGroupType.Engagement ||
			copyRequest.copyobjectgrouptypeid == CopyObjectGroupType.V2Engagement ||
			copyRequest.copyobjectgrouptypeid == CopyObjectGroupType.V2EngagementRollforwardCopy ||
			copyRequest.copyobjectgrouptypeid == CopyObjectGroupType.EngagementByEntity
		) {
			setShowValidation(true);
			if (selectedClientId > -1 && selectedWorkspaceId > 0) {
				acceptEngagementPackage();
			}
		}
	}, [onFinishClick]);

	useEffect(() => {
		if (selectedClientId < 1) {
			errorMessage = labels.selectaclienttocontinuemsg;
		}
		updateWizardFromComponent();
	}, [selectedClientId, selectedWorkspaceId]);

	const setComponentValid = () => {
		if (selectedClientId > -1 && selectedWorkspaceId > 0) {
			isComponentValid(true);
		} else {
			isComponentValid(false);
		}
	};

	const acceptEngagementPackage = () => {
		setIsLoading(true);
		setIsSaving(true);
		dispatch(
			patchCopyRequest(
				env.getURL('serviceUrl') +
					'/' +
					urls.GROUPAUDITENGAGEMENTS_URL +
					'?id=' +
					copyRequest.id +
					'&phase=copyhubRequest',
				{
					collections: {
						iepnotifications: [
							{
								id: copyRequest.id,
								data: {
									operationtype: 'acceptIEPRequest',
									selectedworkspaceid: selectedWorkspaceId,
									targetengagementid: 0,
									copyobjectgrouptypeid: copyRequest.copyobjectgrouptypeid,
									targetengagementdescription: '',
									isimportforreview: isChkImportForReviewOnly
								}
							}
						]
					}
				}
			)
		)
			.then(() => {
				dispatch(getNotifications(getNotificationsUrl));
				closeWizard();
			})
			.catch(() => {
				isLoading(false);
				isComponentValid(true);
			});
	};

	const updateWizardFromComponent = () => {
		// Pass on data to next step
		updateWizardData('domainClientId', selectedClientId);
		updateWizardData('domainClientName', clientName);
		updateWizardData('domainWorkspaceId', selectedWorkspaceId);
		updateWizardData('domainWorkspaceName', workspaceName);

		setComponentValid();
	};

	const getWorkspaces = (key, value) => {
		if (value === null) {
			setSelectedClientId(-1);
			setClientWorkspaces([]);
			return;
		}

		setSelectedClientId(value);
		updateWizardFromComponent();

		let selectedClient =
			clientData &&
			clientData.collections &&
			clientData.collections.clients &&
			clientData.collections.clients.filter(function (item) {
				if (item.id === value) {
					return item;
				}
			});

		selectedClient &&
			selectedClient.map(({data, collections}) => {
				setClientName(data.clientname);
				setClientWorkspaces(collections.workspaces);
			});

		// Disabling client ddl if no workspaces are present
		if (!clientWorkspaces) {
			setDisableClient(true);
		}

		setShowValidation(false);
	};

	const handleWorkspaceSelection = (key, value) => {
		setSelectedWorkspaceId(key);
		setWorkspaceName(value);
		setShowValidation(false);
		updateWizardFromComponent();
	};

	const isWorkspaceEligibleForNewengagementCreation = (data) => {
		if ((copyRequest.copyobjectgrouptypeid == CopyObjectGroupType.Engagement ||
			copyRequest.copyobjectgrouptypeid == CopyObjectGroupType.V2Engagement ||
			copyRequest.copyobjectgrouptypeid == CopyObjectGroupType.V2EngagementRollforwardCopy ||
			copyRequest.copyobjectgrouptypeid == CopyObjectGroupType.EngagementByEntity) && data.iseligiblefornewengagement === false) {
			return false;
		}
		else {
			return true;
		}
	};

	const Workspaces = () => {
		let result = [];
		if (clientWorkspaces) {
			clientWorkspaces.forEach(({ id, data }) => {
				let isNewEngagementCreationAllowed = isWorkspaceEligibleForNewengagementCreation(data);
				result.push(
					( <Radio
						value={id}						
						className={isNewEngagementCreationAllowed === false ? 'disabled' : ''}			
						key={id}
						name="radio"
						checked={id == selectedWorkspaceId}
						onClick={() => handleWorkspaceSelection(id, data.workspacedesc)}						
					>
						{data.workspacedesc}
						{isNewEngagementCreationAllowed === false ? (<span className='newengagement-eligible'>{'(' + labels.workspacenotelibilefornewengagement + ')'}</span>) : null}
					</Radio>					
				
					)
				);
			});
		}

		return (
			<section>
				<FormRadio name="radioStack" onChange={() => {}}>
					{result}
				</FormRadio>
			</section>
		);
	};

	return (
		<>
			{isLoading ? (
				<Loader view={isSaving ? 'fullscreen' : null} />
			) : (
				<Selectexistingworkspace>
					<header>
						<h5>{labels.selectEngagementHeader}</h5>
					</header>
					<form>
						<section>
							<section className="existingworkspacelist">
								<FormComboBox
									id="clients"
									name="clients"
									value={selectedClientId}
									is_required
									errormessage={labels.isRequired.replace('{0}', labels.client)}
									help
									searchable
									placeholder={labels.selectworkspaceclient}
									options={
										clientData &&
										clientData.collections &&
										clientData.collections.clients.map(({id, data}) => {
											return {
												value: id,
												label: data.clientname,
												title: data.clientname
											};
										})
									}
									onChange={(id, value) => getWorkspaces(id, value)}
									disabled={disableClient}
								/>
							</section>

							<section className="import-error">
								{showValidation === true ? (
									<section>
										<SVGICONS styleName="small" id="icon" name="error" />
										<span>{errorMessage}</span>
									</section>
								) : (
									standardStrings.EMPTY
								)}
							</section>

							<section className="selectexistingworkspace">
								{selectedClientId && selectedClientId < 0
									? showValidation == false
										? labels.selectclient
										: null
									: clientWorkspaces.length > 0
									? Workspaces()
									: labels.selectworkspacenoclientworkspaceexists}
							</section>
						</section>
					</form>
				</Selectexistingworkspace>
			)}
		</>
	);
};
export default withRouter(SelectWorkspace);

const Selectexistingworkspace = styled.section`
	& .selectexistingworkspace {
		padding-top: var(--px-10);
		font-size: var(--px-14);

		& div.form-group .radio {
			margin: 0 0 var(--px-15) 0;
		}

		& .form-group label.control-label {
			display: inline;
		}
	}
	.radio.disabled label,
	input[type=radio][disabled] {
		cursor:default;
	}
	.radio.disabled {
		pointer-events: none;
		cursor: default;
	}
	& .import-error {
		font-size: var(--px-12);
		line-height: normal;
		height: var(--px-20);
		padding-top: var(--px-10);
		padding-bottom: var(--px-20);

		& svg {
			fill: var(--red-600);
			vertical-align: text-top;
			margin-right: 0.5em;
		}
	}

	& .form-group .radio {
		vertical-align: middle;
		margin: 0;

		& label input[type='radio'] {
			margin: 0 0 0 calc(var(--px-30) * -1);
		}
		& label {
			padding-left: var(--px-30);
			width: auto;
			word-wrap: break-word;
			font-weight: normal;
		}
		& .newengagement-eligible {
			display:inline-block;
			padding-left: var(--px-5);
			width: auto;
			font-weight: 300;
			font-size:var(--px-14);
			color: var(--neutrals-700);

		}
	}

	& .existingworkspacelist {
		display: inline-block;
		width: 95%;
		@media screen and (max-width: 1366px) {
			.Select {
				&.is-open .Select-menu-outer {
					max-height: 200px !important;
					& * {
						max-height: 200px !important;
					}
				}
			}
		}
		& div.form-group {
			display: inline-flex;
			width: 100%;
			font-size: var(--px-14);
			margin: 0;
			padding: 0 0 var(--px-20) 0;
			border-bottom: var(--px-1) solid var(--neutrals-200);

			& > section {
				width: var(--px-400);
				float: left;

				& .select-label {
					line-height: normal;
					color: var(--neutrals-900);
					font-size: var(--px-14);
				}

				& .Select-control {
					margin: 0 var(--px-20) 0 0;
					vertical-align: middle;
					border: var(--px-1) solid var(--neutrals-300);
					line-height: var(--px-46);
				}
			}
		}
	}
`;
