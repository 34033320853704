import React, {useEffect, useState} from 'react';
import Checkbox from '@ey/motif-react-wrapper/Checkbox';
import FormField from '@ey/motif-react-wrapper/FormField';
import Tooltip from '@ey/motif-react-wrapper/Tooltip';
import {
	dateFormats,
	dateFormatsListItems,
	labels
} from '../../../util/uiconstants';
import styled from 'styled-components';

import {CopyObjectGroupType} from '../../../util/enumerations';
import EllipsesControl from '@ey/ellipses-control';
import {FormRadio} from '@ey/form';
import {FormStaticText} from '@ey/form';
import Importscot from './importscot';
import {Radio} from 'react-bootstrap';
import {SVGICONS} from '@ey/icons';
import moment from 'moment';
import {useSelector} from 'react-redux';

const TopLevelStyle = styled.section`
	& .importscotdata {
		margin: var(--px-10) 0 0 0;

		& .form-group .radio {
			vertical-align: middle;
			margin: 0;

			& label input[type='radio'] {
				margin: 0 0 0 calc(var(--px-30) * -1);
			}
			& label {
				padding-left: var(--px-30);
				width: 95%;
				word-wrap: break-word;
			}
		}

		& > .form-group {
			margin: 0 0 var(--px-15) 0;
		}

		& .form-group label.control-label {
			display: inline;
		}
	}

	& .importscotdata div.form-group .radio {
		vertical-align: middle;
		margin: 0 0 var(--px-15) 0;

		& label input[type='radio'] {
			margin: calc(var(--px-1) * -1) 0 0 calc(var(--px-30) * -1);
		}
		& label {
			padding-left: var(--px-30);
		}
	}
`;

const TopLevelImportForReview = styled.div`
	position: absolute;
	bottom: 0;
	display: flex;
	width: 100%;

	.inner-top-level-import-for-review {
		border-top: var(--px-1) solid var(--neutrals-300);
		width: 100%;
		display: flex;
		padding-top: var(--px-15);
	}

	.motif-checkbox-custom {
		width: var(--px-20);
		height: var(--px-20);
	}

	.info-icon-import-for-review {
		margin-left: 8px;
	}

	.info-tooltip {
		font-size: 14px;
	}
`;

const ChooseAcceptDeclineDelegate = (props) => {
	const {
		getDependencyData,
		updateWizardData,
		importForReviewCheck,
		isGroupAudit
	} = props;

	let pastSelection = 0;
	let storedSelection = getDependencyData('domainAcceptDelegateDecline');
	if (storedSelection != undefined && storedSelection != null) {
		pastSelection = storedSelection;
	}

	const [accept, setAccept] = useState(pastSelection == 0 ? true : false);
	const [delegate, setDelegate] = useState(pastSelection == 1 ? true : false);
	const [decline, setDecline] = useState(pastSelection == 2 ? true : false);
	const [isChkImportForReviewOnlyChecked, setIsChkImportForReviewOnlyChecked] =
		useState(false);

	const copyRequest = useSelector((state) => state.copyHub.copyRequest || {});
	const linkRequest = useSelector((state) => state.copyHub.linkRequest || {});

	const acceptRadioButtonAttributes = accept === true ? {checked: true} : {};
	const delegateRadioButtonAttributes =
		delegate === true ? {checked: true} : {};
	const declineRadioButtonAttributes = decline === true ? {checked: true} : {};

	const _updateWizardData = (_accept, _decline, _delegate) => {
		let val = 0;

		if (_accept) val = 0;
		else if (_delegate) val = 1;
		else val = 2;

		updateWizardData('domainAcceptDelegateDecline', val);
		updateWizardData('domainAccept', _accept);
		updateWizardData('domainDecline', _decline);
		updateWizardData('domainDelegate', _delegate);
	};

	useEffect(() => {
		_updateWizardData(accept, decline, delegate);
	}, []);

	const isChkImportForReviewVisible = () => {
		return (
			copyRequest.copyobjectgrouptypeid === CopyObjectGroupType.V2Engagement ||
			copyRequest.copyobjectgrouptypeid === CopyObjectGroupType.EngagementByEntity
		);
	};

	const handleRadioButtonChange = (key, value) => {
		if (value === 'accept') {
			setAccept(true);
			setDecline(false);
			setDelegate(false);
			_updateWizardData(true, false, false);
		} else if (value === 'decline') {
			setAccept(false);
			setDecline(true);
			setDelegate(false);
			_updateWizardData(false, true, false);
			setImportForReviewState(false);
		} else if (value === 'delegate') {
			setAccept(false);
			setDecline(false);
			setDelegate(true);
			_updateWizardData(false, false, true);
			setImportForReviewState(false);
		}
	};

	const handleChkImportForReviewChecked = (isChecked) => {
		setImportForReviewState(isChecked);
	};

	const setImportForReviewState = (value) => {
		setIsChkImportForReviewOnlyChecked(value);
		if (importForReviewCheck) {
			importForReviewCheck(value);
		}
	};

	const getHeaderForAction = () => {
		const packageRecieveDate = moment
			.utc(copyRequest?.iepreceivedate)
			.format(dateFormatsListItems[dateFormats].label);

		if (isGroupAudit) {
			return (
				<Importscot>
					<header>
						<h5>{labels.gaacceptdeclinedelegatequestionlabel}</h5>
					</header>
					<section className="importscot">
						<section className="packagesourcedetail">
							<section className="packagedetailscolumn">
								<FormStaticText label={labels.sourceengagement + ':'}>
									<EllipsesControl
										id="sourceengagementname"
										content={linkRequest.primaryengagementname}
										tooltip={linkRequest.primaryengagementname}
										isTooltipAvailable
									/>
								</FormStaticText>
								<FormStaticText label={labels.garegioncomponent + ':'}>
									<EllipsesControl
										id="regionComponent"
										content={linkRequest.componentshortname}
										tooltip={linkRequest.componentshortname}
										isTooltipAvailable
									/>
								</FormStaticText>
								<FormStaticText label={labels.gascope + ':'}>
									<EllipsesControl
										id="Scope"
										content={linkRequest.gascopename}
										tooltip={linkRequest.gascopename}
										isTooltipAvailable
									/>
								</FormStaticText>
							</section>
						</section>
					</section>
				</Importscot>
			);
		} else {
			return (
				<Importscot>
					<header>
						<h5>{labels.importdatapackagequestionlabel}</h5>
					</header>{' '}
					<section className="importscot">
						<section className="packagesourcedetail">
							<FormStaticText label={labels.package + ':'}>
								<EllipsesControl
									id="packagename"
									content={copyRequest.copyname}
									tooltip={copyRequest.copyname}
									isTooltipAvailable
								/>
							</FormStaticText>
							<FormStaticText label={labels.sourceengagement + ':'}>
								<EllipsesControl
									id="sourceengagementname"
									content={copyRequest.sourceengagementdescription}
									tooltip={copyRequest.sourceengagementdescription}
									isTooltipAvailable
								/>
							</FormStaticText>
							<section className="sentbydetails">
								<FormStaticText label={labels.sentby + ':'}>
									{copyRequest.id
										? copyRequest.sendername +
										  ' ' +
										  labels.on +
										  ' ' +
										  packageRecieveDate
										: ''}
								</FormStaticText>
							</section>
						</section>
					</section>
				</Importscot>
			);
		}
	};

	return (
		<form className="section-step-1 relative">
			<TopLevelStyle>
				{getHeaderForAction()}
				<section className="importscotdata">
					<FormRadio
						label=""
						name="radioStack"
						help
						is_required
						onChange={handleRadioButtonChange}
					>
						<Radio value={'accept'} key={1} {...acceptRadioButtonAttributes}>
							{labels.accept}
						</Radio>
						<Radio
							value={'delegate'}
							key={2}
							{...delegateRadioButtonAttributes}
						>
							{labels.delegate}
						</Radio>
						<Radio value={'decline'} key={3} {...declineRadioButtonAttributes}>
							{labels.decline}
						</Radio>
					</FormRadio>
				</section>
			</TopLevelStyle>
			<TopLevelImportForReview
				style={{
					display: isChkImportForReviewVisible() ? 'block' : 'none'
				}}
			>
				<div className="inner-top-level-import-for-review">
					<FormField className="confirmbox">
						<Checkbox
							id="chkImportForReviewOnly"
							name="chkImportForReviewOnly"
							value="chkImportForReviewOnly"
							checked={isChkImportForReviewOnlyChecked}
							disabled={!accept}
							onChange={(event) =>
								handleChkImportForReviewChecked(event.target.checked)
							}
						>
							<span>{labels.importForReviewChecboxText}</span>
						</Checkbox>
					</FormField>
					<section className="info-icon-import-for-review">
						<Tooltip
							contentClassName="import-for-review-info-tooltip"
							placement="top"
							closeButtonProps={{}}
							trigger={<SVGICONS styleName="medium" id="icon" name="info" />}
						>
							<span>{labels.importForReviewTooltip}</span>
						</Tooltip>
					</section>
				</div>
			</TopLevelImportForReview>
		</form>
	);
};

export default ChooseAcceptDeclineDelegate;
