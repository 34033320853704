/* eslint-disable prettier */

import {
	Addon
} from 'react-bootstrap/lib/InputGroup';

/**
 * Created by calhosh on 4/14/2017.
* FR CA resource file
 */
export const labels = {
	defaultDropdown: "Menu déroulant",
	status: "État",
	language: "Langue",
	channel: "Édition",
	header: "En-tête",
	headerName: "Nom de l'en-tête",
	sectionCustomLabelLabel: "Étiquette personnalisée",
	sectionNamePlaceHolder: "Entrer le titre de la section",
	showRelatedObjectLabel: "Afficher les objets associés",
	errorBanner: "{0} erreur(s)",
	engagements: "Missions",
	selectedengagements: "Mission",
	addEngagement: "Ajouter une mission",
	archives: "Archives",
	userInitialsTitle: "Options de l’utilisateur",
	clickForOptions: "Cliquez ici pour plus d’options",
	comingDue: "Arrivant à échéance",
	whatsNew: "Nouveautés",
	auditMetrics: "Mesure de l’audit",
	id: "Code",
	archived: "Archivée",
	myarchives: "Mes archives",
	allarchives: "Toutes les archives",
	logout: "Se déconnecter",
	norecords: "Aucun enregistrement trouvé",
	nomatches: "Aucun résultat",
	notemplates: "Aucun gabarit n’est disponible pour la région/zone sélectionnée.",
	pleasetryagain: "Veuillez réessayer",
	firstpage: "Première page",
	previous: "Précédent",
	next: "Suivant",
	prevpage: "Page précédente",
	nextpage: "Page suivante",
	lastpage: "Dernière page",
	allengagements: "Toutes les missions",
	searchPlaceholder: "Rechercher ",
	searchEngagementsPlaceholder: "Rechercher des missions",
	clearSearch: "Effacer la recherche",
	searchTextPlaceHolder: "Rechercher",
	show: "Afficher",
	manageaccess: "Gérer les accès",
	restore: "Restaurer ",
	rollforward: "Récupérer",
	viewaccesslist: "Afficher la liste d'accès",
	teammembers: "Membres de l'équipe",
	name: "Nom",
	initials: "Initiales",
	role: "Rôle",
	usersettings: "Paramètres de l'utilisateur",
	usersettingsnewengagements: "Les paramètres s'appliquent aux missions nouvellement créées",
	usersettingsallengagements: "Les paramètres s'appliquent à tous les espaces de travail et toutes les missions",
	enterfirstname: "Afficher le prénom",
	enterlastname: "Afficher le nom de famille",
	firstname: "Prénom",
	lastname: "Nom de famille",
	enter: "Entrer",
	select: "Sélectionner…",
	email: "Adresse courriel",
	contact: "Numéro de téléphone",
	accesslist: "Liste d'accès",
	engagementsettings: "Paramètres de la mission",
	displayname: "Nom d'affichage",
	signoffinitials: "Initiales pour l'approbation",
	primaryoffice: "Bureau principal",
	roles: "Rôle",
	phonenumber: "Numéro de téléphone",
	globalapplicationsettings: "Paramètres généraux de l'application",
	displaylanguage: "Langue d'affichage",
	preferreddateformat: "Format de date à utiliser ",
	preferrednumberformat: "Format numérique à utiliser",
	save: "Enregistrer ",
	cancel: "Annuler",
	isRequired: "{0} est requis",
	arcretrymessage: "Une erreur s’est produite pendant le processus d’archivage. La date d’archivage actuelle du dossier est le {0}. EY Canvas procède automatiquement à la récupération de votre archive. Veuillez attendre la fin du processus avant de communiquer avec le Service de dépannage, soit quinze heures à partir du moment où l’archive a été envoyée. Si le Service de dépannage peut résoudre le problème sans réactiver la mission, la date d’archivage demeurera le {0}. Si le Service de dépannage doit réactiver la mission pour permettre à l’équipe de prendre des mesures correctives, la date d’archivage sera réinitialisée lorsque l’équipe enverra de nouveau l’archive. Si cette action cause un délai d’archivage, l’équipe devra ajouter à la mission un document où seront indiqués le numéro du billet fourni par le Service de dépannage, une description du problème et les mesures prises pour résoudre le problème conformément à la politique d’archivage locale.",
	ArchiveRetryLink: "Nouvelle tentative d'archivage",
	workspacelist: "Liste des espaces de travail",
	engagementslist: "Liste des missions",
	accessrequested: "Accès demandé",
	noaccess: "Aucun accès",
	independenceOnlyNoAccessMessage: "Votre rôle dans la mission est défini à « Indépendance seulement ». Veuillez communiquer avec un membre de l’équipe de mission pour obtenir le droit de restaurer ou de récupérer la mission.",
	accessrejected: "Accès refusé",
	requestaccess: "Demander l'accès",
	allarchiveinitialmsg: "Commencer par entrer le nom du client et le nom de l'espace de travail ou le nom de la mission.",
	noengagementsforusermessage: "Vous n'avez aucune mission active. Cliquez sur «Créer une mission» pour commencer. ",
	noengagementstoimportto: "Vous n’avez aucune mission à importer.",
	favorites: "Favoris",
	portfolioview: "Toutes les missions (v2)",
	reviewengagement: "Missions d’examen",
	noreviewengagementsmessage: "Vous n’avez aucune mission d’examen.",
	noreviewengagementsmessagecontact: "Vous n’avez aucune mission d’examen. Communiquez avec un membre de l’équipe de mission pour obtenir les droits d’accès.",
	nofavoriteengagementsmsg1: "Vous n'avez aucune mission dans vos favoris.",
	nofavoriteengagementsmsg2: "Pour ajouter des missions à cette vue, accédez à toutes les missions,",
	nofavoriteengagementsmsg3: "et cliquez sur l'icône des favoris à partir de la fiche de la mission.",
	markasfavorite: "Ajouter aux favoris",
	unmarkfromfavorites: "Supprimer des favoris",
	selectworkspacenoclientworkspaceexists: "Vous n'avez pas d'espace de travail existant. Cliquez sur Retour et sélectionnez «Nouvel espace de travail» à l'étape 1.",
	importpkgselectworkspacenoclientworkspaceexists: "Il n’y a aucun espace de travail existant. Cliquez sur Retour et sélectionnez «Créer un nouvel espace de travail» à l’étape précédente.",
	expirydatelabel: "Cette mission sera supprimée dans",
	expirydatetodaytomorrowlabel: "Cette mission sera supprimée",
	/*Reporting Hub*/
	reportingHub: "Rapport",
	reportingHubTitle: "Assistant rapport d'EY Canvas",
	selectEngagement: "Sélectionner une mission pour afficher les rapports",
	connectVPN: "Vous ne pouvez accéder aux rapports que si vous êtes connecté au réseau d’EY ou à EY Remote Connect.",
	reportsNotLoaded: "Les rapports n’ont pas pu être téléchargés. Veuillez actualiser la page et réessayer plus tard. Si le problème persiste, veuillez communiquer avec le Service de dépannage.",
	searchByEngagementNameOrClientId: "Sélectionner la mission",
	dataExportLabel: "Exportations de données",
	summaryScreensLabel: "Écrans de synthèse",
	projectManagementLabel: "Gestion de projet",
	engagement: "Nom de la mission",
	reportingNotAvailable: "La fonction Rapport n’est pas disponible à l’emplacement sélectionné. Veuillez communiquer avec le champion d’EY Canvas de votre bureau pour obtenir de plus amples renseignements.",
	client: "Client",
	workspace: "Espace de travail",
	serviceline: "Gamme de services",
	subserviceline: "Sous-gamme de services",
	engagementstartdate: "Date de clôture",
	engagementenddate: "Date de fin de la période visée",
	deleteafter: "Supprimer après",
	auditorsreportdate: "Date du rapport",
	createengagementquestionlabel: "Créer une mission",
	choosenewexistingquestionheader: "Voulez-vous créer la mission dans un espace de travail existant ou dans un nouvel espace de travail?",
	createengagementheader: "Créer la mission",
	restoreengagementheader: "Restaurer la mission",
	createworkspacestepheader: "Créer un nouvel espace de travail",
	selectexistingworkspacestepheader: "Sélectionner un espace de travail existant",
	workspacenotelibilefornewengagement: "Espace de travail non disponible pour les nouvelles missions",
	choosenewexistingstepheader: "Nouvel espace de travail ou espace de travail existant",
	editengagementwizheader: "Modifier la mission",
	removeengagementwizheader: "Retirer la mission",
	editworkspacewizheader: "Modifier l'espace de travail",
	editworkspacestepfooter: "Cliquez sur « Enregistrer et fermer » pour enregistrer les changements apportés à l’espace de travail ou cliquez sur « Enregistrer et continuer » pour modifier une mission.",
	editwizheader: "Modifier",
	newworkspace: "Nouvel espace de travail",
	existingworkspace: "Espace de travail existant",
	back: "Retour",
	continue: "Continuer",
	saveclose: "Enregistrer et fermer",
	savecontinue: "Enregistrer et continuer",
	finish: "Terminer",
	step01: "Étape 01",
	step02: "Étape 02",
	step03: "Étape 03",
	step04: "Étape 04",
	step05: "Étape 05",
	engagementname: "Nom de la mission",
	confirmdecline: "Confirmer le refus de la demande de validation. Cette action ne peut pas être annulée.",
	confirmdeclinevalidation: "Confirmez que vous voulez refuser.",
	responderror: "Vous avez déjà répondu à cette invitation. Actualisez la page et réessayez.",
	engagementcode: "Code mission",
	engagementcodename: "Nom du code mission",
	selectEngagementHeader: "Sélectionner un espace de travail existant ",

	selectworkspaceclient: "Sélectionner le client",
	selectclient: "Sélectionner un client pour afficher les espaces de travail qui y sont associés",
	createnewworkspace: "Créer un nouvel espace de travail",
	editworkspace: "Modifier l’espace de travail",
	clientcode: "Code client ",
	clientname: "Nom du client",
	invalidclientcode: "Code client non valide",
	workspacename: "Nom de l'espace de travail",
    location: "Emplacement",
	workspacecodenameplaceholder: "Saisir le code client pour chercher un nom de client",
	engagementcodenameplaceholder: "Saisir le code mission pour chercher un nom de code",
	isprimary: "Principal",
	addengagementcode: "Ajouter un code mission",
	engagementnamerequired: "Le nom de la mission est requis",
	primaryofficerequired: "Le bureau principal est requis",
	languagerequired: "La langue est requise",
	servicelinerequired: "Le nom de la gamme de services est requis",
	subservicelinerequired: "Le nom de la sous-gamme de services est requis",
	engagementcodevalidationmessage: "Le code mission ne correspond pas au code client",
	engagementcoderequired: "Le code mission est requis",
	yearenddate: "Date de clôture",
	periodenddate: "Date de fin de la période",
	reportdate: "Date du rapport",
	complete: "Terminée",
	end: "Fin",
	started: "Commencée",
	notstarted: "Non commencée",
	inprogress: "En cours",
	inreview: "En cours de revue",
	taskstatus: "État de la tâche",
	confirmlabel: "Confirmer",
	submitlabel: "Soumettre",
	editengagementquestionlabel: "Modifier une mission",
	removeengagementquestionlabel: "Retirer la mission",
	removeengaementconfirmationmessage1: "Voulez-vous vraiment retirer la mission suivante de la liste de missions? Si vous retirez la mission, vous ne pourrez plus y accéder.",
	removeengagementerrormessage: "La mission ne peut pas être retirée de Mes espaces de travail, car vous êtes le seul utilisateur actif. Veuillez ajouter un autre membre de l’équipe ou supprimer la mission.",
	deleteengagement: "Supprimer la mission",
	deletreviewcopyoption: "Supprimer",
	deleteengagementconfirmationmessage: "Voulez-vous vraiment envoyer une demande pour supprimer la mission suivante? ",
	deleteengagementconfirmationmessage1: "Les équipes de mission doivent consulter les Services professionnels pour déterminer si l’archivage est nécessaire pour une mission à laquelle on a mis fin ou à l’issue de laquelle nous ne délivrons pas de rapport.",
	deleteengagementconfirmationmessageoneuser: "Voulez-vous vraiment supprimer la mission suivante? ",
	deleteengagementconfirmationmessageoneuser1: "Les exigences en matière d’archivage énoncées à ",
	deleteengagementconfirmationmessageoneuser2: "la section DOC+ARC 3.1",
	deleteengagementconfirmationmessageoneuser3: "doivent être prises en compte avant la suppression. Par exemple, pour les missions qui ont été résiliées ou au terme desquelles nous ne délivrons pas de rapport, nous devons consulter les Services professionnels pour déterminer s’il y a lieu d’archiver la mission.",
	deleteengagementmarkeddelete: "Cette mission fait déjà l’objet d’une demande de suppression.",
	deleteengagementoption: "Supprimer",
	deleteengagementreasonlabel: "Indiquer la raison de la suppression",
	deleteengagementreasonquestion: "Pourquoi supprimez-vous cette mission?",
	deleteengagementreasonrequired: "Une raison est requise. Sélectionnez une option ou indiquez une raison ci-dessus.",
	deleteengagementcommentreasonrequired: "Une raison est requise. Sélectionnez une raison ci-dessus.",
	deleteengagementcommentrequiredlabel: "Veuillez indiquer la raison de la suppression.",
	deletereasontrainingengagementlabel: "Mission de formation",
	deletereasonrestoredorcopiedlabel: "Mission restaurée ou copiée à des fins de référence seulement",
	deletereasoncreatedbymistakelabel: "Créée par erreur",
	deletereasonotherlabel: "Autre",
	deletereasonterminedbyclientlabel: "Mission à laquelle on a mis fin et pour laquelle aucun travail n’a été réalisé. Si des travaux ont été réalisés dans le cadre de la mission, ils doivent être archivés.",
	markeddeleteconfirm: "Confirmer la suppression",
	deleterequestbannertext1: "Demande de suppression de ",
	deleterequestbannertext2: " il y a X jours",
	markeddeleteconfirmmessage: "Voulez-vous vraiment supprimer la mission suivante? ",
	markeddeleteconfirmmessage1: "Si vous confirmez la suppression, cette action ne peut pas être annulée.",
	markeddeletereasonlabel: "Raisons justifiant la suppression",
	engagementlistplaceholder: "Sélectionner une mission",
	engagementrequired: "La mission est requise",
	restoreconfirmationstepheader: "Confirmation",
	restoreconfirmationheader: "Voulez-vous vraiment restaurer la mission?",
	selectarchiveheader: "Sélectionner le fichier archivé",
	restoreengagementstepheader: "Restaurer la mission",
	chooserollfwdhdr: "Récupérer dans un nouvel espace de travail ou un espace de travail existant",
	rollfwdstephdrext: "Récupérer dans un espace de travail existant",
	rollfwdstephdrnew: "Récupérer dans un nouvel espace de travail",
	rollfwdhdrqstn: "Voulez-vous récupérer la mission dans un nouvel espace de travail ou dans un espace de travail existant?",
	rollforwardengagementstepheader: "Récupérer une mission",
	genericerror1: "Une erreur s’est produite. Veuillez réessayer ou communiquez avec l’équipe de soutien. ",
	restoreengagementmaxcopies: "Le nombre maximal de copies restaurées ({0}) pour la mission est dépassé. Demandez l’accès à une copie existante.",
	restoreengagementengcopies: "{0} copies restaurées de cette mission sont disponibles.",
	restoreengagementengcopy: "{0} copie restaurée de cette mission est disponible.",
	restoreengagementengnocopies: "Aucune autre copie restaurée de cette mission n’est disponible.",
	globalexceptionrestoreengagementengcopies: "{0} copies restaurées de cette mission sont disponibles. Cochez la case ci-dessus pour restaurer le statut d’exception à l’échelle mondiale.",
	globalexception: "Exception globale",
	globalexceptioncheckerror: "Cochez l’option Exception mondiale pour continuer.",
	restoreengagementrestoredon: "restaurée le",
	restoreengagementusers: "utilisateurs",
	restoreengagementuser: "utilisateur",
	at: "le",
	arcfileuserunavailable: "Utilisateur non disponible",

	restorehdrqstn: "Voulez-vous restaurer la mission dans un nouvel espace de travail ou dans un espace de travail existant?",
	chooserestorehdr: "Restaurer dans un nouvel espace de travail ou dans un espace de travail existant",
	restorestephdrext: "Restaurer dans un espace de travail existant",
	restorestephdrnew: "Restaurer dans un nouvel espace de travail",

	close: "Fermer",
	confirm: "Confirmer",
	settings: "Paramètres",
	expand: "Développer",
	collapse: "Réduire",
	contextMenuEdit: "Modifier",
	contextMenuEditEngagement: "Modifier la mission",
	contextMenuRemoveEngagement: "Retirer la mission",

	contextMenuDeleteEngagement: "Supprimer la mission",
	contextMenuEditWorkspace: "Modifier l’espace de travail",
	contextMenuDelete: "Supprimer",
	contextMenuOverrideArchiveDate: "Remplacer la date limite d’archivage",
	na: "s. o.",
	archivesubmittedby: "Envoyée par",
	archivedateandtime: "Date et heure",
	notifications: "Notifications",
	nonotificationmsg: "Vous n’avez aucune notification.",
	notifcenter: "Centre de notification",
	notifitemspendingact: "Éléments en attente",
	accept: "Accepter",
	reject: "Rejeter",
	acceptall: "Tout accepter",
	rejectall: "Tout rejeter",
	notifEngInvite: "Invitations à une mission",
	notifengaccesreq: "Demande d’accès à la mission",
	keyevents: "Événements clés",
	milestones: "Jalons",
	importselecttemplateheader: "Veuillez sélectionner le gabarit que vous souhaitez utiliser.",
	importtemplateheader: "Veuillez sélectionner la région/zone de laquelle vous souhaitez importer le gabarit.",
	import: "Importer",
	importregionstepheader: "Sélectionner la région/zone",
	importtemplatestepheader: "Sélectionner un gabarit",
	importtemplatemsg: "Sélectionner une région/zone pour continuer",
	importselecttemplatemsg: "Sélectionner un gabarit pour continuer",
	importengagementheader: "Importer le gabarit",
	importhdrqstn: "Importer dans un nouvel espace de travail ou dans un espace de travail existant",
	importstephdrext: "Importer dans un espace de travail existant",
	importstephdrnew: "Importer dans un nouvel espace de travail",
	chooseimporthdr: "Importer dans un nouvel espace de travail ou dans un espace de travail existant",
	selectexistingworkspacemsg: "Sélectionner un espace de travail pour continuer",
	selectaclienttocontinuemsg: "Sélectionner un client pour continuer",
	selectengagementmsg: "Sélectionner une mission pour accepter le paquet de données",
	selectgaengagementmsg: "Vous devez sélectionner une mission pour accepter la demande de lien.",
	arearegion: "Région/zone",
	importdatapackagequestionlabel: "Que voulez-vous faire?",
	sentby: "Envoyé par",
	on: "le",
	by: "par",
	view: "Afficher",
	sourceengagement: "Mission source",
	package: "Paquet",
	decline: "Refuser",
	delegate: "Déléguer",
	chooseaction: "Choisir une action",
	selectworkspace: "Sélectionner un espace de travail",
	declinepackage: "Refuser la demande",
	delegatepackage: "Déléguer le paquet",
	selectengagement: "Sélectionner une mission",
	datapackagerequest: "Demande de paquets de données",
	respond: "cliquez ici pour répondre",
	deletionrequestfor: "Demande de suppression reçue pour",
	invalidengagementcode: "Code mission invalide",
	noNotifications: "Aucune notification disponible",
	bellIconTitle: "Centre de notification",
	restoredengagement: "Mission restaurée",
	profiledeliveryinprogress: "Envoi du profil en cours",
	completeprofileheader: "Établir le profil",
	completeindependenceheader: "Produire la confirmation d’indépendance",
	independencecompletedheader: "Confirmation d’indépendance remplie",
	retry: "Réessayer",
	errorDate: "Erreur",
	invitationDate: "Date de l’invitation",
	engCreationFailedWarning: "Échec de la demande de création de la mission. Actualisez la page et réessayez. Si le problème persiste, veuillez communiquer avec le Service de dépannage.",
	deletedEngagementWarning: "Si la mission était en cours d’utilisation et a été supprimée par erreur, communiquez avec le Service de dépannage immédiatement.",
	searchEngagementWarning: "Recherchez ces missions pour prendre d’autres mesures. ",
	archiveOverrideWarning: "Le Centre des archives ne permet pas de remplacer le délai d’archivage sélectionné. La date limite d’archivage a été rétablie à la valeur par défaut pour votre emplacement.",
	archiveOverrideInProgress: "Demande de remplacement du délai d’archivage en attente de l’approbation du Centre des archives",
	archiveOverrideAccepted: "Le délai d’archivage a été remplacé.",
	archiveOverrideRejected: "Le Centre des archives ne permet pas de remplacer le délai d’archivage sélectionné. La date limite d’archivage a été rétablie à la valeur par défaut pour votre emplacement.",
	scopeName: "Nom de l’étendue",
	shortName: "Nom abrégé",
	invited: "Invité",
	invitedOn: "Invité le",
	instructions: "instructions",
	updates: "mises à jour",
	viewinstructions: "Afficher les instructions",
	viewinstructionsupdates: "Afficher les mises à jour",
	more: "Plus",
	addmembers: "Ajouter des membres",
	statusChangeDate: "Modification de l’état",
	deletedBy: "Supprimée par",
	deletedDate: "Supprimée",
	deactivatedBy: "Désactivée par",
	deactivatedDate: "Désactivée",
	requestUserName: "Demandée par",
	requestDate: "Demandée",
	archiveDate: "Archivée",
	receivedFrom: "Reçue de",
	received: "Reçue",
	clear: "Effacer",
	clearAll: "Tout effacer",
	type: "Type",
	engagementhasbeendeleted: "La mission sélectionnée a été supprimée par un autre utilisateur. Actualisez la page et réessayez.",
	independenceOnlyRejectInvite: {
		engagementIsNotAvailable: "La mission n’est pas disponible. Veuillez actualiser la page et réessayer. Si le problème persiste, veuillez communiquer avec le Service de dépannage.",
		failedToRejectInvite: "Échec du refus de l’invitation. Veuillez actualiser la page et réessayer. Si le problème persiste, veuillez communiquer avec le Service de dépannage.",
		succesfulRejection: "L’invitation a bien été refusée.",
		loadingLabel: "Refus de l’invitation en cours...",
	},
	manageTeam: "Gérer l’équipe",
	childinprogress: "Configuration de la mission en cours",
	// SeeMore component
	showMore: "Afficher plus",
	showLess: "Afficher moins",
	showMoreEllipsis: "Afficher plus…",
	showLessEllipsis: "Afficher moins…",
	accepted: "Acceptée",
	acceptedOn: "Acceptée le :",
	rejected: "Rejetée",
	deniedBy: "Refusée par",
	engagementstatusrestored: "Restaurée",
	engagementstatusmarkedfordeletion: "Inscrite comme étant à supprimer",
	delegaterequired: "Délégué requis",
	username: "Nom de l’utilisateur",
	noresults: "Aucun résultat. Veuillez réessayer.",
	importdatapackageconcurrencymessage: "L’état de la demande de copie a changé. Actualisez la page et réessayez.",
	selectdelegateuser: "Sélectionner un délégué",
	eyusersearchplaceholder: "Entrer le nom ou l’adresse courriel à rechercher",
	optional: "(Optional)",
	engagementAlerts: "Alertes de la mission",
	engagementMetrics: "Mesures de la mission",
	overdue: "En retard",
	/*day labels*/
	mon: "Lun",
	tues: "Mar",
	wed: "Mer",
	thurs: "Jeu",
	fri: "Ven",
	sat: "Sam",
	sun: "Dim",
	canvas: 'Canvas',
	overrideArchiveDateModalHeader: "Remplacer la date limite d’archivage",
	overrideArchiveDateModalMessage: "Voulez-vous vraiment remplacer la date limite d’archivage?",
	overrideArchiveDateModalComboPlaceholder: "Remplacer la date limite d’archivage",
	createdOn: "Créée le",
	expiresOn: "Expire le",
	archiveDeadlineDate: "Date limite d’archivage",
	defaultOverriden: "Valeur par défaut remplacée",
	selectexistingworkspace: "Sélectionner un espace de travail existant",
	importtonewworkspace: "Importer dans un nouvel espace de travail",
	groupaudit: "Demande de lien pour l’audit du groupe",
	gaacceptdeclinedelegatequestionlabel: "Que voulez-vous faire de cette demande de lien?",
	gaacceptselectengagementquestionlabel: "Sélectionner une mission à associer",
	gadelegatequestionlabel: "Déléguer la demande de lien pour l’audit de groupe",
	gadeclinequestionlabel: "Refuser la demande de lien pour l’audit de groupe",
	garegioncomponent: "Zone/composante",
	gascope: "Étendue",
	gamxTransfer: "Transférer à partir de GAMx",
	closeNotificationCenter: "Fermer le centre de notification",
	contextMenucrp: "Créer une copie de la revue",
	workingoffline: "Travailler hors ligne",
	trainingoffline: "Travailler hors ligne (formation)",
	training: "Formation",
	crpdescription: "Créer une copie de la mission pour les réviseurs externes.",
	crpeditdescritpion: "Modifier le nom ou la date d’expiration de la mission. Les missions dont la date d’échéance est passée seront supprimées automatiquement.  ",
	expiry: "Échéance",
	enteremail: "Entrer l’adresse courriel",
	create: "Créer",
	metricsPlaceholderText: "Aucune donnée n’est disponible pour la période sélectionnée.",
	deleteReviewCopy: "Supprimer la copie pour revue",
	deleteReviewCopyMsg: "Voulez-vous vraiment supprimer cette copie pour revue? Une fois supprimée, la mission ne sera plus accessible et ne pourra pas être récupérée.",
	crpNotAvailable: "La copie pour revue ne peut pas être créée pour cette mission, car le portail EY Canvas n’est pas accessible aux utilisateurs externes pour le moment. Veuillez communiquer avec le Service de dépannage pour de plus amples renseignements.",
	externalmember: "Membres externes",
	internalmember: "Membres internes",
	add: "Ajouter",
	crpEditMembers: "Modifier",
	invalidEmail: "Entrer une adresse courriel valide",
	emailNotFound: "Adresse courriel introuvable",
	dupExternalUser: "Un membre externe a déjà été ajouté.",
	noExternalUser: "Aucun membre externe n’a été ajouté.",
	expiresIn: "Expire dans",
	days: "{0} jour(s)",
	today: "aujourd’hui",
	tomorrow: "demain",
	reviewengagementname: "Nom de la mission pour revue",
	enternameoremail: "Entrer le nom ou l’adresse courriel",
	options: "Options",
	userNotFound: "Utilisateur introuvable",
	remove: "Retirer",
	crpEditHeader: "Modifier la copie pour revue",
	globalexceptionrestore: "Restaurer le statut d’exception à l’échelle mondiale",
	expired: "Expiré",
	softwareUpdate: "Mises à jour logicielles",
	updateNow: "Mettre à jour maintenant",
	updateMsg: "Des mises à jour logicielles sont disponibles pour EY Canvas. Sélectionnez Mettre à jour maintenant pour télécharger et installer les mises à jour. La page sera actualisée.",
	preferredlandingpageview: "Affichage recommandé pour la page d’accueil ",
	preferredlandingpagefilter: "Filtre recommandé pour la page d’accueil",
	engagementlist: "Liste de missions",
	workspacelistusersettings: "Liste des espaces de travail",
	engagementsclientstoload: "Nombre de missions / clients à charger chaque fois que vous accédez à l’application",
	favoriteengagements: "Favoris",
	preferredEngagementDashboard: "Paramètres du tableau de bord de la mission",
	preferredEngagementDashboardTeamMembers: "Membres de l’équipe",
	preferredEngagementDashboardExecutives: "Direction",
	linkedHelixProjects: "Associés à EY Helix",
	loadingLabel: "Chargement en cours…",
	loadingErrorText: "Une erreur s’est produite lors du chargement du calendrier. Actualisez la page et réessayez. ",
	selectYear: "Sélectionner l’année (obligatoire)",
	clientNameOrId: "Nom ou identifiant du client",
	engagementNameOrId: "Nom ou identifiant de la mission",
	engagementCodeOrCodeName: "Code mission ou nom de code",
	searchArchives: "Recherche",
	clearSearchArchives: "Effacer la recherche",
	searchArchivesMessage: "Pour commencer, sélectionnez l’année d’archivage de la mission. Effectuez ensuite une recherche à l’aide d’une ou de plusieurs des options présentées ci-après :",
	titleUpdateProfile: "Information",
	msgUpdateProfileLn1: "Vous avez mis à jour la langue de la mission. Pour recevoir le contenu dans cette langue, cliquez sur Mises à jour du contenu de la mission, puis sélectionnez Mise à jour du contenu mondial.",
	sessionTimerMsg1: "Votre session prendra fin bientôt pour cause d’inactivité.",
	sessionTimerMsg2: "Par souci de sécurité, les sessions ouvertes prennent fin automatiquement après 30 minutes d’inactivité.",
	sessionTimerMsg3: "Votre session actuelle expirera dans {0} minutes.",
	sessionTimerMsg4: "Pour rester connecté, cliquez sur",
	sessionTimerHeader: "Déconnexion automatique pour votre protection",
	theme: "Thème",
	light: "Clair",
	dark: "Sombre",
	chooseDisplayTheme: "Choisissez votre thème d’affichage par défaut.",
	searchByCountry: "Sélectionner le pays",
	primaryEngagementLabel: "Mission principale",
	componentEngagementLabel: "Mission de l’équipe affectée à l’audit de la composante",
	regionalEngagementLabel: "Mission de l’équipe zonale",
	linkedLabel: "Associée",
	eyCanvasEconomicsTooltip: "Données financières d’EY Canvas",
	eyOversightTooltip: "Surveillance d’EY Canvas",
	eyPrivacyNoticeTooltip: "Avis de confidentialité",
	eyTermsOfServiceTooltip: "Modalités de service",
	eyGeneralHelp: "Aide générale",

	preferrednegativenumberformat: "Format numérique négatif à utiliser",

	protectedEngagementMessage: "Empêcher l’impression, la copie ou la reproduction des documents ",
	documentRrestriction: "Restriction des documents :",
	on: "Le",
	off: "Désactivée",
	protectedEngagementMessageOn: "- Il est interdit d’imprimer, de copier ou de reproduire de quelconque manière les documents associés à cette mission.",
	protectedEngagementMessageOff: "- L’impression, la copie ou la reproduction des documents associés à cette mission est autorisée.",

	/* Dashboard labels*/
	homeLabel: "Accueil",
	manageAccessHeader: "Gérer l’accès",
	sharepointWorkspacesHeader: "Espaces de travail SharePoint",
	showInactiveUserslabel: "Afficher les utilisateurs inactifs",
	showExpiredMessageslabel: "Afficher les messages expirés",
	addUserButtonLabel: "Ajouter un utilisateur",
	searchUserText: "Rechercher",
	nameHeader: "Nom",
	dataCenterNameHeader: "Nom du centre de données",
	numberOfWorkSpacesHeader: "Nombre d’espaces de travail fournis",
	workspaceName: "Nom de l’espace de travail",
	documentCount: "Nombre de documents",
	percentCapacity: "Capacité en pourcentage",
	emailHeader: "Adresse courriel",
	actionHeader: "Action",
	manageRolesLabel: "Gérer les rôles",
	deactivateUserlabel: "Désactiver l’utilisateur",
	manageRolesTableModule: "Module",
	manageRolesTableRoles: "Rôles",
	manageRolesTableServiceLine: "Gamme de services",
	manageRolesTableCountries: "Pays",
	manageAccessAssignRole: "Attribuer un rôle",
	manageAccessNoRolesAdded: "Aucun rôle n’a été ajouté",
	manageAccessDeleteRoleModalHeader: "Supprimer le rôle",
	manageAccessDeleteRoleButton: "Supprimer",
	noRoleResults: "Aucun résultat",
	confirmAssignRoleButton: "Attribuer",
	permissionGroupName: "Nom du groupe",
	permissionGroupDescription: "Description du groupe",
	activeMembers: "Membres actifs",
	permissionGroupMembersTitle: "Titre",
	permissionGroupMembersPhone: "Téléphone",
	permissionGroupMembersGui: "Identifiant global unique (GUI)",
	permissionGroupMembersUserId: "Identifiant de l’utilisateur",
	permissionGroupMembersAdStatus: "État – AD",
	permissionGroupAddMember: "Ajouter un membre",
	permissionGroupUserDeleteLabel: "Supprimer",
	permissionGroupUserDeleteHeader: "Supprimer le membre",
	permissionGroupDetails: "Renseignements sur les groupes d’autorisation",
	cache: "Clé du cache",
	cacheSelection: "Sélectionner l’emplacement du cache",
	engagementID: "Identifiant de la mission",
	cacheKey: "Clé du cache",
	validationMessage: "Champ obligatoire",
	getCache: "Récupération du cache",
	clearResult: "Effacer les résultats",
	removeCache: "Supprimer le cache",
	requiredField: "Champ obligatoire",
	ticketNumber: "Numéro du billet (requis)",
	confirmationMessage: "Veuillez entrer un numéro de billet pour cette action",
	confirmButtonLabel: "Confirmer",
	cancelButtonLabel: "Annuler",
	modalHeaderLabel: "Supprimer le cache",
	adminModuleinvalidEngagementId: "Identifiant de la mission invalide",
	adminModuleInvalidCacheKey: "La clé du cache contient des caractères invalides",
	clearResults: "Effacer les résultats",
	getDocuments: "Récupérer les documents",
	documentsHeader: "Documents",
	validTicketNumber: "Veuillez entrer un numéro de billet valide",

	egagementsPageDescription: "Rechercher des missions à partir d’un ou de plusieurs critères pour mettre à jour les données d’une mission, inviter de nouveaux membres dans l’équipe, mettre à jour l’état et les autorisations des membres de l’équipe existants.  ",
	engagementSearchCriteria: "L’identifiant de la mission ou du pays est requis pour effectuer la recherche",
	engagementsHeader: "Mission",
	engagementIdSearchPlaceholder: "Identifiant de la mission",
	engagementNameSearchPlaceholder: "Nom de la mission",
	workSpaceSearchPlaceholder: "Nom ou identifiant de l’espace de travail",
	clientSearchPlaceholder: "Nom du client ou code client",
	engagementCodeSearchPlaceholder: "Code mission ou nom du code",
	userSearchPlaceholder: "Identifiant global unique (GUI) de l’utilisateur, adresse courriel ou identifiant de l’utilisateur",
	countrySearchPlaceholder: "Pays",
	engagementTableHeader: "Mission",
	nameTableHeader: "Nom",
	workspaceIdTableHeader: "Emplacement",
	clientTableHeader: "Client",
	engagementCodeTableHeader: "Code mission",
	yearEndTableHeader: "Fin d’exercice",
	engagementdeleteDate: "Date de suppression de la mission",
	archivalguid: "Identifiant global unique (GUID) d’archivage",
	archivalLogId: "Identifiant de journal d’archivage",
	archivalFileguid: "Identifiant global unique (GUID) du fichier d’archivage",
	profile: "Profil complet",
	independence: "Indépendance",
	archiveRetry: "Nouvelle tentative d’archivage",
	engagementdetails: "Renseignements sur la mission",
	engagementsdetails: "Renseignements sur la mission",
	statusTableHeader: "État",
	attributesTableHeader: "Attributs",
	attributesProfileComplete: "Profil complet",
	attributesIndpendenceApplicable: "Applicabilité de l’indépendance ",
	engagementPagePlaceholder: "Rechercher les missions indiquées ci-dessus. Les résultats s’afficheront ici",
	noEngagementFound: "Aucune mission trouvée",
	emptySearchFieldError: "Une valeur doit être ajoutée à au moins un champ de recherche.",
	largeCountrySelectionError: "Veuillez sélectionner 10 pays ou moins.",
	saveEngagement: "Enregistrer",
	undoEditEngagement: "Réinitialiser",
	engagementUpdateSuccess: "Les modifications ont bien été enregistrées.",
	archiveRetrySuccessMessage: "Le processus d’archivage a bien été lancé pour cette mission. Veuillez vérifier l’état plus tard pour confirmer que l’archivage a réussi. ",
	welcome: "Bienvenue dans ",
	welcomeadmin: "Outil d’administration",
	engagementCard: "Missions",
	messagingCard: "Messages",
	templatesCard: "Gabarits",
	permissionGroupCard: "Groupes de permission",
	manageAccessCard: "Gérer l'accès",
	sharepointWorkspacesCard: "Espaces de travail SharePoint",
	databaseLocatorCard: "Synchronisation du localisateur de base de données",
	importForReviewTooltip: "Pendant l’optimisation de la mission pour la revue, certaines fonctionnalités ne seront pas disponibles. ",
	importForReviewChecboxText: "Importation à des fins de revue seulement",
	clientCode: "Code client",
	primaryEngagementCode: "Code de la mission de l’équipe principale",
	invalidEngagementTitle: "Code client ou code mission invalide",
	invalidEngagementMessage: "Le code client et/ou le code mission doit être validé pour que vous puissiez accéder à cette mission. Cliquez sur Continuer pour mettre à jour et valider les codes.",
	insufficientPermission: "Vous ne disposez pas des autorisations requises pour accéder à ce module",
	noPermission: "Aucune autorisation",
	noAdminRights: "Vous ne disposez pas des droits pour accéder à cet outil.",



	//Engagement Users Lables
	engmtUserTableUserNameHeader: "Nom de l’utilisateur",
	engmtUserTableEmailAddressHeader: "Adresse courriel",
	engmtUserTableRoleHeader: "Rôle",
	engmtUserTableGUIeHeader: "Identifiant global unique (GUI)",
	engmtUserTableStatuseHeader: "État",
	engmtUserTableIndependenceStatuseHeader: "Indépendance",
	engmtUserTableConfidentialityHeader: "Confidentialité",
	engmtUserTableADStatusHeader: "État – AD",
	engmtUserTablePermissionsHeader: "Autorisations",
	engmtUserTableActionHeader: "Action",
	engagementUsersTitle: "Utilisateurs de la mission",
	engagementInviteMember: "Inviter un membre",
	engagementInviteUser: "Inviter un utilisateur ",
	engagementRoles: "Rôle",
	engagementRolesRequired: "Le rôle dans la mission est requis",
	engagementInviteMemberSuccessMessage: "Le membre a bien été invité dans la mission",
	engagementInviteMemberDescription: "Rechercher des utilisateurs par prénom, nom de famille ou adresse courriel ci-dessus. Les résultats s’afficheront ici.",
	engagementUsersTitleEdit: "Modifier",
	engagementUpdatePermissionToolTip: "Vous ne disposez pas des autorisations requises pour mettre à jour la mission.",
	engagementRetryArchivePermissionToolTip: "Vous ne disposez pas des autorisations requises pour relancer le processus d’archivage.",
	usersEditPermissionToolTip: "Vous n’êtes pas autorisé à effectuer cette action. Veuillez communiquer avec l’administrateur pour demander une autorisation.",
	usersPermissionToolTip: "Vous ne disposez pas des autorisations requises pour mettre à jour l’utilisateur de la mission.",
	invalidArchivalGuidMessage: "Identifiant global unique (GUID) d’archivage invalide",
	invalidArchivalFileGuidMessage: "Identifiant global unique (GUID) du fichier d’archivage invalide",
	invalidArchivalLogId: "Identifiant du journal d’archivage invalide",
	datePickerLabel: "Sélectionner une date",
	updateEngagement: "Mettre à jour la mission",

	invalidEngagementMessage: "Le code client et/ou le code mission doit être validé pour que vous puissiez accéder à cette mission. Cliquez sur Continuer pour mettre à jour et valider les codes.",
	invalidEngagementId: "L’identifiant de la mission n’est pas valide.",
	serverErrorMessage: "Erreur de serveur. Réessayez plus tard.",
	engagementDetailsNoPermission: "Vous ne disposez pas de l’autorisation requise pour afficher les renseignements de la mission.",
	engagementNotFound: "Renseignements sur la mission introuvables",
	pageNotFound: "Page introuvable",
	assignRoleModalMessage: "Sélectionnez un module ou une fonction précise à activer pour l’utilisateur",
	selectModule: "Sélectionner un module",
	selectRole: "Sélectionner un rôle",
	serviceLine: "Gamme de services",
	countries: "Pays",
	confirmAssignRoleButton: "Attribuer",
	addUser: "Ajouter un utilisateur",
	addAdminUserHeader: "Saisissez le prénom, le nom de famille ou l’adresse courriel de l’utilisateur et appuyez sur Entrée pour rechercher",
	addMemberDescription: "Rechercher des utilisateurs par prénom, nom de famille ou adresse courriel ci-dessus. Les résultats s’afficheront ici.",
	seachInputRequired: "Entrer le texte recherché",
	searchUsers: "Rechercher des utilisateurs",
	noResultsFound: "Aucun résultat",
	usersToBeAdded: "Utilisateurs à ajouter",
	addUserSuccessMessage: "L’utilisateur Administrateur a bien été ajouté.",
	addMemberSuccessMessage: "Le membre a bien été ajouté au groupe.",
	deactivateWarning: "Voulez-vous vraiment désactiver l’utilisateur suivant?",
	deactivateButton: "Désactiver",
	deactivateModalTitle: "Désactiver l’utilisateur",
	generateReport: "Générer le rapport",
	generateValidationReport: "Produire un rapport de validation",

	templateManagement: "Gabarits",
	templateName: "Nom du gabarit",
	templateAreaRegion: "Région/zone",
	templateSize: "Taille",
	templateCreatedBy: "Créé par",
	templateCreatedDate: "Date de création",
	templateCountOfEngagement: "Missions",
	templateStatus: "État",
	templatePublished: "Publié",
	templateUnpublished: "Non publié",
	templateSearchText: "Entrer le texte recherché et appuyer sur Entrée",
	templateInsufficientPermissions: "Vous ne disposez pas des autorisations requises pour accéder à ce module.",
	templateMultipleArea: "Plusieurs",
	templateEdit: "Modifier le gabarit",
	templateEditSave: "Enregistrer",
	templateEditCancel: "Annuler",
	templateEditSuccessMessage: "Le gabarit a bien été mis à jour.",
	templateDeleteConfirmationMessage: "Voulez-vous vraiment supprimer ce gabarit?",
	templateDelete: "Supprimer le gabarit",
	templateEditDelete: "Supprimer",

	messagingMessageTitle: "Titre du message ",
	messagingDescriptionTitle: "Description du message",
	messagingStartsOn: "Commence le (UTC)",
	messagingEndsOn: "Termine le (UTC)",
	messagingAffectedDataCenters: "Centres de données touchés ",
	messagingStatus: "État",
	messagingCreatedBy: "Créé par",
	messagingCreatedDate: "Date de création",
	messagingDataCenterSystemWide: "À l’échelle du système",
	messagingDataCenterMultiple: "Plusieurs",
	messagingDataCenterSingle: "Un seul",
	messageName: "Titre du message ",
	messageStartsOn: "Commence le ",
	messageEndsOn: "Termine le ",
	messageDateTime: "Sélectionner la date et l’heure",
	messageAllTimeInUTC: "Toutes les heures sont présentées selon l’UTC.",
	messageTargetAudience: "Destinataires",
	messageDataCenters: "Centres de données   ",
	messageSelectADate: "Sélectionner une date",
	messageAllDataCenters: "Tous les centres de données",
	messageKendoDescription: "Le formatage réduit la longueur maximale de la description (4 000 caractères)",
	messageSelectDataCenter: "Sélectionner un centre de données",
	messageFieldRequired: "Champ obligatoire",
	messageStartTimeError: "La date et l’heure de début ne peuvent pas être antérieures à la date et à l’heure du jour  ",
	messageEndTimeError1: "La date et l’heure de fin ne peuvent pas être antérieures à la date et à l’heure du jour  ",
	messageEndTimeError2: "La date et l’heure de fin doivent être postérieures à la date et à l’heure de début",
	messageDataCenterError: "Sélectionner au moins un centre de données",
	messageSubmitValidationError: "Champs obligatoires manquants. Veuillez faire défiler la page et vous assurer que tous les champs obligatoires sont remplis.",
	messageChangesValidationError: "Aucun changement n’a été effectué. Effectuer au moins un changement pour enregistrer.",
	currentutc: "Date du jour et heure actuelle (UCT) : ",
	messageCurrentUTCTime: " {0} (en format 24 heures)",
	createMessage: "Créer un message",
	createMessagePermissions: "Vous ne disposez pas des autorisations requises pour accéder à ce module",
	createMessageSuccess: "Message créé avec succès",
	editMessage: "Modifier le message",
	editMessageSuccess: "Message modifié avec succès",
	editHoverText: "Modifier",

	messagingModalDeleteTitle: "Supprimer le message",
	messagingModalDeleteMessage: "Voulez-vous vraiment supprimer le message?",
	messagingModalDeleteButton: "Supprimer",
	messagingModalDeleteConfirm: "Confirmer",
	messagingModalDeleteCancel: "Annuler",
	messagingModalDeletedSuccessfully: "Message supprimé avec succès",

	// databaselocator
	databaseLocatorSuccessMessage: "La synchronisation du localisateur de base de données a été ajoutée à la file d’attente",

	// documents module
	docListHeaderDocId: "Identifiant du document",
	docListHeaderDocVersion: "Version",
	docListHeaderDocName: "Nom du document",
	docListHeaderFileSize: "Taille du fichier",
	docListHeaderContentUri: "URI du contenu",
	docListHeaderFileExists: "Le fichier existe",
	docListNoResults: "Aucun document trouvé",
	docListTotalDocuments: "Nombre total de documents : ",
	docListDocIdSearchLabel: "Identifiant du document",
	docListExportBtn: "Exporter",

	// Internal user roles abbr
	picRole: "PIC",
	adminRole: "Admin",
	readOnlyRoleForThirdPartyUser: "Lecture seule",
	legacy: "Sans restriction",
	permissionLabel: "Autorisation",
	permission: "Autorisations",
	active: "Actif",
	inactive: "Inactif",
	EngagementUserStatus: "État",
	IndependenceStatus: "Indépendance",
	Confidentiality: "Confidentialité",
	EditUserPermissionsMainTitle: "Vous pouvez modifier vos autorisations",
	EditUserPermissionsInstructions: "Vous ne pourrez jamais modifier vos propres autorisations, seulement celles des autres.",
	PermissionSaveButtonText: "Enregistrer",
	PermissionCancelButtonText: "Annuler",
	UnknownCountry: "Inconnu",
	engagementNoPermissionMesage: "Vous ne disposez pas des autorisations requises pour accéder au module de la mission.",
	cacheRemoveSuccessMessage: "Le cache a bien été retiré",
	cacheKeyNotFound: "Aucun résultat trouvé pour la clé du cache",

	// Portfolio labels
	informationText: "Rebienvenue,",
	filterEngagements: {
		all: "Toutes les missions",
		favorite: "Missions enregistrées dans les favoris",
		review: "Missions d’examen"
	},
	filterEngagementsLabel: "Filtrer les missions",
	engv2noActiveEngagements: "Vous n’avez aucune mission active. Pour commencer, cliquez sur le bouton « Ajouter une mission ».",
	engv2noFavoriteEngagements: "Vous n’avez aucune mission dans vos favoris. Pour ajouter des missions à cette vue, accédez à Toutes les missions et cliquez sur l’icône des favoris dans le coin inférieur droit des fiches de mission. ",
	engv2noReviewEngagements: "Vous n’avez aucune mission d’examen. Communiquez avec un membre de l’équipe de mission pour obtenir les droits d’accès.",

	// Engagement Progress Labels
	engagementProgressStrategyPhaseLabel: "Étape Étendue et stratégie",
	engagementProgressExecutionPhaseLabel: "Étape Exécution",
	engagementProgressConclusionPhaseLabel: "Étape Conclusion",
	engagementProgressCompletionPercentage: "terminé",
	engagementProgressCompleted: "Terminé",
	engagementProgressNotAvailable: "L’avancement de la mission sera disponible lorsque le centre de données local aura été mis à niveau. ",

	// Engagement archive countdown indicator
	EngagementArchiveCountdownIndicator: {
		reportreleasedate: "La date de délivrance du rapport est le",
		archivedeadlinedate: "La date limite d’archivage est le",
		today: "aujourd’hui",
		tommorow: "demain",
		overdue: "en retard",
		reportreleasedays: "avant la date de délivrance du rapport",
		archivedeadlinedays: "avant la date limite d’archivage",
		days: "jours"
	},
	postArchive: "Après l’archivage",

	adminTool: "Outil d’administration",
	characters: "caractères",
	placeholder: "Espace réservé",
	ofLabel: "de",
	kendoTextLimitMessage: "La limite de caractères maximale est dépassée",
	kendoRequiredMessage: "Requis ",
	engv2Errors: {
		noReviewEngagementsPermission: "Vous ne disposez pas des autorisations requises pour passer en revue des missions. Veuillez communiquer avec un membre de l’équipe de mission pour obtenir les droits d’accès."
	},
	timeTracking: 'Time Tracker',
	timeTrackingToolMessage: "Cet outil optionnel a été spécialement créé pour vous aider à mieux gérer votre temps dans le cadre de vos missions.",
	timeTrackingToolMessage1: "Une fois que vous avez commencé à consigner du temps, vous pouvez accéder à un résumé des données recueillies depuis un maximum de 14 jours. Les données de plus de 14 jours seront automatiquement retirées.",
	timeTrackingToolUpdatedMessage: "Cet outil, dont l’utilisation est facultative, permet d’enregistrer la façon dont vous utilisez votre temps. Le temps consigné est conservé pendant une durée maximum de deux semaines et ne sera pas archivé.",
	timeTrackerErrorFallBackMessage: "Le résumé des heures ne peut pas être chargé. Veuillez actualiser la page et réessayer. Si le problème persiste, veuillez communiquer avec le Service de dépannage.",
	serviceGateway: "Service Gateway",
	reportinghubDisableMessage: "Spotfire sera mis hors service en juin 2024. Les nouveaux rapports EY Canvas sont accessibles à partir de votre mission EY Canvas. Après le déploiement de la version 1 en 2024, vous pourrez y accéder à partir du menu Plus. Assurez-vous de mettre à jour les rapports personnalisés ou les automatisations qui dépendent de ces rapports dans la nouvelle version avant juin 2024.",
	mobileVersionLabels: {
		canvas: "Canvas",
		noEngagementFound: "Vous n’avez aucune mission active. Veuillez accéder à EY Canvas à partir de votre ordinateur pour commencer.",
		searchEngagementPlaceholder: "Rechercher…",
		search: "Rechercher",
		independenceIncomplete: "Confirmation d’indépendance incomplète",
		profileIncomplete: "Profil incomplet",
		allEngagements: "Toutes les missions",
		noMatchesFound: "Aucun résultat. Veuillez réessayer.",
		inValidUserLogin: "Veuillez accéder à votre ordinateur pour définir les paramètres de l’utilisateur",
		pagination: {
			pageLabel: "Page",
			ofLabel: "de"
		}
	},
	independenceOnlyStatus: "Indépendance seulement",
	toggleView: "Changer d’affichage",

	// Adobe Analytics
	aaCookieConsentTitle: "Bienvenue dans",
	aaCookieContentPrompt: "Voulez-vous autoriser les témoins?",
	aaCookieConsentExplanation: '<p>Outre les témoins strictement nécessaires au fonctionnement de ce site Web, nous utilisons les types de témoins suivants afin d’améliorer votre expérience et nos services : <strong>les témoins de fonctionnalité</strong>, qui nous permettent d’améliorer votre expérience utilisateur (p. ex., en conservant en mémoire tous les paramètres que vous avez sélectionnés); <strong>les témoins de performance</strong>, qui nous aident à évaluer la performance du site Web et à améliorer votre expérience utilisateur; <strong>les témoins à des fins de publicité ou de ciblage</strong>, qui sont configurés par des tiers avec lesquels nous réalisons des campagnes publicitaires et qui nous permettent de vous présenter des annonces pertinentes pour vous.</p><p>Consultez le site <a target="_blank" href="https://www.ey.com/fr_ca/cookie-policy">Politique sur l’utilisation de témoins</a> pour de plus amples renseignements.</p>',
	aaCookieConsentExplanationWithDoNotTrack: '<p>Outre les témoins strictement nécessaires au fonctionnement de ce site Web, nous utilisons les types de témoins suivants afin d’améliorer votre expérience et nos services : <strong>les témoins de fonctionnalité</strong>, qui nous permettent d’améliorer votre expérience utilisateur (p. ex., en conservant en mémoire tous les paramètres que vous avez sélectionnés); <strong>les témoins de performance</strong>, qui nous aident à évaluer la performance du site Web et à améliorer votre expérience utilisateur; <strong>les témoins à des fins de publicité ou de ciblage</strong>, qui sont configurés par des tiers avec lesquels nous réalisons des campagnes publicitaires et qui nous permettent de vous présenter des annonces pertinentes pour vous.</p><p>Nous avons détecté que vous avez activé le paramètre Ne pas me suivre (DNT) dans votre navigateur; par conséquent, les témoins à des fins de publicité ou de ciblage sont automatiquement désactivés.</p><p>Consultez le site <a target="_blank" href="https://www.ey.com/fr_ca/cookie-policy">Politique sur l’utilisation de témoins</a> pour de plus amples renseignements.</p>',
	aaCookieConsentDeclineOptionalAction: "Je refuse les témoins optionnels.",
	aaCookieConsentAcceptAllAction: "J’accepte tous les témoins.",
	aaCookieConsentCustomizeAction: "Personnaliser les témoins",
	aaCookieConsentCustomizeURL: 'https://www.ey.com/en_us/cookie-settings',

	// Cookie Settings
	cookieSettings: {
		title: "Paramètres des témoins",
		explanation: "Veuillez consentir à l’utilisation de témoins sur ey.com et sur la plateforme My EY. Sélectionnez un ou plusieurs types de témoins ci-dessous, puis sauvegardez votre choix. Consultez la liste ci-dessous pour connaître les détails sur les types de témoins et leur fonction. ",
		emptyCookieListNotice: "Les témoins de cette catégorie ne sont pas utilisés dans cette application.",
		nameTableHeader: "Nom du témoin",
		providerTableHeader: "Fournisseur du témoin",
		purposeTableHeader: "Fonction du témoin",
		typeTableHeader: "Type de témoin",
		durationTableHeader: "Durée d’activité du témoin",
		formSubmit: "Enregistrer ma sélection",
		requiredCookieListTitle: "Témoins obligatoires",
		functionalCookieListTitle: "Témoins fonctionnels",
		functionalCookieAcceptance: "J’accepte les témoins fonctionnels ci-dessous.",
		functionalCookieExplanation: "Les témoins fonctionnels, qui nous permettent d’améliorer votre expérience utilisateur (p. ex., en conservant en mémoire tous les paramètres que vous avez sélectionnés).",
		performanceCookieListTitle: "Témoins de performance",
		performanceCookieAcceptance: "J’accepte les témoins de performance ci-dessous.",
		performanceCookieExplanation: "Les témoins de performance, qui nous aident à évaluer la performance du site Web et à améliorer votre expérience utilisateur. Quand nous utilisons des témoins de performance, nous ne stockons pas de données à caractère personnel, et nous n’utilisons les informations recueillies à l’aide de tels témoins que sous leur forme agrégée et anonyme.",
		advertisingCookieListTitle: "Témoins de ciblage",
		advertisingCookieAcceptance: "J’accepte les témoins publicitaires / de ciblage ci-dessous.",
		advertisingCookieExplanation: "Les témoins publicitaires ou de ciblage, qui nous permettent de faire le suivi de l’activité et des sessions utilisateurs, de sorte que nous puissions fournir un service plus personnalisé. Dans le cas des témoins publicitaires, qui sont configurés par des tiers avec lesquels nous réalisons des campagnes publicitaires, ils nous permettent de vous présenter des annonces qui sont pertinentes pour vous.",
		doNotTrackNotice: "Nous avons détecté que vous avez activé le paramètre Ne pas me suivre (DNT) dans votre navigateur; par conséquent, les témoins à des fins de publicité ou de ciblage sont automatiquement désactivés.",
	},

	//Copyright
	copyrightMessage: "© <année> Tous droits réservés.",
	addWorkspace: 'Add workspace'
};

export const errors = {
	// GenericErrors Codes
	'0005': "Opération en attente",
	'0006': "Échec de l'opération",
	'0008': "La description ne peut pas contenir les caractères suivants :  \\",
	'0010': "Échec de la demande au Centre des archives",

	0: "Échec de la demande",
	928: "Accès non autorisé - vous n’avez pas accès à l’option sélectionnée. Communiquez avec le Service de dépannage pour obtenir les instructions sur la façon d'obtenir l'accès, le cas échéant.",

	1009: "Serveur de données introuvable",
	1010: "Centre de données introuvable",
	1011: "Le centre de données du pays considéré n'a pas été configuré",
	1013: "Code du pays ou code mission introuvable",
	1014: "Centre de données du pays introuvable",
	1016: "Échec du mappage du localisateur de base de données",
	1017: "La requête oData ne peut pas être nulle ou vide",
	1018: "La requête oData ne contient aucun champ",
	1019: "Centre de données universel introuvable",
	1020: "L'utilisateur n'existe pas dans le système",
	1021: "La fonction Rapport n’est pas disponible à l’emplacement sélectionné. Veuillez communiquer avec le champion d’EY Canvas de votre bureau pour obtenir de plus amples renseignements.",

	// Scope & Strategy 1021 - 1200
	1026: "Ligne introuvable",
	1030: "Nom du client introuvable",

	// Restore Engagement
	1101: "La mission ne peut pas être restaurée dans le pays sélectionné. Actualisez la page et réessayez.",

	// Execution   1201-1400
	1219: "Méthode introuvable",
	1234: "Le code du rôle ne peut pas être nul ou vide",
	1260: "L'élément sur lequel vous travaillez a été supprimé par un autre utilisateur.",
	1300: "Objet de requête invalide",
	1301: "Rôle dans la mission invalide",
	1302: "Langue de l'utilisateur invalide",
	1303: "Renseignements sur l'utilisateur de la mission introuvables",
	1304: "Emplacement invalide",
	1305: "Nom de la mission introuvable",
	1306: "Le nom de la mission dépasse la longueur maximale (255 caractères).",
	1307: "Date de fin de la période visée introuvable",
	1308: "Date invalide",
	1309: "Date du rapport d'audit introuvable",
	1310: "Date limite d'archivage de la mission introuvable",
	1311: "La date limite d'archivage de la mission ne peut pas être antérieure à la date du jour",
	1312: "Il existe des codes mission en double pour cette mission.",
	1313: "Emplacement introuvable",
	1314: "Nom de l'espace de travail introuvable",
	1315: "Le nom de l'espace de travail dépasse la longueur maximale (255 caractères)",
	1316: "Le code client dépasse la longueur maximale (50 caractères)",
	1317: "Le nom du client dépasse la longueur maximale (255 caractères)",
	1318: "La valeur de l'indicateur signalant si l'espace de travail sert à la formation est invalide",
	1319: "L'indicateur signalant que l'espace de travail sert à la formation est introuvable",
	1320: "L'indicateur de validation de l'identité du client est introuvable",
	1321: "La valeur de l'indicateur de validation de l'identité du client est invalide",
	1322: "Code client invalide",
	1323: "Codes mission introuvables",
	1324: "Code mission introuvable",
	1325: "Code du bureau principal invalide",
	1052: "Code de la gamme de services invalide",
	1053: "Code de la sous-gamme de service invalide",
	1326: "Code du pays introuvable",
	1327: "Code de l'espace de travail invalide",
	1328: "État du membre de l'équipe de mission invalide",
	1329: "État de la mission invalide",
	1330: "La mission a déjà été supprimée par un autre membre de l’équipe",
	1331: "Date de clôture introuvable",
	1339: "Nombre maximal de missions permises dépassé",
	1340: "Renseignements sur l’emplacement introuvables",
	1341: "Les données de la mission à mettre à jour n'ont pas été reçues comme il faut",
	1342: "Renseignements sur la mission actuelle introuvables",
	1343: "Données sommaires sur la mission actuelle introuvables",
	1344: "Le code sélectionné ne correspond à aucune mission",
	1359: "Le code du centre de données pour l'une des missions actives est invalide",
	1363: "Échec de la création de la mission dans le centre de données universel",
	1364: "Échec de la création de la mission dans le centre de données local",
	1373: "Sélectionner un espace de travail existant ou créer un nouvel espace de travail",
	1374: "Le nom de la mission existe déjà. Veuillez entrer un nouveau nom de mission pour importer le gabarit.",
	1375: "Les dates d’expiration erronées ne peuvent pas être mises à jour.",
	1379: "Le contenu est en cours d’actualisation. Réessayez plus tard. Si le problème persiste, communiquez avec le Service de dépannage.",
	1380: "La nouvelle tentative d’archivage a échoué. Veuillez actualiser la page et réessayer. Si le problème persiste, communiquez avec le Service de dépannage.",
	1381: "Create / RF / RS Engagement failed, due to mis-match in country Id in Workspace and engagement.",
	1382: "Create / RF / RS Engagement failed, due to mis-match in primary office in Workspace and engagement.",
	1384: "La restauration ou la récupération est déjà en cours pour cette mission. Veuillez réessayer plus tard.",
	1386: "Espace de travail non disponible pour les nouvelles missions. Veuillez actualiser la page et réessayer. Si le problème persiste, veuillez communiquer avec le Service de dépannage.",
	1385: "La mission ne peut pas être créée à l’emplacement sélectionné. Veuillez actualiser la page et réessayer. Si le problème persiste, veuillez communiquer avec le Service de dépannage.",
	1389: "La mission d’examen échue ne peut pas être mise à jour.",
	// Invite Team Members (1401 - 1500)
	1403: "Le message de bienvenue de la mission dépasse la longueur maximale (200 caractères)",
	1406: "Aucune collection de missions trouvée",
	1424: "Renseignements sur la mission ou l'utilisateur introuvables",
	1425: "Renseignements sur la mission introuvables",

	// User details Error Codes (1426 - 1434)
	1426: "Le prénom est requis",
	1427: "Le nom de famille est requis",
	1428: "Les initiales sont requises",
	1429: "Le bureau principal est requis",
	1430: "Le numéro de téléphone principal est requis",
	1431: "La langue est requise",
	1432: "Le format de date à utiliser est requis",
	1433: "Le format numérique à utiliser est requis",
	1434: "Les renseignements sur l'utilisateur ne peuvent être nuls ou vides",
	1436: "Conflit détecté. Si le problème persiste, veuillez communiquer avec le Service de dépannage.",
	1438: "La collection d'utilisateurs ne peut pas être vide.",
	1448: "Le pays sélectionné n’a pas été mis à jour en fonction de la plus récente version d’EY Canvas. Consultez EY Atlas pour connaître le calendrier de déploiement et réessayez une fois la mise à jour effectuée. Si le problème persiste, veuillez communiquer avec le Service de dépannage.",

	1502: "L'opération ne peut pas être traitée pour le moment. Actualisez la page et réessayez plus tard. Si le problème persiste, veuillez communiquer avec le Service de dépannage.",
	1504: "L'opération ne peut pas être traitée pour le moment. Actualisez la page et réessayez plus tard. Si le problème persiste, veuillez communiquer avec le Service de dépannage.",
	1505: "Entrée invalide",

	// Common control errors (1601 - 1700)
	1601: "Pourcentage invalide",
	18000: "Erreur de traitement du Centre des archives : l'opération demandée ne peut être effectuée, car des travaux de maintenance sont en cours au Centre des archives. Veuillez réessayer.",
	18306: "Erreur de traitement du Centre des archives : Le formulaire d'archivage lié à ArchivalGUID dans Canvas est introuvable",
	18307: "Erreur de traitement du Centre des archives : la mission a été supprimée au Centre des archives. Vous ne pouvez pas effectuer l'opération sélectionnée. Communiquez avec l'administrateur du Centre des archives de votre bureau pour obtenir de l'aide.",
	18309: "Erreur de traitement du Centre des archives : l'opération demandée ne peut être effectuée, car la mission archivée fait l'objet de restrictions réglementaires. Communiquez avec l'administrateur du Centre des archives de votre bureau pour obtenir de l'aide.",
	18320: "Erreur de traitement du Centre des archives : le formulaire d'archivage est en attente (en suspens) et ne peut pas être modifié.",
	18327: "Erreur de traitement du Centre des archives : le formulaire d'archivage est en attente d'être supprimé et ne peut être modifié.",
	18328: "Erreur de traitement du Centre des archives : l'opération demandée ne peut être effectuée parce que la mission fait l'objet d'une demande de suppression au Centre des archives. Communiquez avec l'administrateur du Centre des archives de votre bureau pour obtenir de l'aide.",
	18329: "Erreur de traitement du Centre des archives : les associés n'ont pas accès à cette mission. Communiquez avec l'administrateur du Centre des archives de votre bureau pour obtenir de l'aide.",
	18330: "Erreur de traitement du Centre des archives : l'opération demandée ne peut être effectuée parce que la mission est verrouillée en raison de restrictions réglementaires. Communiquez avec l'administrateur du Centre des archives de votre bureau pour obtenir de l'aide.",
	2027: "Demande erronée",
	2046: "Non autorisée",
	2047: "Interdite",
	2048: "Enregistrement introuvable",
	2049: "Corps de requête trop grand",
	2052: "Non acceptable",
	2053: "Une erreur s'est produite pendant la récupération des données. Actualisez la page et réessayez plus tard. Si le problème persiste, veuillez communiquer avec le Service de dépannage.",
	2056: "Une erreur générique s'est produite",
	2057: "Service non disponible",

	// Roll Forward Error Codes (2301-2400)
	2301: "Durant la récupération, le fichier archivé n'a pas été trouvé",
	2302: "Durant la récupération, la demande de référence au système des archives a échoué",
	2303: "Le fichier archivé retourné durant la récupération ne contient aucun identificateur unique ",
	2304: "Le fichier archivé retourné durant la récupération ne contient aucune adresse URL de stockage",
	2305: "Le fichier archivé retourné durant la récupération ne contient aucune valeur de hachage",
	2306: "L’utilisateur n’est pas autorisé à effectuer cette action",
	2307: "Le nombre de copies restaurées permis a été dépassé",

	// Link Error Codes
	2568: "Le lien ne fonctionne plus, car vous n'êtes actuellement pas la personne-ressource. Veuillez actualiser la page.",
	3005: "L'équipe principale a déjà supprimé la composante de la mission de groupe.",
	2571: "La demande de lien a été mise à jour. Veuillez actualiser la page.",

	// Error for v1 engagement not supported features
	4001: "La mission sélectionnée a été créée à partir d'une ancienne version d’EY Canvas et ne peut pas être restaurée tant que le centre de données local n’aura pas été mis à jour à la nouvelle version d’EY Canvas. Veuillez communiquer avec le Service de dépannage local pour obtenir de plus amples renseignements.",
	4002: "La mission sélectionnée a été créée à partir d'une ancienne version d’EY Canvas et la demande d’accès n’est pas disponible tant que le centre de données local n’aura pas été mis à jour à la nouvelle version d’EY Canvas. Veuillez communiquer avec le Service de dépannage local pour obtenir de plus amples renseignements.",
	4214: "Le gabarit ne peut pas être importé dans un espace de travail de l’emplacement sélectionné. Veuillez sélectionner un autre emplacement et réessayer.",

	// Common framework error
	4205: "Renseignements sur la demande de copie introuvables",
	401000: "Un changement de réseau a été détecté. Veuillez actualiser la page pour continuer.",

	// CopyHub concurrency scenarios
	4217: "L’état de la demande de paquets de données a changé. Actualisez la page et réessayez plus tard.",
	4219: "Les copies entre différentes versions d’EY Canvas ne sont pas prises en charge dans cette version en raison des améliorations apportées au processus de copie. Consultez EY Atlas pour plus de détails sur le calendrier de déploiement. Après la mise à niveau de votre centre de données, il sera possible de faire une copie.",
	4220: "La version du gabarit n’est pas prise en charge pour ce pays. ",
	4221: "La version du gabarit n’est pas prise en charge pour ce pays. ",
	4222: "Il est impossible de terminer l’importation tant que le l’emplacement de destination n’a pas été mis à niveau pour la dernière version d’EY Canvas. Veuillez sélectionner un autre emplacement pour continuer. Si le problème persiste, veuillez communiquer avec le Service de dépannage.",

	// Error for Mobility API
	4501: "Aucune tâche disponible dans la mission",
	4502: "Aucune tâche À faire disponible dans la mission",

	// Counrty API version error
	5001: "L’emplacement sélectionné utilise une ancienne version d’EY Canvas. Une mission ne peut pas être transférée dans un nouvel espace de travail tant que le centre de données local de l’emplacement sélectionné n’aura pas été mis à jour à la nouvelle version d’EY Canvas. Veuillez communiquer avec le Service de dépannage local pour obtenir de plus amples renseignements.",

	//CRP checks
	5101: "La copie pour revue ne peut pas être créée pour cette mission, car le portail EY Canvas n’est pas accessible aux utilisateurs externes pour le moment. Veuillez communiquer avec le Service de dépannage pour de plus amples renseignements.",
	5102: "Une copie de la revue est déjà en cours pour cette mission. Veuillez réessayer plus tard.",
	5103: "La copie pour revue ne peut pas être modifiée pour cette mission, car le portail EY Canvas n’est pas accessible aux utilisateurs externes pour le moment. Veuillez communiquer avec le Service de dépannage pour de plus amples renseignements.",
	5104: "La fonction Restriction des documents n’est pas disponible pour cette mission. Veuillez décocher l’option et réessayer.",

	18100: "Gamme de services introuvable",
	18101: "Sous-gamme de services introuvable.",

	//RBAC realted error messages.
	935: "Vous ne disposez pas des autorisations requises pour effectuer cette action. Veuillez communiquer avec l’administrateur de la mission et demander l’autorisation d’effectuer cette action.",
	8001: "L’opération ne peut pas être traitée pour le moment. Actualisez la page et réessayez. Si le problème persiste, communiquez avec le Service de dépannage.",
	336699: "Le résumé des heures ne peut pas être chargé. Veuillez actualiser la page et réessayer. Si le problème persiste, communiquez avec le Service de dépannage.",

	//Concurrency errors
	7821: "Vous avez déjà répondu à cette invitation. Veuillez actualiser la page et réessayer.",

	/*Information Security */
	200001: "Cette action est interdite par les services de sécurité de l’information d’EY. Veuillez actualiser la page et réessayer. Si le problème persiste, veuillez communiquer avec le Service de dépannage."
};

export const adminErrors = {
	1332: "Erreur pendant le lancement de la nouvelle tentative d’archivage",
	1501: "L’utilisateur est inactif. Actualisez la page et réessayez.",
	156: "Vous ne disposez pas des autorisations requises pour accéder à ce module",
	70740: "Les modifications ne peuvent pas être enregistrées. Au moins un membre actif de la mission doit être autorisé à gérer la mission et avoir résolu les questions d’indépendance. Si le problème persiste, veuillez communiquer avec le Service de dépannage.",
	70790: "Le domaine n’est pas autorisé. Veuillez réessayer et si le problème persiste, communiquez avec le Service de dépannage.",
	70800: "Pour inviter un utilisateur tiers, l’utilisateur doit être valide.",
	70810: "Rôles RBAC sélectionnés invalides/inactifs pour l’utilisateur tiers.",
	70820: "Sélectionner un rôle RBAC valide pour l’utilisateur tiers.",
	70830: "Sélectionner un rôle RBAC valide pour l’utilisateur tiers."
};

export const actionTypes = {
	1: "Messages système",
	2: "Invitations à une mission",
	3: "Accès refusé",
	4: "Demandes de suppression de la mission",
	5: "Erreur lors de la création de la mission",
	6: "Demande d’accès à la mission",
	7: "Missions supprimées",
	8: "Missions archivées",
	9: "Missions désactivées",
	10: "Remplacement de la date limite d’archivage",
	11: "Demandes de lien pour le groupe",
	12: "Instructions pour le groupe reçues",
	13: "Copies reçues",
	14: "Mission en cours"
};
export const timephaseType = {
	'-1': "Date de fin de la période visée",
	'-2': "Date du rapport",
	'-3': "Date limite d'archivage",
	'1': "Étendue",
	'2': "Stratégie",
	'3': "Tests de cheminement",
	'4': "Tests des contrôles intermédiaires",
	'5': "Tests de corroboration intermédiaires",
	'6': "Tests de cheminement (processus de fin d’exercice)",
	'7': "Test des contrôles de fin d’exercice",
	'8': "Tests de corroboration de fin d’exercice",
	'9': "Conclusion",
	'10': "s.o.",
	'11': "Exécution",
	'12': "Activités axées sur la qualité en lien avec l’étendue et la stratégie",
	'13': "Autres activités à l’étape Étendue et stratégie",
	'14': "EGR",
	'15': "Évaluation des risques et caractère significatif",
	'16': "Finaliser l’étendue et la stratégie",
	'17': "Activités de gestion de projet post-intermédiaires"
};
export const dataExport = [
	"Formulaire d'archivage",
	"Formulaires Canvas",
	"Collaboration",
	"Formulaires sur les contrôles",
	"Documents",
	"Tâches",
	"Membres de l'équipe",
	"Balance des comptes"
];
export const dataExportReportNames = {
	"Formulaire d'archivage": 'Archive Form',
	'Formulaires Canvas': 'Canvas Form Report',
	'Collaboration': 'Collaboration',
	'Formulaires sur les contrôles': 'Control Report',
	'Documents': 'All Documents Report',
	'Tâches': 'All Tasks Report',
	"Membres de l'équipe": 'Team Members',
	'Balance des comptes': 'Numbers Report'
};
export const projectManagement = [
	"Détails de la collaboration",
	"Tableau de bord numérique",
	"Jalons",
	"Notes de revue",
	"Contrôles de validation"
];
export const projectManagementReportNames = {
	'Détails de la collaboration': 'Client Collaboration Dashboard',
	'Jalons': 'Milestone Report',
	'Notes de revue': 'Review Notes Report',
	'Contrôles de validation': 'Validation Checks Report'
};
export const summaryScreenDefaults = [
	"Rapport sur le plan de mission",
	"Plan de mission avec éléments probants connexes",
	"Outil de revue des contrôles (CTR)",
	"Synthèse de l’EGR",
	"Constatations",
	"Synthèse TI/SS",
	"Synthèse des catégories importantes d’opérations",
	"Matrice d’évaluation des procédures de corroboration"
];
export const summaryScreenDefaultsReportNames = {
	'Rapport sur le plan de mission': 'Audit Plan Report',
	'Plan de mission avec éléments probants connexes': 'Audit Plan with Related Evidence',
	'Outil de revue des contrôles (CTR)': 'Controls Review Tool (CRT)',
	'Synthèse de l’EGR': 'CRA Summary',
	'Constatations': 'Findings',
	'Synthèse TI/SS': 'ITSO CRT',
	'Synthèse des catégories importantes d’opérations': 'SCOT Summary',
	'Matrice d’évaluation des procédures de corroboration': 'SEM'
};
export const summaryScreenInterim = [
	"Rapport sur le plan de travail",
	"Plan de travail avec éléments probants connexes"
];
export const summaryScreenInterimReportNames = {
	'Rapport sur le plan de travail': 'Audit Plan Report',
	'Plan de travail avec éléments probants connexes': 'Audit Plan with Related Evidence'
};
export const summaryScreenGroupAudit = [
	"Rapport sur le plan de mission",
	"Plan de mission avec éléments probants connexes",
	"Outil de revue des contrôles (CTR)",
	"Synthèse de l’EGR",
	"Constatations",
	"Synthèse des instructions du groupe",
	"Rapport de groupe",
	"Synthèse de la structure du groupe",
	"Synthèse TI/SS",
	"Synthèse des catégories importantes d’opérations",
	"Matrice d’évaluation des procédures de corroboration"
];
export const summaryScreenGroupAuditReportNames = {
	'Rapport sur le plan de mission': 'Audit Plan Report',
	'Plan de mission avec éléments probants connexes': 'Audit Plan with Related Evidence',
	'Outil de revue des contrôles (CTR)': 'Controls Review Tool (CRT)',
	'Synthèse de l’EGR': 'CRA Summary',
	'Constatations': 'Findings',
	'Synthèse des instructions du groupe': 'Group Instruction Summary',
	'Rapport de groupe': 'Group Report',
	'Synthèse de la structure du groupe': 'Group Structure Summary',
	'Synthèse TI/SS': 'ITSO CRT',
	'Synthèse des catégories importantes d’opérations': 'SCOT Summary',
	'Matrice d’évaluation des procédures de corroboration': 'SEM'
};
export const summaryScreenGroupAuditInterim = [
	"Synthèse des instructions du groupe",
	"Synthèse de la structure du groupe",
	"Rapport sur le plan de travail",
	"Plan de travail avec éléments probants connexes"
];
export const summaryScreenGroupAuditInterimReportNames = {
	'Synthèse des instructions du groupe': 'Group Instruction Summary',
	'Synthèse de la structure du groupe': 'Group Structure Summary',
	'Rapport sur le plan de travail': 'Audit Plan Report',
	'Plan de travail avec éléments probants connexes': 'Audit Plan with Related Evidence'
};
export const rolelistitems = [{
	id: 8,
	label: "Stagiaire"
},
{
	id: 7,
	label: "Comptable senior"
},
{
	id: 6,
	label: "Chef d'équipe"
},
{
	id: 5,
	label: "Chef d'équipe senior"
},
{
	id: 3,
	label: "Associé délégué"
},
{
	id: 4,
	label: "Principal"
},
{
	id: 11,
	label: "Autre associé"
},
{
	id: 2,
	label: "Associé responsable de la mission"
},
{
	id: 1,
	label: "Associé responsable de l'audit"
},
{
	id: 10,
	label: "Responsable de la revue de la qualité de la mission "
},
{
	id: 17,
	label: "Responsable du contrôle qualité de l'audit"
},
{
	id: 16,
	label: "Directeur des affaires juridiques"
},
{
	id: 12,
	label: "Groupe PSM – Membre du personnel"
},
{
	id: 30,
	label: "Groupe PSM – Professionnel senior"
},
{
	id: 31,
	label: "Groupe PSM – Chef d’équipe"
}
];
export const roleType = {
	'11': "Autre associé",
	'24': "Autre (p. ex., revue de contrôle préalable)",
	'15': "Services de soutien aux cadres",
	'18': "Équipe affectée à l'audit d'une composante pour les entités à établissements multiples",
	'17': "Responsable du contrôle qualité de l'audit",
	'1': "Associé responsable de l'audit",
	'2': "Associé responsable de la mission",
	'9': "Étudiant stagiaire",
	'13': "Services consultatifs (professionnels des TI pour les audits comptables, SCT, Capital humain ou autre)",
	'14': "Fiscalité",
	'27': "Secteur d’activité",
	'10': "Responsable de la revue de la qualité de la mission ",
	'25': "Bureau",
	'8': "Stagiaire",
	'20': "Personnel du client",
	'19': "Superviseur du client",
	'5': "Chef d'équipe senior",
	'16': "Directeur des affaires juridiques",
	'26': "Région",
	'23': "Autorité de réglementation",
	'22': "Personnel de la fonction d’audit interne",
	'3': "Associé délégué",
	'21': "Superviseur de la fonction d’audit interne",
	'6': "Chef d'équipe",
	'4': "Principal",
	'7': "Comptable senior",
	'12': "Bassin de talents mondial",
	'28': "National",
	'29': "Mondial",
	'12': "Groupe PSM – Membre du personnel",
	'30': "Groupe PSM – Professionnel senior",
	'31': "Groupe PSM – Chef d’équipe"
};

export const copyObjectGroupType = {
	1: "Mission",
	2: "Comptes",
	3: "Catégories importantes d’opérations",
	4: "Applications informatiques et sociétés de services",
	5: "Demandes",
	6: "Éléments probants",
	7: "Numéros",
	8: "Demandes et tâches externes",
	9: "Fichiers temporaires",
	10: "Éléments probants de la période précédente",
	11: "Fichiers récemment supprimés",
	12: "Fichiers historiques",
	13: "Gabarit de la mission",
	14: "Restaurer la copie pour le Bureau mondial de la conformité",
	15: "Copie de la revue externe",
	16: "Revue externe de la mission active",
	17: "Formulaires Canvas",
	20: "Éléments probants",
	21: "Mission",
	22: "Comptes",
	23: "Catégories importantes d’opérations",
	24: "Récupération de la mission",
	27: "Éléments probants externes",
	28: "Flux de travail guidés",
	29: 'Engagement by Entity'
};

export const EngagementStatusText = [
	"Non définie",
	"Active",
	"Suspendue",
	"Archivée",
	"Supprimée",
	"ArchivageEnCours",
	"Réactivée",
	"Restaurée",
	"InscriteCommeÉtantÀSupprimer",
	"ErreurLorsDeL’Archivage",
	"ErreurLorsDeLaRécupération",
	"ErreurLorsDeLaRéactivation",
	"ErreurLorsDeLaRestauration",
	"RécupérationEnCours",
	"RéactivationEnCours",
	"RestaurationEnCours",
	"MigrationDeLaMissionGamxEnCours",
	"ErreurLorsDeLaMigrationDeLaMissionGamx",
	"CréationDeL’OpérationEnAttente",
	"ÉchecDeLaCréationDeL’Opération",
	"MiseÀJourDeL’OpérationEnAttente",
	"ÉchecDeLaMiseÀJourDeL’Opération",
	"CopieEnCours",
	"ErreurLorsDeLaCopie",
	"ErreurLorsDeLaCopieDuGabarit",
	"RevueExterneDeLaMissionEnCours",
	"ErreurLorsDeLaRevueExterneDeLaMission",
	"Revue externe de la mission"
];
export const overdueIssues = {
	partnerInvitationPending: "Invitation de l’associé en attente",
	eqrInvitationPending: "Invitation du Responsable de la revue de la qualité de la mission en attente",
	invalidEngagementCode: "Code mission invalide",
	overdueArchiveDeadline: "Date limite d’archivage dépassée",
	upcomingArchiveDeadline: "Date limite d’archivage à venir",
	contentDeliveryInProgress: "Envoi du contenu en cours",
	overdueMilestones: "Jalons de l’équipe en retard",
	overduePartnerTasks: "Tâches de l’associé en retard",
	overdueEqrTasks: "Tâches du Responsable de la revue de la qualité de la mission en retard"
};
export const engagementMetricNames = [{
	propName: 'preparer',
	value: "À préparer"
},
{
	propName: 'forMyReview',
	value: "À passer en revue"
},
{
	propName: 'upcomingReview',
	value: "Revue à venir"
},
{
	propName: 'reviewNotesAssigned',
	value: "Notes pour moi"
},
{
	propName: 'reviewNotesAuthored',
	value: "Notes par moi"
},
{
	propName: 'clientRequests',
	value: "Demandes"
},
{
	propName: 'timephases',
	value: "Périodes"
},
{
	propName: 'groupTasks',
	value: "Tâches pour le groupe"
},
{
	propName: 'groupDeliverables',
	value: "Livrables du groupe"
},
{
	propName: 'automationDocuments',
	value: "Automatisation"
}
];
export const metricFilterComingDue = [{
	value: 1,
	label: "Arrivant à échéance demain"
},
{
	value: 3,
	label: "Arrivant à échéance dans les 3 prochains jours"
},
{
	value: 5,
	label: "Arrivant à échéance dans les 5 prochains jours",
	isDefault: true
},
{
	value: 10,
	label: "Arrivant à échéance dans les 10 prochains jours"
},
{
	value: 30,
	label: "Arrivant à échéance dans les 30 prochains jours",
	isMax: true
}
];
export const metricFilterWhatsNew = [{
	value: 1,
	label: "Nouveautés d’hier"
},
{
	value: 3,
	label: "Nouveautés des 4 derniers jours"
},
{
	value: 5,
	label: "Nouveautés des 5 derniers jours",
	isDefault: true
},
{
	value: 10,
	label: "Nouveautés des 10 derniers jours",
	isMax: true
}
];

export const type1Alerts = [
	"hasPartnerInvited",
	"hasEqrInvited",
	"hasValidEngagementCode",
	"overdueArchiveDeadline"
];

export const engagementStatuses = {
	1: "Active",
	2: "Suspendue",
	3: "Archivée",
	4: "Supprimée",
	5: "Archivage en cours",
	6: "Réactivée",
	7: "Restaurée",
	8: "Inscrite comme étant à supprimer",
	9: "Erreur lors de l’archivage",
	10: "Erreur lors de la récupération",
	11: "Erreur lors de la réactivation",
	12: "Erreur lors de la restauration",
	13: "Récupération en cours",
	14: "Réactivation en cours",
	15: "Restauration en cours",
	16: "Migration de la mission Gamx en cours",
	17: "Erreur lors de la migration de la mission Gamx",
	18: "Création de l’opération en attente",
	19: "Échec de la création de l’opération",
	20: "Mise à jour de l’opération en attente",
	21: "Échec de la mise à jour de l’opération",
	22: "Copie en cours",
	23: "Erreur lors de la copie",
	24: "Erreur lors de la copie du gabarit",
	25: "Revue externe de la mission en cours",
	26: "Erreur lors de la revue externe de la mission",
	27: "Revue externe de la mission",
	28: "Localisé dans un centre de données universel",
	29: "Localisé dans un centre de données universel archivé",
	30: "Envoi du contenu en cours",
	31: "Erreur lors de l’envoi du contenu",
	32: "Échec de l’envoi du contenu",
	33: "Portail externe d’EY Canvas (V2)",
	34: "Portail externe d’EY Canvas (V2) − en cours",
	35: "Portail externe d’EY Canvas (V2) − erreur",
	100: "Division du centre de données"
};

export const overrideArchiveDeadlineDateOptions = [{
	value: 0,
	label: "Valeur par défaut"
},
{
	value: 10,
	label: "10 jours"
},
{
	value: 14,
	label: "14 jours"
},
{
	value: 20,
	label: "20 jours"
},
{
	value: 21,
	label: "21 jours"
},
{
	value: 25,
	label: "25 jours"
},
{
	value: 30,
	label: "30 jours"
},
{
	value: 45,
	label: "45 jours"
}
];
export const ExternalReviewCopyExpiryDays = [{
	value: 30,
	label: "30 jours"
},
{
	value: 60,
	label: "60 jours"
},
{
	value: 90,
	label: "90 jours"
},
{
	value: 180,
	label: "180 jours"
}
];

export const serviceLines = [{
	servicelineid: 1,
	servicelinename: "Certification"
},
{
	servicelineid: 3,
	servicelinename: "Stratégie et transactions"
},
{
	servicelineid: 2,
	servicelinename: "Consultation"
},
{
	servicelineid: 4,
	servicelinename: "Fiscalité"
},
{
	servicelineid: 5,
	servicelinename: "Bureau mondial de la conformité"
}
];

export const subServiceLines = [{
	subservicelineid: 1,
	servicelineid: 1,
	subservicelinename: "Audit"
},
{
	subservicelineid: 2,
	servicelineid: 1,
	subservicelinename: "FIDS"
},
{
	subservicelineid: 3,
	servicelineid: 2,
	subservicelinename: "R&CTI"
},
{
	subservicelineid: 9,
	servicelineid: 1,
	subservicelinename: "SCCF"
},
{
	subservicelineid: 10,
	servicelineid: 1,
	subservicelinename: "CCIF"
},
{
	subservicelineid: 11,
	servicelineid: 1,
	subservicelinename: "SCCDD"
},
{
	subservicelineid: 4,
	servicelineid: 3,
	subservicelinename: "Transformation du capital"
},
{
	subservicelineid: 5,
	servicelineid: 3,
	subservicelinename: "Soutien à la transaction"
},
{
	subservicelineid: 6,
	servicelineid: 2,
	subservicelinename: "Amélioration de la performance"
},
{
	subservicelineid: 7,
	servicelineid: 2,
	subservicelinename: "Risque"
},
{
	subservicelineid: 7,
	servicelineid: 23,
	subservicelinename: "Consultation - Entreprises"
},
{
	subservicelineid: 24,
	servicelineid: 6,
	subservicelinename: "Autre"
},
{
	subservicelineid: 8,
	servicelineid: 2,
	subservicelinename: "Autre"
},
{
	subservicelineid: 12,
	servicelineid: 4,
	subservicelinename: "SFE"
},
{
	subservicelineid: 13,
	servicelineid: 4,
	subservicelinename: "Capital humain"
},
{
	subservicelineid: 14,
	servicelineid: 4,
	subservicelinename: "Services juridiques"
},
{
	subservicelineid: 15,
	servicelineid: 4,
	subservicelinename: "Taxes indirectes"
},
{
	subservicelineid: 16,
	servicelineid: 4,
	subservicelinename: "ODEM"
},
{
	subservicelineid: 17,
	servicelineid: 4,
	subservicelinename: "Fiscalité transactionnelle"
},
{
	subservicelineid: 18,
	servicelineid: 4,
	subservicelinename: "SFI"
},
{
	subservicelineid: 19,
	servicelineid: 5,
	subservicelinename: "Bureau mondial de la conformité"
},
{
	subservicelineid: 20,
	servicelineid: 2,
	subservicelinename: "Consultation - Entreprises"
},
{
	subservicelineid: 21,
	servicelineid: 2,
	subservicelinename: "Consultation - Technologie"
},
{
	subservicelineid: 22,
	servicelineid: 3,
	subservicelinename: "Transactions et conseils en financement"
},
{
	subservicelineid: 23,
	servicelineid: 3,
	subservicelinename: "EY-Parthenon"
},
{
	subservicelineid: 25,
	servicelineid: 1,
	subservicelinename: "Risque technologique"
}
];

export const modules = [{
	id: 1,
	name: "Gestion des accès"
},
{
	id: 2,
	name: "Missions"
},
{
	id: 3,
	name: "Groupes d’autorisation"
},
{
	id: 4,
	name: "Gabarits"
},
{
	id: 5,
	name: "Messages"
},
{
	id: 6,
	name: "Cache"
},
{
	id: 7,
	name: "Localisateur de base de données"
},
{
	id: 8,
	name: "Documents"
},
{
	id: 9,
	name: "Espaces de travail SharePoint"
}
];

export const roles = [{
	id: 17,
	ModuleId: 1,
	name: "Lecture seule"
},
{
	id: 18,
	ModuleId: 1,
	name: "Ajouter/supprimer l’utilisateur et les autorisations"
},
{
	id: 19,
	ModuleId: 2,
	name: "Lecture seule"
},
{
	id: 20,
	ModuleId: 2,
	name: "Mettre à jour la mission"
},
{
	id: 21,
	ModuleId: 2,
	name: "Inviter un membre de l’équipe"
},
{
	id: 22,
	ModuleId: 2,
	name: "Mettre à jour l’utilisateur de la mission"
},
{
	id: 23,
	ModuleId: 3,
	name: "Lecture seule"
},
{
	id: 24,
	ModuleId: 3,
	name: "Ajouter/supprimer un membre"
},
{
	id: 25,
	ModuleId: 3,
	name: "Synchronisation de base de données"
},
{
	id: 26,
	ModuleId: 4,
	name: "Lecture seule"
},
{
	id: 27,
	ModuleId: 4,
	name: "Mettre à jour le gabarit"
},
{
	id: 28,
	ModuleId: 4,
	name: "Supprimer le gabarit"
},
{
	id: 29,
	ModuleId: 5,
	name: "Lecture seule"
},
{
	id: 30,
	ModuleId: 5,
	name: "Créer/mettre à jour le message"
},
{
	id: 31,
	ModuleId: 5,
	name: "Supprimer le message"
},
{
	id: 32,
	ModuleId: 6,
	name: "Lire et supprimer le cache"
},
{
	id: 33,
	ModuleId: 8,
	name: "Accéder aux documents"
},
{
	id: 34,
	ModuleId: 2,
	name: "Nouvelle tentative d’archivage"
},
{
	id: 38,
	ModuleId: 9,
	name: "Lecture seule"
},
{
	id: 39,
	ModuleId: 9,
	name: "Ajouter des espaces de travail"
},
{
	id: 40,
	ModuleId: 9,
	name: "Supprimer des espaces de travail"
}
];

export const countries = {
	1: "Mayotte",
	2: "Îles Vierges britanniques",
	3: "Espagne",
	4: "Bélize",
	5: "Pérou",
	6: "Slovaquie",
	7: "Venezuela",
	8: "Norvège",
	9: "Îles Malouines",
	10: "Mozambique",
	11: "Chine continentale",
	12: "Soudan",
	13: "Israël",
	14: "Belgique",
	15: "Arabie saoudite",
	16: "Gibraltar",
	17: "Guam",
	18: "Île Norfolk",
	19: "Zambie",
	20: "Réunion",
	21: "Azerbaïdjan",
	22: "Sainte-Hélène",
	23: "Iran",
	24: "Monaco",
	25: "Saint-Pierre-et-Miquelon",
	26: "Nouvelle-Zélande",
	27: "Îles Cook",
	28: "Sainte-Lucie",
	29: "Zimbabwe",
	30: "Iraq",
	31: "Tonga",
	32: "Samoa américaines",
	33: "Maldives",
	34: "Maroc",
	35: "Normes internationales d’audit (ISA)",
	36: "Albanie",
	37: "Afghanistan",
	38: "Gambie",
	39: "Burkina Faso",
	40: "Tokelau",
	41: "Libye",
	42: "Canada",
	43: "Émirats arabes unis",
	44: "République populaire démocratique de Corée",
	45: "Montserrat",
	46: "Groenland",
	47: "Rwanda",
	48: "Fidji",
	49: "Djibouti",
	50: "Botswana",
	51: "Koweït",
	52: "Madagascar",
	53: "Île de Man",
	54: "Hongrie",
	55: "Namibie",
	56: "Malte",
	57: "Jersey",
	58: "Thaïlande",
	59: "Saint-Kitts-et-Nevis",
	60: "Bhoutan",
	61: "Panama",
	62: "Somalie",
	63: "Bahreïn",
	64: "Bosnie-Herzégovine",
	65: "France",
	66: "République de Corée",
	67: "Islande",
	68: "Portugal",
	69: "Tunisie",
	70: "Ghana",
	71: "Cameroun",
	72: "Grèce",
	73: "Terres australes françaises",
	74: "Îles Heard et McDonald",
	75: "Andorre",
	76: "Luxembourg",
	77: "Samoa",
	78: "Anguilla",
	79: "Pays-Bas",
	80: "Guinée-Bissau",
	81: "Nicaragua",
	82: "Paraguay",
	83: "Antigua-et-Barbuda",
	84: "Normes internationales d’information financière (IFRS)",
	85: "Niger",
	86: "Égypte",
	87: "État de la Cité du Vatican",
	88: "Lettonie",
	89: "Chypre",
	90: "Îles mineures éloignées des États-Unis",
	91: "Autres entités juridiques de la Russie",
	92: "Saint-Vincent-et-les-Grenadines",
	93: "Guernsey",
	94: "Burundi",
	95: "Cuba",
	96: "Guinée équatoriale",
	97: "Territoire britannique de l’océan Indien",
	98: "Suède",
	99: "Ouganda",
	100: "Macédoine du Nord",
	101: "Eswatini",
	102: "El Salvador",
	103: "Kirghizistan",
	104: "Irlande",
	105: "Kazakhstan",
	106: "Honduras",
	107: "Uruguay",
	108: "Géorgie",
	109: "Trinité-et-Tobago",
	110: "Autorité palestinienne",
	111: "Martinique",
	112: "Îles d’Aland",
	113: "Polynésie française",
	114: "Côte d’Ivoire",
	115: "Monténégro",
	116: "Afrique du Sud",
	117: "Géorgie du Sud et Îles Sandwich-du-Sud",
	118: "Yémen",
	119: "Hong Kong",
	120: "Kenya",
	121: "Tchad",
	122: "Colombie",
	123: "Costa Rica",
	124: "Angola",
	125: "Lituanie",
	126: "Syrie",
	127: "Malaisie",
	128: "Sierra Leone",
	129: "Serbie",
	130: "Pologne",
	131: "Suriname",
	132: "Haïti",
	133: "Nauru",
	134: "Sao Tomé-et-Principe",
	135: "Svalbard et Jan Mayen",
	136: "Singapour",
	137: "Moldavie",
	138: "Taïwan",
	139: "Sénégal",
	140: "Gabon",
	141: "Mexique",
	142: "Seychelles",
	143: "États fédérés de Micronésie",
	144: "Algérie",
	145: "Italie",
	146: "San Marino",
	147: "Libéria",
	148: "Brésil",
	149: "Croatie",
	150: "Îles Féroé",
	151: "Palaos",
	152: "Finlande",
	153: "Philippines",
	154: "Jamaïque",
	155: "Guyane française",
	156: "Cap-Vert",
	157: "Myanmar",
	158: "Lesotho",
	159: "Îles Vierges américaines",
	160: "Îles Caïmans",
	161: "Niue",
	162: "Togo",
	163: "Biélorussie",
	164: "Dominique",
	165: "Indonésie",
	166: "Ouzbékistan",
	167: "Nigéria",
	168: "Wallis-et-Futuna",
	169: "Barbade",
	170: "Sri Lanka",
	171: "Royaume-Uni",
	172: "Équateur",
	173: "Guadeloupe",
	174: "Laos",
	175: "Jordanie",
	176: "Îles Salomon",
	177: "Timor-Oriental",
	178: "Liban",
	179: "République centrafricaine",
	180: "Inde",
	181: "Île Christmas",
	182: "Vanuatu",
	183: "Brunei",
	184: "Bangladesh",
	185: "Antarctique",
	186: "Bolivie",
	187: "Turquie",
	188: "Bahamas",
	189: "Comores",
	190: "Sahara-Occidental",
	191: "République tchèque",
	192: "Ukraine",
	193: "Estonie",
	194: "Bulgarie",
	195: "Mauritanie",
	196: "République démocratique du Congo",
	197: "Liechtenstein",
	198: "Pitcairn",
	199: "Danemark",
	200: "Îles Marshall",
	201: "Japon",
	202: "Autriche",
	203: "Oman",
	204: "Mongolie",
	205: "Tadjikistan",
	206: "Suisse",
	207: "Guatemala",
	208: "Érythrée",
	209: "Népal",
	210: "Mali",
	211: "Slovénie",
	212: "Îles Mariannes du Nord",
	213: "(sans objet)",
	214: "Aruba",
	215: "Congo",
	216: "Qatar",
	217: "Guinée",
	218: "États-Unis",
	219: "Éthiopie",
	220: "Autres",
	221: "Guyane",
	222: "Allemagne",
	223: "Bermudes",
	224: "Îles Turques-et-Caïques",
	225: "Australie",
	226: "Kiribati",
	227: "Puerto Rico",
	228: "Pakistan",
	229: "Maurice",
	230: "Malawi",
	231: "Turkménistan",
	232: "Cambodge",
	233: "Chili",
	234: "Nouvelle-Calédonie",
	235: "Papouasie-Nouvelle-Guinée",
	236: "Île Bouvet",
	237: "Tuvalu",
	238: "Curaçao",
	239: "République dominicaine",
	240: "Vietnam",
	241: "Îles Cocos (Keeling)",
	242: "Grenade",
	243: "Tanzanie",
	244: "Argentine",
	245: "Macao",
	246: "Bénin",
	247: "Roumanie",
	248: "Arménie",
	249: "mondial",
	250: "IFRS pour les PME",
	251: "PCGR des États-Unis",
	252: "Référentiel d’information financière de l’AICPA pour les petites et moyennes entités",
	253: "Soudan du Sud",
	254: "Kosovo",
	255: "États-Unis - Gouvernement",
	256: "Russia OOO EY & OOO EY Vneshaudit",
	257: "Arménie-Russie ̶ Centre de données",
	258: "Biélorussie-Russie ̶ Centre de données",
	259: "Ouzbékistan-Russie ̶ Centre de données"
};

export const common = {
	selected: "Sélectionnée",
	cancel: "Annuler",
	checkbox: {
		selectAllOn: "Tout sélectionner",
		selectAllOff: "Tout désélectionner"
	},
	clear: "Effacer",
	close: "Fermer",
	pagination: {
		show: "Afficher",
		iconHover: {
			first: "Première page",
			previous: "Page précédente",
			next: "Page suivante",
			last: "Dernière page"
		}
	},
	engv2Pagination: {
		show: "Afficher",
		iconHover: {
			first: "Première page",
			previous: "Page précédente",
			next: "Page suivante",
			last: "Dernière page"
		}
	},
	search: {
		clear: "Effacer la recherche",
		buttonTitle: "Rechercher",
		placeholder: "Rechercher",
		noResultsPlaceholder: "Aucun résultat "
	},
	table: {
		remove: "Supprimer",
		noDataPlaceholder: "Aucune donnée disponible",
		noResultsPlaceholder: "Aucun résultat "
	},
	toggleSwitch: {
		no: "Non ",
		yes: "Oui"
	},
	wizard: {
		next: "Continuer",
		back: "Précédent",
		finish: "Terminer"
	}
};

export const ConfidentialityTypes = [{
	id: 0,
	Name: "Par défaut"
},
{
	id: 10,
	Name: "Faible"
},
{
	id: 12,
	Name: "Modérée"
},
{
	id: 11,
	Name: "Élevée"
}
];

export const internalUserRoles = [{
	roleKey: 3,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Autorisations",
	roleNameUI: "Archiver la mission",
	displayName: "Archiver la mission",
	roleDisplayOrder: 2,
	tooltipText: "Possibilité d’archiver la mission"
},
{
	roleKey: 4,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Autorisations",
	roleNameUI: "Gérer la mission",
	displayName: "Gérer la mission",
	roleDisplayOrder: 1,
	tooltipText: "Possibilité d’inviter des membres internes et externes et d’attribuer des autorisations aux membres internes"
},
{
	roleKey: 5,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Autorisations",
	roleNameUI: "Mettre à jour le contenu",
	displayName: "Mettre à jour le contenu",
	roleDisplayOrder: 6,
	tooltipText: "Possibilité de modifier le profil et d’accepter une mise à jour du contenu"
},
{
	roleKey: 7,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Autorisations",
	roleNameUI: "Créer une demande de service",
	displayName: "Gérer les demandes de service",
	roleDisplayOrder: 4,
	tooltipText: "Possibilité de créer une demande de service et de marquer les demandes de service comme étant terminées."
},
{
	roleKey: 8,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Autorisations",
	roleNameUI: "Gérer le groupe",
	displayName: "Gérer le groupe",
	roleDisplayOrder: 5,
	tooltipText: "Accepter ou rejeter les instructions pour l’audit de groupe reçues de l’équipe principale ou de l’équipe zonale."
},
{
	roleKey: 9,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Autorisations",
	roleNameUI: "Copier, récupérer et restaurer des missions",
	displayName: "Copier, récupérer et restaurer des missions",
	roleDisplayOrder: 3,
	tooltipText: "Permet de copier une mission ainsi que de récupérer et restaurer l’archive qui y est associée."
},
{
	roleKey: 0,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "Confidentialité",
	roleNameUI: "Par défaut",
	displayName: "Aucun (par défaut)",
	roleDisplayOrder: 1,
	tooltipText: "Rôle donnant accès aux documents de niveau de confidentialité par défaut"
},
{
	roleKey: 10,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "Confidentialité",
	roleNameUI: "Faible",
	displayName: "Faible",
	roleDisplayOrder: 8,
	tooltipText: "Rôle donnant accès aux documents de niveau de confidentialité faible"
},
{
	roleKey: 11,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "Confidentialité",
	roleNameUI: "Élevée",
	displayName: "Élevée",
	roleDisplayOrder: 10,
	tooltipText: "Rôle donnant accès aux documents de niveau de confidentialité élevé"
},
{
	roleKey: 12,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "Confidentialité",
	roleNameUI: "Modérée",
	displayName: "Modérée",
	roleDisplayOrder: 9,
	tooltipText: "Rôle donnant accès aux documents de niveau de confidentialité modéré"
},
// {
// roleKey: 13,
// sectionId:1,
// groupDisplayOrder: 200,
// groupName:"Confidentiality",
// roleNameUI: "Confidential",
// displayName: "Confidential",
// roleDisplayOrder: 11,
// tooltipText: 'Strictly Confidential Documents Role'
// },
{
	roleKey: 14,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "Restrictions",
	roleNameUI: "Lecture seule",
	displayName: "Lecture seule",
	roleDisplayOrder: 12,
	tooltipText: "Cette fonction ne doit être utilisée que pour donner accès à cette mission EY Canvas à des réviseurs tiers. Les approbations et les autorisations appropriées doivent être obtenues auprès des Services professionnels."
},
{
	roleKey: 16,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "Restrictions",
	roleNameUI: "Revue seule",
	displayName: "Revue seule",
	roleDisplayOrder: 13,
	tooltipText: "La restriction Revue seule permet à l’utilisateur de voir l’information d’une mission dans Canvas, de l’approuver et d’utiliser la fonctionnalité Note de revue. Cette restriction ne devrait être utilisée que lorsque d’autres utilisateurs d’EY doivent passer en revue la mission (p. ex., équipe principale de l’audit de groupe) sans pouvoir effectuer de modifications.",
},
{
	roleKey: 37,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "Restrictions",
	roleNameUI: "Indépendance seulement",
	displayName: "Indépendance seulement",
	roleDisplayOrder: 14,
	tooltipText: "La restriction Indépendance seulement permet à cet utilisateur de remplir le formulaire sur l’indépendance pour la mission, mais il n’aura pas un accès complet à la mission EY Canvas. Cette restriction ne doit être utilisée que lorsqu’un utilisateur a réalisé des travaux dans le cadre de la mission, mais qu’il n’a pas besoin d’avoir accès à EY Canvas.",
},
{
	roleKey: 6,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Autorisations",
	roleNameUI: "Annuler les modifications",
	displayName: "Reconstituer les documents",
	roleDisplayOrder: 7,
	tooltipText: "Possibilité de reconstituer les documents lorsque des modifications ont été apportées par erreur après la date de délivrance du rapport et de les supprimer du formulaire 440 d’EY Canvas."
},
{
	roleKey: 35,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Autorisations",
	roleNameUI: "Gérer le partage de l’outil FIT d’EY Canvas",
	displayName: "Gérer les flux de travail guidés",
	roleDisplayOrder: 14,
	tooltipText: "Permet de partager les flux de travail guidés entre les missions EY Canvas."
},
{
	roleKey: 36,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Autorisations",
	roleNameUI: "Importer des données d’EY Helix",
	displayName: "Importer des données d’EY Helix",
	roleDisplayOrder: 15,
	tooltipText: "Permet d’importer des données d’EY Helix"
}
];

export const restoreengagementexpirydates = [{
	value: 3,
	label: "3 mois"
},
{
	value: 6,
	label: "6 mois"
},
{
	value: 9,
	label: "9 mois"
},
{
	value: 12,
	label: "12 mois"
}
];

export const messageStatus = {
	0: "En cours",
	1: "Planifié",
	2: "Échu"
};

export const kendoLabels = {
	alignLeft: "Aligner le texte à gauche",
	alignRight: "Aligner le texte à droite",
	alignCenter: "Centrer le texte",
	alignFull: "Justifier le texte",
	addComment: "Ajouter un commentaire",
	addColumnBefore: "Ajouter une colonne à gauche",
	addColumnAfter: "Ajouter une colonne à droite",
	addRowAbove: "Ajouter une ligne au-dessus",
	addRowBelow: "Ajouter une ligne en dessous",
	bulletList: "Insérer une liste non triée",
	bold: "Gras",
	backColor: "Surbrillance",
	createLink: "Insérer un hyperlien",
	createTable: "Créer un tableau",
	cleanFormatting: "Effacer la mise en forme",
	deleteRow: "Supprimer la ligne",
	deleteColumn: "Supprimer la colonne",
	deleteTable: "Supprimer le tableau",
	fontSizeInherit: "Taille de la police",
	foreColor: "Couleur",
	format: "Format",
	fontSize: "Taille de la police",
	hyperlink: "Insérer une adresse URL",
	italic: "Italique",
	indent: "Retrait",
	insertTableHint: "Créer un tableau {0} par {1}",
	"hyperlink-dialog-content-address": "Adresse de la page Web",
	"hyperlink-dialog-title": "Insérer un hyperlien",
	"hyperlink-dialog-content-title": "Titre",
	"hyperlink-dialog-content-newwindow": "Ouvrir le lien dans une nouvelle fenêtre",
	"hyperlink-dialog-cancel": "Annuler",
	"hyperlink-dialog-insert": "Insérer",
	outdent: "Retrait négatif",
	orderedList: "Insérer une liste triée",
	print: "Imprimer",
	pdf: "exporter en format PDF",
	redo: "recommencer",
	strikethrough: "Barré",
	subscript: "Indice",
	superscript: "Exposant",
	underline: "Souligné",
	undo: "Annuler"
};

export const kendoFormatOptions = [{
	text: "Paragraphe",
	value: "p"
},
{
	text: "Titre 1",
	value: "h1"
},
{
	text: "Titre 2",
	value: "h2"
},
{
	text: "Titre 3",
	value: "h3"
},
{
	text: "Titre 4",
	value: "h4"
},
{
	text: "Titre 5",
	value: "h5"
},
{
	text: "Titre 6",
	value: "h6"
}
];

export const kendoFontSize = [{
	text: '8',
	value: "8 px"
},
{
	text: '9',
	value: "9 px"
},
{
	text: '10',
	value: "10 px"
},
{
	text: '11',
	value: "11 px"
},
{
	text: '12',
	value: "12 px"
},
{
	text: '14',
	value: "14 px"
},
{
	text: '16',
	value: "16 px"
},
{
	text: '18',
	value: "18 px "
},
{
	text: '20',
	value: "20 px"
},
{
	text: '22',
	value: "22 px"
},
{
	text: '24',
	value: "24 px"
},
{
	text: '26',
	value: "26 px"
},
{
	text: '28',
	value: "28 px"
},
{
	text: '36',
	value: "36 px"
},
{
	text: '48',
	value: "48 px"
},
{
	text: '72',
	value: "72 px"
}
];

export const cacheOptionValue = [{
	value: 1,
	label: "Centre de données local"
},
{
	value: 2,
	label: "Universel"
}
]
