/* eslint-disable prettier */

import {
	Addon
} from 'react-bootstrap/lib/InputGroup';

/**
 * Created by calhosh on 4/14/2017.
* ZH TR resource file
 */
export const labels = {
	defaultDropdown: "下拉式清單",
	status: "狀態",
	language: "語言",
	channel: "頻道",
	header: "標題",
	headerName: "標題名稱",
	sectionCustomLabelLabel: "自訂標籤",
	sectionNamePlaceHolder: "輸入段落標題",
	showRelatedObjectLabel: "顯示相關物件",
	errorBanner: "{0} 錯誤",
	engagements: "案件",
	selectedengagements: 'Engagement',
	addEngagement: "加入案件",
	archives: "歸檔",
	userInitialsTitle: "使用者選項",
	clickForOptions: "點選以取得更多選項",
	comingDue: "即將到期",
	whatsNew: "What's new",
	auditMetrics: "審計指標",
	id: "ID",
	archived: "已歸檔",
	myarchives: "我的歸檔",
	allarchives: "全部歸檔",
	logout: "登出",
	norecords: "找不到紀錄",
	nomatches: "找不到相符者",
	notemplates: "所選地區/區域無表單",
	pleasetryagain: "請重試",
	firstpage: "第一頁",
	previous: 'Previous',
	next: 'Next',
	prevpage: "前一頁",
	nextpage: "下一頁",
	lastpage: "最後頁",
	allengagements: "全部案件",
	searchPlaceholder: "搜尋",
	searchEngagementsPlaceholder: "搜尋案件",
	clearSearch: "清除搜尋",
	searchTextPlaceHolder: "搜尋",
	show: "顯示",
	manageaccess: "管理存取",
	restore: "還原歸檔",
	rollforward: "後推",
	viewaccesslist: "檢視存取清單",
	teammembers: "團隊成員",
	name: "名稱",
	initials: "縮寫",
	role: "角色",
	usersettings: "使用者設定",
	usersettingsnewengagements: "設定適用於新建立之案件",
	usersettingsallengagements: "設定適用於所有工作區及案件",
	enterfirstname: "顯示名稱(名字)",
	enterlastname: "顯示名稱(姓氏)",
	firstname: "名字",
	lastname: "姓氏",
	enter: "輸入",
	select: "選擇…",
	email: "電子郵件地址",
	contact: "電話號碼",
	accesslist: "存取清單",
	engagementsettings: "案件設定",
	displayname: "顯示名稱",
	signoffinitials: "簽名縮寫",
	primaryoffice: "主要辦公室",
	roles: "角色",
	phonenumber: "電話號碼",
	globalapplicationsettings: "全球應用程式設定",
	displaylanguage: "顯示語言",
	preferreddateformat: "預設日期格式",
	preferrednumberformat: "預設數字格式",
	save: "儲存",
	cancel: "取消",
	isRequired: "{0} 為必須",
	arcretrymessage: "歸檔流程發生錯誤。歸檔紀錄日期目前為{0}。EY Canvas正在自動重試您的歸檔，請自歸檔提交後等待15小時使自動復原流程完成再聯絡Help Desk。若Help Desk可解決而不必重啟案件，歸檔日期則仍然為{0}。若Help Desk必須重起案件供團隊進行修正動作，歸檔日期將重設為當團隊再次提交歸檔。若導致逾期歸檔，團隊應於案件中記錄Help Desk ticket number、問題、以及依據當地歸檔政策為解決問題所採取步驟。",
	ArchiveRetryLink: "重試歸檔",
	workspacelist: "工作區清單",
	engagementslist: "案件清單",
	accessrequested: "存取要求",
	noaccess: "無存取權限",
	independenceOnlyNoAccessMessage: "Your role in the engagement was 'Independence only'. Contact a member of the engagement to grant rights to restore or rollforward the engagement.",
	accessrejected: "拒絕存取",
	requestaccess: "請求存取",
	allarchiveinitialmsg: "輸入客戶名稱、工作區名稱、或案件名稱以開始",
	noengagementsforusermessage: "您無任何使用中案件。點選「建立案件」以開始。",
	noengagementstoimportto: "您沒有任何案件可匯入。",
	favorites: "我的最愛",
	portfolioview: "所有案件 v2",
	reviewengagement: "核閱案件",
	noreviewengagementsmessage: "您無任何核閱案件。",
	noreviewengagementsmessagecontact: "您無任何複核案件。若需登入請聯絡案件團隊成員。",
	nofavoriteengagementsmsg1: "您無任何我的最愛案件。",
	nofavoriteengagementsmsg2: "加入案件至此檢視畫面，請至全部案件",
	nofavoriteengagementsmsg3: "並點選案件卡上我的最愛圖示。",
	markasfavorite: "標記為我的最愛",
	unmarkfromfavorites: "從我的最愛中移除",
	selectworkspacenoclientworkspaceexists: "您無任何現存工作區。請點選返回並從第1步選擇「新工作區」。",
	importpkgselectworkspacenoclientworkspaceexists: "您無任何現存工作區。請點back並自前一步選擇「建立新工作區」。",
	expirydatelabel: "該案件將會被刪除在",
	expirydatetodaytomorrowlabel: "該案件將會被刪除",
	/*Reporting Hub*/
	reportingHub: "報導中心",
	reportingHubTitle: "EY Canvas報表",
	selectEngagement: "選擇案件以檢視報表",
	connectVPN: "僅連到EY Network或EY Remote Connect才可取得報告",
	reportsNotLoaded: "無法載入報告。請重新整理頁面並重試。若問題仍然存在，請聯絡Help Desk。",
	searchByEngagementNameOrClientId: "選擇案件",
	dataExportLabel: "資料匯出",
	summaryScreensLabel: "彙總畫面",
	projectManagementLabel: "案件管理",
	engagement: "案件名稱",
	reportingNotAvailable: "所選地區無報表。更多詳細內容請聯絡您的當地EY Canvas Champion。",
	client: "客戶",
	workspace: "工作區",
	serviceline: "業務線",
	subserviceline: "子業務線",
	engagementstartdate: "年度結束日期",
	engagementenddate: "涵蓋期間結束日期",
	deleteafter: "在…之後刪除",
	auditorsreportdate: "報告日期",
	createengagementquestionlabel: "建立案件",
	choosenewexistingquestionheader: "於新工作區或現存工作區建立案件？",
	createengagementheader: "建立案件",
	restoreengagementheader: "還原歸檔案件",
	createworkspacestepheader: "建立新工作區",
	selectexistingworkspacestepheader: "選擇現存工作區",
	workspacenotelibilefornewengagement: "工作區不符合新案件的條件",
	choosenewexistingstepheader: "新工作區或現存工作區",
	editengagementwizheader: "編輯案件",
	removeengagementwizheader: "移除案件",
	editworkspacewizheader: "編輯工作區",
	editworkspacestepfooter: "點選‘儲存並關閉’以儲存變更至工作區或點選‘儲存並繼續’以編輯案件。",
	editwizheader: "編輯",
	newworkspace: "新工作區",
	existingworkspace: "現存工作區",
	back: "返回",
	continue: "繼續",
	saveclose: "儲存並關閉",
	savecontinue: "儲存並繼續",
	finish: "完成",
	step01: "步驟一",
	step02: "步驟二",
	step03: "步驟三",
	step04: "步驟四",
	step05: "步驟五",
	engagementname: "案件名稱",
	confirmdecline: "確認拒絕。此動作不可復原。",
	confirmdeclinevalidation: "確認是否要拒絕。",
	responderror: "您已回應此邀請，請重新整理頁面並重試。",
	engagementcode: "案件編號",
	engagementcodename: "案件編號名稱",
	selectEngagementHeader: "選擇現存工作區",

	selectworkspaceclient: "選擇客戶",
	selectclient: "選擇客戶以檢視相關工作區",
	createnewworkspace: "建立新工作區",
	editworkspace: "編輯工作區",
	clientcode: "客戶編號",
	clientname: "客戶名稱",
	invalidclientcode: "無效之客戶編號",
	workspacename: '工作區名稱',
	location: "地點",
	workspacecodenameplaceholder: "輸入客戶編號以搜尋客戶名稱",
	engagementcodenameplaceholder: "輸入案件編號以搜尋案件名稱",
	isprimary: "主要",
	addengagementcode: "加入案件編號",
	engagementnamerequired: "案件名稱為必填",
	primaryofficerequired: "主要辦公室為必填",
	languagerequired: "語言為必填",
	servicelinerequired: "業務線為必填",
	subservicelinerequired: "子業務線為必填",
	engagementcodevalidationmessage: "案件編號與客戶編號不相符",
	engagementcoderequired: "案件編號為必填",
	yearenddate: "年度結束日期",
	periodenddate: "期間結束日期",
	reportdate: "報告日期",
	complete: "完成",
	end: "結束",
	started: "已開始",
	notstarted: "尚未開始",
	inprogress: "進行中",
	inreview: "複核中",
	taskstatus: "任務狀態",
	confirmlabel: "確認",
	submitlabel: "提交",
	editengagementquestionlabel: "編輯案件",
	removeengagementquestionlabel: "移除案件",
	removeengaementconfirmationmessage1: "請確認是否從您的案件清單移除以下案件？若您移除案件，您將不再有存取權限。",
	removeengagementerrormessage: "您為最後的使用者，無法從工作區移除案件。請加入其他團隊成員或刪除案件。",
	deleteengagement: "刪除案件",
	deletreviewcopyoption: "刪除",
	deleteengagementconfirmationmessage: "您確定要傳送刪除下列案件之請求?",
	deleteengagementconfirmationmessage1: "針對終止案件或不出具報告之案件，案件團隊應向Professional Practice諮詢以決定是否必須進行歸檔。",
	deleteengagementconfirmationmessageoneuser: "您確定要刪除下列案件?",
	deleteengagementconfirmationmessageoneuser1: "歸檔要求",
	deleteengagementconfirmationmessageoneuser2: "DOC+ARC 3.1",
	deleteengagementconfirmationmessageoneuser3: "刪除前應考慮。例如，終止或不發布報告的案件可能需要諮詢Professional Practice以確定是否需要歸檔。",
	deleteengagementmarkeddelete: "案件已被標記刪除",
	deleteengagementoption: "刪除",
	deleteengagementreasonlabel: "請輸入刪除之原因",
	deleteengagementreasonquestion: "該案件為何被刪除?",
	deleteengagementreasonrequired: "理由為必要。請選擇一個選項或在上方輸入原因。",
	deleteengagementcommentreasonrequired: "理由為必要。請由上述原因選擇一項。",
	deleteengagementcommentrequiredlabel: "請輸入刪除理由",
	deletereasontrainingengagementlabel: "訓練案件",
	deletereasonrestoredorcopiedlabel: "僅供參考之備份或複製案件",
	deletereasoncreatedbymistakelabel: "誤建案件",
	deletereasonotherlabel: "其他",
	deletereasonterminedbyclientlabel: "Terminated engagement where no work was performed. If work was performed in this engagement, then it should be archived.",
	markeddeleteconfirm: "確認刪除",
	deleterequestbannertext1: "刪除申請從",
	deleterequestbannertext2: "前",
	markeddeleteconfirmmessage: "您確定要刪除下列案件?",
	markeddeleteconfirmmessage1: "倘若您確認刪除，該動作將無法復原。",
	markeddeletereasonlabel: "刪除理由",
	engagementlistplaceholder: "選擇案件",
	engagementrequired: "案件為必填",
	restoreconfirmationstepheader: "確認",
	restoreconfirmationheader: "請確認是否欲還原此案件？",
	selectarchiveheader: "選擇歸檔檔案",
	restoreengagementstepheader: "還原歸檔案件",
	chooserollfwdhdr: "後推至新的或已存在的工作區",
	rollfwdstephdrext: "後推至已存在的工作區",
	rollfwdstephdrnew: "後推至新的工作區",
	rollfwdhdrqstn: "後推案件至新的或已存在的工作區？",
	rollforwardengagementstepheader: "後推案件",
	genericerror1: "發生錯誤，請重試或聯絡支援團隊。",
	restoreengagementmaxcopies: "案件已超過還原歸檔副本最多數量({0})。請申請現存副本之存取權限。",
	restoreengagementengcopies: "此案件有{0}還原歸檔副本：",
	restoreengagementengcopy: "此案件有{0}還原歸檔副本：",
	restoreengagementengnocopies: "此案件無其他還原歸檔副本。",
	globalexceptionrestoreengagementengcopies: "此案件有{0}個還原副本。請於上面勾選以還原global例外情況。",
	globalexception: "Global例外情況",
	globalexceptioncheckerror: "確認Global例外情況選項以繼續",
	restoreengagementrestoredon: "還原歸檔於",
	restoreengagementusers: "使用者",
	restoreengagementuser: "使用者",
	at: "於",
	arcfileuserunavailable: "無使用者",

	restorehdrqstn: "還原歸檔案件至新或現存工作區？",
	chooserestorehdr: "還原歸檔至新或現存工作區",
	restorestephdrext: "還原歸檔至現存工作區",
	restorestephdrnew: "還原歸檔至新工作區",

	close: "關閉",
	confirm: "確認",
	settings: "設定",
	expand: "展開",
	collapse: "收合",
	contextMenuEdit: "編輯",
	contextMenuEditEngagement: "編輯案件",
	contextMenuRemoveEngagement: "移除案件",

	contextMenuDeleteEngagement: "刪除案件",
	contextMenuEditWorkspace: "編輯工作區",
	contextMenuDelete: "刪除",
	contextMenuOverrideArchiveDate: "覆蓋歸檔截止日",
	na: "不適用",
	archivesubmittedby: "提交由",
	archivedateandtime: "日期及時間",
	notifications: "通知",
	nonotificationmsg: "您無任何通知。",
	notifcenter: "通知中心",
	notifitemspendingact: "項目待辦事項",
	accept: "接受",
	reject: "拒絕",
	acceptall: "接受全部",
	rejectall: "拒絕全部",
	notifEngInvite: "案件邀請",
	notifengaccesreq: "案件存取權限申請",
	keyevents: "關鍵事件",
	milestones: "里程碑",
	importselecttemplateheader: "請選擇您欲使用之表單",
	importtemplateheader: "請選擇您欲匯入之地區/區域",
	import: "匯入",
	importregionstepheader: "選擇地區/區域",
	importtemplatestepheader: "選擇表單",
	importtemplatemsg: "選擇地區/區域以繼續",
	importselecttemplatemsg: "選擇表單以繼續",
	importengagementheader: "匯入表單",
	importhdrqstn: "匯入至新工作區或現存工作區",
	importstephdrext: "匯入至現存工作區",
	importstephdrnew: "匯入至新工作區",
	chooseimporthdr: "匯入至新或現存工作區",
	selectexistingworkspacemsg: "選擇工作區以繼續",
	selectaclienttocontinuemsg: "選擇客戶以繼續",
	selectengagementmsg: "選擇案件以接收數據資料",
	selectgaengagementmsg: "您必須選擇一個案件以接受連結請求",
	arearegion: "地區/區域",
	importdatapackagequestionlabel: "請問您欲進行的動作？",
	sentby: "傳送由",
	on: "於",
	by: "由",
	view: "檢視",
	sourceengagement: "來源案件",
	package: "資料",
	decline: "拒絕",
	delegate: "委派",
	chooseaction: "選擇動作",
	selectworkspace: "選擇工作區",
	declinepackage: "拒絕請求",
	delegatepackage: "委派資料",
	selectengagement: "選擇案件",
	datapackagerequest: "資料請求",
	respond: "點選以回應",
	deletionrequestfor: "接收刪除請求以",
	invalidengagementcode: "無效的案件編號",
	noNotifications: "無通知",
	bellIconTitle: "通知中心",
	restoredengagement: "還原案件",
	profiledeliveryinprogress: "基本資料傳遞中",
	completeprofileheader: "完成基本資料",
	completeindependenceheader: "完成獨立性",
	independencecompletedheader: 'Independence completed',
	retry: "重試",
	errorDate: "錯誤",
	invitationDate: "邀請日期",
	engCreationFailedWarning: "案件建立錯誤。請重新整理頁面並重試。若問題仍然存在，請聯絡Help Desk。",
	deletedEngagementWarning: "若誤刪使用中案件，請立即聯絡Help Desk。",
	searchEngagementWarning: "搜尋案件以執行進一步動作。",
	archiveOverrideWarning: "ARC不允許所選歸檔覆蓋期間。歸檔截止日期已還原為您地點的預設值。",
	archiveOverrideInProgress: "歸檔覆蓋待ARC核准",
	archiveOverrideAccepted: "歸檔覆蓋完成",
	archiveOverrideRejected: "所選擇歸檔覆蓋期間不被ARC允許。歸檔期限日期已被恢復為您地點之預設值。",
	scopeName: "範圍名稱",
	shortName: "簡稱",
	invited: "已邀請",
	invitedOn: "邀請至",
	instructions: "指引",
	updates: "更新",
	viewinstructions: "檢視指示",
	viewinstructionsupdates: "檢視更新",
	more: "更多",
	addmembers: "加入成員",
	statusChangeDate: "狀態變更",
	deletedBy: "刪除由",
	deletedDate: "已刪除",
	deactivatedBy: "停用由",
	deactivatedDate: "已停用",
	requestUserName: "請求由",
	requestDate: "已請求",
	archiveDate: "已歸檔",
	receivedFrom: "接收自",
	received: "已接收",
	clear: "清除",
	clearAll: "清除全部",
	type: "類型",
	engagementhasbeendeleted: "所選案件已被其他使用者刪除。請重新整理頁面並重試。",
	independenceOnlyRejectInvite: {
		engagementIsNotAvailable: 'Engagement is not available. Refresh the page and try again. If the issue persists, contact Help Desk.',
		failedToRejectInvite: 'Failed to reject the invite. Refresh the page and try again. If the issue persists, contact Help Desk.',
		succesfulRejection: 'Invite successfully rejected.',
		loadingLabel: "Rejecting invitation..."
	},
	manageTeam: "管理團隊",
	childinprogress: "案件設置處理中",
	// SeeMore component
	showMore: "顯示較多",
	showLess: "顯示較少",
	showMoreEllipsis: "顯示較多…",
	showLessEllipsis: "顯示較少…",
	accepted: "已接受",
	acceptedOn: "接受於：",
	rejected: "已拒絕",
	deniedBy: "拒絕由",
	engagementstatusrestored: "已復原",
	engagementstatusmarkedfordeletion: "標記刪除",
	delegaterequired: "必須委派",
	username: "使用者名稱",
	noresults: "找不到結果。請重試。",
	importdatapackageconcurrencymessage: "複製請求狀態已變更。請重新整理頁面並重試。",
	selectdelegateuser: "選擇委派",
	eyusersearchplaceholder: "輸入名稱或郵件以搜尋",
	optional: "(非必須)",
	engagementAlerts: "案件警告",
	engagementMetrics: "案件指標",
	overdue: "逾期",
	/*day labels*/
	mon: "星期一",
	tues: "星期二",
	wed: "星期三",
	thurs: "星期四",
	fri: "星期五",
	sat: "星期六",
	sun: "星期日",
	canvas: 'Canvas',
	overrideArchiveDateModalHeader: "覆蓋歸檔期限日期",
	overrideArchiveDateModalMessage: "請確認是否覆蓋歸檔期限日期？",
	overrideArchiveDateModalComboPlaceholder: "覆蓋歸檔期限日期",
	createdOn: "建立於",
	expiresOn: "到期於",
	archiveDeadlineDate: "歸檔期限日期",
	defaultOverriden: "預設被覆蓋",
	selectexistingworkspace: "選擇現存工作區",
	importtonewworkspace: "匯入至新工作區",
	groupaudit: "集團查核連結請求",
	gaacceptdeclinedelegatequestionlabel: "您對連結請求欲執行的動作？",
	gaacceptselectengagementquestionlabel: "選擇案件以連結",
	gadelegatequestionlabel: "委派集團查核連結請求",
	gadeclinequestionlabel: "拒絕集團查核連結請求",
	garegioncomponent: "區域/組成個體",
	gascope: "範圍",
	gamxTransfer: "從GAMx傳輸",
	closeNotificationCenter: "關閉通知中心",
	contextMenucrp: "建立複核副本",
	workingoffline: "離線工作",
	trainingoffline: "離線工作(訓練)",
	training: "訓練",
	crpdescription: "Create a copy of the engagement for external reviewers.",
	crpeditdescritpion: "請編輯案件名稱或期限。過期案件將被自動刪除。",
	expiry: "效期",
	enteremail: "輸入電子郵件地址",
	create: "建立",
	metricsPlaceholderText: "所選時間範圍無資料",
	deleteReviewCopy: "刪除複核副本",
	deleteReviewCopyMsg: "請確認是否刪除複核副本？一旦刪除，案件將不再存在且無法復原。",
	crpNotAvailable: "Review copy cannot be created for this engagement because the Canvas External Access Portal is not available at this time. Contact the help desk for further information.",
	externalmember: "外部成員",
	internalmember: "內部成員",
	add: "加入",
	crpEditMembers: "編輯",
	invalidEmail: "輸入有效之電子郵件地址",
	emailNotFound: "電子郵件地址找不到",
	dupExternalUser: "外部成員已加入",
	noExternalUser: "無已加入之外部成員。",
	expiresIn: "過期於",
	days: "天",
	today: "今天",
	tomorrow: "明天",
	reviewengagementname: "複核案件名稱",
	enternameoremail: "輸入名稱或電子郵件地址",
	options: "選項",
	userNotFound: "找不到使用者",
	remove: "移除",
	crpEditHeader: "編輯複核副本",
	globalexceptionrestore: "Global exception restore",
	expired: "已過期",
	softwareUpdate: "軟體更新",
	updateNow: "現在更新",
	updateMsg: "EY Canvas有軟體更新。請選擇「現在更新」以下載並安裝更新。頁面將重新整理。",
	preferredlandingpageview: "預設登入頁面檢視",
	preferredlandingpagefilter: "預設登入頁面篩選",
	engagementlist: "案件清單",
	workspacelistusersettings: "工作區清單",
	engagementsclientstoload: "您登入應用程式每次載入案件/客戶數量",
	favoriteengagements: "最愛案件",
	preferredEngagementDashboard: "Engagement dashboard settings",
	preferredEngagementDashboardTeamMembers: "Team members",
	preferredEngagementDashboardExecutives: "Executives",
	linkedHelixProjects: "連結至 EY Helix",
	loadingLabel: "載入中…",
	loadingErrorText: "載入時間軸發生錯誤。請重新整理頁面並重試。",
	selectYear: "Select year (required)",
	clientNameOrId: "客戶名稱或ID",
	engagementNameOrId: "案件名稱或ID",
	engagementCodeOrCodeName: "Engagement code or code name",
	searchArchives: "Search",
	clearSearchArchives: "Clear search",
	searchArchivesMessage: "請先選擇案件歸檔年度。接下來請用以下選項搜尋：",
	titleUpdateProfile: "資訊",
	msgUpdateProfileLn1: "You have updated the engagement language. In order to receive content in this language, go to Engagement content updates and select Global content update.",
	sessionTimerMsg1: "由於未使用，您目前的工作階段即將到期。",
	sessionTimerMsg2: "為了您的安全性，EY Canvas階段將於未使用30分鐘後自動結束。",
	sessionTimerMsg3: "您目前的階段將於{0}分鐘到期。",
	sessionTimerMsg4: "若您希望繼續登入，請點選",
	sessionTimerHeader: "為保護您已自動登出",
	theme: "主題",
	light: "淺色",
	dark: "暗色",
	chooseDisplayTheme: "請選擇您的預設顯示主題。",
	searchByCountry: "請選擇國家",
	primaryEngagementLabel: "主查團隊案件",
	componentEngagementLabel: "組成個體案件",
	regionalEngagementLabel: "區域團隊案件",
	linkedLabel: "已連結",
	eyCanvasEconomicsTooltip: "EY Canvas Economics",
	eyOversightTooltip: "EY Canvas Oversight",
	eyPrivacyNoticeTooltip: "隱私權聲明",
	eyTermsOfServiceTooltip: "服務條款",
	eyGeneralHelp: "一般幫助",

	preferrednegativenumberformat: "負數格式設定",

	protectedEngagementMessage: "禁止列印、複製、或以其他方式重製文件",
	documentRrestriction: "文件限制：",
	on: "On ",
	off: "Off ",
	protectedEngagementMessageOn: "- 本案件之文件無法列印、複製、或以其他方式重製",
	protectedEngagementMessageOff: "- 本案件之文件可列印、複製、或以其他方式重製",

	/* Dashboard labels*/
	homeLabel: "Home",
	manageAccessHeader: "Manage access",
	sharepointWorkspacesHeader: 'SharePoint workspaces',
	showInactiveUserslabel: "Show inactive users",
	showExpiredMessageslabel: 'Show expired messages',
	addUserButtonLabel: "Add user",
	searchUserText: "Search",
	nameHeader: "Name",
	dataCenterNameHeader: 'Data center name',
	numberOfWorkSpacesHeader: 'Number of workspaces provisioned',
	workspaceName: "Workspace name",
	documentCount: "Document count",
	percentCapacity: "Percent capacity",
	emailHeader: "Email",
	actionHeader: "Action",
	manageRolesLabel: "Manage roles",
	deactivateUserlabel: "Deactivate user",
	manageRolesTableModule: 'Module',
	manageRolesTableRoles: 'Roles',
	manageRolesTableServiceLine: 'Service line',
	manageRolesTableCountries: 'Countries',
	manageAccessAssignRole: 'Assign role',
	manageAccessNoRolesAdded: 'No roles added',
	manageAccessDeleteRoleModalHeader: "刪除角色",
	manageAccessDeleteRoleButton: "刪除",
	noRoleResults: 'No results',
	confirmAssignRoleButton: 'Assign',
	permissionGroupName: 'Group name',
	permissionGroupDescription: 'Group description',
	activeMembers: 'Active members',
	permissionGroupMembersTitle: "Title",
	permissionGroupMembersPhone: "Phone",
	permissionGroupMembersGui: "GUI",
	permissionGroupMembersUserId: "User ID",
	permissionGroupMembersAdStatus: "AD status",
	permissionGroupAddMember: "新增成員",
	permissionGroupUserDeleteLabel: "刪除",
	permissionGroupUserDeleteHeader: "刪除成員",
	permissionGroupDetails: "許可團隊細節",
	cache: 'Cache key',
	cacheSelection: 'Select cache location',
	engagementID: "案件ID",
	cacheKey: "快取金鑰",
	validationMessage: "必要欄位",
	getCache: "取得快取",
	clearResult: "清除結果",
	removeCache: "移除快取",
	requiredField: "必要欄位",
	ticketNumber: "Ticket Number（必填）",
	confirmationMessage: "請輸入此動作的Ticket Number",
	confirmButtonLabel: "確認",
	cancelButtonLabel: "取消",
	modalHeaderLabel: "刪除緩存",
	adminModuleinvalidEngagementId: "案件ID無效",
	adminModuleInvalidCacheKey: "Cache key contains invalid characters",
	clearResults: "清除結果",
	getDocuments: "獲得文件",
	documentsHeader: "文件",
	validTicketNumber: "請輸入有效的ticketNumber",

	egagementsPageDescription: "Search for engagements based on one or multiple criteria to update engagement data, invite new team members, update existing team member status and permissions.",
	engagementSearchCriteria: "執行搜尋需要國家或案件ID",
	engagementsHeader: "Engagement",
	engagementIdSearchPlaceholder: "案件 ID",
	engagementNameSearchPlaceholder: "案件名稱",
	workSpaceSearchPlaceholder: "Workspace name or ID",
	clientSearchPlaceholder: "Client name or code",
	engagementCodeSearchPlaceholder: "Engagement code or code name",
	userSearchPlaceholder: "User GUI, email or UserID",
	countrySearchPlaceholder: "國家",
	engagementTableHeader: "Engagement",
	nameTableHeader: "Name",
	workspaceIdTableHeader: "Workspace",
	clientTableHeader: "Client",
	engagementCodeTableHeader: "Engagement code",
	yearEndTableHeader: "Year end",
	engagementdeleteDate: "Engagement delete date",
	archivalguid: "Archival GUID",
	archivalLogId: "Archival log ID",
	archivalFileguid: "Archival file GUID",
	profile: "Profile complete",
	independence: "獨立性",
	archiveRetry: "歸檔重試",
	engagementdetails: "Engagement details",
	engagementsdetails: 'Engagments details',
	statusTableHeader: "Status",
	attributesTableHeader: "Attributes",
	attributesProfileComplete: "Profile Complete",
	attributesIndpendenceApplicable: "Independence applicability",
	engagementPagePlaceholder: "Search for engagements above, results will display here",
	noEngagementFound: "No Engagement found",
	emptySearchFieldError: "At least one search field should have a value.",
	largeCountrySelectionError: "Please select 10 or less countries.",
	saveEngagement: "儲存",
	undoEditEngagement: "重設",
	engagementUpdateSuccess: "變更已被成功儲存",
	archiveRetrySuccessMessage: "已為此案件成功啟動歸檔。 請稍後檢查狀態以驗證歸檔是否成功。",
	welcome: "歡迎來到",
	welcomeadmin: "管理工具",
	engagementCard: "Engagements",
	messagingCard: "Messaging",
	templatesCard: "Templates",
	permissionGroupCard: "Permission groups",
	manageAccessCard: "Manage access",
	sharepointWorkspacesCard: "SharePoint workspaces",
	databaseLocatorCard: "同步資料庫定位器",
	importForReviewTooltip: "Engagement will be optimized for review and some features and functionalities will not be available.",
	importForReviewChecboxText: "Import for review purposes only",
	clientCode: "Client code",
	primaryEngagementCode: "Primary engagement code",
	invalidEngagementTitle: "Invalid client or engagement code",
	invalidEngagementMessage: "Client code and/or engagement code need to be validated before you can access this engagement. Click Continue to update and validate the codes.",
	insufficientPermission: "此模組權限不足",
	noPermission: "No Permission",
	noAdminRights: "You do not have rights to this tool.",



	//Engagement Users Lables
	engmtUserTableUserNameHeader: "User name",
	engmtUserTableEmailAddressHeader: "Email address",
	engmtUserTableRoleHeader: "角色",
	engmtUserTableGUIeHeader: "GUI",
	engmtUserTableStatuseHeader: "狀態",
	engmtUserTableIndependenceStatuseHeader: "獨立性",
	engmtUserTableConfidentialityHeader: "Confidentiality",
	engmtUserTableADStatusHeader: "AD status",
	engmtUserTablePermissionsHeader: "Permissions",
	engmtUserTableActionHeader: "Action",
	engagementUsersTitle: "Engagement users",
	engagementInviteMember: "邀請成員",
	engagementInviteUser: "邀請使用者",
	engagementRoles: "角色",
	engagementRolesRequired: "案件角色為必要",
	engagementInviteMemberSuccessMessage: "成員被邀請至該案件成功",
	engagementInviteMemberDescription: "使用「名」、「姓」或「電子郵件地址」來尋找使用者。結果將被顯示於此。",
	engagementUsersTitleEdit: "Edit",
	engagementUpdatePermissionToolTip: "You have insufficient permissions to update the engagement.",
	engagementRetryArchivePermissionToolTip: "您沒有足夠的權限重試歸檔",
	usersEditPermissionToolTip: "You do not have permission to take this action. Contact Administrator to request permission.",
	usersPermissionToolTip: "You have insufficient permissions to update the engagement user.",
	invalidArchivalGuidMessage: "無效的歸檔GUID",
	invalidArchivalFileGuidMessage: "歸檔文件GUID無效",
	invalidArchivalLogId: "歸檔紀錄ID無效",
	datePickerLabel: "選擇日期",
	updateEngagement: "更新案件",

	invalidEngagementMessage: "Client code and/or engagement code need to be validated before you can access this engagement. Click Continue to update and validate the codes.",
	invalidEngagementId: "Engagement Id is invalid",
	serverErrorMessage: "Server error, come back later",
	engagementDetailsNoPermission: "You have insufficient permission to view the engagement details.",
	engagementNotFound: "Engagement details not found",
	pageNotFound: "Page Not found",
	assignRoleModalMessage: "Select a module and specific function to be enabled for the user",
	selectModule: "Select Module",
	selectRole: "Select Role",
	serviceLine: "Service Line",
	countries: "Countries",
	confirmAssignRoleButton: 'Assign',
	addUser: "Add user",
	addAdminUserHeader: "Enter first name, last name or email address of user and press enter to search",
	addMemberDescription: "以名(first name)、姓(last name)或電子郵件地址來搜尋使用者。結果將顯示於此處。",
	seachInputRequired: 'Search input required',
	searchUsers: 'Search users',
	noResultsFound: 'No results found',
	usersToBeAdded: 'Users to be added',
	addUserSuccessMessage: 'Admin user added successfully.',
	addMemberSuccessMessage: "成員加入至該群組成功。",
	deactivateWarning: "您確定要解除下列使用者?",
	deactivateButton: "非使用中",
	deactivateModalTitle: "非使用中之使用者",
	generateReport: "產生報表",
	generateValidationReport: "生成驗證報告",

	templateManagement: 'Templates',
	templateName: 'Template name',
	templateAreaRegion: 'Area/region',
	templateSize: 'Size',
	templateCreatedBy: 'Created by',
	templateCreatedDate: 'Created date',
	templateCountOfEngagement: 'Engagements',
	templateStatus: 'Status',
	templatePublished: 'Published',
	templateUnpublished: 'Unpublished',
	templateSearchText: 'Input Search text and press Enter',
	templateInsufficientPermissions: 'Insufficient permissions to this module',
	templateMultipleArea: 'Multiple',
	templateEdit: 'Edit template',
	templateEditSave: 'Save',
	templateEditCancel: 'Cancel',
	templateEditSuccessMessage: "表單已成功更新。",
	templateDeleteConfirmationMessage: "請確認是否要刪除該模板？",
	templateDelete: "刪除範例",
	templateEditDelete: "刪除",

	messagingMessageTitle: "訊息標題",
	messagingDescriptionTitle: "留言說明",
	messagingStartsOn: "開始於(UTC)",
	messagingEndsOn: "結束於(UTC)",
	messagingAffectedDataCenters: "受影響的資料中心",
	messagingStatus: "狀態",
	messagingCreatedBy: "建立由",
	messagingCreatedDate: "建立日期",
	messagingDataCenterSystemWide: "系統範圍",
	messagingDataCenterMultiple: "多數的",
	messagingDataCenterSingle: "單獨的",
	messageName: "訊息標題",
	messageStartsOn: "開始於",
	messageEndsOn: "結束於",
	messageDateTime: "選擇日期和時間",
	messageAllTimeInUTC: "所有時間均為UTC",
	messageTargetAudience: "目標閱讀者",
	messageDataCenters: "資料中心",
	messageSelectADate: "選擇日期",
	messageAllDataCenters: "所有資料中心",
	messageKendoDescription: "使用格式化將減少4,000字元的最大描述長度",
	messageSelectDataCenter: "選擇資料中心",
	messageFieldRequired: "必要欄位",
	messageStartTimeError: "開始日期和時間不能早於目前時間",
	messageEndTimeError1: "結束日期和時間不應早於目前日期和時間",
	messageEndTimeError2: "結束日期和時間應早於開始日期和時間",
	messageDataCenterError: "選擇至少一個資料中心",
	messageSubmitValidationError: "缺少必填字段。 請上下移動並確保填寫所有必填字段。",
	messageChangesValidationError: "沒有進行任何變更。 至少編輯一個欄位才能儲存。",
	currentutc: "當前UTC日期和時間：",
	messageCurrentUTCTime: " {0}(24 小時格式)",
	createMessage: "建立訊息",
	createMessagePermissions: "該模組權限不足",
	createMessageSuccess: "訊息建立成功",
	editMessage: "編輯訊息",
	editMessageSuccess: "訊息編輯成功",
	editHoverText: "編輯",

	messagingModalDeleteTitle: "刪除訊息",
	messagingModalDeleteMessage: "您確定要刪除該訊息嗎？",
	messagingModalDeleteButton: "刪除",
	messagingModalDeleteConfirm: "確認",
	messagingModalDeleteCancel: "取消",
	messagingModalDeletedSuccessfully: "訊息刪除成功",

	// databaselocator
	databaseLocatorSuccessMessage: "資料庫定位器同步已在排隊",

	// documents module
	docListHeaderDocId: "文件編號",
	docListHeaderDocVersion: "版本",
	docListHeaderDocName: "文件名稱",
	docListHeaderFileSize: "文件大小",
	docListHeaderContentUri: "內容 URI",
	docListHeaderFileExists: "文件已存在",
	docListNoResults: "沒有找到文件",
	docListTotalDocuments: "文件總數：",
	docListDocIdSearchLabel: "文件ID",
	docListExportBtn: "匯出",

	// Internal user roles abbr
	picRole: "PIC",
	adminRole: 'Admin',
	readOnlyRoleForThirdPartyUser: 'Read-only',
	legacy: 'Unrestricted',
	permissionLabel: 'Permission',
	permission: "Permissions",
	active: "Active",
	inactive: "Inactive",
	EngagementUserStatus: "狀態",
	IndependenceStatus: "獨立性",
	Confidentiality: "Confidentiality",
	EditUserPermissionsMainTitle: "You can edit your permissions",
	EditUserPermissionsInstructions: "It will always be editing other people permissions not yours.",
	PermissionSaveButtonText: "Save",
	PermissionCancelButtonText: "Cancel",
	UnknownCountry: "Unknown",
	engagementNoPermissionMesage: "You have insufficient permissions to the engagement module.",
	cacheRemoveSuccessMessage: "快取已被成功移除",
	cacheKeyNotFound: "快取金鑰尋找並無結果",

	// Portfolio labels
	informationText: "歡迎回來，",
	filterEngagements: {
		all: "所有案件",
		favorite: "我的最愛案件",
		review: "核閱案件"
	},
	filterEngagementsLabel: "篩選案件",
	engv2noActiveEngagements: "您沒有任何活躍的案件。點選“新增案件”按鈕開始。",
	engv2noFavoriteEngagements: "您沒有任何最喜歡的案件。要將案件新增到此視窗，請移動至所有案件並點選案件卡右下角的收藏夾圖示。",
	engv2noReviewEngagements: "您沒有任何複核案件。若您需要訪問權限，請聯繫案件團隊成員。",

	// Engagement Progress Labels
	engagementProgressStrategyPhaseLabel: "範圍與策略階段",
	engagementProgressExecutionPhaseLabel: "執行階段",
	engagementProgressConclusionPhaseLabel: "結論階段",
	engagementProgressCompletionPercentage: "完全的",
	engagementProgressCompleted: "完全的",
	engagementProgressNotAvailable: "案件進度將可使用，當您的本地資料中心升級時",

	// Engagement archive countdown indicator
	EngagementArchiveCountdownIndicator: {
		reportreleasedate: 'Report release date is',
		archivedeadlinedate: 'Archive deadline date is',
		today: "今天",
		tommorow: "明天",
		overdue: "過期",
		reportreleasedays: 'until Report release date',
		archivedeadlinedays: 'until Archive deadline date',
		days: 'days'
	},
	postArchive: "發布歸檔",

	adminTool: "管理工具",
	characters: "特性",
	placeholder: "Placeholder",
	ofLabel: "of",
	kendoTextLimitMessage: "超出最大字元數限制",
	kendoRequiredMessage: "必須的",
	engv2Errors: {
		noReviewEngagementsPermission: "您無權限查看案件。若您需要訪問權限，請聯繫案件團隊成員。"
	},
	timeTracking: 'Time Tracker',
	timeTrackingToolMessage: "此選擇性工具是專門為幫助您在處理案件時更好地管理時間而建立的。",
	timeTrackingToolMessage1: "一旦您開始記錄時間，您可以找到最多 14 天的收集數據的摘要。 超過 14 天的數據將被自動移除。",
	timeTrackingToolUpdatedMessage: "此選擇性工具有助於記錄您所花費的時間。記錄的時間最多保留兩週並且不會被歸檔。",
	timeTrackerErrorFallBackMessage: "無法加載時間摘要。 請重新整理頁面並重試。若錯誤仍持續，請聯絡Help Desk。",
	serviceGateway: "Service Gateway",
	reportinghubDisableMessage: "Spotfire 將於 2024 年 6 月停用。新的 EY Canvas 報告可透過您的 EY Canvas 案件取得。 2024 年第 1 版釋出後，可以從「更多」選單中存取。 請確保在 2024 年 6 月之前將依賴這些報告的任何自訂報告或自動化更新為新版本。",
	mobileVersionLabels: {
		canvas: 'Canvas',
		noEngagementFound: 'You do not have any active engagements. Access Canvas on your computer to get started.',
		searchEngagementPlaceholder: 'Search...',
		search: 'Search',
		independenceIncomplete: 'Incomplete Independence',
		profileIncomplete: 'Incomplete Profile',
		allEngagements: 'All engagements',
		noMatchesFound: 'No matches found. Please try again.',
		inValidUserLogin: 'Access your computer to complete the user settings',
		pagination: {
			pageLabel: "Page",
			ofLabel: "of"
		}
	},
	independenceOnlyStatus: "Independence only",
	toggleView: "Switch View",

	// Adobe Analytics
	aaCookieConsentTitle: "Welcome to",
	aaCookieContentPrompt: "Do you want to allow cookies?",
	aaCookieConsentExplanation: '<p>In addition to cookies that are strictly necessary to operate this website, we use the following types of cookies to improve your experience and our services: <strong>Functional cookies</strong> to enhance your experience (e.g. remember settings), <strong>Performance cookies</strong> to measure the website\'s performance and improve your experience, <strong>Advertising/Targeting cookies</strong>, which are set by third parties with whom we execute advertising campaigns and allow us to provide you with advertisements relevant to you.</p><p>Review our <a target="_blank" href="https://www.ey.com/en_us/cookie-policy">cookie policy</a> for more information.</p>',
	aaCookieConsentExplanationWithDoNotTrack: '<p>In addition to cookies that are strictly necessary to operate this website, we use the following types of cookies to improve your experience and our services: <strong>Functional cookies</strong> to enhance your experience (e.g. remember settings), <strong>Performance cookies</strong> to measure the website\'s performance and improve your experience, <strong>Advertising/Targeting cookies</strong>, which are set by third parties with whom we execute advertising campaigns and allow us to provide you with advertisements relevant to you.</p><p>We have detected that you have enabled the Do Not Track setting in your browser; as a result, Advertising/Targeting cookies are automatically disabled.</p><p>Review our <a target="_blank" href="https://www.ey.com/en_us/cookie-policy">cookie policy</a> for more information.</p>',
	aaCookieConsentDeclineOptionalAction: "I decline optional cookies",
	aaCookieConsentAcceptAllAction: "I accept all cookies",
	aaCookieConsentCustomizeAction: "Customize cookies",
	aaCookieConsentCustomizeURL: 'https://www.ey.com/en_us/cookie-settings',

	// Cookie Settings
	cookieSettings: {
		title: "Cookie Settings",
		explanation: "Please provide your consent for cookie usage on ey.com and the My EY platform. Select one or more of the cookie types listed below, and then save your selection(s). Refer to the listing below for details on the types of cookies and their purpose.",
		emptyCookieListNotice: "Cookies from this category are not used in this app",
		nameTableHeader: "Name of cookie",
		providerTableHeader: "Cookie provider",
		purposeTableHeader: "Purpose of cookie",
		typeTableHeader: "Type of cookie",
		durationTableHeader: "Duration of cookie",
		formSubmit: "Save my selection",
		requiredCookieListTitle: "Required Cookies",
		functionalCookieListTitle: "Functional Cookies",
		functionalCookieAcceptance: "I accept the functional cookies below",
		functionalCookieExplanation: "Functionality cookies, which allow us to enhance your experience (for example by remembering any settings you may have selected).",
		performanceCookieListTitle: "Performance Cookies",
		performanceCookieAcceptance: "I accept the performance cookies below",
		performanceCookieExplanation: "Performance cookies, which help us measure the website’s performance and improve your experience. In using performance cookies we do not store any personal data, and only use the information collected through these cookies in aggregated and anonymised form.",
		advertisingCookieListTitle: "Targeting Cookies",
		advertisingCookieAcceptance: "I accept the advertising/targeting cookies below",
		advertisingCookieExplanation: "Advertising/targeting cookies, which we use to track user activity and sessions so that we can deliver a more personalized service, and (in the case of advertising cookies) which are set by the third parties with whom we execute advertising campaigns and allow us to provide advertisements relevant to you.",
		doNotTrackNotice: "We have detected that you have enabled the Do Not Track setting in your browser; as a result, advertising/targeting cookies are automatically disabled.'",
	},

	//Copyright
	copyrightMessage: 'Copyright © <year> all rights reserved',
	addWorkspace: 'Add workspace'
};

export const errors = {
	// GenericErrors Codes
	'0005': "交易待處理",
	'0006': "交易失敗",
	'0008': "說明不可包含任何以下字元: \\",
	'0010': "Arc Call失敗",

	0: "請求失敗",
	928: "未授權存取 - 您無存取權限至所選擇的選項。如已授權請聯絡Help Desk關於如何取得存取權限之指引。",

	1009: "找不到資料伺服器",
	1010: "找不到資料中心",
	1011: "此國家尚未設置資料中心",
	1013: "找不到國家ID或案件ID",
	1014: "找不到國家資料中心",
	1016: "資料庫定位對應失敗",
	1017: "oDataQuery不可為null或空白",
	1018: "oDataQuery不包括任何欄位",
	1019: "找不到通用資料中心",
	1020: "使用者不存在於該系統",
	1021: "所選地點無報表。更多詳細內容請聯絡您的當地EY Canvas Champion。",

	// Scope & Strategy 1021 - 1200
	1026: "找不到列",
	1030: "找不到客戶名稱",

	// Restore Engagement
	1101: "Unable to restore engagement to the country selected. Refresh the page and try again.",

	// Execution   1201-1400
	1219: "找不到方法",
	1234: "角色ID不可為null或空白",
	1260: "您所處理之項目已被其他使用者刪除。",
	1300: "無效之要求物件",
	1301: "無效之案件角色",
	1302: "無效之使用者語言",
	1303: "找不到案件使用者明細",
	1304: "無效之地點",
	1305: "找不到案件名稱",
	1306: "案件名稱超過最大長度(255字元)",
	1307: "找不到涵蓋期間結束日期",
	1308: "無效之日期",
	1309: "找不到會計師報告日期",
	1310: "找不到案件歸檔期限日期",
	1311: "案件歸檔期限日期不可早於今日",
	1312: "案件有重複之案件編號",
	1313: "找不到地點",
	1314: "找不到工作區名稱",
	1315: "工作區名稱超過最大長度(255字元)",
	1316: "客戶編號超過最大長度(50字元)",
	1317: "客戶名稱超過最大長度(255字元)",
	1318: "標示是否為訓練工作區之值無效",
	1319: "找不到訓練工作區標示",
	1320: "找不到客戶已驗證標示",
	1321: "標示客戶是否已驗證之值無效",
	1322: "無效之客戶Id",
	1323: "找不到案件編號",
	1324: "找不到案件Id",
	1325: "無效之主要辦公室ID",
	1052: "無效的服務線ID",
	1053: "無效的子服務線ID",
	1326: "找不到國家ID",
	1327: "無效之工作區id",
	1328: "無效之案件團隊成員狀態",
	1329: "無效之案件狀態",
	1330: "案件已被其他團隊成員刪除",
	1331: "找不到年度結束日期",
	1339: "超過最大可允許案件數目",
	1340: "找不到地點詳細內容",
	1341: "案件資料更新未正確接收",
	1342: "目前案件找不到案件詳細內容",
	1343: "目前案件找不到案件彙總資料",
	1344: "所選ID案件不存在",
	1359: "某一使用中案件之資料中心ID無效",
	1363: "通用案件建立失敗",
	1364: "LDC案件建立失敗",
	1373: "選擇現存工作區或建立新工作區",
	1374: "案件名稱已存在。請輸入新案件名稱以匯入表單。",
	1375: 'Incorrect expiration days cannot be updated.',
	1379: "內容已重新整理。請稍後重試。若問題仍然存在請聯絡IT Help Desk。",
	1380: "無法完成歸檔重試。請重新整理頁面並重試。若問題仍然存在，請聯絡Help Desk。",
	1381: "Create / RF / RS Engagement failed, due to mis-match in country Id in Workspace and engagement.",
	1382: "Create / RF / RS Engagement failed, due to mis-match in primary office in Workspace and engagement.",
	1384: "此案件的還原或後推已在進行中。請稍後再試。",
	1386: "工作區不符合新案件的條件。請重新整理頁面並重試。若錯誤仍持續，請聯絡Help Desk。",
	1385: "無法在所選位置建立案件。請重新整理頁面並重試。若錯誤仍持續，請聯絡Help Desk。",
	1389: 'Expired review engagement cannot be updated.',
	// Invite Team Members (1401 - 1500)
	1403: "案件歡迎訊息超過最大長度(200字元)",
	1406: "找不到案件集合",
	1424: "找不到案件或使用者詳細內容",
	1425: "找不到案件詳細內容",

	// User details Error Codes (1426 - 1434)
	1426: "名字為必須",
	1427: "姓氏為必須",
	1428: "縮寫為必須",
	1429: "主要辦公室為必須",
	1430: "預設電話號碼為必須",
	1431: "語言為必須",
	1432: "預設日期格式為必須",
	1433: "預設號碼格式為必須",
	1434: "使用者明細不可為null或空白",
	1436: "偵測到衝突。若問題仍然存在，請聯絡Help Desk。",
	1438: "使用者集合不可為空白。",
	1448: 'The selected country has not been updated to the latest version of EY Canvas. Consult EY Atlas for the deployment timeline and retry after the update has occurred. If the issue persists contact the Help Desk.',

	1502: "目前無法完成作業。請重新整理頁面並重試。若問題仍然存在，請聯絡Help Desk。",
	1504: "目前無法完成作業。請重新整理頁面並重試。若問題仍然存在，請聯絡Help Desk。",
	1505: "無效之輸入值",

	// Common control errors (1601 - 1700)
	1601: "無效之比率",
	18000: "ARC進行錯誤：因ARC維修中故所請求作業無法執行。請稍後重試。",
	18306: "ARC進行錯誤：找不到連結至Canvas ArchivalGUID之ARC表單",
	18307: "ARC進行錯誤：案件已於ARC刪除。您無法進行所選選項。若需協助請聯絡您的ARC管理者。",
	18309: "ARC進行錯誤：由於已歸檔案件受法令管制，所請求作業無法執行。若需協助請聯絡您的ARC管理者。",
	18320: "ARC進行錯誤：ARC表單為保留 (暫緩)狀態且不可修改。",
	18327: "ARC進行錯誤：ARC表單為保留待刪除狀態且不可修改。",
	18328: "ARC進行錯誤：所請求動作無法執行，因案件於ARC標註為刪除。若需協助請聯絡您的ARC管理者。",
	18329: "ARC進行錯誤：此案件會計師無法存取。若需協助請聯絡您的ARC管理者。",
	18330: "ARC進行錯誤：所請求作業無法執行，由於案件受法令管制而鎖定。若需協助請聯絡您的ARC管理者。",
	2027: "錯誤請求",
	2046: "未授權",
	2047: "禁止",
	2048: "找不到紀錄",
	2049: "請求企業過大",
	2052: "不可接受",
	2053: "檢索資料時發生錯誤。請重新整理頁面並重試。若問題仍然存在，請聯絡Help Desk。",
	2056: "發生一般錯誤",
	2057: "服務無法使用",

	// Roll Forward Error Codes (2301-2400)
	2301: "向前推滾時找不到歸檔檔案",
	2302: "向前推滾時歸檔參照失敗",
	2303: "向前推滾時所退回歸檔檔案有空白唯一識別",
	2304: "向前推滾時所退回歸檔檔案有空白檔案儲存URL",
	2305: "向前推滾時所退回歸檔檔案有空白雜湊值",
	2306: "使用者未被授權執行此動作",
	2307: "已超過還原副本所允許數目",

	// Link Error Codes
	2568: "由於您非目前聯絡窗口，連結已無法使用。請重新整理頁面。",
	3005: "主查團隊已從集團案件刪除組成個體",
	2571: "此連結請求已更新。請重新整理頁面。",

	// Error for v1 engagement not supported features
	4001: "所選案件係建立於較舊版本之EY Canvas且無法還原歸檔，直到所選地點之當地資料中心已升級至最新版本之EY Canvas。更多資訊請聯絡當地Help Desk。",
	4002: "所選案件係建立於較舊版本之EY Canvas且無法請求存取權限，直到您的當地資料中心已升級至最新版本。更多資訊請聯絡當地Help Desk。",
	4214: "表單無法匯入至於所選地點之工作區。請選擇不同地點並重試",

	// Common framework error
	4205: "找不到複製請求詳細內容",
	401000: "偵測到網路變更，請重新載入頁面以繼續",

	// CopyHub concurrency scenarios
	4217: "資料狀態請求已變更。請重新整理頁面並重試。",
	4219: "Copies between different versions of EY Canvas are not supported this release due to enhancements made to the copy process. Review EY Atlas for details for the deployment schedule. After your data center has been upgraded, a copy can be made.",
	4220: "The template version is not supported for this country.",
	4221: "The template version is not supported for this country.",
	4222: "在目標位置升級到最新版本的 EY Canvas 之前，無法完成匯入。請選擇另一個位置以繼續，若錯誤仍持續，請聯絡Help Desk。",

	// Error for Mobility API
	4501: "案件中無任務",
	4502: "案件中無待辦任務",

	// Counrty API version error
	5001: "所選地點EY Canvas為較舊版本且案件無法向前推滾至新工作區，直到所選地點當地資料中心已升級至最新版本之EY Canvas。更多資訊請聯絡當地Help Desk。",

	//CRP checks
	5101: "Review copy cannot be created for this engagement because the Canvas External Access Portal is not available at this time. Contact the help desk for further information.",
	5102: "CRP副本已於此案件進行中。請稍後重試。",
	5103: "Review copy cannot be edited for this engagement because the Canvas External Access Portal is not available at this time. Contact the help desk for further information.",
	5104: "本案件無文件限制。請取消勾選選項並重試。",

	18100: "找不到服務線。",
	18101: "找不到子服務線。",

	//RBAC realted error messages.
	935: "權限無法執行此動作。請聯絡案件管理者並請求權限以執行此動作。",
	8001: "目前無法完成作業。請重新整理頁面並重試。若問題仍然存在，請聯絡Help Desk。",
	336699: "無法加載時間彙總。請重新整理頁面並重試。若錯誤仍持續，請聯絡Help Desk。",

	//Concurrency errors
	7821: 'You have already responded to this invite, refresh the page and try again.',

	/*Information Security */
	200001: 'The action taken was forbidden by EY Information Security. Refresh the page and try again. If the issue persists contact the Help Desk.'
};

export const adminErrors = {
	1332: "觸發歸檔重試時出錯",
	1501: "User is inactive. Refresh page and try again",
	156: "該模組權限不足",
	70740: "無法儲存變更。案件必須至少有一名使用中成員有權管理案件並已解決獨立性問題。若錯誤仍持續，請聯絡Help Desk。",
	70790: "不允許定義域。請重試，若錯誤仍持續，請聯絡Help Desk。",
	70800: "無效使用者邀請第三方使用者。",
	70810: "為第三方使用者選擇無效/非使用中RBAC角色。",
	70820: "為第三方使用者選擇有效的RBAC 角色。",
	70830: "為第三方使用者選擇唯讀角色。"
};

export const actionTypes = {
	1: "系統訊息",
	2: "案件邀請",
	3: "拒絕登入",
	4: "案件刪除請求",
	5: "案件建立錯誤",
	6: "案件登入請求",
	7: "刪除案件",
	8: "歸檔案件",
	9: "停用案件",
	10: "覆蓋歸檔期限日期",
	11: "集團連結請求",
	12: "已接收集團指引",
	13: "複製已接收",
	14: "案件處理中"
};
export const timephaseType = {
	'-1': "涵蓋期間結束日期",
	'-2': "報告日期",
	'-3': "歸檔截止日期",
	'1': "範圍",
	'2': "策略",
	'3': "簡易測試(Walkthroughs)",
	'4': "期中控制測試",
	'5': "期中證實測試",
	'6': "簡易測試(Walkthroughs)(年末流程)",
	'7': "年末控制測試",
	'8': "年末證實測試",
	'9': "結論",
	'10': "不適用",
	'11': "執行",
	'12': "範圍與策略品質活動",
	'13': "其他範圍與策略",
	'14': "CRA",
	'15': "風險評估及重大性",
	'16': "總結範圍與策略",
	'17': "期中過後案件管理"
};
export const dataExport = [
	"歸檔表單",
	"Canvas表單",
	"Collaboration",
	"控制表單",
	"文件",
	"任務",
	"團隊成員",
	"試算表"
];
export const dataExportReportNames = {
	'歸檔表單': 'Archive Form',
	'Canvas表單': 'Canvas Form Report',
	'Collaboration': 'Collaboration',
	'控制表單': 'Control Report',
	'文件': 'All Documents Report',
	'任務': 'All Tasks Report',
	'團隊成員': 'Team Members',
	'試算表': 'Numbers Report'
};
export const projectManagement = [
	"合作詳細內容",
	"Digital scorecard",
	"里程碑",
	"複核註記",
	"驗證檢查"
];
export const projectManagementReportNames = {
	'合作詳細內容': 'Client Collaboration Dashboard',
	'里程碑': 'Milestone Report',
	'複核註記': 'Review Notes Report',
	'驗證檢查': 'Validation Checks Report'
};
export const summaryScreenDefaults = [
	"查核計畫報告",
	"查核計畫相關證據",
	"Controls Review Tool (CRT)",
	"CRA彙總",
	"發現",
	"IT App & SO彙總",
	"SCOT彙總",
	"SEM"
];
export const summaryScreenDefaultsReportNames = {
	'查核計畫報告': 'Audit Plan Report',
	'查核計畫相關證據': 'Audit Plan with Related Evidence',
	'Controls Review Tool (CRT)': 'Controls Review Tool (CRT)',
	'CRA彙總': 'CRA Summary',
	'發現': 'Findings',
	'IT App & SO彙總': 'ITSO CRT',
	'SCOT彙總': 'SCOT Summary',
	'SEM': 'SEM'
};
export const summaryScreenInterim = [
	"工作計畫報告",
	"工作計畫相關證據"
];
export const summaryScreenInterimReportNames = {
	'工作計畫報告': 'Audit Plan Report',
	'工作計畫相關證據': 'Audit Plan with Related Evidence'
};
export const summaryScreenGroupAudit = [
	"查核計畫報告",
	"查核計畫相關證據",
	"Controls Review Tool (CRT)",
	"CRA彙總",
	"發現",
	"集團指引彙總",
	"集團報表",
	"集團架構彙總",
	"IT App & SO彙總",
	"SCOT彙總",
	"SEM"
];
export const summaryScreenGroupAuditReportNames = {
	'查核計畫報告': 'Audit Plan Report',
	'查核計畫相關證據': 'Audit Plan with Related Evidence',
	'Controls Review Tool (CRT)': 'Controls Review Tool (CRT)',
	'CRA彙總': 'CRA Summary',
	'發現': 'Findings',
	'集團指引彙總': 'Group Instruction Summary',
	'集團報表': 'Group Report',
	'集團架構彙總': 'Group Structure Summary',
	'IT App & SO彙總': 'ITSO CRT',
	'SCOT彙總': 'SCOT Summary',
	'SEM': 'SEM'
};
export const summaryScreenGroupAuditInterim = [
	"集團指引彙總",
	"集團架構彙總",
	"工作計畫報告",
	"工作計畫相關證據"
];
export const summaryScreenGroupAuditInterimReportNames = {
	'集團指引彙總': 'Group Instruction Summary',
	'集團架構彙總': 'Group Structure Summary',
	'工作計畫報告': 'Audit Plan Report',
	'工作計畫相關證據': 'Audit Plan with Related Evidence'
};
export const rolelistitems = [{
	id: 8,
	label: "組員(Staff)"
},
{
	id: 7,
	label: "組長(Senior)"
},
{
	id: 6,
	label: "經理(Manager)"
},
{
	id: 5,
	label: "資深經理(Senior Manager)"
},
{
	id: 3,
	label: "執行總監(Executive Director)"
},
{
	id: 4,
	label: "負責人(Principal)"
},
{
	id: 11,
	label: "其他會計師(Other Partner)"
},
{
	id: 2,
	label: "案件會計師(Engagement Partner)"
},
{
	id: 1,
	label: "主辦會計師(Partner in Charge)"
},
{
	id: 10,
	label: "案件品質複核者(EQR)"
},
{
	id: 17,
	label: "查核品質複核者(AQR)"
},
{
	id: 16,
	label: "總顧問(General Counsel)"
},
{
	id: 12,
	label: "GDS - Staff"
},
{
	id: 30,
	label: "GDS - Senior"
},
{
	id: 31,
	label: "GDS - Manager"
}
];
export const roleType = {
	'11': "其他會計師(Other Partner)",
	'24': "其他(例如：盡職調查複核(due diligence review))",
	'15': "執行支援服務(Executive Support Services)",
	'18': "ML組成個體團隊",
	'17': "查核品質複核者(AQR)",
	'1': "主辦會計師(Partner in Charge)",
	'2': "案件會計師(Engagement Partner)",
	'9': "實習生(Intern)",
	'13': "Advisory (FAIT, TAS, Human Capital or Other)",
	'14': "Tax",
	'27': "Industry",
	'10': "案件品質複核者(EQR)",
	'25': "辦公室",
	'8': "組員(Staff)",
	'20': "客戶組員(Client Staff)",
	'19': "客戶主管(Client Supervisor)",
	'5': "資深經理(Senior Manager)",
	'16': "總顧問(General Counsel)",
	'26': "地區",
	'23': "主管機關(Regulator)",
	'22': "內部稽核組員(Internal Audit Staff)",
	'3': "執行總監(Executive Director)",
	'21': "內部稽核主管(Internal Audit Supervisor)",
	'6': "經理(Manager)",
	'4': "負責人(Principal)",
	'7': "組長(Senior)",
	'12': "Global Talent Hub",
	'28': "National",
	'29': "Global",
	'12': "GDS - Staff",
	'30': "GDS - Senior",
	'31': "GDS - Manager"
};

export const copyObjectGroupType = {
	1: "案件",
	2: "科目",
	3: "SCOTs",
	4: "IT應用程式及服務機構",
	5: "請求",
	6: "證據",
	7: "數字",
	8: "請求及外部任務",
	9: "暫存檔案",
	10: "前期證據",
	11: "最近刪除檔案",
	12: "歷史檔案",
	13: "案件範本",
	14: "還原GCO複本",
	15: "外部複核複本",
	16: "使用中外部複核案件",
	17: "Canvas表單",
	20: "Evidence",
	21: "Engagement",
	22: "Accounts",
	23: "SCOTs",
	24: "Engagement roll forward",
	27: "External Evidences",
	28: "Guided Workflows",
	29: 'Engagement by Entity'
};

export const EngagementStatusText = [
	"未定義",
	"使用中",
	"已暫停",
	"已歸檔",
	"已刪除",
	"歸檔處理中",
	"已重新啟用",
	"已還原",
	"標記刪除",
	"歸檔錯誤",
	"後推錯誤",
	"重新啟用錯誤",
	"還原錯誤",
	"後推處理中",
	"重新啟用處理中",
	"還原處理中",
	"GamxMigration處理中",
	"GamxMigration錯誤",
	"建立交易擱置中",
	"建立交易失敗",
	"更新交易擱置中",
	"更新交易失敗",
	"複製處理中",
	"複製錯誤",
	"複製範本錯誤",
	"外部複核案件處理中",
	"外部複核案件錯誤",
	"外部複核案件"
];
export const overdueIssues = {
	partnerInvitationPending: "會計師尚未進入案件",
	eqrInvitationPending: "EQR尚未進入案件",
	invalidEngagementCode: "無效案件編號",
	overdueArchiveDeadline: "歸檔截止日期逾期",
	upcomingArchiveDeadline: "歸檔截止日即將到來",
	contentDeliveryInProgress: "內容傳輸處理中",
	overdueMilestones: "團隊里程碑逾期",
	overduePartnerTasks: "會計師任務逾期",
	overdueEqrTasks: "EQR任務逾期"
};
export const engagementMetricNames = [{
	propName: 'preparer',
	value: "供編製"
},
{
	propName: 'forMyReview',
	value: "供複核"
},
{
	propName: 'upcomingReview',
	value: "即將到來複核"
},
{
	propName: 'reviewNotesAssigned',
	value: "註記給我"
},
{
	propName: 'reviewNotesAuthored',
	value: "由我註記"
},
{
	propName: 'clientRequests',
	value: "請求"
},
{
	propName: 'timephases',
	value: "時程"
},
{
	propName: 'groupTasks',
	value: "集團任務"
},
{
	propName: 'groupDeliverables',
	value: "集團交付項目"
},
{
	propName: 'automationDocuments',
	value: "自動化"
}
];
export const metricFilterComingDue = [{
	value: 1,
	label: "明天到期"
},
{
	value: 3,
	label: "3天後到期"
},
{
	value: 5,
	label: "5天後到期",
	isDefault: true
},
{
	value: 10,
	label: "10天後到期"
},
{
	value: 30,
	label: "30天後到期",
	isMax: true
}
];
export const metricFilterWhatsNew = [{
	value: 1,
	label: "昨天更新"
},
{
	value: 3,
	label: "前三天更新"
},
{
	value: 5,
	label: "前五天更新",
	isDefault: true
},
{
	value: 10,
	label: "前十天更新",
	isMax: true
}
];

export const type1Alerts = [
	"hasPartnerInvited",
	"hasEqrInvited",
	"hasValidEngagementCode",
	"overdueArchiveDeadline"
];

export const engagementStatuses = {
	1: "使用中",
	2: "已暫停",
	3: "已歸檔",
	4: "已刪除",
	5: "歸檔處理中",
	6: "已重新啟用",
	7: "已還原",
	8: "標記刪除",
	9: "歸檔錯誤",
	10: "後推錯誤",
	11: "重新啟用錯誤",
	12: "還原錯誤",
	13: "後推處理中",
	14: "重新啟用處理中",
	15: "還原處理中",
	16: "GAMX Migration處理中",
	17: "GAMX Migration錯誤",
	18: "建立交易擱置中",
	19: "建立交易失敗",
	20: "更新交易擱置中",
	21: "更新交易失敗",
	22: "複製處理中",
	23: "複製錯誤",
	24: "複製範本錯誤",
	25: "外部複核案件處理中",
	26: "外部複核案件錯誤",
	27: "外部複核案件",
	28: "隔離通用在地化",
	29: "隔離通用在地化歸檔",
	30: "內容傳輸處理中",
	31: "內容傳輸錯誤",
	32: "內容傳輸失敗",
	33: "Canvas 外部訪問者 V2",
	34: "Canvas 外部訪問者 V2 正在進行中",
	35: "Canvas 外部訪問者 V2 錯誤",
	100: "Datacenter Split"
};

export const overrideArchiveDeadlineDateOptions = [{
	value: 0,
	label: "預設"
},
{
	value: 10,
	label: "10天"
},
{
	value: 14,
	label: "14天"
},
{
	value: 20,
	label: "20天"
},
{
	value: 21,
	label: "21天"
},
{
	value: 25,
	label: "25天"
},
{
	value: 30,
	label: "30天"
},
{
	value: 45,
	label: "45天"
}
];
export const ExternalReviewCopyExpiryDays = [{
	value: 30,
	label: "30天"
},
{
	value: 60,
	label: "60天"
},
{
	value: 90,
	label: "90天"
},
{
	value: 180,
	label: "180天"
}
];

export const serviceLines = [{
	servicelineid: 1,
	servicelinename: "Assurance"
},
{
	servicelineid: 3,
	servicelinename: "Strategy and Transactions"
},
{
	servicelineid: 2,
	servicelinename: "Consulting"
},
{
	servicelineid: 4,
	servicelinename: "Tax"
},
{
	servicelineid: 5,
	servicelinename: "GCO"
}
];

export const subServiceLines = [{
	subservicelineid: 1,
	servicelineid: 1,
	subservicelinename: "Audit"
},
{
	subservicelineid: 2,
	servicelineid: 1,
	subservicelinename: "FIDS"
},
{
	subservicelineid: 3,
	servicelineid: 2,
	subservicelinename: "ITRA"
},
{
	subservicelineid: 9,
	servicelineid: 1,
	subservicelinename: "FAAS"
},
{
	subservicelineid: 10,
	servicelineid: 1,
	subservicelinename: "ACR"
},
{
	subservicelineid: 11,
	servicelineid: 1,
	subservicelinename: "CCaSS"
},
{
	subservicelineid: 4,
	servicelineid: 3,
	subservicelinename: "Capital Transformation"
},
{
	subservicelineid: 5,
	servicelineid: 3,
	subservicelinename: "Transaction Support"
},
{
	subservicelineid: 6,
	servicelineid: 2,
	subservicelinename: "Performance Improvement"
},
{
	subservicelineid: 7,
	servicelineid: 2,
	subservicelinename: "Risk"
},
{
	subservicelineid: 7,
	servicelineid: 23,
	subservicelinename: "Business Consulting"
},
{
	subservicelineid: 24,
	servicelineid: 6,
	subservicelinename: "Other"
},
{
	subservicelineid: 8,
	servicelineid: 2,
	subservicelinename: "Other"
},
{
	subservicelineid: 12,
	servicelineid: 4,
	subservicelinename: "BTS"
},
{
	subservicelineid: 13,
	servicelineid: 4,
	subservicelinename: "Human Capital"
},
{
	subservicelineid: 14,
	servicelineid: 4,
	subservicelinename: "Law"
},
{
	subservicelineid: 15,
	servicelineid: 4,
	subservicelinename: "Indirect Tax"
},
{
	subservicelineid: 16,
	servicelineid: 4,
	subservicelinename: "GCR"
},
{
	subservicelineid: 17,
	servicelineid: 4,
	subservicelinename: "Transaction Tax"
},
{
	subservicelineid: 18,
	servicelineid: 4,
	subservicelinename: "ITS"
},
{
	subservicelineid: 19,
	servicelineid: 5,
	subservicelinename: "GCO"
},
{
	subservicelineid: 20,
	servicelineid: 2,
	subservicelinename: "Business Consulting"
},
{
	subservicelineid: 21,
	servicelineid: 2,
	subservicelinename: "Technology Consulting"
},
{
	subservicelineid: 22,
	servicelineid: 3,
	subservicelinename: "Transactions and Corporate Finance"
},
{
	subservicelineid: 23,
	servicelineid: 3,
	subservicelinename: "EY Parthenon"
},
{
	subservicelineid: 25,
	servicelineid: 1,
	subservicelinename: "Technology Risk"
}
];

export const modules = [{
	id: 1,
	name: "Manage access"
},
{
	id: 2,
	name: "Engagements"
},
{
	id: 3,
	name: "Permission Groups"
},
{
	id: 4,
	name: "Templates"
},
{
	id: 5,
	name: "Messaging"
},
{
	id: 6,
	name: "快取"
},
{
	id: 7,
	name: "資料庫定位器"
},
{
	id: 8,
	name: "文件"
},
{
	id: 9,
	name: "SharePoint workspaces"
}
];

export const roles = [{
	id: 17,
	ModuleId: 1,
	name: "Read only"
},
{
	id: 18,
	ModuleId: 1,
	name: "Add/remove user and permissions"
},
{
	id: 19,
	ModuleId: 2,
	name: "Read only"
},
{
	id: 20,
	ModuleId: 2,
	name: "Update engagement"
},
{
	id: 21,
	ModuleId: 2,
	name: "Invite team member"
},
{
	id: 22,
	ModuleId: 2,
	name: "Update engagement user"
},
{
	id: 23,
	ModuleId: 3,
	name: "Read only"
},
{
	id: 24,
	ModuleId: 3,
	name: "Add/remove member"
},
{
	id: 25,
	ModuleId: 3,
	name: "Sync DatabaseLocator"
},
{
	id: 26,
	ModuleId: 4,
	name: "Read only"
},
{
	id: 27,
	ModuleId: 4,
	name: "Update template"
},
{
	id: 28,
	ModuleId: 4,
	name: "Delete template"
},
{
	id: 29,
	ModuleId: 5,
	name: "Read only"
},
{
	id: 30,
	ModuleId: 5,
	name: "Create/update message"
},
{
	id: 31,
	ModuleId: 5,
	name: "Delete message"
},
{
	id: 32,
	ModuleId: 6,
	name: "閱讀及刪除快取"
},
{
	id: 33,
	ModuleId: 8,
	name: "訪問文件"
},
{
	id: 34,
	ModuleId: 2,
	name: "歸檔重試"
},
{
	id: 38,
	ModuleId: 9,
	name: "Read only"
},
{
	id: 39,
	ModuleId: 9,
	name: "Add workspaces"
},
{
	id: 40,
	ModuleId: 9,
	name: "Delete workspaces"
}
];

export const countries = {
	1: "Mayotte",
	2: "British Virgin Islands",
	3: "Spain",
	4: "Belize",
	5: "Peru",
	6: "Slovakia",
	7: "Venezuela",
	8: "Norway",
	9: "Falkland Islands (Malvinas)",
	10: "Mozambique",
	11: "Mainland China",
	12: "Sudan",
	13: "Israel",
	14: "Belgium",
	15: "Saudi Arabia",
	16: "Gibraltar",
	17: "Guam",
	18: "Norfolk Islands",
	19: "Zambia",
	20: "Reunion",
	21: "Azerbaijan",
	22: "Saint Helena",
	23: "Iran",
	24: "Monaco",
	25: "Saint Pierre and Miquelon",
	26: "New Zealand",
	27: "Cook Islands",
	28: "Saint Lucia",
	29: "Zimbabwe",
	30: "Iraq",
	31: "Tonga",
	32: "American Samoa",
	33: "Maldives",
	34: "Morocco",
	35: "International Standards on Auditing (ISA)",
	36: "Albania",
	37: "Afghanistan",
	38: "Gambia",
	39: "Burkina Faso",
	40: "Tokelau",
	41: "Libya",
	42: "Canada",
	43: "United Arab Emirates",
	44: "Korea, Democratic People's Republic of",
	45: "Montserrat",
	46: "Greenland",
	47: "Rwanda",
	48: "Fiji",
	49: "Djibouti",
	50: "Botswana",
	51: "Kuwait",
	52: "Madagascar",
	53: "Isle of Man",
	54: "Hungary",
	55: "Namibia",
	56: "Malta",
	57: "Jersey",
	58: "Thailand",
	59: "Saint Kitts and Nevis",
	60: "Bhutan",
	61: "Panama",
	62: "Somalia",
	63: "Bahrain",
	64: "Bosnia and Herzegovina",
	65: "France",
	66: "Korea, Republic of",
	67: "Iceland",
	68: "Portugal",
	69: "Tunisia",
	70: "Ghana",
	71: "Cameroon",
	72: "Greece",
	73: "French Southern Territories",
	74: "Heard and McDonald Islands",
	75: "Andorra",
	76: "Luxembourg",
	77: "Samoa",
	78: "Anguilla",
	79: "Netherlands",
	80: "Guinea-Bissau",
	81: "Nicaragua",
	82: "Paraguay",
	83: "Antigua and Barbuda",
	84: "International Financial Reporting Standard (IFRS)",
	85: "Niger",
	86: "Egypt",
	87: "Vatican City State",
	88: "Latvia",
	89: "Cyprus",
	90: "US Minor Outlying Islands",
	91: "Russia Other Legal Entities",
	92: "Saint Vincent and the Grenadines",
	93: "Guernsey",
	94: "Burundi",
	95: "Cuba",
	96: "Equatorial Guinea",
	97: "British Indian Ocean Territory",
	98: "Sweden",
	99: "Uganda",
	100: "North Macedonia",
	101: "Swaziland",
	102: "El Salvador",
	103: "Kyrgyzstan",
	104: "Ireland",
	105: "Kazakhstan",
	106: "Honduras",
	107: "Uruguay",
	108: "Georgia",
	109: "Trinidad and Tobago",
	110: "Palestinian Authority",
	111: "Martinique",
	112: "Aland Islands",
	113: "French Polynesia",
	114: "Ivory Coast",
	115: "Montenegro",
	116: "South Africa",
	117: "South Georgia and the South Sandwich Islands",
	118: "Yemen",
	119: "Hong Kong",
	120: "Kenya",
	121: "Chad",
	122: "Colombia",
	123: "Costa Rica",
	124: "Angola",
	125: "Lithuania",
	126: "Syria",
	127: "Malaysia",
	128: "Sierra Leone",
	129: "Serbia",
	130: "Poland",
	131: "Suriname",
	132: "Haiti",
	133: "Nauru",
	134: "Sao Tome and Principe",
	135: "Svalbard and Jan Mayen",
	136: "Singapore",
	137: "Moldova",
	138: "Taiwan",
	139: "Senegal",
	140: "Gabon",
	141: "Mexico",
	142: "Seychelles",
	143: "Micronesia, Federated States of",
	144: "Algeria",
	145: "Italy",
	146: "San Marino",
	147: "Liberia",
	148: "Brazil",
	149: "Croatia",
	150: "Faroe Islands",
	151: "Palau",
	152: "Finland",
	153: "Philippines",
	154: "Jamaica",
	155: "French Guiana",
	156: "Cape Verde",
	157: "Myanmar",
	158: "Lesotho",
	159: "US Virgin Islands",
	160: "Cayman Islands",
	161: "Niue",
	162: "Togo",
	163: "Belarus",
	164: "Dominica",
	165: "Indonesia",
	166: "Uzbekistan",
	167: "Nigeria",
	168: "Wallis and Futuna",
	169: "Barbados",
	170: "Sri Lanka",
	171: "United Kingdom",
	172: "Ecuador",
	173: "Guadeloupe",
	174: "Laos",
	175: "Jordan",
	176: "Solomon Islands",
	177: "East Timor",
	178: "Lebanon",
	179: "Central African Republic",
	180: "India",
	181: "Christmas Island",
	182: "Vanuatu",
	183: "Brunei",
	184: "Bangladesh",
	185: "Antarctica",
	186: "Bolivia",
	187: "Turkey",
	188: "Bahamas",
	189: "Comoros",
	190: "Western Sahara",
	191: "Czech Republic",
	192: "Ukraine",
	193: "Estonia",
	194: "Bulgaria",
	195: "Mauritania",
	196: "Congo, The Democratic Republic of the",
	197: "Liechtenstein",
	198: "Pitcairn",
	199: "Denmark",
	200: "Marshall Islands",
	201: "Japan",
	202: "Austria",
	203: "Oman",
	204: "Mongolia",
	205: "Tajikistan",
	206: "Switzerland",
	207: "Guatemala",
	208: "Eritrea",
	209: "Nepal",
	210: "Mali",
	211: "Slovenia",
	212: "Northern Mariana Islands",
	213: "(Not Applicable)",
	214: "Aruba",
	215: "Congo",
	216: "Qatar",
	217: "Guinea",
	218: "United States",
	219: "Ethiopia",
	220: "Other",
	221: "Guyana",
	222: "Germany",
	223: "Bermuda",
	224: "Turks and Caicos Islands",
	225: "Australia",
	226: "Kiribati",
	227: "Puerto Rico",
	228: "Pakistan",
	229: "Mauritius",
	230: "Malawi",
	231: "Turkmenistan",
	232: "Cambodia",
	233: "Chile",
	234: "New Caledonia",
	235: "Papua New Guinea",
	236: "Bouvet Island",
	237: "Tuvalu",
	238: "Curacao",
	239: "Dominican Republic",
	240: "Vietnam",
	241: "Cocos (Keeling) Islands",
	242: "Grenada",
	243: "Tanzania",
	244: "Argentina",
	245: "Macao",
	246: "Benin",
	247: "Romania",
	248: "Armenia",
	249: "global",
	250: "IFRS for SMEs",
	251: "US GAAP",
	252: "AICPA financial reporting framework for small and medium sized entities",
	253: "South Sudan",
	254: "Kosovo",
	255: "United States - Government",
	256: "Russia OOO EY & OOO EY Vneshaudit",
	257: "Armenia-RussiaDC",
	258: "Belarus-RussiaDC",
	259: "Uzbekistan-RussiaDC"
};

export const common = {
	selected: "Selected",
	cancel: "Cancel",
	checkbox: {
		selectAllOn: "Select all",
		selectAllOff: "Unselect all"
	},
	clear: "Clear",
	close: "Close",
	pagination: {
		show: "顯示",
		iconHover: {
			first: "First Page",
			previous: "Previous Page",
			next: "Next Page",
			last: "Last Page"
		}
	},
	engv2Pagination: {
		show: "顯示",
		iconHover: {
			first: "第一頁",
			previous: "上一頁",
			next: "下一頁",
			last: "最後一頁"
		}
	},
	search: {
		clear: "Clear search",
		buttonTitle: "Search",
		placeholder: "Search",
		noResultsPlaceholder: "No results found"
	},
	table: {
		remove: "Remove",
		noDataPlaceholder: "No data available",
		noResultsPlaceholder: "No results found"
	},
	toggleSwitch: {
		no: "No",
		yes: "Yes"
	},
	wizard: {
		next: "Continue",
		back: "Back",
		finish: "Finish"
	}
};

export const ConfidentialityTypes = [{
	id: 0,
	Name: "Default"
},
{
	id: 10,
	Name: "Low"
},
{
	id: 12,
	Name: "Moderate"
},
{
	id: 11,
	Name: "High"
}
];

export const internalUserRoles = [{
	roleKey: 3,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permissions",
	roleNameUI: "Archive engagement",
	displayName: "Archive engagement",
	roleDisplayOrder: 2,
	tooltipText: "Ability to archive the engagement"
},
{
	roleKey: 4,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permissions",
	roleNameUI: "Administer engagement",
	displayName: "Administer engagement",
	roleDisplayOrder: 1,
	tooltipText: "Ability to invite internal and external members and assign permissions to internal members"
},
{
	roleKey: 5,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permissions",
	roleNameUI: "Update content",
	displayName: "Update content",
	roleDisplayOrder: 6,
	tooltipText: "Ability to edit the profile and accept a content update"
},
{
	roleKey: 7,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permissions",
	roleNameUI: "Create service request",
	displayName: "Manage service requests",
	roleDisplayOrder: 4,
	tooltipText: "Ability to create service requests and mark service requests complete"
},
{
	roleKey: 8,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permissions",
	roleNameUI: "Manage group",
	displayName: "Manage group",
	roleDisplayOrder: 5,
	tooltipText: "Accept or reject group instructions received from the Primary or Regional team."
},
{
	roleKey: 9,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permissions",
	roleNameUI: "Copy, Roll-forward, Restore engagements",
	displayName: "Copy, Roll-forward, Restore engagements",
	roleDisplayOrder: 3,
	tooltipText: "能夠複製案件、後推和還原其歸檔"
},
{
	roleKey: 0,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "Confidentiality",
	roleNameUI: "Default",
	displayName: "None (Default)",
	roleDisplayOrder: 1,
	tooltipText: "Default Confidential Documents Role"
},
{
	roleKey: 10,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "Confidentiality",
	roleNameUI: "Low",
	displayName: "Low",
	roleDisplayOrder: 8,
	tooltipText: "Low Confidential Documents Role"
},
{
	roleKey: 11,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "Confidentiality",
	roleNameUI: "High",
	displayName: "High",
	roleDisplayOrder: 10,
	tooltipText: "High Confidential Documents Role"
},
{
	roleKey: 12,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "Confidentiality",
	roleNameUI: "Moderate",
	displayName: "Moderate",
	roleDisplayOrder: 9,
	tooltipText: "Moderate Confidential Documents Role"
},
// {
// roleKey: 13,
// sectionId:1,
// groupDisplayOrder: 200,
// groupName:"Confidentiality",
// roleNameUI: "Confidential",
// displayName: "Confidential",
// roleDisplayOrder: 11,
// tooltipText: 'Strictly Confidential Documents Role'
// },
{
	roleKey: 14,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "Restrictions",
	roleNameUI: "Read-only",
	displayName: "Read-only",
	roleDisplayOrder: 12,
	tooltipText: "This functionality should only be used to grant third-party reviewers to this EY Canvas engagement. Appropriate approvals and authorization should be acquired from Professional Practice."
},
{
	roleKey: 16,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "Restrictions",
	roleNameUI: "Review only",
	displayName: "Review only",
	roleDisplayOrder: 13,
	tooltipText: "Review only gives this user the ability to view information in the Canvas engagement, sign-off, and use the review note functionality. This should be used when other EY users need to review this engagement (e.g., Group Audit Primary Team) but not be able to make edits.",
},
{
	roleKey: 37,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "Restrictions",
	roleNameUI: "Independence only",
	displayName: "Independence only",
	roleDisplayOrder: 14,
	tooltipText: "Independence only gives this user the ability to complete their independence form for the engagement, but will not allow user to fully access the EY Canvas engagement. This should be used when a user has performed work for the engagement, but does not need to have access to EY Canvas."
},
{
	roleKey: 6,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permissions",
	roleNameUI: "Undo edits",
	displayName: "Roll-back documents",
	roleDisplayOrder: 7,
	tooltipText: "Ability to roll-back documents if changes were accidentally made after the report release date and remove them from the 440 Canvas Form."
},
{
	roleKey: 35,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "權限",
	roleNameUI: "管理 EY Canvas FIT enablement的共享",
	displayName: "管理引導式工作流程",
	roleDisplayOrder: 14,
	tooltipText: "能夠在 EY Canvas 案件之間共享引導式工作流程"
},
{
	roleKey: 36,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "權限",
	roleNameUI: "匯入 EY Helix 資料",
	displayName: "匯入 EY Helix 資料",
	roleDisplayOrder: 15,
	tooltipText: "能夠從 EY Helix 匯入資料"
}
];

export const restoreengagementexpirydates = [{
	value: 3,
	label: "3個月"
},
{
	value: 6,
	label: "6個月"
},
{
	value: 9,
	label: "9個月"
},
{
	value: 12,
	label: "12個月"
}
];

export const messageStatus = {
	0: "目前的",
	1: "預定的",
	2: "已到期"
};

export const kendoLabels = {
	alignLeft: "靠左對齊",
	alignRight: "靠右對齊",
	alignCenter: "置中對齊",
	alignFull: "左右對齊",
	addComment: "新增註解",
	addColumnBefore: "新增左方欄",
	addColumnAfter: "新增右方欄",
	addRowAbove: "新增上方列",
	addRowBelow: "新增下方列",
	bulletList: "插入排序清單",
	bold: "粗體",
	backColor: "醒目提示",
	createLink: "插入超連結",
	createTable: "建立表格",
	cleanFormatting: "清除格式",
	deleteRow: "刪除列",
	deleteColumn: "刪除欄",
	deleteTable: "刪除表格",
	fontSizeInherit: "字體大小",
	foreColor: "顏色",
	format: "字型",
	fontSize: "字體大小",
	hyperlink: "插入網頁連結",
	italic: "斜體",
	indent: "縮排",
	insertTableHint: "建立一個{0}使用{1}表格",
	"hyperlink-dialog-content-address": "網址",
	"hyperlink-dialog-title": "插入超連結",
	"hyperlink-dialog-content-title": "標題",
	"hyperlink-dialog-content-newwindow": "在新視窗開啟連結",
	"hyperlink-dialog-cancel": "取消",
	"hyperlink-dialog-insert": "插入",
	outdent: "凸排",
	orderedList: "插入未排序清單",
	print: "列印",
	pdf: "匯出至pdf",
	redo: "重做",
	strikethrough: "刪除線",
	subscript: "下標",
	superscript: "上標",
	underline: "底線",
	undo: "復原"
};

export const kendoFormatOptions = [{
	text: "段落",
	value: "p"
},
{
	text: "標題 1",
	value: "h1"
},
{
	text: "標題 2",
	value: "h2"
},
{
	text: "標題 3",
	value: "h3"
},
{
	text: "標題 4",
	value: "h4"
},
{
	text: "標題 5",
	value: "h5"
},
{
	text: "標題 6",
	value: "h6"
}
];

export const kendoFontSize = [{
	text: '8',
	value: "8px"
},
{
	text: '9',
	value: "9px"
},
{
	text: '10',
	value: "10px"
},
{
	text: '11',
	value: "11px"
},
{
	text: '12',
	value: "12px"
},
{
	text: '14',
	value: "14px"
},
{
	text: '16',
	value: "16px"
},
{
	text: '18',
	value: "18px"
},
{
	text: '20',
	value: "20px"
},
{
	text: '22',
	value: "22px"
},
{
	text: '24',
	value: "24px"
},
{
	text: '26',
	value: "26px"
},
{
	text: '28',
	value: "28px"
},
{
	text: '36',
	value: "36px"
},
{
	text: '48',
	value: "48px"
},
{
	text: '72',
	value: "72px"
}
];

export const cacheOptionValue = [{
	value: 1,
	label: "LDC"
},
{
	value: 2,
	label: "Universal"
}
]
