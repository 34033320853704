import React from 'react';
import {NavLink} from 'react-router-dom';
import MotifButton from '@ey/motif-react-wrapper/Button';
import MotifIcon from '@ey/motif-react-wrapper/Icon';
import {
	IconoirHome,
	IconoirCustomListStack,
	IconoirClock,
	IconoirUnity
} from '@ey-xd/motif-icon';
import {labels} from '../../../util/uiconstants';
import _ from 'lodash';

export function MenuIconOptions(featureConfigurations) {
	let menuOptions = [];

	const isTimeTrackingEnabled = featureConfigurations.istimetrackingenabled;
	const isServiceGatewayBaseUri = !_.isEmpty(
		featureConfigurations.servicegatewaybaseuri
	);
	const serviceGatewayBaseUri = featureConfigurations.servicegatewaybaseuri;

	menuOptions.push(
		{
			key: 'LandingPage',
			className: 'LandingPage',
			isLink: false,
			children: (
				<MotifButton
					aria-label="Click to go to dashboard"
					type="button"
					variant="ghost"
					size="medium"
					title={labels.engagements}
					className="motif-button-icon-only"
				>
					<NavLink to={'/'} title={labels.engagements}>
						<MotifIcon
							src={IconoirHome}
							size="24"
							title={labels.engagements}
						/>
					</NavLink>
				</MotifButton>
			)
		},
		{
			key: 'topNavEyUserArchives',
			isLink: false,
			children: (
				<MotifButton
					aria-label="Click to go to archives"
					type="button"
					variant="ghost"
					size="medium"
					title={labels.archives}
					className="motif-button-icon-only"
				>
					<NavLink to="/archives">
						<MotifIcon
							src={IconoirCustomListStack}
							className="archives-icon"
							size="24"
							title={labels.archives}
						/>
					</NavLink>
				</MotifButton>
			)
		}
	);

	if (isTimeTrackingEnabled) {
		menuOptions.push({
			key: 'topNavEyUserTimeTracking',
			isLink: false,
			children: (
				<MotifButton
					aria-label="Click to go to time tracking"
					type="button"
					variant="ghost"
					size="medium"
					title={labels.timeTracking}
					className="motif-button-icon-only"
				>
					<NavLink to="/timesheet" title={labels.timeTracking}>
						<MotifIcon
							src={IconoirClock}
							size="24"
							title={labels.timeTracking}
						/>
					</NavLink>
				</MotifButton>
			)
		});
	}

	if (isServiceGatewayBaseUri) {
		menuOptions.push({
			key: 'topNavEyUserServiceGateway',
			isLink: false,
			children: (
				<MotifButton
					aria-label="Click to go to service gateway"
					type="button"
					variant="ghost"
					size="medium"
					title={labels.serviceGateway}
					className="motif-button-icon-only"
				>
					<a href={serviceGatewayBaseUri} title={labels.serviceGateway}>
						<MotifIcon
							src={IconoirUnity}
							size="24"
							title={labels.serviceGateway}
						/>
					</a>
				</MotifButton>
			)
		});
	}
	return menuOptions;
}
