import * as types from '../actiontypes';

export function getAllActiveDataCenters(url) {
	return {
		type: types.GET_ALL_DAAS_WORKSPACES,
		meta: {
			api: {
				url: url,
				method: 'GET'
			}
		}
	};
}
//Todo: Need to modify this based on how we pass the dataCenterId when the API ready.
export function getWorkspacesDetails(url,dataCenterId) {
	return {
		type: types.GET_ALL_WORKSPACE_DETAILS,
		meta: {
			api: {
				url: url,
				method: 'GET',
				data:{dataCenterId}
			}
		}
	};
}

export function resetsharepointWorkSpaces(){
	return {
		type: types.RESET_SHAREPOINT_WORKSPACES,
	}
}