import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Card from '@ey/motif-react-wrapper/Card';
import Icon from '@ey/motif-react-wrapper/Icon';
import { SVGICONS } from '@ey/icons';
import styled from 'styled-components';
import { labels, successMessageDisplayDuration } from '../../../util/uiconstants';
import { Link } from 'react-router-dom';
import { getMeCall } from '../../../actions/admin/mepermissionsactions';
import AccessDeniedMessage from '../../Common/AccessDeniedMessage/AccessDeniedMessage';
import {
	meCallPermissions,
	engagementsSearchRetainFlag
} from '../../../util/uiconstants';
import env from '../../../util/env';
import { postSyncDatabaseLocator } from '../../../actions/admin/databaselocatoractions';
import { getLocalizedAPIError } from '../common/util';
import { setIsModalOpen } from '../../../actions/modalstateactions';
import { clearErrors } from '../../../actions/erroractions';
import Toast from '../../Common/Toast/Toast';
import { notificationIcSync24px } from '@ey/motif-react-wrapper/assets/icons/index';
import MotifIcon from '@ey/motif-react-wrapper/Icon';
import {
	IconoirFolder,
	IconoirGoogleDocs,
	IconoirCustomUserAdmin,
	IconoirGroup,
	IconoirMessageText,
	IconoirCustomUserManage,
	IconoirUserBadgeCheck,
	IconoirRefreshDouble,
	IconoirSuitcase,
	IconoirMultiplePages,
	IconoirCustomDocumentCompleted
} from '@ey-xd/motif-icon';

const Dashboardwrapper = styled.section`
	padding: 0 var(--px-34) var(--px-30) var(--px-34);

	&.wrapper {
		position: relative;
		.toast {
			position: absolute;
			width: calc(100% - var(--px-68));
		}
	}

	.synclocator {
		display: flex;
		margin-top: var(--px-20);

		h2 {
			font-size: var(--px-24);
			margin: 0;
			margin-right: auto;
		}

		.titleholder {
			text-align: right;
			line-height: var(--px-29);
			a {
				cursor: pointer;
				&:hover {
					text-decoration: none;
				}

				.title {
					font-size: var(--px-16);
					font-weight: bold;
					color: var(--blue-600);
				}

				.motif-icon {
					margin-right: var(--px-5);
					vertical-align: middle;
					svg {
						fill: var(--blue-600);
						vertical-align: text-bottom;
					}
				}
			}
		}
	}

	h1 {
		color: var(--neutrals-900);
	}

	.link-holder {
		margin: var(--px-20) 0;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-column-gap: var(--px-20);

		.motif-card-orientation-vertical{
		width: 100%;
		}
		.motif-card-border {
			border-radius: var(--px-8);
			margin-bottom: var(--px-50);
			display: flex;

			a {
				width: 80%;
			}

			&:last-child {
				margin-right: 0;
			}

			&:hover {
				cursor: pointer;
			}

			&:hover .sub-item svg {
				fill: none;
			}

			&:hover .card-name {
				color: var(--gray-600);
			}

			.carditem {
				width: var(--px-220);
				height: var(--px-190);
				display: table-cell;
				vertical-align: middle;
				.sub-item {
					justify-content: center;
					display: flex;
					width: 70%;

					svg {
						min-width: var(--px-60);
						min-height: var(--px-60);
					}

					.card-name {
						font-size: var(--px-16);
						font-weight: bold;
						margin: var(--px-10) 0;
						text-align: center;
					}
				}
			}
		}
	}
`;

function Dashboard() {
	const userPermissions = useSelector((store) => store.userPermissions);

	const [successMessage, setSuccessMessage] = useState();
	const [errorMessage, setErrorMessage] = useState();

	const successMessageDuration = successMessageDisplayDuration;

	let dispatch = useDispatch();

	useEffect(() => {
		localStorage.setItem(
			engagementsSearchRetainFlag.IsRetainEngagementsSearchResult,
			false
		);
		dispatch(getMeCall());
	}, []);

	const dashboardCards = [
		{
			iconName: IconoirFolder,
			tooltipText: 'Engagements',
			cardName: labels.engagementCard,
			permissions: [
				meCallPermissions.hasEngagementDefaultRole,
				meCallPermissions.canReadEngagement,
				meCallPermissions.canUpdateEngagement,
				meCallPermissions.canInviteEngagementMember,
				meCallPermissions.canUpdateEngagementUser
			],
			path: '/admin/engagements'
		},
		{
			iconName: IconoirMessageText,
			tooltipText: 'Messaging',
			cardName: labels.messagingCard,
			permissions: [
				meCallPermissions.canCreateUpdateMessaging,
				meCallPermissions.canDeleteMessaging,
				meCallPermissions.canReadMessaging
			],
			path: '/admin/messaging'
		},
		{
			iconName: IconoirGoogleDocs,
			tooltipText: labels.templatesCard,
			cardName: labels.templatesCard,
			permissions: [
				meCallPermissions.canReadTemplates,
				meCallPermissions.canUpdateTemplates,
				meCallPermissions.canDeleteTemplates
			],
			path: '/admin/template'
		},
		{
			iconName: IconoirGroup,
			tooltipText: 'Permission groups',
			cardName: labels.permissionGroupCard,
			permissions: [
				meCallPermissions.canReadPermissionsGroups,
				meCallPermissions.canCreateUpdatePermissionsGroups,
				meCallPermissions.canSyncPermissionsGroups
			],
			path: '/admin/permissiongroups'
		},
		{
			iconName: IconoirUserBadgeCheck,
			tooltipText: 'Manage access',
			cardName: labels.manageAccessCard,
			permissions: [
				meCallPermissions.canReadManageAccess,
				meCallPermissions.canCreateUpdateManageAccess
			],
			path: '/admin/manageaccess'
		},
		{
			iconName: IconoirRefreshDouble,
			tooltipText: 'Cache',
			cardName: 'Cache',
			permissions: [meCallPermissions.canReadDeleteCache],
			path: '/admin/cache'
		},
		{
			iconName: IconoirMultiplePages,
			tooltipText: 'Documents',
			cardName: 'Documents',
			permissions: [meCallPermissions.canAccessDocuments],
			path: '/admin/documents'
		},
		{
			iconName: IconoirSuitcase,
			tooltipText: 'Sharepoint workspaces',
			cardName: labels.sharepointWorkspacesCard,
			permissions: [
				meCallPermissions.canReadCollaborationSites,
				meCallPermissions.canCreateUpdateCollaborationSites,
				meCallPermissions.canDeleteCollaborationSites
			],
			path: '/admin/sharepointworkspaces'
		},
	];

	useEffect(() => {
		if (successMessage) {
			var timer = setTimeout(() => {
				setSuccessMessage('');
			}, successMessageDuration);

			return () => clearTimeout(timer);
		}
	}, [successMessage]);

	const clearError = (isModelOpen) => {
		dispatch(clearErrors()).then(() => {
			dispatch(setIsModalOpen(isModelOpen));
		});
	};

	const onDBLocatorSyncClick = () => {
		const url =
			env.getURL('serviceUrlV2') + 'admin/dblocatorsynchronizationrequests';
		dispatch(postSyncDatabaseLocator(url))
			.then(() => {
				clearError(true);
				setSuccessMessage(labels.databaseLocatorSuccessMessage);
			})
			.catch((e) => {
				clearError(true);
				setErrorMessage(getLocalizedAPIError(e));
			});
	};

	const hasUserPermission = (cardInfo) => {
		if (
			userPermissions &&
			userPermissions.permissions &&
			userPermissions.permissions?.hasAdminDefaultRole
		) {
			let permKeys = Object.keys(userPermissions.permissions);
			let neededPerms = cardInfo.permissions;
			let allowedRoles = permKeys.filter((pkey) =>
				neededPerms.some((n) => n == pkey && userPermissions.permissions[pkey])
			);
			return allowedRoles.length;
		}
		return true;
	};

	const getContent = () => {
		let allowedCards = dashboardCards.filter((item) => hasUserPermission(item));
		return !allowedCards.length ? (
			<AccessDeniedMessage />
		) : (
			allowedCards.map((item, index) => (
				<Card key={item.cardName + index}>
					<Link to={item.path}>
						<section className="carditem">
							<section className="sub-item">
								<MotifIcon
									name="card-icon"
									className="card-icon"
									iconFunction={item.iconName}
									title={item.tooltipText}
									strokeWidth="0.5" 
									fill="none"
									size={24}
								/>
								{/* <SVGICONS
									styleName="large"
									name={item.iconName}
									hoverText={item.tooltipText}
								/> */}
							</section>
							<section className="sub-item">
								<span className="card-name">{item.cardName}</span>
							</section>
						</section>
					</Link>
				</Card>
			))
		);
	};

	return (
		<Dashboardwrapper className="mainResponsiveWrapper wrapper">
			<section className="toast">
				{successMessage && (
					<Toast
						variant="success"
						duration={successMessageDuration}
						onClose={() => setSuccessMessage(undefined)}
					>
						{successMessage}
					</Toast>
				)}
				{errorMessage && (
					<Toast variant="error" onClose={() => setErrorMessage(undefined)}>
						{errorMessage}
					</Toast>
				)}
			</section>
			<section className="synclocator">
				<h2>
					<span>{labels.welcome}</span>
					<span>
						<b>{labels.welcomeadmin}</b>
					</span>
				</h2>
				<section className="titleholder">
					{userPermissions?.permissions?.canSyncDatabaseLocator && (
						<a onClick={onDBLocatorSyncClick}>
							<Icon src={notificationIcSync24px} />
							<span className="title">{labels.databaseLocatorCard}</span>
						</a>
					)}
				</section>
			</section>
			<section className="link-holder">{getContent()}</section>
		</Dashboardwrapper>
	);
}
export default Dashboard;
