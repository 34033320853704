import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import env from '../../../util/env';
import { useSelector, useDispatch } from 'react-redux';
import Table from '@ey/motif-react-wrapper/Table';
import EllipsesControl from '@ey/ellipses-control';
import Loader from '../../Common/Loaders/loadingindicator';
import { labels } from '../../../util/uiconstants';
import AccessDeniedMessage from '../../Common/AccessDeniedMessage/AccessDeniedMessage';
import Wrapper from './Styles/sharepointWorkspacesbasestyle';
import { clearErrors } from '../../../actions/erroractions';
import { setIsModalOpen } from '../../../actions/modalstateactions';
import { getAllActiveDataCenters } from '../../../actions/admin/sharepointworkspacesactions';
import WorkspacesWrapper from './WorkspaceDrawer/workspaceswrapper';
import FormField from '@ey/motif-react-wrapper/FormField';
import Search from '@ey/motif-react-wrapper/Search';
import styled from 'styled-components';
import { IconoirPlusCircle } from '@ey-xd/motif-icon';
import MotifButton from '@ey/motif-react-wrapper/Button';
import MotifIcon from '@ey/motif-react-wrapper/Icon';
import AddWorkspaceModal from './addworkspacemodal';

function DataCenterDetails() {

	const dataCentersSharepointWorkspaces = useSelector((store) => store.dataCentersSharepointWorkspaces);
	const userPermissions = useSelector((store) => store.userPermissions);
	const [loading, setLoading] = useState(false);
	const [showDrawer, setshowDrawer] = useState(false);
	const [currentDatacenter, setCurrentDatacenter] = useState({});
	const [searchInputValue, setSearchInputValue] = useState('');
	const [gridApi, setGridApi] = useState(null);
	const [showWorkspaceModal, setShowWorkspaceModal] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		clearError(true);
		loadDatacenters();
	}, []);

	const headerCellRender = (params) => {
		return (
			<StyledHeader className="header motif-body2-default-regular">
				<section>{params.label}</section>
			</StyledHeader>
		);
	};

	const columnDefs = [
		{
			minWidth: 450,
			width: 450,
			headerComponentParams: {
				label: labels.dataCenterNameHeader
			},
			headerComponent: (params) => {
				return headerCellRender(params);
			},
			field: 'dataCenterName',
			cellRenderer: (params) => dataCenterNameNameCellRenderer(params),
			lockPosition: true,
			suppressSizeToFit: true
		},
		{
			minWidth: 450,
			width: 450,
			headerComponentParams: {
				label: labels.numberOfWorkSpacesHeader
			},
			headerComponent: (params) => {
				return headerCellRender(params);
			},
			field: 'sharePointWorkspaceCount',
			cellRenderer: (params) => numberofWorkspacesDecriptionCellRenderer(params),
			lockPosition: true,
			suppressSizeToFit: true
		}
	];

	const dataCenterNameNameCellRenderer = (e) => {
		return (
			<>
				<section className="roleType motif-body2-default-light"  >
					<section>
						<a onClick={() => { onDatacenterClick(e.data) }} className="groupname">
							{ellipsesControlRender(e.data.dataCenterName)}
						</a>
					</section>
				</section>
			</>
		);
	};

	const numberofWorkspacesDecriptionCellRenderer = (e) => {
		let sharePointWorkspacesCount = e?.data?.sharePointWorkspaces?.length ?? 0;
		return (
			<>
				<section className="roleType motif-body2-default-light">
					<section>
						{ellipsesControlRender(JSON.stringify(sharePointWorkspacesCount))}
					</section>
				</section>
			</>
		);
	};

	const ellipsesControlRender = (text) => {
		return (
			<section className="roleType">
				<EllipsesControl
					content={text}
					tooltip={text}
					noOfLines={1}
					ellipsisClass="columnName"
				/>
			</section>
		);
	};

	const onDatacenterClick = (data) => {
		setshowDrawer(true);
		setCurrentDatacenter(data)
	}

	const closeDrawer = () => {
		setshowDrawer(false);
		setCurrentDatacenter({});
	}

	const loadDatacenters = () => {
		setLoading(true);
		let sharepointWorkSpacesUrl =
			env.getURL('serviceUrlV2') + `admin/datacenters?includeSharePointWorkspaces=true`;

		dispatch(getAllActiveDataCenters(sharepointWorkSpacesUrl))
			.then(() => {
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const setCurrentDatacenterCallBack = (currentDataCenter) => {
		setCurrentDatacenter(currentDataCenter);
	}

	const clearError = (isModelOpen) => {
		dispatch(clearErrors()).then(() => {
			dispatch(setIsModalOpen(isModelOpen));
		});
	};

	const onSearchChange = () => {
		if (searchInputValue != undefined) {
			gridApi.setGridOption("quickFilterText", searchInputValue);
		}
	};

	const onClearSearchClick = () => {
		setSearchInputValue("");
		gridApi.setGridOption("quickFilterText", "");
	};

	function hasPermissions() {
		let sharepointWorkSpacesPermission = userPermissions?.permissions;
		return (
			sharepointWorkSpacesPermission &&
			(sharepointWorkSpacesPermission.canReadCollaborationSites ||
				sharepointWorkSpacesPermission.canCreateUpdateCollaborationSites ||
				sharepointWorkSpacesPermission.canDeleteCollaborationSites)
		);
	}

	const getRowStyle = (params) => {
		if (params.node.rowIndex % 2 === 0) {
			return { background: 'var(--neutrals-00white)' };
		} else {
			return { background: 'var(--ui-background-secondary)' };
		}
	};

	const handleGridReady = (params) => {
		params.api.sizeColumnsToFit();
		setGridApi(params.api);
	};

	return (
		<Wrapper>
			{hasPermissions() ?
				<section className="mainwrapper">
					<section className="sharepointWorkspace mainResponsiveWrapper">
						<section className="add-on">
							<section className="search">
								<FormField>
									<Search
										placeholder={labels.searchUserText}
										value={searchInputValue}
										onChange={(e) => setSearchInputValue(e.target.value)}
										onSearchButtonClick={onSearchChange}
										onClear={onClearSearchClick}
										onEnter={onSearchChange}
										clearHoverText={labels.clear}
									/>
								</FormField>
							</section>
							<section>
								<MotifButton
									className="add-workspace"
									variant="text-alt"
									size="medium"
									onClick={() => { setShowWorkspaceModal(true) }}
								>
									<MotifIcon
										className="icon-container"
										iconFunction={IconoirPlusCircle}
										size="20"
									/>
									{labels.addWorkspace}
								</MotifButton>
							</section>
						</section>
					</section>

					{loading ? (
						<Loader show view="blockView" styleName="loadingstyle" />
					) : (
						dataCentersSharepointWorkspaces?.length > 0 ? (
							<section className="sharepointWorkspaceTable mainResponsiveWrapper">
								<Table
									defaultColDef={{
										flex: 1,
										sortable: false,
										filter: false,
										resizable: false,
										minWidth: 180,
										lockPosition: true
									}}
									onGridReady={handleGridReady}
									getRowStyle={getRowStyle}
									rowHeight={32}
									enableCellTextSelection
									suppressRowClickSelection
									suppressCellSelection
									columnDefs={columnDefs}
									rowSelection="multiple"
									rowData={dataCentersSharepointWorkspaces}
									rowBuffer={200}
								/>
							</section>
						) : (
							<section className="norecord">{labels.noResultsFound}</section>
						)
					)}
					{currentDatacenter.dataCenterId && <section>
						<WorkspacesWrapper
							show={showDrawer}
							onClose={closeDrawer}
							currentDatacenter={currentDatacenter}
							setCurrentDatacenter={setCurrentDatacenterCallBack}
							dataCenterListForNavigation={dataCentersSharepointWorkspaces}

						/>
					</section>}
					<section>
						{showWorkspaceModal && <AddWorkspaceModal
							showModal={showWorkspaceModal}
							setShowModal={setShowWorkspaceModal}
							dataCenterList={dataCentersSharepointWorkspaces}
						/>}
					</section>
				</section>
				: (
					<section className="message">
						<AccessDeniedMessage
							permissionMesage={labels.insufficientPermission}
						/>
					</section>
				)}
		</Wrapper>
	);
}

export default DataCenterDetails;

const StyledHeader = styled.section`
	&.header {
		width: 100%;
		display: flex;
		color: var(--neutrals-900);
	}
`;