/* eslint-disable prettier */

import {
	Addon
} from 'react-bootstrap/lib/InputGroup';

/**
 * Created by calhosh on 4/14/2017.
* JA JP resource file
 */
export const labels = {
	defaultDropdown: "ドロップダウンメニュー",
	status: "ステータス",
	language: "言語",
	channel: "チャンネル",
	header: "ヘッダー",
	headerName: "ヘッダー名",
	sectionCustomLabelLabel: "カスタムラベル",
	sectionNamePlaceHolder: "セクションのタイトルの入力",
	showRelatedObjectLabel: "関連するオブジェクトの表示",
	errorBanner: "エラー件数　{0}",
	engagements: "エンゲージメント",
	selectedengagements: "エンゲージメント",
	addEngagement: "エンゲージメントの追加",
	archives: "アーカイブ",
	userInitialsTitle: "ユーザーオプション",
	clickForOptions: "クリックし他のオプションを表示",
	comingDue: "期限間近",
	whatsNew: "新着",
	auditMetrics: "監査指標",
	id: "ID",
	archived: "アーカイブ済",
	myarchives: "マイアーカイブ",
	allarchives: "すべてのアーカイブ",
	logout: "ログアウト",
	norecords: "レコードが見つかりませんでした",
	nomatches: "一致していません",
	notemplates: "選択されたエリア/リージョンに対するテンプレートがありません",
	pleasetryagain: "もう一度試してみてください",
	firstpage: "最初のページ",
	previous: "前",
	next: "次",
	prevpage: "前のページ",
	nextpage: "次のページ",
	lastpage: "最後のページ",
	allengagements: "すべてのエンゲージメント",
	searchPlaceholder: "検索",
	searchEngagementsPlaceholder: "エンゲージメント検索",
	clearSearch: "検索をクリア",
	searchTextPlaceHolder: "検索",
	show: "表示",
	manageaccess: "アクセス管理",
	restore: "リストア",
	rollforward: "ロールフォワード",
	viewaccesslist: "アクセスリストの表示",
	teammembers: "チームメンバー",
	name: "名前",
	initials: "イニシャル",
	role: "役割",
	usersettings: "ユーザー設定",
	usersettingsnewengagements: "設定は、新たに作成されたエンゲージメントに適用されます。",
	usersettingsallengagements: "設定は、すべてのワークスペースとエンゲージメントに適用されます。",
	enterfirstname: "表示名（First name)",
	enterlastname: "表示名（Last name)",
	firstname: "First name",
	lastname: "Last name",
	enter: "入力",
	select: "選択",
	email: "Eメールアドレス",
	contact: "電話番号",
	accesslist: "アクセスリスト",
	engagementsettings: "エンゲージメントの設定",
	displayname: "表示名",
	signoffinitials: "サインオフのイニシャル",
	primaryoffice: "オフィス",
	roles: "役割",
	phonenumber: "電話番号",
	globalapplicationsettings: "グローバルアプリケーション設定",
	displaylanguage: "表示言語",
	preferreddateformat: "推奨データフォーマット",
	preferrednumberformat: "推奨数値フォーマット",
	save: "保存",
	cancel: "キャンセル",
	isRequired: "{0}が必要です",
	arcretrymessage: "アーカイブ処理中にエラーが発生しました。アーカイブの記録日は現在{0}です。EY Canvasは自動的にアーカイブ処理を再試行します。このアーカイブを実施してから、自動回復プロセスが完了するまで15時間待ってから、ヘルプデスクにご連絡ください。ヘルプデスクがエンゲージメントを再アクティブ化せず解決できる場合、アーカイブ日付は引き続き{0}で記録されます。ヘルプデスクが、チームに修正処置を依頼しなければならない場合、アーカイブ日付はチームが再度アーカイブを提出した日付で記録されることになります。これによりアーカイブが遅れる場合、チームはエンゲージメントに、ローカルアーカイブポリシーに従い、ヘルプデスクのチケットナンバー、問題点、解決のために採られた手順を記載した文書を追加しなければなりません。",
	ArchiveRetryLink: "アーカイブの再試行",
	workspacelist: "ワークスペースリスト",
	engagementslist: "エンゲージメントリスト",
	accessrequested: "アクセスの要求",
	noaccess: "アクセスがありません",
	independenceOnlyNoAccessMessage: "「独立性のみ」の役割が割当てられています。エンゲージメントのリストア又はロールフォワードの権限を付与するために、エンゲージメントのメンバーに連絡してください。",
	accessrejected: "拒否されたアクセス",
	requestaccess: "アクセスの要求",
	allarchiveinitialmsg: "クライアント名、ワークスペース名、またはエンゲージメント名を入力して開始します。",
	noengagementsforusermessage: "アクティブなエンゲージメントがありません。ドメインの追加をクリックして開始します。",
	noengagementstoimportto: "インポート先のエンゲージメントがありません",
	favorites: "お気に入り",
	portfolioview: "全てのエンゲージメント v2",
	reviewengagement: "レビューエンゲージメント",
	noreviewengagementsmessage: "レビューエンゲージメントはありません。",
	noreviewengagementsmessagecontact: "あなたにはレビューエンゲージメントがありません。 アクセスが必要な場合エンゲージメントチームメンバーに連絡してください。",
	nofavoriteengagementsmsg1: "お気に入りのエンゲージメントはありません。",
	nofavoriteengagementsmsg2: "このビューにエンゲージメントを追加するには、[すべてのエンゲージメント]に移動します。 ",
	nofavoriteengagementsmsg3: "エンゲージメントカードのお気に入りアイコンをクリックします。",
	markasfavorite: "お気に入りとしてマーク",
	unmarkfromfavorites: "お気に入りから削除",
	selectworkspacenoclientworkspaceexists: "既存のワークスペースがありません。[戻る]をクリックして、手順1.の[ワークスペースの新規作成]を選択します。",
	importpkgselectworkspacenoclientworkspaceexists: "既存のワークスペースがありません。戻るをクリックして、前の手順で「新規ワークスペースの作成」を選択します。",
	expirydatelabel: "で、このエンゲージメントは削除されます",
	expirydatetodaytomorrowlabel: "このエンゲージメントは削除されます",
	/*Reporting Hub*/
	reportingHub: "レポーティング",
	reportingHubTitle: "EY Canvasレポーティング",
	selectEngagement: "PSPインデックスを選択して内容を表示する",
	connectVPN: "レポートにアクセスできるのは、EYネットワークまたはEYリモートコネクトに接続されている場合だけです。",
	reportsNotLoaded: "レポートを読み込みできませんでした。ページを更新してもう一度やり直してください。問題が解決しない場合は、ヘルプデスクに連絡してください。",
	searchByEngagementNameOrClientId: "エンゲージメントの選択",
	dataExportLabel: "データエクスポート",
	summaryScreensLabel: "サマリー画面",
	projectManagementLabel: "プロジェクトマネジメント",
	engagement: "エンゲージメント名",
	reportingNotAvailable: "レポートは、選択されたロケーションでは使用できません。詳細については、現地のEY Canvas Championにお問い合わせください。",
	client: "クライアント",
	workspace: "ワークスペース",
	serviceline: "サービスライン",
	subserviceline: "サブサービスライン",
	engagementstartdate: "期末日",
	engagementenddate: "対象期間の終了日",
	deleteafter: "以降削除",
	auditorsreportdate: "レポート日",
	createengagementquestionlabel: "エンゲージメントの作成",
	choosenewexistingquestionheader: "新しいワークスペースまたは既存のワークスペースにエンゲージメントを作成しますか？",
	createengagementheader: "エンゲージメントの作成",
	restoreengagementheader: "エンゲージメントのリストア",
	createworkspacestepheader: "新規ワークスペースの作成",
	selectexistingworkspacestepheader: "既存のワークスペースの選択",
	workspacenotelibilefornewengagement: "新しいエンゲージメントに対応していないワークスペース",
	choosenewexistingstepheader: "新規または既存の作業領域",
	editengagementwizheader: "エンゲージメントの編集",
	removeengagementwizheader: "エンゲージメントのリムーブ",
	editworkspacewizheader: "ワークスペースの編集",
	editworkspacestepfooter: "ワークスペースへの変更を保存するには「保存して閉じる」をクリックし、エンゲージメントを編集するには「保存して続行」をクリックします。",
	editwizheader: "編集",
	newworkspace: "新しいワークスペース",
	existingworkspace: "既存のワークスペース",
	back: "戻る",
	continue: "続行",
	saveclose: "保存して閉じる",
	savecontinue: "保存して続行",
	finish: "終了",
	step01: "Step 01",
	step02: "Step 02",
	step03: "Step 03",
	step04: "Step 04",
	step05: "Step 05",
	engagementname: "エンゲージメント名",
	confirmdecline: "却下します。このアクションは元に戻すことはできません。",
	confirmdeclinevalidation: "却下してよろしいですか？",
	responderror: "このインバイトには既に応答しています。ページを更新して再試行してください。",
	engagementcode: "エンゲージメントコード",
	engagementcodename: "エンゲージメントコード名",
	selectEngagementHeader: "既存のワークスペースの選択",

	selectworkspaceclient: "クライアントの選択",
	selectclient: "関連するワークスペースを表示するクライアントを選択してください",
	createnewworkspace: "新規ワークスペースの作成",
	editworkspace: "ワークスペースの編集",
	clientcode: "クライアントコード",
	clientname: "クライアント名",
	invalidclientcode: "クライアントコードが無効です",
	workspacename: 'ワークスペース名',
	location: "ロケーション",
	workspacecodenameplaceholder: "クライアント名を検索するにはクライアントコードを入力してください",
	engagementcodenameplaceholder: "コード名を検索するにはエンゲージメントコードを入力してください",
	isprimary: "プライマリ",
	addengagementcode: "エンゲージメントコードの追加",
	engagementnamerequired: "エンゲージメント名は必須です",
	primaryofficerequired: "オフィスは必須です",
	languagerequired: "言語は必須です",
	servicelinerequired: "サービスラインは必須です",
	subservicelinerequired: "サブサービスラインは必須です",
	engagementcodevalidationmessage: "エンゲージメントコードがクライアントコードと一致していません",
	engagementcoderequired: "エンゲージメントコードが必要です",
	yearenddate: "期末日",
	periodenddate: "対象期間終了日",
	reportdate: "報告書日",
	complete: "完了",
	end: "終了",
	started: "開始済",
	notstarted: "開始前",
	inprogress: "進行中",
	inreview: "レビュー中",
	taskstatus: "タスクステータス",
	confirmlabel: "確認",
	submitlabel: "提出",
	editengagementquestionlabel: "エンゲージメントの編集",
	removeengagementquestionlabel: "エンゲージメントのリムーブ",
	removeengaementconfirmationmessage1: "エンゲージメントリストから次のエンゲージメントを削除してもよろしいですか? エンゲージメントを削除すると、今後アクセスできなくなります。 ",
	removeengagementerrormessage: "あなたが最後のアクティブユーザであるため、ワークスペースからエンゲージメントを削除することはできません。別のチームメンバーを追加するか、またはエンゲージメントそのものを削除してください。 ",
	deleteengagement: "エンゲージメントのデリート",
	deletreviewcopyoption: "削除",
	deleteengagementconfirmationmessage: "次のエンゲージメントを削除するリクエストを送信してよろしいですか?",
	deleteengagementconfirmationmessage1: "終了したエンゲージメントまたはレポートを発行しないエンゲージメントの場合、エンゲージメントチームは品質管理本部に相談して、アーカイブが必要かどうかを判断する必要があります。",
	deleteengagementconfirmationmessageoneuser: "次のエンゲージメントを削除してよろしいですか?",
	deleteengagementconfirmationmessageoneuser1: "アーカイブ要件：",
	deleteengagementconfirmationmessageoneuser2: "DOC+ARC 3.1",
	deleteengagementconfirmationmessageoneuser3: "は、削除する前に検討する必要があります。例えば、エンゲージメントが終了した場合、またはレポートを発行しない場合は、アーカイブが必要かどうかを判断するために監査監理部との協議が必要になる場合があります。",
	deleteengagementmarkeddelete: "エンゲージメントは既に削除とマークされています",
	deleteengagementoption: "削除",
	deleteengagementreasonlabel: "削除の理由を入力してください",
	deleteengagementreasonquestion: "このエンゲージメントが削除されるのはなぜですか？",
	deleteengagementreasonrequired: "理由は必須です。オプションより選択するか、上記に理由を入力してください。",
	deleteengagementcommentreasonrequired: "理由は必須です。上記より理由を選択してください。",
	deleteengagementcommentrequiredlabel: "削除理由を記入してください",
	deletereasontrainingengagementlabel: "トレーニングエンゲージメント",
	deletereasonrestoredorcopiedlabel: "参照用にのみリストアまたはコピーされたエンゲージメント",
	deletereasoncreatedbymistakelabel: "誤って作成された",
	deletereasonotherlabel: "その他",
	deletereasonterminedbyclientlabel: "終了したエンゲージメントで何も作業が実施されていません。このエンゲージメントで作業が行われた場合、エンゲージメントをアーカイブしてください。",
	markeddeleteconfirm: "削除を確認",
	deleterequestbannertext1: "削除リクエスト者：",
	deleterequestbannertext2: " 日前",
	markeddeleteconfirmmessage: "次のエンゲージメントを削除してよろしいですか?",
	markeddeleteconfirmmessage1: "削除を確認した場合、この操作は元に戻せません。",
	markeddeletereasonlabel: "削除理由",
	engagementlistplaceholder: "エンゲージメントの選択",
	engagementrequired: "エンゲージメントが必要です",
	restoreconfirmationstepheader: "確認-Confirmation-",
	restoreconfirmationheader: "エンゲージメントをリストアしますか？",
	selectarchiveheader: "アーカイブされたファイルの選択",
	restoreengagementstepheader: "エンゲージメントのリストア",
	chooserollfwdhdr: "新規または既存のワークスペースにロールフォワード",
	rollfwdstephdrext: "既存のワークスペースにロールフォワード",
	rollfwdstephdrnew: "新規のワークスペースにロールフォワード",
	rollfwdhdrqstn: "新規または既存のワークスペースにエンゲージメントをロールフォワードしますか？",
	rollforwardengagementstepheader: "エンゲージメントのロールフォワード",
	genericerror1: "不具合が発生しました。再試行するかサポートチームに連絡してください。",
	restoreengagementmaxcopies: "このエンゲージメントは、リストアの最大数({0})を超えています。既存のコピーエンゲージメントへのアクセスをリクエストしてください。",
	restoreengagementengcopies: "このエンゲージメントのリストアは、{0}あります：",
	restoreengagementengcopy: "このエンゲージメントでは、リストアが{0}利用可能です：",
	restoreengagementengnocopies: "このエンゲージメントの他にリストアはありません。",
	globalexceptionrestoreengagementengcopies: "このエンゲージメントのリストアが、{0}つ存在します。上のボックスを確認してグローバルの例外でリストアしてください。",
	globalexception: "グローバルの例外",
	globalexceptioncheckerror: "グローバルの例外オプションを選択して続行する。",
	restoreengagementrestoredon: "リストア先",
	restoreengagementusers: "ユーザー",
	restoreengagementuser: "ユーザー",
	at: "場所",
	arcfileuserunavailable: "利用できないユーザー",

	restorehdrqstn: "新規ワークスペースまたは既存ワークスペースにエンゲージメントをリストアしますか？",
	chooserestorehdr: "新規または既存のワークスペースにリストアする",
	restorestephdrext: "既存のワークスペースにリストア",
	restorestephdrnew: "新規のワークスペースにリストア",

	close: "閉じる",
	confirm: "確認",
	settings: "設定",
	expand: "展開する",
	collapse: "折りたたむ",
	contextMenuEdit: "編集",
	contextMenuEditEngagement: "エンゲージメントの編集",
	contextMenuRemoveEngagement: "エンゲージメントのリムーブ",

	contextMenuDeleteEngagement: "エンゲージメントのデリート",
	contextMenuEditWorkspace: "ワークスペースの編集",
	contextMenuDelete: "削除",
	contextMenuOverrideArchiveDate: "アーカイブ期日の更新",
	na: "該当なし",
	archivesubmittedby: "提出者",
	archivedateandtime: "日付と時間",
	notifications: "通知",
	nonotificationmsg: "通知はありません。",
	notifcenter: "通知センター",
	notifitemspendingact: "アクション待ちアイテム",
	accept: "承諾",
	reject: "却下",
	acceptall: "全て承諾",
	rejectall: "全て却下",
	notifEngInvite: "エンゲージメントのインバイト",
	notifengaccesreq: "エンゲージメントのアクセスリクエスト",
	keyevents: "キーイベント",
	milestones: "マイルストーン",
	importselecttemplateheader: "使用するテンプレートを選択してください",
	importtemplateheader: "インポートするエリア/リージョンを選択してください",
	import: "インポート",
	importregionstepheader: "エリア/リージョンの選択",
	importtemplatestepheader: "テンプレートの選択",
	importtemplatemsg: "エリア/リージョンを選択して続行",
	importselecttemplatemsg: "テンプレートを選択して続行",
	importengagementheader: "テンプレートのインポート",
	importhdrqstn: "新規または既存のワークスペースにインポートする",
	importstephdrext: "既存のワークスペースにインポート",
	importstephdrnew: "新規のワークスペースにインポート",
	chooseimporthdr: "新規または既存のワークスペースにインポートする",
	selectexistingworkspacemsg: "ワークスペースを選択して続行",
	selectaclienttocontinuemsg: "クライアントを選択して続行",
	selectengagementmsg: "エンゲージメントを選択してデータパッケージを受け取る",
	selectgaengagementmsg: "リンクリクエストを受け入れるには、エンゲージメントを選択する必要があります",
	arearegion: "エリア/リージョン",
	importdatapackagequestionlabel: "どの操作を行いたいですか？",
	sentby: "送信者",
	on: "送信日付",
	by: "by",
	view: "表示",
	sourceengagement: "ソースエンゲージメント",
	package: "パッケージ",
	decline: "却下",
	delegate: "委譲",
	chooseaction: "アクションの選択",
	selectworkspace: "ワークスペースの選択",
	declinepackage: "リクエストの却下",
	delegatepackage: "パッケージの移譲",
	selectengagement: "エンゲージメントの選択",
	datapackagerequest: "データパッケージリクエスト",
	respond: "ここをクリックして返答",
	deletionrequestfor: "受領した移譲リクエストの対象",
	invalidengagementcode: "無効なエンゲージメントコード",
	noNotifications: "利用可能な通知はありません",
	bellIconTitle: "通知センター",
	restoredengagement: "リストアされたエンゲージメント",
	profiledeliveryinprogress: "プロファイル送信の進行中",
	completeprofileheader: "プロファイルの完成",
	completeindependenceheader: "独立性確認の完了",
	independencecompletedheader: "独立性完了",
	retry: "再試行",
	errorDate: "エラー",
	invitationDate: "インビテーションの日付",
	engCreationFailedWarning: "エンゲージメントの作成に失敗しました。ページを更新して再試行してください。問題が解決しない場合は、ヘルプデスクにお問い合わせください。",
	deletedEngagementWarning: "エンゲージメントを使用中で、誤って削除した場合は、すぐにヘルプデスクまで連絡してください。",
	searchEngagementWarning: "このエンゲージメントを検索して更なるアクションを実行します。",
	archiveOverrideWarning: "選択したアーカイブ期限はARCで認められていません。アーカイブ期日はロケーションのデフォルト値に戻されました。",
	archiveOverrideInProgress: "ARC承認が保留中のアーカイブの上書き",
	archiveOverrideAccepted: "アーカイブの上書きが完了しました",
	archiveOverrideRejected: "選択したアーカイブまでの期間はARCで認められていません。アーカイブ期日はロケーションのデフォルトに戻されました。",
	scopeName: "スコープ名",
	shortName: "略称",
	invited: "インバイト済",
	invitedOn: "インバイト日",
	instructions: "インストラクション",
	updates: "更新",
	viewinstructions: "インストラクションの表示",
	viewinstructionsupdates: "更新の表示",
	more: "追加",
	addmembers: "メンバーの追加",
	statusChangeDate: "ステータスの変更",
	deletedBy: "削除者 ",
	deletedDate: "削除済",
	deactivatedBy: "非アクティブ化実施者",
	deactivatedDate: "非アクティブ化済",
	requestUserName: "リクエスト者",
	requestDate: "リクエスト済",
	archiveDate: "アーカイブ済",
	receivedFrom: "送信者",
	received: "受信済",
	clear: "クリア",
	clearAll: "全てクリアする",
	type: "タイプ",
	engagementhasbeendeleted: "選択されたエンゲージメントが別のユーザーによって削除されました。ページを更新してもう一度やり直してください。",
	independenceOnlyRejectInvite: {
		engagementIsNotAvailable: "エンゲージメントは利用できません。ページを更新し再試行してください。問題が解決しない場合は、ヘルプデスクに連絡してください。",
		failedToRejectInvite: "インバイトの拒否に失敗しました。ページを更新し再試行してください。問題が解決しない場合は、ヘルプデスクに連絡してください。",
		succesfulRejection: "インバイトは正常に拒否されました。",
		loadingLabel: "インバイトを拒否しています...",
	},
	manageTeam: "チームの管理",
	childinprogress: "エンゲージメントセットアップ中",
	// SeeMore component
	showMore: "さらに表示",
	showLess: "表示を減らす",
	showMoreEllipsis: "さらに表示...",
	showLessEllipsis: "表示を減らす...",
	accepted: "承諾済",
	acceptedOn: "承諾日:",
	rejected: "リジェクト済",
	deniedBy: "否認者",
	engagementstatusrestored: "リストア済",
	engagementstatusmarkedfordeletion: "削除としてマーク済",
	delegaterequired: "デリゲートが必要",
	username: "ユーザー名",
	noresults: "結果が見つかりませんでした。再試行してください",
	importdatapackageconcurrencymessage: "コピーリクエストの状態が変更されました。ページを更新してもう一度やり直してください。",
	selectdelegateuser: "デリゲートの選択",
	eyusersearchplaceholder: "名前または電子メールアドレスを入力して検索",
	optional: "（任意）",
	engagementAlerts: "エンゲージメントのアラート",
	engagementMetrics: "エンゲージメント指標",
	overdue: "期日超過",
	/*day labels*/
	mon: "月",
	tues: "火",
	wed: "水",
	thurs: "木",
	fri: "金",
	sat: "土",
	sun: "日",
	canvas: 'Canvas',
	overrideArchiveDateModalHeader: "アーカイブ期日の上書き",
	overrideArchiveDateModalMessage: "アーカイブ期日を上書きしますか？",
	overrideArchiveDateModalComboPlaceholder: "アーカイブ期日の上書き",
	createdOn: "作成日：",
	expiresOn: "有効期限：",
	archiveDeadlineDate: "アーカイブ期日",
	defaultOverriden: "デフォルトの更新",
	selectexistingworkspace: "既存のワークスペースの選択",
	importtonewworkspace: "新規のワークスペースにインポート",
	groupaudit: "グループ監査リンクリクエスト",
	gaacceptdeclinedelegatequestionlabel: "このリンクリクエストに対してどのように対応しますか？",
	gaacceptselectengagementquestionlabel: "リンクするエンゲージメントを選択してください",
	gadelegatequestionlabel: "グループ監査リンクリクエストのデリゲート",
	gadeclinequestionlabel: "グループ監査リンクリクエストの却下",
	garegioncomponent: "リージョン/構成単位",
	gascope: "スコープ",
	gamxTransfer: "GAMxからの転送",
	closeNotificationCenter: "通知センターを閉じる",
	contextMenucrp: "レビューコピーの作成",
	workingoffline: "オフライン作業",
	trainingoffline: "オフライン作業（トレーニング）",
	training: "トレーニング",
	crpdescription: "外部レビューア用のエンゲージメントのコピーを作成",
	crpeditdescritpion: "エンゲージメント名又は有効期限を編集してください。 期限切れのエンゲージメントは自動的に削除されます。",
	expiry: "保存期限",
	enteremail: "Eメールアドレスの入力",
	create: "新規作成",
	metricsPlaceholderText: "選択したタイムフレームの利用できるデータがありません",
	deleteReviewCopy: "レビューコピーの削除",
	deleteReviewCopyMsg: "このレビューコピーを削除してもよろしいですか？削除すると、エンゲージメントは使用できなくなり、復元できなくなります。",
	crpNotAvailable: "現在Canvas External Access Portalは利用できないため、このエンゲージメントのレビュー用コピーを編集できません。 詳細については、ヘルプデスクに連絡してください。",
	externalmember: "外部メンバー",
	internalmember: "内部メンバー",
	add: "追加",
	crpEditMembers: "編集",
	invalidEmail: "有効なEメールアドレスを入力してください",
	emailNotFound: "Eメールアドレスが見つかりません",
	dupExternalUser: "外部メンバーが既に追加されています",
	noExternalUser: "外部メンバーが追加されていません。",
	expiresIn: "期日まで",
	days: "日",
	today: "今日",
	tomorrow: "明日",
	reviewengagementname: "エンゲージメント名のレビュー",
	enternameoremail: "名前またはEメールアドレスを入力",
	options: "オプション",
	userNotFound: "ユーザーが見つかりません",
	remove: "リムーブ",
	crpEditHeader: "レビューコピーの編集",
	globalexceptionrestore: "グローバル例外リストア",
	expired: "期限切れ",
	softwareUpdate: "ソフトウェアアップデート",
	updateNow: "今すぐアップデート",
	updateMsg: "EY Canvasの最新ソフトウェアを利用できます。「今すぐ更新」を選択してダウンロードし、インストールしてください。ページが更新されます。",
	preferredlandingpageview: "優先するランディングページビュー",
	preferredlandingpagefilter: "優先するランディングページフィルター",
	engagementlist: "エンゲージメントリスト",
	workspacelistusersettings: "ワークスペースリスト",
	engagementsclientstoload: "アプリケーションへのアクセスごとに読み込むエンゲージメント/クライアントの数",
	favoriteengagements: "お気に入りエンゲージメント",
	preferredEngagementDashboard: "エンゲージメントダッシュボードの設定",
	preferredEngagementDashboardTeamMembers: "チームメンバー",
	preferredEngagementDashboardExecutives: "エグゼクティブ",
	linkedHelixProjects: "EY Helixにリンク済",
	loadingLabel: "読込み中…",
	loadingErrorText: "タイムラインの読込み中にエラーが発生しました。ページを更新して再試行してください。",
	selectYear: "年度を選択（必須）",
	clientNameOrId: "クライアント名又はID",
	engagementNameOrId: "エンゲージメント名又はID",
	engagementCodeOrCodeName: "エンゲージメントコードまたはコード名",
	searchArchives: "検索",
	clearSearchArchives: "検索をクリア",
	searchArchivesMessage: "開始するにはエンゲージメントがアーカイブされた年度を選択してください。 その上で以下の１つ以上のオプションにより検索してください:",
	titleUpdateProfile: "情報",
	msgUpdateProfileLn1: "エンゲージメントの言語が更新されました。この言語でコンテンツを受領するにはエンゲージメントコンテンツ更新に進みグローバルコンテンツ更新を選択してください。",
	sessionTimerMsg1: "操作が行われないため作業セッションはまもなく中断されます。",
	sessionTimerMsg2: "セキュリティ上の理由から、 EY Canvasセッションは最後の操作から30分後に自動で終了します。",
	sessionTimerMsg3: "現在のセッションは {0}分後に終了します。",
	sessionTimerMsg4: "ログインを継続する際には、 続行をクリックしてください。",
	sessionTimerHeader: "セキュリティ保護のため自動ログアウトされました。",
	theme: "テーマ",
	light: "ライト",
	dark: "ダーク",
	chooseDisplayTheme: "デフォルトの表示テーマを選択してください。",
	searchByCountry: "国を選択してください。",
	primaryEngagementLabel: "プライマリーエンゲージメント",
	componentEngagementLabel: "構成単位エンゲージメント",
	regionalEngagementLabel: "リージョナルエンゲージメント",
	linkedLabel: "リンク済",
	eyCanvasEconomicsTooltip: "EY Canvas Economics",
	eyOversightTooltip: "EY Canvas Oversight",
	eyPrivacyNoticeTooltip: "プライバシー通知",
	eyTermsOfServiceTooltip: "利用規約",
	eyGeneralHelp: "一般的なヘルプ",

	preferrednegativenumberformat: "お好みの負数の表示形式",

	protectedEngagementMessage: "文書の印刷、コピーあるいは再作製されることを防止します",
	documentRrestriction: "文書上の制限",
	on: "On ",
	off: "Off",
	protectedEngagementMessageOn: "本エンゲージメント内の文書は印刷、コピーあるいは再作製できません",
	protectedEngagementMessageOff: "本エンゲージメント内の文書は印刷、コピーあるいは再作製することが可能です",

	/* Dashboard labels*/
	homeLabel: "ホーム",
	manageAccessHeader: "アクセスの管理",
	sharepointWorkspacesHeader: "SharePointワークスペース",
	showInactiveUserslabel: "非アクティブユーザーの表示",
	showExpiredMessageslabel: "期限切れのメッセージを表示",
	addUserButtonLabel: "ユーザーの追加",
	searchUserText: "検索",
	nameHeader: "名前",
	dataCenterNameHeader: "データセンター名",
	numberOfWorkSpacesHeader: "提供されたワークスペース数",
	workspaceName: "ワークスペース名",
	documentCount: "文書数",
	percentCapacity: "容量割合",
	emailHeader: "Eメールアドレス",
	actionHeader: "アクション",
	manageRolesLabel: "ロールの管理",
	deactivateUserlabel: "ユーザーの非アクティブ化",
	manageRolesTableModule: "モジュール",
	manageRolesTableRoles: "役割",
	manageRolesTableServiceLine: "サービスライン",
	manageRolesTableCountries: "国",
	manageAccessAssignRole: "役割の割当て",
	manageAccessNoRolesAdded: "追加された役割なし",
	manageAccessDeleteRoleModalHeader: "ロールの削除",
	manageAccessDeleteRoleButton: "削除",
	noRoleResults: "結果なし",
	confirmAssignRoleButton: "割当て",
	permissionGroupName: "グループ名",
	permissionGroupDescription: "グループの説明",
	activeMembers: "アクティブメンバー",
	permissionGroupMembersTitle: "タイトル",
	permissionGroupMembersPhone: "電話",
	permissionGroupMembersGui: "GUI",
	permissionGroupMembersUserId: "ユーザーID",
	permissionGroupMembersAdStatus: "ADステータス",
	permissionGroupAddMember: "メンバーの追加",
	permissionGroupUserDeleteLabel: "削除",
	permissionGroupUserDeleteHeader: "メンバーの削除",
	permissionGroupDetails: "権限グループの詳細",
	cache: "キャッシュキー",
	cacheSelection: "キャッシュロケーションの選択",
	engagementID: "エンゲージメントID",
	cacheKey: "キャッシュキー",
	validationMessage: "必須フィールド",
	getCache: "キャッシュの取得",
	clearResult: "結果のクリア",
	removeCache: "キャッシュの削除",
	requiredField: "必須フィールド",
	ticketNumber: "チケットナンバー（必須）",
	confirmationMessage: "このアクションを行うためにはチケットナンバーを入力してください",
	confirmButtonLabel: "確認",
	cancelButtonLabel: "キャンセル",
	modalHeaderLabel: "キャッシュの削除",
	adminModuleinvalidEngagementId: "無効なエンゲージメントID",
	adminModuleInvalidCacheKey: "キャッシュキーに無効な文字が含まれています",
	clearResults: "結果のクリア",
	getDocuments: "文書の取得",
	documentsHeader: "文書",
	validTicketNumber: "有効なチケットナンバーを入力してください",

	egagementsPageDescription: "1つまたは複数の基準に基づいてエンゲージメントを検索し、エンゲージメントデータを更新し、新しいメンバーをインバイトし、既存のチームメンバーのステータスと権限を更新します。",
	engagementSearchCriteria: "検索を実行するには国またはエンゲージメントIDが必要です",
	engagementsHeader: "エンゲージメント",
	engagementIdSearchPlaceholder: "エンゲージメントID",
	engagementNameSearchPlaceholder: "エンゲージメント名",
	workSpaceSearchPlaceholder: "ワークスペース名又はID",
	clientSearchPlaceholder: "クライアントの名称又はコード",
	engagementCodeSearchPlaceholder: "エンゲージメントコード又はコード名",
	userSearchPlaceholder: "ユーザーGUI、Eメール又はユーザーID",
	countrySearchPlaceholder: "国",
	engagementTableHeader: "エンゲージメント",
	nameTableHeader: "名前",
	workspaceIdTableHeader: "ワークスペース",
	clientTableHeader: "クライアント",
	engagementCodeTableHeader: "エンゲージメントコード",
	yearEndTableHeader: "期末",
	engagementdeleteDate: "エンゲージメント削除日",
	archivalguid: "アーカイブGUID",
	archivalLogId: "アーカイブlog ID",
	archivalFileguid: "アーカイブファイルGUID",
	profile: "プロファイル完了",
	independence: "独立性",
	archiveRetry: "アーカイブの再試行",
	engagementdetails: "エンゲージメント詳細",
	engagementsdetails: "エンゲージメント詳細",
	statusTableHeader: "ステータス",
	attributesTableHeader: "属性",
	attributesProfileComplete: "プロファイルの完了",
	attributesIndpendenceApplicable: "独立性の適用",
	engagementPagePlaceholder: "上記のエンゲージメントを検索すると、結果がここに表示されます",
	noEngagementFound: "エンゲージメントが見つかりません。",
	emptySearchFieldError: "最低1つの検索フィールドには値を入力する必要があります",
	largeCountrySelectionError: "10か国以下の国を選択してください。",
	saveEngagement: "保存",
	undoEditEngagement: "リセット",
	engagementUpdateSuccess: "変更は正常に保存されました",
	archiveRetrySuccessMessage: "このエンゲージメントのアーカイブは正常に開始されました。後でステータスをチェックして、アーカイブが成功したかを検証してください。",
	welcome: "ようこそ - ",
	welcomeadmin: "管理ツール",
	engagementCard: "エンゲージメント",
	messagingCard: "メッセージ",
	templatesCard: "テンプレート",
	permissionGroupCard: "権限グループ",
	manageAccessCard: "アクセスの管理",
	sharepointWorkspacesCard: "SharePointワークスペース",
	databaseLocatorCard: "データベースロケーターの同期化",
	importForReviewTooltip: "エンゲージメントはレビュー目的に最適化され、一部の機能は利用できなくなります。",
	importForReviewChecboxText: "レビュー目的専用のインポート",
	clientCode: "クライアントコード",
	primaryEngagementCode: "プライマリーエンゲージメントコード",
	invalidEngagementTitle: "無効なクライアントまたはエンゲージメントコード",
	invalidEngagementMessage: "このエンゲージメントにアクセスする前に、クライアントコード及びエンゲージメントコードを検証する必要があります。「続行」をクリックすると、コードを更新して検証できます。",
	insufficientPermission: "このモジュールへの許可が不十分です",
	noPermission: "権限なし",
	noAdminRights: "このツールへの権限がありません。",



	//Engagement Users Lables
	engmtUserTableUserNameHeader: "ユーザー名",
	engmtUserTableEmailAddressHeader: "Eメールアドレス",
	engmtUserTableRoleHeader: "役割",
	engmtUserTableGUIeHeader: "GUI",
	engmtUserTableStatuseHeader: "ステータス",
	engmtUserTableIndependenceStatuseHeader: "独立性",
	engmtUserTableConfidentialityHeader: "機密保持",
	engmtUserTableADStatusHeader: "ADステータス",
	engmtUserTablePermissionsHeader: "権限",
	engmtUserTableActionHeader: "アクション",
	engagementUsersTitle: "エンゲージメントユーザー",
	engagementInviteMember: "メンバーのインバイト",
	engagementInviteUser: "ユーザーのインバイト",
	engagementRoles: "ロール",
	engagementRolesRequired: "必須のエンゲージメントロール",
	engagementInviteMemberSuccessMessage: "メンバーはエンゲージメントに正常にインバイトされました",
	engagementInviteMemberDescription: "上で姓、名、メールアドレスで検索して下さい。結果がここに表示されます。",
	engagementUsersTitleEdit: "編集",
	engagementUpdatePermissionToolTip: "エンゲージメントを更新する十分な権限がありません。",
	engagementRetryArchivePermissionToolTip: "アーカイブを再試行するための十分な権限がありません",
	usersEditPermissionToolTip: "このアクションを行う権限がありません。管理者に相談し権限をリクエストしてください。",
	usersPermissionToolTip: "エンゲージメントユーザーを更新する十分な権限がありません。",
	invalidArchivalGuidMessage: "無効なアーカイブGUID",
	invalidArchivalFileGuidMessage: "無効なアーカイブファイルGUID",
	invalidArchivalLogId: "無効なアーカイブログid",
	datePickerLabel: "日付を選択",
	updateEngagement: "エンゲージメントのアップデート",

	invalidEngagementMessage: "このエンゲージメントにアクセスする前に、クライアントコード及びエンゲージメントコードを検証する必要があります。「続行」をクリックすると、コードを更新して検証できます。",
	invalidEngagementId: "エンゲージメントIDが無効です。",
	serverErrorMessage: "サーバーエラー。しばらくたってから試行してください。",
	engagementDetailsNoPermission: "エンゲージメントの詳細を閲覧する十分な権限がありません。",
	engagementNotFound: "エンゲージメントの詳細が見つかりません。",
	pageNotFound: "ページが見つかりません。",
	assignRoleModalMessage: "モジュール及び特定機能を選択しこのユーザーにとって利用できるようにしてください。",
	selectModule: "モジュールの選択",
	selectRole: "役割の選択",
	serviceLine: "サービスライン",
	countries: "国",
	confirmAssignRoleButton: "割当て",
	addUser: "ユーザーの追加",
	addAdminUserHeader: "ユーザーの名 姓又はEメールアドレスを入力後、Enterキーを入力して検索してください。",
	addMemberDescription: "上で姓、名、メールアドレスで検索して下さい。結果がここに表示されます。",
	seachInputRequired: "検索入力が必要です。",
	searchUsers: "ユーザーの検索",
	noResultsFound: "結果なし",
	usersToBeAdded: "追加対象のユーザー",
	addUserSuccessMessage: "管理者ユーザーの追加ができました。",
	addMemberSuccessMessage: "グループへのメンバー追加に成功しました",
	deactivateWarning: "次のユーザーを非アクティブ化してよろしいですか？",
	deactivateButton: "非アクティブ化",
	deactivateModalTitle: "ユーザーの非アクティブ化",
	generateReport: "レポートの生成",
	generateValidationReport: "バリデーションレポートの生成",

	templateManagement: "テンプレート",
	templateName: "テンプレート名",
	templateAreaRegion: "エリア/リージョン",
	templateSize: "サイズ",
	templateCreatedBy: "作成者",
	templateCreatedDate: "作成日",
	templateCountOfEngagement: "エンゲージメント",
	templateStatus: "ステータス",
	templatePublished: "発行済",
	templateUnpublished: "未発行",
	templateSearchText: "検索したい語句を入力しEnterキーを入力してください。",
	templateInsufficientPermissions: "このモジュールへの権限が不十分です",
	templateMultipleArea: "複数",
	templateEdit: "テンプレートの編集",
	templateEditSave: "保存",
	templateEditCancel: "キャンセル",
	templateEditSuccessMessage: "テンプレートは正常に更新されました。",
	templateDeleteConfirmationMessage: "テンプレートを削除してしてよろしいですか？",
	templateDelete: "テンプレートの削除",
	templateEditDelete: "削除",

	messagingMessageTitle: "メッセージタイトル",
	messagingDescriptionTitle: "メッセージの説明",
	messagingStartsOn: "開始日 (UTC)",
	messagingEndsOn: "終了日 (UTC)",
	messagingAffectedDataCenters: "影響を受けるデータセンター",
	messagingStatus: "ステータス",
	messagingCreatedBy: "作成者：",
	messagingCreatedDate: "作成日：",
	messagingDataCenterSystemWide: "システム全体",
	messagingDataCenterMultiple: "複数",
	messagingDataCenterSingle: "単一",
	messageName: "メッセージタイトル",
	messageStartsOn: "開始日：",
	messageEndsOn: "終了日：",
	messageDateTime: "日付と時刻を選択",
	messageAllTimeInUTC: "全ての時刻はUTCです。",
	messageTargetAudience: "対象者",
	messageDataCenters: "データセンター",
	messageSelectADate: "日付の選択",
	messageAllDataCenters: "すべてのデータセンター",
	messageKendoDescription: "フォーマットを使用すると、説明が最大4,000文字に短縮されます。",
	messageSelectDataCenter: "データセンターの選択",
	messageFieldRequired: "必須フィールド",
	messageStartTimeError: "開始日時は現在の時間より前にできません",
	messageEndTimeError1: "終了日時は現在の日時より前にできません",
	messageEndTimeError2: "終了日時は開始日時より後にする必要があります",
	messageDataCenterError: "少なくとも1つのデータセンターを選択してください",
	messageSubmitValidationError: "必須フィールドが未入力です。スクロールして、必須フィールドが全て入力されていることを確認してください。",
	messageChangesValidationError: "変更は行われていません。保存するには、少なくとも1か所以上のフィールドを編集してください。",
	currentutc: "現在のUTC日時：",
	messageCurrentUTCTime: " {0} (24時間形式)",
	createMessage: "メッセージの作成",
	createMessagePermissions: "このモジュールへの十分な権限がありません",
	createMessageSuccess: "メッセージは正常に作成されました",
	editMessage: "メッセージの編集",
	editMessageSuccess: "メッセージは正常に編集されました",
	editHoverText: "編集",

	messagingModalDeleteTitle: "メッセージの削除",
	messagingModalDeleteMessage: "メッセージを削除してよろしいですか?",
	messagingModalDeleteButton: "削除",
	messagingModalDeleteConfirm: "確認",
	messagingModalDeleteCancel: "キャンセル",
	messagingModalDeletedSuccessfully: "メッセージは正常に削除されました",

	// databaselocator
	databaseLocatorSuccessMessage: "データベースロケータの同期がキューに入れられました",

	// documents module
	docListHeaderDocId: "文書ID",
	docListHeaderDocVersion: "バージョン",
	docListHeaderDocName: "文書名",
	docListHeaderFileSize: "ファイルサイズ",
	docListHeaderContentUri: "コンテンツURI",
	docListHeaderFileExists: "ファイルは存在します",
	docListNoResults: "文書がありません",
	docListTotalDocuments: "文書数合計：",
	docListDocIdSearchLabel: "文書ID",
	docListExportBtn: "エクスポート",

	// Internal user roles abbr
	picRole: "PIC",
	adminRole: "管理者",
	readOnlyRoleForThirdPartyUser: "読取り専用",
	legacy: "読み取り専用",
	permissionLabel: "権限",
	permission: "権限",
	active: "アクティブ",
	inactive: "アクティブでない",
	EngagementUserStatus: "ステータス",
	IndependenceStatus: "独立性",
	Confidentiality: "機密保持",
	EditUserPermissionsMainTitle: "権限を編集できます。",
	EditUserPermissionsInstructions: "いつでも他者の権限を編集できます。",
	PermissionSaveButtonText: "保存",
	PermissionCancelButtonText: "キャンセル",
	UnknownCountry: "不明",
	engagementNoPermissionMesage: "このエンゲージメントのモジュールへの権限が十分でありません",
	cacheRemoveSuccessMessage: "キャッシュは正常に削除されました",
	cacheKeyNotFound: "キャッシュキーの結果が見つかりません",

	// Portfolio labels
	informationText: "おかえりなさい、",
	filterEngagements: {
		all: "全てのエンゲージメント",
		favorite: "お気に入りエンゲージメント",
		review: "レビューエンゲージメント"
	},
	filterEngagementsLabel: "エンゲージメントをフィルタ",
	engv2noActiveEngagements: "アクティブなエンゲージメントがありません。'エンゲージメントの追加'をクリックして始めてください。",
	engv2noFavoriteEngagements: "お気に入りのエンゲージメントがありません。このビューにエンゲージメントを追加するためには、すべてのエンゲージメントにおいてエンゲージメントカードの右下にあるお気に入りアイコンをクリックしてください。",
	engv2noReviewEngagements: "レビューエンゲージメントがありません。アクセスするにはエンゲージメントチームに連絡してください。",

	// Engagement Progress Labels
	engagementProgressStrategyPhaseLabel: "スコープ及び戦略フェーズ",
	engagementProgressExecutionPhaseLabel: "実施フェーズ",
	engagementProgressConclusionPhaseLabel: "総括フェーズ",
	engagementProgressCompletionPercentage: "完了",
	engagementProgressCompleted: "完了",
	engagementProgressNotAvailable: "ローカルのデータセンターがアップグレードされればエンゲージメントの進捗状況が利用できます。",

	// Engagement archive countdown indicator
	EngagementArchiveCountdownIndicator: {
		reportreleasedate: "監査報告書日：",
		archivedeadlinedate: "アーカイブ期日：",
		today: "今日",
		tommorow: "明日",
		overdue: "期限超過",
		reportreleasedays: "監査報告書日まで",
		archivedeadlinedays: "アーカイブ期日まで",
		days: "日"
	},
	postArchive: "アーカイブ後",

	adminTool: "管理ツール",
	characters: "文字",
	placeholder: "Placeholder",
	ofLabel: "／",
	kendoTextLimitMessage: "最大文字数制限を超えました",
	kendoRequiredMessage: "必須",
	engv2Errors: {
		noReviewEngagementsPermission: "エンゲージメントをレビューする許可がありません。アクセスするにはエンゲージメントチームに連絡してください。",
	},
	timeTracking: ' JA JP Time Tracker',
	timeTrackingToolMessage: "このオプションツールは、エンゲージメントにおける時間管理をサポートするために特化されています。",
	timeTrackingToolMessage1: "時間のログ収集を開始すると、最大14日間、終始したデータの概要を確認できます。14日以上前のデータは削除されます。",
	timeTrackingToolUpdatedMessage: "このオプションツールは、時間の使い方を記録するのに役立ちます。記録された時間は最大 2週間保持され、アーカイブされません。",
	timeTrackerErrorFallBackMessage: "タイムサマリーを読み込むことができません。ページを更新して、再試行してください。エラーが続く場合は、ヘルプデスクに連絡してください。",
	serviceGateway: "サービスゲートウェイ",
	reportinghubDisableMessage: "Spotfireは2024年6月に廃止される予定です。新しいEY Canvas レポートには、EY Canvasエンゲージメントからアクセスできます。 2024 Release 1 以降は、詳細メニューからアクセスできます。これらのレポートに依存するカスタムレポートまたはオートメーションは、2024年6月までに必ず新しいバージョンに更新してください。",
	mobileVersionLabels: {
		canvas: "Canvas",
		noEngagementFound: "アクティブなエンゲージメントがありません。開始するにはお使いのコンピュータでCanvasにアクセスしてください。",
		searchEngagementPlaceholder: "検索中....",
		search: "検索",
		independenceIncomplete: "未完了の独立性",
		profileIncomplete: "未完了のプロファイル",
		allEngagements: "全てのエンゲージメント",
		noMatchesFound: "一致したものはありません。再試行してください。",
		inValidUserLogin: "コンピュータにアクセスしてユーザー設定を完了します",
		pagination: {
			pageLabel: "ページ",
			ofLabel: "／"
		}
	},
	independenceOnlyStatus: "独立性のみ",
	toggleView: "表示の切り替え",

	// Adobe Analytics
	aaCookieConsentTitle: "ようこそ",
	aaCookieContentPrompt: "クッキーを許可しますか？",
	aaCookieConsentExplanation: '<p>このウェブサイトの運営上必要なクッキーに加え、エクスペリエンス及びサービス向上のために、以下のタイプのクッキーを使用しています：<strong>機能性クッキー</strong>(設定の保存等のエクスペリエンス向上目的)、<strong>パフォーマンスクッキー </strong> (ウェブサイトパフォーマンス測定及びエクスペリエンス向上目的)、 <strong>広告/ターゲティングクッキー </strong> (広告キャンペーン実施先を対象に第三者により設定され、かつ、関連する広告を提供する目的)、 </p><p> 詳細については<a target="_blank" href="https://www.ey.com/en_us/cookie-policy">クッキーポリシー</a>を参照してください。</p>',
	aaCookieConsentExplanationWithDoNotTrack: '<p>このウェブサイトの運営上必要なクッキーに加え、エクスペリエンス及びサービス向上のために、以下のタイプのクッキーを使用しています：<strong>機能性クッキー</strong>(設定の保存等のエクスペリエンス向上目的)、<strong>パフォーマンスクッキー </strong> (ウェブサイトパフォーマンス測定及びエクスペリエンス向上目的)、 <strong>広告/ターゲティングクッキー </strong> (広告キャンペーン実施先を対象に第三者により設定され、かつ、関連する広告を提供する目的)、</p><p>ブラウザで「トラッキング拒否」設定が有効になっていることを検出しました。その結果、広告/ターゲティングクッキーは自動的に無効になっています。</p><p> 詳細については<a target="_blank" href="https://www.ey.com/en_us/cookie-policy">クッキーポリシー</a>を参照してください。</p>',
	aaCookieConsentDeclineOptionalAction: "オプショナルクッキーを拒否します",
	aaCookieConsentAcceptAllAction: "全てのクッキーを許可します",
	aaCookieConsentCustomizeAction: "クッキーのカスタマイズ",
	aaCookieConsentCustomizeURL: 'https://www.ey.com/en_us/cookie-settings',

	// Cookie Settings
	cookieSettings: {
		title: "クッキーの設定",
		explanation: "ey.com及びMy EYプラットフォームでのクッキーの使用に同意してください。以下にリストされているクッキーの種類から1つ以上を選択し、選択内容を保存してください。クッキーの種類とその目的の詳細については、以下のリストを参照してください。",
		emptyCookieListNotice: "このカテゴリのクッキーはこのアプリでは使用されていません",
		nameTableHeader: "クッキー名",
		providerTableHeader: "クッキーの提供者",
		purposeTableHeader: "クッキーの目的",
		typeTableHeader: "クッキーの種類",
		durationTableHeader: "クッキーの有効期間",
		formSubmit: "選択の保存",
		requiredCookieListTitle: "必須クッキー",
		functionalCookieListTitle: "機能性クッキー",
		functionalCookieAcceptance: "以下の機能性クッキーを受け入れます",
		functionalCookieExplanation: "機能性クッキーによりエクスペリエンスを向上させることができます(たとえば選択した設定を記憶するなど)。",
		performanceCookieListTitle: "パフォーマンスクッキー",
		performanceCookieAcceptance: "以下のパフォーマンスクッキーを受け入れます",
		performanceCookieExplanation: "パフォーマンスクッキーはWebサイトのパフォーマンスを測定し、エクスペリエンスの向上に繋がります。パフォーマンスクッキーを使用するにあたり、個人データは保存されず、これらのクッキーを通じて収集された情報を集約され匿名化された形式でのみ使用します。",
		advertisingCookieListTitle: "ターゲティングクッキー",
		advertisingCookieAcceptance: "以下の広告/ターゲティングクッキーを受け入れます",
		advertisingCookieExplanation: "ユーザーのアクティビティとセッションを追跡するために使用する広告/ターゲティングクッキーにより、また広告クッキーの場合、広告キャンペーン実施先を対象に第三者により設定され、かつ、関連する広告を提供する目的で、よりパーソナライズされたサービスを提供できるようにします。",
		doNotTrackNotice: "ブラウザで「トラッキング拒否」設定が有効になっていることを検出しました。その結果、広告/ターゲティングクッキーは自動的に無効になります。",
	},

	//Copyright
	copyrightMessage: "Copyright © <year> all rights reserved",
	addWorkspace: 'JA JP Add workspace'
};

export const errors = {
	// GenericErrors Codes
	'0005': "トランザクション保留中",
	'0006': "トランザクションが失敗しました",
	'0008': "次の文字を含めることはできません：\\",
	'0010': "アークコール失敗",

	0: "要求が失敗しました",
	928: "未承認のアクセス - エンゲージメントにアクセスできません。権限を付与するには、ヘルプデスクに連絡してください。",

	1009: "データサーバーが見つかりません。",
	1010: "データセンターが見つかりません。",
	1011: "この国のデータセンターが設定されていません",
	1013: "Country IDまたはEngagement IDが見つかりません",
	1014: "カントリーデータセンターが見つかりません",
	1016: "データベースロケータのマッピングが失敗しました",
	1017: "oデータクエリはNULLまたは空欄にはできません",
	1018: "oデータクエリにフィールドが含まれていません",
	1019: "ユニバーサルデータセンターが見つかりません",
	1020: "システム上にユーザーが存在しません",
	1021: "レポーティングは選択されたロケーションでは使用できません。詳細については、現地のEY Canvas Championにご連絡ください。",

	// Scope & Strategy 1021 - 1200
	1026: "列が見つかりません",
	1030: "クライアント名が見つかりません",

	// Restore Engagement
	1101: "選択された国についてエンゲージメントのリストアはできません。ページを更新し再試行してください。",

	// Execution   1201-1400
	1219: "方法が見つかりません",
	1234: "Role IDはNULLまたは空欄にすることはできません",
	1260: "作業中のアイテムが別のユーザーによって削除されました。",
	1300: "無効な要求オブジェクト",
	1301: "無効なエンゲージメントロール",
	1302: "無効なユーザー言語",
	1303: "エンゲージメントユーザーの詳細が見つかりません",
	1304: "無効なロケーション",
	1305: "エンゲージメント名が見つかりません",
	1306: "エンゲージメント名が最大文字数（全角127文字、半角255文字）を超えています",
	1307: "カバーされている期間終了日が見つかりません",
	1308: "無効な日付",
	1309: "「監査報告日が見つかりません」",
	1310: "エンゲージメントアーカイブの期限が見つかりません",
	1311: "「エンゲージメント・アーカイブの期限は本日以前にすることはできません。」",
	1312: "エンゲージメントコードが重複しています",
	1313: "ロケーションが見つかりません",
	1314: "ワークスペース名が見つかりません",
	1315: "ワークスペース名が最大文字数（全角127文字、半角255文字）を超えています",
	1316: "クライアントコードが最大文字数（全角25文字、半角50文字）を超えています",
	1317: "クライアント名が最大文字数（全角127文字、半角255文字）を超えています",
	1318: "ワークスペースがトレーニング用かどうかを示すフラグの値が無効です",
	1319: "ワークスペースがトレーニング用であることを示すフラグが見つかりません",
	1320: "クライアントが有効かどうかを示すフラグが見つかりません",
	1321: "クライアントが有効かどうかを示すフラグの値が無効です",
	1322: "クライアントIDが無効です",
	1323: "エンゲージメントコードが見つかりません",
	1324: "エンゲージメントIDが見つかりません",
	1325: "オフィスIDが無効です",
	1052: "無効なサービスラインIDです",
	1053: "無効なサブサービスラインIDです",
	1326: "カントリーIDが見つかりません",
	1327: "言語IDが無効です",
	1328: "エンゲージメントチームメンバーステータスが無効です",
	1329: "エンゲージメントステータスが無効です",
	1330: "エンゲージメントは別のチームメンバーによって既に削除されています",
	1331: "期末日が見つかりません",
	1339: "最大エンゲージメント数を超えました",
	1340: "ロケーションの詳細が見つかりません",
	1341: "エンゲージメントデータの更新が正しく受信されていません",
	1342: "現在のエンゲージメントに関するエンゲージメントの詳細が見つかりません",
	1343: "現在のエンゲージメントに関するエンゲージメントのサマリーデータが見つかりません",
	1344: "選択されたIDに対するエンゲージメントは存在しません",
	1359: "アクティブなエンゲージメントの1つに対するデータセンターIDが無効です。",
	1363: "ユニバーサルでのエンゲージメントの作成が失敗しました",
	1364: "LDCでのエンゲージメントの作成が失敗しました",
	1373: "既存のワークスペースを選択するか、新しいワークスペースを作成します",
	1374: "エンゲージメント名はすでに存在します。テンプレートをインポートするには、新しいエンゲージメント名を入力してください。",
	1375: "有効期限が誤っている場合は更新できません。",
	1379: "コンテンツを更新しています。後で再試行してください。長時間問題が継続する場合は、ITヘルプデスクに連絡してください。",
	1380: "アーカイブ再試行は完了できませんでした。ページを更新し再試行してください。エラーが続く場合は、ヘルプデスクに連絡してください。",
	1381: "ワークスペースとエンゲージメントの国Idが一致しないため、エンゲージメントの作成/ロールフォワード/リストアに失敗しました。",
	1382: "ワークスペースとエンゲージメントのプライマリーオフィスが一致しないため、エンゲージメントの作成/ロールフォワード/リストアに失敗しました。",
	1384: "このエンゲージメントのリストアもしくはロールフォワードは既に進行中です。後ほど再試行してください。",
	1386: "新しいエンゲージメントにこのワークスペースは対応していません。ページを更新し再試行してください。問題が解決しない場合は、ヘルプデスクに連絡してください。",
	1385: "選択したロケーションではエンゲージメントを作成できません。ぺージを更新して、再試行してください。問題が解消しない場合には、ヘルプデスクに連絡してください。",
	1389: "有効期限が切れたレビューエンゲージメントは更新できません。",
	// Invite Team Members (1401 - 1500)
	1403: "エンゲージメントのウェルカムメッセージが最大文字数（全角100文字、半角200文字）を超えています",
	1406: "エンゲージメントコレクションが見つかりません",
	1424: "エンゲージメントまたはユーザーの詳細が見つかりません",
	1425: "エンゲージメントの詳細が見つかりません",

	// User details Error Codes (1426 - 1434)
	1426: "First nameは必須です",
	1427: "Last nameは必須です",
	1428: "イニシャルは必須です",
	1429: "オフィスは必須です",
	1430: "電話番号は必須です",
	1431: "言語は必須です",
	1432: "優先するデータフォーマットは必須です",
	1433: "優先するナンバーフォーマットは必須です",
	1434: "ユーザーの詳細はNULLまたは空欄にすることはできません",
	1436: "競合が検出されました。問題が解決しない場合は、ヘルプデスクまでお問い合わせください。",
	1438: "ユーザーコレクションを空欄にすることはできません。",
	1448: "選択された国はEY Canvasの最新バージョンが反映されておりません。導入タイムラインについてはEY Atlasを参照しアップデート後に再試行してください。エラーが続く場合は、ヘルプデスクに連絡してください。",

	1502: "この時点で操作を完了できません。ページを更新してもう一度やり直してください。問題が解決しない場合は、ヘルプデスクに連絡してください。",
	1504: "この時点で操作を完了できません。ページを更新してもう一度やり直してください。問題が解決しない場合は、ヘルプデスクに連絡してください。",
	1505: "入力が無効です",

	// Common control errors (1601 - 1700)
	1601: "パーセンテージが無効です",
	18000: "ARC処理エラー: ARCがメンテナンス中であるため、要求された操作は実行できません。後で試してみてください。",
	18306: "ARC処理エラー: CanvasArchivalGUIDに関連するARCフォームが見つかりません",
	18307: "ARC処理エラー: エンゲージメントはARCで削除されました。選択したオプションを続行することはできません。追加のサポートが必要な場合は、ARC Adminに問い合わせてください。",
	18309: "ARC処理エラー: アーカイブされたエンゲージメントが規制上の制限下にあるため、要求された操作は実行できません。追加のサポートが必要な場合は、ARC Adminに問い合わせてください。",
	18320: "ARC処理エラー: ARCフォームは保留中(Suspended)であり、修正できません。",
	18327: "ARC処理エラー: ARCフォームは削除状態で保留中であり、修正できません。",
	18328: "ARC処理エラー: エンゲージメントはARCで削除とマークされているため、要求された操作は実行できません。追加のサポートが必要な場合は、ARC Adminに問い合わせてください。",
	18329: "ARC処理エラー: このエンゲージメントは、パートナーからアクセスできません。追加のサポートが必要な場合は、ARC Adminに問い合わせてください。",
	18330: "ARC処理エラー: 規制上の制限があるため、エンゲージメントがロックされているため、要求された操作は実行できません。追加のサポートが必要な場合は、ARC Adminに問い合わせてください。",
	2027: "不適切な要求",
	2046: "未承認",
	2047: "禁止",
	2048: "レコードが見つかりません",
	2049: "エンティティが大きすぎます",
	2052: "使用できません",
	2053: "データの取得中にエラーが発生しました。ページを更新してもう一度やり直してください。問題が解決しない場合は、ヘルプデスクまでお問い合わせください。",
	2056: "汎用エラーが発生しました",
	2057: "サービスが利用できません",

	// Roll Forward Error Codes (2301-2400)
	2301: "ロールフォワード中にアーカイブされたファイルは見つかりませんでした",
	2302: "ロールフォワード中にアーカイブリファレンスコールが失敗しました",
	2303: "ロールフォワード中に返却されたアーカイブファイルには一意の識別子がありません",
	2304: "ロールフォワード中に返却されたアーカイブファイルにはファイルストアURLがありません",
	2305: "ロールフォワード中に返却されたアーカイブファイルにはハッシュ値がありません",
	2306: "ユーザーにはこの操作を実行する権限がありません",
	2307: "許容されたリストア済のエンゲージメントコピー数を上回りました",

	// Link Error Codes
	2568: "現在のコンタクト先ではないため、リンクは使用できなくなりました。ページを更新してください。",
	3005: "プライマリチームは、すでに[グループエンゲージメント]からコンポーネントを削除しています。",
	2571: "リンク要求が更新されました。ページを更新してください。",

	// Error for v1 engagement not supported features
	4001: "「選択したエンゲージメントは、古いバージョンのEY Canvasで作成されたものであり、選択されたロケーションのローカルデータセンターが最新バージョンのEY Canvasにアップグレードされるまでリストアできません。詳細については、ヘルプデスクにお問い合わせください。」",
	4002: "選択されたエンゲージメントは、古いバージョンのEY Canvasで作成されました。ローカルデータセンターが最新バージョンにアップグレードされるまでは、アクセスは使用できません。詳細については、ヘルプデスクまでお問い合わせください。",
	4214: "テンプレートは、選択したロケーションのワークスペースにインポートできません。別のロケーションを選択して再度実施してください",

	// Common framework error
	4205: "コピーリクエストの詳細が見つかりません",
	401000: "ネットワークの変更が検出されました。続行するにはページを再読み込みしてください。",

	// CopyHub concurrency scenarios
	4217: "データパッケージリクエストのステータスが変更されました。ページを更新して再度実施してください。",
	4219: "EY Canvasの異なるバージョン間のコピーは、コピープロセス強化のため、このリリースではサポートされていません。導入スケジュールの詳細については、EY Atlasを参照してください。データセンターがアップグレードされた後、コピーを作成できます。",
	4220: "テンプレートバージョンがこの国ではサポートされていません。",
	4221: "テンプレートバージョンがこの国ではサポートされていません。",
	4222: "インポート先がEY Canvasの最新バージョンにアップグレードされるまで、インポートは完了しません。続行するには別のロケーションを選択してください。問題が続く場合はヘルプデスクに連絡してください。",

	// Error for Mobility API
	4501: "エンゲージメントに利用可能なタスクはありません",
	4502: "エンゲージメントに利用可能なTo-doはありません",

	// Counrty API version error
	5001: "「選択したロケーションでは、古いバージョンのEY Canvasが使われているため、ローカルデータセンターが最新バージョンのEY Canvasにアップグレードされるまで、新しいワークスペースにロールフォワードすることはできません。詳細については、ヘルプデスクまでお問い合わせください。",

	//CRP checks
	5101: "現在Canvas External Access Portalは利用できないため、このエンゲージメントのレビュー用コピーを編集できません。 詳細については、ヘルプデスクに連絡してください。",
	5102: "このエンゲージメントはCRPコピーの作成中です。後でもう一度試してみてください。",
	5103: "現在Canvas External Access Portalは利用できないため、このエンゲージメントのレビュー用コピーを編集できません。 詳細については、ヘルプデスクに連絡してください。",
	5104: "このエンゲージメントにおいて文書上の制限を付すことができません。オプションのチェックを外して再試行してください。",

	18100: "サービスラインが見つかりません。",
	18101: "サブサービスラインが見つかりません。",

	//RBAC realted error messages.
	935: "この操作を実行するための権限が不足しています。 エンゲージメント管理者に連絡して、この操作を実行する許可を要求してください。 ",
	8001: "現在、操作が完了できません。ページを更新して再試行してください。問題が継続する場合はヘルプデスクに連絡してください。",
	336699: "タイムサマリーを読込むことができません。ページを更新して、再試行してください。エラーが続く場合は、ヘルプデスクに連絡してください。",

	//Concurrency errors
	7821: "あなたはすでにインバイトに返答しています。ページを更新して再試行してください。",

	/*Information Security */
	200001: "このアクションはEY Information Securityによって禁止されました。ページを更新し再試行してください。エラーが続く場合は、ヘルプデスクに連絡してください。"
};

export const adminErrors = {
	1332: "アーカイブ再試行の準備中にエラーが発生しました",
	1501: "ユーザーはアクティブでありません。ページを更新し再試行してください。",
	156: "このモジュールへの十分な権限がありません",
	70740: "変更を保存できません。エンゲージメントには、エンゲージメントを管理する権限を有し、かつ独立性を遵守するアクティブなメンバーが少なくとも1名必要です。問題が解決しない場合は、ヘルプデスクに連絡してください。",
	70790: "ドメインが許可されていません。再試行してください。エラーが続く場合は、ヘルプデスクに連絡してください。",
	70800: "第三者をインバイトするユーザーが無効です。",
	70810: "第三者に無効な/非アクティブなRBACの役割が選択されました。",
	70820: "第三者の有効なRBACの役割を選択します。",
	70830: "第三者の読み取り専用の役割を選択します。"
};

export const actionTypes = {
	1: "システムメッセージ",
	2: "エンゲージメントのインバイト",
	3: "アクセスが拒否されました",
	4: "エンゲージメントの削除リクエスト",
	5: "エンゲージメントの作成エラー",
	6: "エンゲージメントのアクセスリクエスト",
	7: "削除済のエンゲージメント",
	8: "アーカイブ済のエンゲージメント",
	9: "非アクティブ化されたエンゲージメント",
	10: "アーカイブ期日の上書き",
	11: "グループリンクリクエスト",
	12: "受領したグループインストラクション",
	13: "受領したコピー",
	14: "進行中のエンゲージメント"
};
export const timephaseType = {
	'-1': "対象期間終了日",
	'-2': "監査報告書日",
	'-3': "アーカイブ期限",
	'1': "範囲",
	'2': "戦略",
	'3': "ウォークスルー",
	'4': "期中統制テスト",
	'5': "期中実証テスト",
	'6': "ウォークスルー（期末プロセス）",
	'7': "期末統制テスト",
	'8': "期末実証テスト",
	'9': "総括",
	'10': "該当なし",
	'11': "実施",
	'12': "スコープ及び戦略 - 品質活動",
	'13': "その他のスコープ及び戦略",
	'14': "CRA",
	'15': "リスク評価及び重要性",
	'16': "スコープ及び戦略の確定",
	'17': "ポスト・インテリム - プロジェクトマネジメント"
};
export const dataExport = [
	"アーカイブフォーム",
	"Canvasフォーム",
	"コラボレーション",
	"Controlフォーム",
	"文書",
	"タスク",
	"チームメンバー",
	"試算表"
];
export const dataExportReportNames = {
	'アーカイブフォーム': 'Archive Form',
	'Canvasフォーム': 'Canvas Form Report',
	'コラボレーション': 'Collaboration',
	'Controlフォーム': 'Control Report',
	'文書': 'All Documents Report',
	'タスク': 'All Tasks Report',
	'チームメンバー': 'Team Members',
	'試算表': 'Numbers Report'
};
export const projectManagement = [
	"コラボレーションの内容",
	"デジタルスコアカード",
	"マイルストーン",
	"レビューノート",
	"バリデーションチェック"
];
export const projectManagementReportNames = {
	'コラボレーションの内容': 'Client Collaboration Dashboard',
	'マイルストーン': 'Milestone Report',
	'レビューノート': 'Review Notes Report',
	'バリデーションチェック': 'Validation Checks Report'
};
export const summaryScreenDefaults = [
	"監査計画サマリ",
	"監査計画及び関連するエビデンス",
	"Controls Review Tool (CRT)",
	"CRAサマリ",
	"発見事項",
	"ITアプリケーション/サービス受託会社サマリ",
	"SCOTサマリ",
	"SEM"
];
export const summaryScreenDefaultsReportNames = {
	'監査計画サマリ': 'Audit Plan Report',
	'監査計画及び関連するエビデンス': 'Audit Plan with Related Evidence',
	'Controls Review Tool (CRT)': 'Controls Review Tool (CRT)',
	'CRAサマリ': 'CRA Summary',
	'発見事項': 'Findings',
	'ITアプリケーション/サービス受託会社サマリ': 'ITSO CRT',
	'SCOTサマリ': 'SCOT Summary',
	'SEM': 'SEM'
};
export const summaryScreenInterim = [
	"作業計画サマリー",
	"関連するエビデンスと作業計画"
];
export const summaryScreenInterimReportNames = {
	'作業計画サマリー': 'Audit Plan Report',
	'関連するエビデンスと作業計画': 'Audit Plan with Related Evidence'
};
export const summaryScreenGroupAudit = [
	"監査計画レポート",
	"監査計画及び関連するエビデンス",
	"Controls Review Tool (CRT)",
	"CRAサマリ",
	"発見事項",
	"グループインストラクションサマリ",
	"Group Report",
	"グループストラクチャサマリ",
	"ITアプリケーション/サービス受託会社サマリ",
	"SCOTサマリ",
	"SEM"
];
export const summaryScreenGroupAuditReportNames = {
	'監査計画レポート': 'Audit Plan Report',
	'監査計画及び関連するエビデンス': 'Audit Plan with Related Evidence',
	'Controls Review Tool (CRT)': 'Controls Review Tool (CRT)',
	'CRAサマリ': 'CRA Summary',
	'発見事項': 'Findings',
	'グループインストラクションサマリ': 'Group Instruction Summary',
	'Group Report': 'Group Report',
	'グループストラクチャサマリ': 'Group Structure Summary',
	'ITアプリケーション/サービス受託会社サマリ': 'ITSO CRT',
	'SCOTサマリ': 'SCOT Summary',
	'SEM': 'SEM'
};
export const summaryScreenGroupAuditInterim = [
	"グループインストラクションサマリ",
	"グループ構造サマリ",
	"作業計画サマリ",
	"作業計画及び関連するエビデンス"
];
export const summaryScreenGroupAuditInterimReportNames = {
	'グループインストラクションサマリ': 'Group Instruction Summary',
	'グループ構造サマリ': 'Group Structure Summary',
	'作業計画サマリ': 'Audit Plan Report',
	'作業計画及び関連するエビデンス': 'Audit Plan with Related Evidence'
};
export const rolelistitems = [{
	id: 8,
	label: "スタッフ"
},
{
	id: 7,
	label: "シニア"
},
{
	id: 6,
	label: "マネージャー"
},
{
	id: 5,
	label: "シニアマネージャー"
},
{
	id: 3,
	label: "エグゼクティブディレクター"
},
{
	id: 4,
	label: "プリンシパル"
},
{
	id: 11,
	label: "その他のパートナー"
},
{
	id: 2,
	label: "業務執行社員"
},
{
	id: 1,
	label: "筆頭業務執行社員"
},
{
	id: 10,
	label: "独立審査担当社員"
},
{
	id: 17,
	label: "AQRレビューアー"
},
{
	id: 16,
	label: "法務"
},
{
	id: 12,
	label: "GDS - スタッフ"
},
{
	id: 30,
	label: "GDS - シニア"
},
{
	id: 31,
	label: "GDS - マネージャー"
}
];
export const roleType = {
	'11': "その他のパートナー",
	'24': "その他(例:デューディリジェンスレビュー)",
	'15': "エグゼクティブサポートサービス",
	'18': "Component Team（構成単位チーム）",
	'17': "AQRレビューアー",
	'1': "筆頭業務執行社員",
	'2': "業務執行社員",
	'9': "インターン",
	'13': "アドバイザリー (FAIT, TAS, Human Capital or Other)",
	'14': "税務",
	'27': "業種",
	'10': "独立審査担当社員",
	'25': "オフィス",
	'8': "スタッフ",
	'20': "クライアントの職員",
	'19': "クライアントの管理者",
	'5': "シニアマネージャー",
	'16': "法務",
	'26': "エリア",
	'23': "規制当局",
	'22': "内部監査部門の職員",
	'3': "エグゼクティブディレクター",
	'21': "内部監査部門の管理者",
	'6': "マネージャー",
	'4': "プリンシパル",
	'7': "シニア",
	'12': "GTH",
	'28': "国",
	'29': "グローバル",
	'12': "GDS - スタッフ",
	'30': "GDS - シニア",
	'31': "GDS - マネージャー"
};

export const copyObjectGroupType = {
	1: "エンゲージメント",
	2: "勘定科目",
	3: "SCOTs",
	4: "ＩＴアプリケーション&受託会社",
	5: "リクエスト",
	6: "エビデンス",
	7: "数値",
	8: "リクエストおよび外部タスク",
	9: "一時ファイル",
	10: "前期のエビデンス",
	11: "最近削除されたファイル",
	12: "履歴ファイル",
	13: "エンゲージメントテンプレート",
	14: "GCOコピーのリストア",
	15: "外部レビュー用エンゲージメントコピー",
	16: "アクティブなエンゲージメント外部レビュー",
	17: "Canvasフォーム",
	20: "エビデンス",
	21: "エンゲージメント",
	22: "勘定科目",
	23: "SCOTs",
	24: "エンゲージメントのロールフォワード",
	27: "外部証拠",
	28: "ガイド付きワークフロー",
	29: 'JA JP Engagement by Entity',
};

export const EngagementStatusText = [
	"未設定",
	"アクティブ",
	"停止中",
	"アーカイブ済",
	"削除済",
	"アーカイブ中",
	"再アクティベート済",
	"リストア済",
	"削除としてマーク済",
	"アーカイブエラー",
	"ロールフォワードエラー",
	"再アクティベートエラー",
	"リストアエラー",
	"ロールフォワードエラー中",
	"再アクティベート中",
	"リストア中",
	"GAMxマイグレーション中",
	"GAMxマイグレーションエラー",
	"トランザクション作成の保留中",
	"トランザクションの作成失敗",
	"トランザクションの更新保留中",
	"トランザクションの更新失敗",
	"コピー中",
	"コピーエラー",
	"テンプレートコピーエラー",
	"外部レビュー用エンゲージメントの作成中",
	"外部レビュー用エンゲージメントのエラー",
	"外部レビュー用エンゲージメント"
];
export const overdueIssues = {
	partnerInvitationPending: "パートナーインバイト未了",
	eqrInvitationPending: "EQRインバイト未了",
	invalidEngagementCode: "無効なエンゲージメントコード",
	overdueArchiveDeadline: "アーカイブ期日の更新",
	upcomingArchiveDeadline: "アーカイブ期限前",
	contentDeliveryInProgress: "コンテンツの配信中",
	overdueMilestones: "チームマイルストーン期限超過",
	overduePartnerTasks: "パートナータスク期限超過",
	overdueEqrTasks: "EQRタスク期限超過"
};
export const engagementMetricNames = [{
	propName: 'preparer',
	value: "作成へ"
},
{
	propName: 'forMyReview',
	value: "レビューへ"
},
{
	propName: 'upcomingReview',
	value: "レビュー待ち"
},
{
	propName: 'reviewNotesAssigned',
	value: "私に対するノート"
},
{
	propName: 'reviewNotesAuthored',
	value: "私が作成したノート"
},
{
	propName: 'clientRequests',
	value: "リクエスト"
},
{
	propName: 'timephases',
	value: "タイムフェーズ"
},
{
	propName: 'groupTasks',
	value: "グループタスク"
},
{
	propName: 'groupDeliverables',
	value: "グループのデリバラブルズ"
},
{
	propName: 'automationDocuments',
	value: "オートメーション"
}
];
export const metricFilterComingDue = [{
	value: 1,
	label: "明日の予定"
},
{
	value: 3,
	label: "今後3日間の予定"
},
{
	value: 5,
	label: "今後5日間の予定",
	isDefault: true
},
{
	value: 10,
	label: "今後10日間の予定"
},
{
	value: 30,
	label: "今後30日間の予定",
	isMax: true
}
];
export const metricFilterWhatsNew = [{
	value: 1,
	label: "[新着：昨日]"
},
{
	value: 3,
	label: "[新着：過去3日間]"
},
{
	value: 5,
	label: "[新着：過去5日間]",
	isDefault: true
},
{
	value: 10,
	label: "[新着：過去10日間]",
	isMax: true
}
];

export const type1Alerts = [
	"hasPartnerInvited",
	"hasEqrInvited",
	"hasValidEngagementCode",
	"overdueArchiveDeadline"
];

export const engagementStatuses = {
	1: "アクティブ",
	2: "停止中",
	3: "アーカイブ済",
	4: "削除済",
	5: "アーカイブ中",
	6: "再アクティベート済",
	7: "リストア済",
	8: "削除としてマーク済",
	9: "アーカイブエラー",
	10: "ロールフォワードエラー",
	11: "再アクティブ化実行エラー",
	12: "リストアエラー",
	13: "ロールフォワード中",
	14: "再アクティベート中",
	15: "リストア中",
	16: "GAMxマイグレーション中",
	17: "GAMxマイグレーションエラー",
	18: "トランザクション作成の保留中",
	19: "トランザクションの作成失敗",
	20: "トランザクションの更新保留中",
	21: "トランザクションの更新失敗",
	22: "コピー中",
	23: "コピーエラー",
	24: "テンプレートコピーエラー",
	25: "外部レビュー用エンゲージメントの作成中",
	26: "外部レビュー用エンゲージメントのエラー",
	27: "外部レビュー用エンゲージメント",
	28: "独立したユニバーサルへローカライズ",
	29: "独立したユニバーサルアーカイブへローカライズ",
	30: "コンテンツの配信中",
	31: "コンテンツの配信エラー",
	32: "コンテンツの配信失敗",
	33: "Canvas External Access Portal V2",
	34: "Canvas External Access Portal V2 進行中",
	35: "Canvas External Access Portal V2 エラー",
	100: "データセンタースプリット"
};

export const overrideArchiveDeadlineDateOptions = [{
	value: 0,
	label: "デフォルト"
},
{
	value: 10,
	label: "10日"
},
{
	value: 14,
	label: "14日"
},
{
	value: 20,
	label: "20日"
},
{
	value: 21,
	label: "21日"
},
{
	value: 25,
	label: "25日"
},
{
	value: 30,
	label: "30日"
},
{
	value: 45,
	label: "45日"
}
];
export const ExternalReviewCopyExpiryDays = [{
	value: 30,
	label: "30日"
},
{
	value: 60,
	label: "60日"
},
{
	value: 90,
	label: "90日"
},
{
	value: 180,
	label: "180日"
}
];

export const serviceLines = [{
	servicelineid: 1,
	servicelinename: "Assurance"
},
{
	servicelineid: 3,
	servicelinename: "Strategy and Transactions"
},
{
	servicelineid: 2,
	servicelinename: "Consulting"
},
{
	servicelineid: 4,
	servicelinename: "Tax"
},
{
	servicelineid: 5,
	servicelinename: "GCO"
}
];

export const subServiceLines = [{
	subservicelineid: 1,
	servicelineid: 1,
	subservicelinename: "Audit"
},
{
	subservicelineid: 2,
	servicelineid: 1,
	subservicelinename: "FIDS"
},
{
	subservicelineid: 3,
	servicelineid: 2,
	subservicelinename: "ITRA"
},
{
	subservicelineid: 9,
	servicelineid: 1,
	subservicelinename: "FAAS"
},
{
	subservicelineid: 10,
	servicelineid: 1,
	subservicelinename: "ACR"
},
{
	subservicelineid: 11,
	servicelineid: 1,
	subservicelinename: "CCaSS"
},
{
	subservicelineid: 4,
	servicelineid: 3,
	subservicelinename: "Capital Transformation"
},
{
	subservicelineid: 5,
	servicelineid: 3,
	subservicelinename: "Transaction Support"
},
{
	subservicelineid: 6,
	servicelineid: 2,
	subservicelinename: "Performance Improvement"
},
{
	subservicelineid: 7,
	servicelineid: 2,
	subservicelinename: "Risk"
},
{
	subservicelineid: 7,
	servicelineid: 23,
	subservicelinename: "Business Consulting"
},
{
	subservicelineid: 24,
	servicelineid: 6,
	subservicelinename: "その他"
},
{
	subservicelineid: 8,
	servicelineid: 2,
	subservicelinename: "その他"
},
{
	subservicelineid: 12,
	servicelineid: 4,
	subservicelinename: "BTS"
},
{
	subservicelineid: 13,
	servicelineid: 4,
	subservicelinename: "Human Capital"
},
{
	subservicelineid: 14,
	servicelineid: 4,
	subservicelinename: "Law"
},
{
	subservicelineid: 15,
	servicelineid: 4,
	subservicelinename: "Indirect Tax"
},
{
	subservicelineid: 16,
	servicelineid: 4,
	subservicelinename: "GCR"
},
{
	subservicelineid: 17,
	servicelineid: 4,
	subservicelinename: "Transaction Tax"
},
{
	subservicelineid: 18,
	servicelineid: 4,
	subservicelinename: "ITS"
},
{
	subservicelineid: 19,
	servicelineid: 5,
	subservicelinename: "GCO"
},
{
	subservicelineid: 20,
	servicelineid: 2,
	subservicelinename: "Business Consulting"
},
{
	subservicelineid: 21,
	servicelineid: 2,
	subservicelinename: "Technology Consulting"
},
{
	subservicelineid: 22,
	servicelineid: 3,
	subservicelinename: "Transactions and Corporate Finance"
},
{
	subservicelineid: 23,
	servicelineid: 3,
	subservicelinename: "EY Parthenon"
},
{
	subservicelineid: 25,
	servicelineid: 1,
	subservicelinename: "Technology Risk"
}
];

export const modules = [{
	id: 1,
	name: "アクセスの管理"
},
{
	id: 2,
	name: "エンゲージメント"
},
{
	id: 3,
	name: "グループ権限"
},
{
	id: 4,
	name: "テンプレート"
},
{
	id: 5,
	name: "メッセージ"
},
{
	id: 6,
	name: "キャッシュ"
},
{
	id: 7,
	name: "データベースロケーター"
},
{
	id: 8,
	name: "文書"
},
{
	id: 9,
	name: "SharePointワークスペース"
}
];

export const roles = [{
	id: 17,
	ModuleId: 1,
	name: "読み取り専用"
},
{
	id: 18,
	ModuleId: 1,
	name: "ユーザー及び権限の追加/削除"
},
{
	id: 19,
	ModuleId: 2,
	name: "読み取り専用"
},
{
	id: 20,
	ModuleId: 2,
	name: "エンゲージメントの更新"
},
{
	id: 21,
	ModuleId: 2,
	name: "チームメンバーのインバイト"
},
{
	id: 22,
	ModuleId: 2,
	name: "エンゲージメントユーザーの更新"
},
{
	id: 23,
	ModuleId: 3,
	name: "読み取り専用"
},
{
	id: 24,
	ModuleId: 3,
	name: "ユーザー及び権限の追加/削除"
},
{
	id: 25,
	ModuleId: 3,
	name: "データベースロケーターの同期化"
},
{
	id: 26,
	ModuleId: 4,
	name: "読み取り専用"
},
{
	id: 27,
	ModuleId: 4,
	name: "テンプレートの更新"
},
{
	id: 28,
	ModuleId: 4,
	name: "テンプレートの削除"
},
{
	id: 29,
	ModuleId: 5,
	name: "読み取り専用"
},
{
	id: 30,
	ModuleId: 5,
	name: "メッセージの作成/更新"
},
{
	id: 31,
	ModuleId: 5,
	name: "メッセージの削除"
},
{
	id: 32,
	ModuleId: 6,
	name: "キャッシュの読み取りと削除"
},
{
	id: 33,
	ModuleId: 8,
	name: "文書のアクセス"
},
{
	id: 34,
	ModuleId: 2,
	name: "アーカイブの再試行"
},
{
	id: 38,
	ModuleId: 9,
	name: "読み取り専用"
},
{
	id: 39,
	ModuleId: 9,
	name: "ワークスペースの追加"
},
{
	id: 40,
	ModuleId: 9,
	name: "ワークスペースの削除"
}
];

export const countries = {
	1: "マヨット",
	2: "英国ヴァージン諸島",
	3: "スペイン",
	4: "ベリーズ",
	5: "ペルー",
	6: "スロバキア",
	7: "ベネズエラ",
	8: "ノルウェー",
	9: "フォークランド諸島 (マルビナス)",
	10: "モザンビーク",
	11: "中国大陸",
	12: "スーダン",
	13: "イスラエル",
	14: "ベルギー",
	15: "サウジアラビア",
	16: "ジブラルタル",
	17: "グアム",
	18: "ノーフォーク島",
	19: "ザンビア",
	20: "レユニオン",
	21: "アゼルバイジャン",
	22: "セントヘレナ",
	23: "イラン",
	24: "モナコ",
	25: "サンピエール島・ミクロン島",
	26: "ニュージーランド",
	27: "クック諸島",
	28: "セントルシア",
	29: "ジンバブエ",
	30: "イラク",
	31: "トンガ",
	32: "アメリカ領サモア",
	33: "モルディブ",
	34: "モロッコ",
	35: "国際監査基準 (ISA)",
	36: "アルバニア",
	37: "アフガニスタン",
	38: "ガンビア",
	39: "ブルキナファソ",
	40: "トケラウ",
	41: "リビア",
	42: "カナダ",
	43: "アラブ首長国連邦",
	44: "朝鮮民主主義人民共和国",
	45: "モントセラト",
	46: "グリーンランド",
	47: "ルワンダ",
	48: "フィジー",
	49: "ジブチ",
	50: "ボツワナ",
	51: "クウェート",
	52: "マダガスカル",
	53: "マン島",
	54: "ハンガリー",
	55: "ナミビア",
	56: "マルタ",
	57: "ジャージー",
	58: "タイ",
	59: "セントクリストファー・ネービス",
	60: "ブータン",
	61: "パナマ",
	62: "ソマリア",
	63: "バーレーン",
	64: "ボスニア・ヘルツェゴビナ",
	65: "フランス",
	66: "大韓民国",
	67: "アイスランド",
	68: "ポルトガル",
	69: "チュニジア",
	70: "ガーナ",
	71: "カメルーン",
	72: "ギリシア",
	73: "フランス領南極地方",
	74: "ハード島・マクドナルド諸島",
	75: "アンドラ",
	76: "ルクセンブルク",
	77: "サモア",
	78: "アンギラ",
	79: "オランダ",
	80: "ギニアビサウ",
	81: "ニカラグア",
	82: "パラグアイ",
	83: "アンティグア・バーブーダ",
	84: "国際財務報告基準 (IFRS)",
	85: "ニジェール",
	86: "エジプト",
	87: "バチカン市国",
	88: "ラトビア",
	89: "キプロス",
	90: "合衆国領有小離島",
	91: "ロシア その他の法人",
	92: "セントビンセント及びグレナディーン諸島",
	93: "ガーンジー",
	94: "ブルンジ",
	95: "キューバ",
	96: "赤道ギニア",
	97: "イギリス領インド洋地域",
	98: "スウェーデン",
	99: "ウガンダ",
	100: "北マケドニア",
	101: "エスワティニ",
	102: "エルサルバドル",
	103: "キルギス",
	104: "アイルランド",
	105: "カザフスタン",
	106: "ホンジュラス",
	107: "ウルグアイ",
	108: "ジョージア",
	109: "トリニダードトバゴ",
	110: "パレスチナ",
	111: "マルティニーク",
	112: "オーランド諸島",
	113: "フランス領ポリネシア",
	114: "コートジボワール",
	115: "モンテネグロ",
	116: "南アフリカ",
	117: "サウスジョージアサウスサンドウィッチ諸島",
	118: "イエメン",
	119: "香港",
	120: "ケニア",
	121: "チャド",
	122: "コロンビア",
	123: "コスタリカ",
	124: "アンゴラ",
	125: "リトアニア",
	126: "シリア",
	127: "マレーシア",
	128: "シエラレオネ",
	129: "セルビア",
	130: "ポーランド",
	131: "スリナム",
	132: "ハイチ",
	133: "ナウル",
	134: "サントメプリンシペ",
	135: "スバールバル諸島・ヤンマイエン島",
	136: "シンガポール",
	137: "モルドバ",
	138: "台湾",
	139: "セネガル",
	140: "ガボン",
	141: "メキシコ",
	142: "セーシェル",
	143: "ミクロネシア連邦",
	144: "アルジェリア",
	145: "イタリア",
	146: "サンマリノ",
	147: "リベリア",
	148: "ブラジル",
	149: "クロアチア",
	150: "フェロー諸島",
	151: "パラオ",
	152: "フィンランド",
	153: "フィリピン",
	154: "ジャマイカ",
	155: "フランス領ギアナ",
	156: "カーボベルデ",
	157: "ミャンマー",
	158: "レソト",
	159: "アメリカ領バージン諸島",
	160: "ケイマン諸島",
	161: "ニウエ",
	162: "トーゴ",
	163: "ベラルーシ",
	164: "ドミニカ",
	165: "インドネシア",
	166: "ウズベキスタン",
	167: "ナイジェリア",
	168: "ウォリス・フツナ",
	169: "バルバドス",
	170: "スリランカ",
	171: "イギリス",
	172: "エクアドル",
	173: "グアドループ",
	174: "ラオス",
	175: "ヨルダン",
	176: "ソロモン諸島",
	177: "東ティモール",
	178: "レバノン",
	179: "中央アフリカ共和国",
	180: "インド",
	181: "クリスマス諸島",
	182: "バヌアツ",
	183: "ブルネイ",
	184: "バングラデシュ",
	185: "南極大陸",
	186: "ボリビア",
	187: "トルコ",
	188: "バハマ",
	189: "コモロ",
	190: "西サハラ",
	191: "チェコ",
	192: "ウクライナ",
	193: "エストニア",
	194: "ブルガリア",
	195: "モーリタニア",
	196: "コンゴ",
	197: "リヒテンシュタイン",
	198: "ピトケアン諸島",
	199: "デンマーク",
	200: "マーシャル諸島",
	201: "日本",
	202: "オーストリア",
	203: "オマーン",
	204: "モンゴル",
	205: "タジキスタン",
	206: "スイス",
	207: "グアテマラ",
	208: "エリトリア",
	209: "ネパール",
	210: "マリ",
	211: "スロベニア",
	212: "北マリアナ諸島",
	213: "（該当なし）",
	214: "アルバ",
	215: "コンゴ",
	216: "カタール",
	217: "ギニア",
	218: "アメリカ",
	219: "エチオピア",
	220: "その他",
	221: "ギアナ",
	222: "ドイツ",
	223: "バミューダ",
	224: "タークス・カイコス諸島",
	225: "オーストラリア",
	226: "キリバス",
	227: "プエルトリコ",
	228: "パキスタン",
	229: "モーリシャス",
	230: "マラウイ",
	231: "トルクメニスタン",
	232: "カンボジア",
	233: "チリ",
	234: "ニューカレドニア",
	235: "パプアニューギニア",
	236: "ブーベ島",
	237: "ツバル",
	238: "キュラソー",
	239: "ドミニカ",
	240: "ベトナム",
	241: "ココス（キーリング）諸島",
	242: "グラナダ",
	243: "タンザニア",
	244: "アルゼンチン",
	245: "マカオ",
	246: "ベナン",
	247: "ルーマニア",
	248: "アルメニア",
	249: "グローバル",
	250: "中小企業向けIFRS",
	251: "US GAAP",
	252: "AICPA中小企業向け財務報告フレームワーク",
	253: "南スーダン",
	254: "コソボ",
	255: "アメリカ - 政府",
	256: "Russia OOO EY & OOO EY Vneshaudit",
	257: "アルメニア",
	258: "ベラルーシ",
	259: "ウズベキスタン"
};

export const common = {
	selected: "選択",
	cancel: "キャンセル",
	checkbox: {
		selectAllOn: "全てを選択",
		selectAllOff: "全ての選択を解除"
	},
	clear: "クリア",
	close: "クローズ",
	pagination: {
		show: "表示",
		iconHover: {
			first: "最初のページ",
			previous: "前のページ",
			next: "次のページ",
			last: "最後のページ"
		}
	},
	engv2Pagination: {
		show: "表示",
		iconHover: {
			first: "最初のページ",
			previous: "前のページ",
			next: "次のページ",
			last: "最後のページ"
		}
	},
	search: {
		clear: "検索のクリア",
		buttonTitle: "検索",
		placeholder: "検索",
		noResultsPlaceholder: "結果が見つかりません"
	},
	table: {
		remove: "削除",
		noDataPlaceholder: "利用可能なデータがありません",
		noResultsPlaceholder: "結果が見つかりません"
	},
	toggleSwitch: {
		no: "いいえ",
		yes: "はい"
	},
	wizard: {
		next: "続行",
		back: "戻る",
		finish: "完了"
	}
};

export const ConfidentialityTypes = [{
	id: 0,
	Name: "デフォルト"
},
{
	id: 10,
	Name: "低"
},
{
	id: 12,
	Name: "中"
},
{
	id: 11,
	Name: "高"
}
];

export const internalUserRoles = [{
	roleKey: 3,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "許可",
	roleNameUI: "エンゲージメントのアーカイブ",
	displayName: "エンゲージメントのアーカイブ",
	roleDisplayOrder: 2,
	tooltipText: "エンゲージメントのアーカイブ機能"
},
{
	roleKey: 4,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "許可",
	roleNameUI: "エンゲージメントの管理",
	displayName: "エンゲージメントの管理",
	roleDisplayOrder: 1,
	tooltipText: "内部や外部メンバーのインバイト、内部メンバーに権限を与える機能"
},
{
	roleKey: 5,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "許可",
	roleNameUI: "コンテンツアップデート",
	displayName: "コンテンツアップデート",
	roleDisplayOrder: 6,
	tooltipText: "プロファイル編集やコンテンツ更新受入の機能"
},
{
	roleKey: 7,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "許可",
	roleNameUI: "サービスリクエストの作成",
	displayName: "サービスリクエストの管理",
	roleDisplayOrder: 4,
	tooltipText: "サービスリクエストの作成やサービスリクエスト完了処理の機能"
},
{
	roleKey: 8,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "許可",
	roleNameUI: "グループの管理",
	displayName: "グループの管理",
	roleDisplayOrder: 5,
	tooltipText: "プライマリーもしくはリージョナルチームから受領したグループインストラクションを受諾または差戻"
},
{
	roleKey: 9,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "許可",
	roleNameUI: "エンゲージメントのコピー、ロールフォワード、リストア",
	displayName: "エンゲージメントのコピー、ロールフォワード、リストア",
	roleDisplayOrder: 3,
	tooltipText: "エンゲージメントのコピー、ロールフォワード、アーカイブをリストアする権限",
},
{
	roleKey: 0,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "機密性",
	roleNameUI: "デフォルト",
	displayName: "なし（デフォルト）",
	roleDisplayOrder: 1,
	tooltipText: "Default Confidential Documents Role"
},
{
	roleKey: 10,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "機密性",
	roleNameUI: "低",
	displayName: "低",
	roleDisplayOrder: 8,
	tooltipText: "Low Confidential Documents Role"
},
{
	roleKey: 11,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "機密性",
	roleNameUI: "高",
	displayName: "高",
	roleDisplayOrder: 10,
	tooltipText: "High Confidential Documents Role"
},
{
	roleKey: 12,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "機密性",
	roleNameUI: "中",
	displayName: "中",
	roleDisplayOrder: 9,
	tooltipText: "Moderate Confidential Documents Role"
},
// {
// roleKey: 13,
// sectionId:1,
// groupDisplayOrder: 200,
// groupName:"Confidentiality",
// roleNameUI: "Confidential",
// displayName: "Confidential",
// roleDisplayOrder: 11,
// tooltipText: 'Strictly Confidential Documents Role'
// },
{
	roleKey: 14,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "制限",
	roleNameUI: "読み取り専用",
	displayName: "読み取り専用",
	roleDisplayOrder: 12,
	tooltipText: "この機能は第三者のレビューアーがこのEY Canvasエンゲージメントをレビューする際にのみ使用されます。専門家として判断による適切な許可と承認が求められます。"
},
{
	roleKey: 16,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "制限",
	roleNameUI: "レビュー限定",
	displayName: "レビュー限定",
	roleDisplayOrder: 13,
	tooltipText: "レビュー限定はそのユーザーにCanvasエンゲージメントの情報閲覧、サインオフ、レビューノート機能の権限のみ与えます。この機能は他のEYユーザー（例えばグループ監査のプライマリーチーム）がこのエンゲージメントを編集はしないがレビューする必要がある場合に使用されます。",
},
{
	roleKey: 37,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "制限",
	roleNameUI: "独立性のみ",
	displayName: "独立性のみ",
	roleDisplayOrder: 14,
	tooltipText: "「独立性のみ」は、このユーザーにエンゲージメントの独立性フォームを完成させる権限を付与しますが、EY Canvasエンゲージメントに完全にアクセスすることはできません。これは、ユーザーがエンゲージメントの作業を実施したが、EY Canvasにアクセスする必要がない場合に使用されます。",
},
{
	roleKey: 6,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "許可",
	roleNameUI: "編集を元に戻す",
	displayName: "文書のロールバック",
	roleDisplayOrder: 7,
	tooltipText: "監査報告書日後に誤って編集されてしまった場合に文書をロールバックし、440Canvasフォームから消去することができます。"
},
{
	roleKey: 35,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "権限",
	roleNameUI: "EY Canvas FITイネーブルメント共有の管理",
	displayName: "ガイド付きワークフローの管理",
	roleDisplayOrder: 14,
	tooltipText: "EY Canvasエンゲージメント間でガイド付きワークフローを共有する権限"
},
{
	roleKey: 36,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "権限",
	roleNameUI: "EY Helixデータのインポート",
	displayName: "EY Helixデータのインポート",
	roleDisplayOrder: 15,
	tooltipText: "EY Helixからデータをインポートする権限"
}
];

export const restoreengagementexpirydates = [{
	value: 3,
	label: "3か月"
},
{
	value: 6,
	label: "6か月"
},
{
	value: 9,
	label: "9か月"
},
{
	value: 12,
	label: "12か月"
}
];

export const messageStatus = {
	0: "現在",
	1: "予定済",
	2: "期限切れ"
};

export const kendoLabels = {
	alignLeft: "左揃え",
	alignRight: "右揃え",
	alignCenter: "中央揃え",
	alignFull: "両端揃え",
	addComment: "コメント追加",
	addColumnBefore: "左に列を挿入",
	addColumnAfter: "右に列を挿入",
	addRowAbove: "上に行を挿入",
	addRowBelow: "下に行を挿入",
	bulletList: "番号なしリストの挿入",
	bold: "太字",
	backColor: "ハイライト",
	createLink: "ハイパーリンクの挿入",
	createTable: "表の作成",
	cleanFormatting: "フォーマットのクリア",
	deleteRow: "行の削除",
	deleteColumn: "列の削除",
	deleteTable: "表の削除",
	fontSizeInherit: "フォントサイズ",
	foreColor: "色",
	format: "フォーマット",
	fontSize: "フォントサイズ",
	hyperlink: "webリンクの挿入",
	italic: "斜体",
	indent: "インデント",
	insertTableHint: "{0} × {1} の表を作成",
	"hyperlink-dialog-content-address": "Webアドレス",
	"hyperlink-dialog-title": "ハイパーリンクの挿入",
	"hyperlink-dialog-content-title": "タイトル",
	"hyperlink-dialog-content-newwindow": "新しいウィンドウでリンクを開く",
	"hyperlink-dialog-cancel": "キャンセル",
	"hyperlink-dialog-insert": "挿入",
	outdent: "インデント解除",
	orderedList: "番号付きリストの挿入",
	print: "印刷",
	pdf: "pdfに出力",
	redo: "やり直す",
	strikethrough: "取り消し線",
	subscript: "下付き",
	superscript: "上付き",
	underline: "下線",
	undo: "元に戻す"
};

export const kendoFormatOptions = [{
	text: "段落",
	value: "p"
},
{
	text: "見出し1",
	value: "h1"
},
{
	text: "見出し2",
	value: "h2"
},
{
	text: "見出し3",
	value: "h3"
},
{
	text: "見出し4",
	value: "h4"
},
{
	text: "見出し5",
	value: "h5"
},
{
	text: "見出し6",
	value: "h6"
}
];

export const kendoFontSize = [{
	text: '8',
	value: "8px"
},
{
	text: '9',
	value: "9px"
},
{
	text: '10',
	value: "10px"
},
{
	text: '11',
	value: "11px"
},
{
	text: '12',
	value: "12px"
},
{
	text: '14',
	value: "14px"
},
{
	text: '16',
	value: "16px"
},
{
	text: '18',
	value: "18px"
},
{
	text: '20',
	value: "20px"
},
{
	text: '22',
	value: "22px"
},
{
	text: '24',
	value: "24px"
},
{
	text: '26',
	value: "26px"
},
{
	text: '28',
	value: "28px"
},
{
	text: '36',
	value: "36px"
},
{
	text: '48',
	value: "48px"
},
{
	text: '72',
	value: "72px"
}
];

export const cacheOptionValue = [{
	value: 1,
	label: "LDC"
},
{
	value: 2,
	label: "ユニバーサル"
}
]
