import {EngagementReasonType, EngagementStatus} from '../../util/enumerations';

import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FormField from '@ey/motif-react-wrapper/FormField';
import Label from '@ey/motif-react-wrapper/Label';
import RadioButton from '@ey/motif-react-wrapper/RadioButton';
import RadioButtonGroup from '@ey/motif-react-wrapper/RadioButtonGroup';
import TextLink from '@ey/motif-react-wrapper/TextLink';
import Button from '@ey/button';
import Loader from '@ey/loader';
import ModalControl from '@ey/modal-control';
import {PatchEngagement} from '../../actions/engagementsaction';
import {SVGICONS} from '@ey/icons';
import env from '../../util/env';
import {labels} from '../../util/uiconstants';
import {removeNotification} from '../../actions/notificationsactions';
import styled from 'styled-components';

const EngagementRequestDeleteModal = (props) => {
	const dispatch = useDispatch();

	const {notification, id, hide, show, refreshDashboard} = props;
	const {workspacecountryid, isldcupgraded} = props.data;

	const userSettings = useSelector((store) => store.userSettings);

	const [isActing, setIsActing] = useState(false);
	const [badTryNoReasonEntered, setBadTryNoReasonEntered] = useState(false); // No reason to be here.
	const [badTryNoCommentReasonEntered, setBadTryNoCommentReasonEntered] =
		useState(false);
	const [deleteCommentText, setDeleteCommentText] = useState(
		notification?.otherText
	);
	const [deleteReasonTypeID, setDeleteReasonTypeID] = useState(
		notification?.otherId
	);

	let applyClass =
		badTryNoCommentReasonEntered || badTryNoReasonEntered ? 'red' : '';

	const onModalOkClick = () => {};

	const modelDelete = () => {
		return {
			id: id,
			data: {
				countryid: workspacecountryid,
				engagementstatusid: EngagementStatus.Deleted,
				deleteinitiatorcomments:
					deleteReasonTypeID == EngagementReasonType.Other
						? deleteCommentText
						: undefined,
				ispatchfromuniversal: true,
				deleteReasonTypeID: deleteReasonTypeID
			}
		};
	};

	const modelMarkedDeleteReject = () => {
		return {
			id: id,
			data: {
				engagementstatusid: EngagementStatus.Active,
				operationType: 'rejectengagementdelete',
				ispatchfromuniversal: true
			}
		};
	};

	const handleMarkedDeleteConfirm = async () => {
		if (
			deleteReasonTypeID == EngagementReasonType.Other &&
			(deleteCommentText == undefined || deleteCommentText?.length == 0)
		) {
			setBadTryNoCommentReasonEntered(true);
			return;
		}
		await patchEng('confirmDelete');
	};

	const patchEng = async (action) => {
		let model;
		setIsActing(true);

		switch (action) {
			case 'confirmDelete':
				model = modelDelete();
				break;
			case 'rejectDelete':
				model = modelMarkedDeleteReject();
				break;
			default:
				break;
		}

		try {
			await dispatch(
				PatchEngagement(
					env.getURL('serviceUrl') + '/engagements.json/' + id,
					JSON.stringify(model)
				)
			);
			await dispatch(removeNotification(parseInt(id)));
			refreshDashboard();
		} catch {
			setIsActing(false);
		} finally {
			hide();
		}
	};

	const handleMarkedDeleteReject = async () => {
		await patchEng('rejectDelete');
	};

	const handleDeleteCommentChange = (event) => {
		let text = event.target.value;
		if (text.trimLeft().length <= 4000) {
			setDeleteCommentText(text.trimLeft());
			setBadTryNoCommentReasonEntered(false);
		} else {
			event.target.value = deleteCommentText;
		}
	};

	const handleRationalRadioButtonChange = (event) => {
		if (event.target.value === '' || event.target.value === undefined) {
			setDeleteReasonTypeID(notification.otherId);
			setDeleteCommentText(notification.otherText);
		} else {
			setDeleteReasonTypeID(event.target.value);
		}
	};

	return (
		<div>
			{isActing ? (
				<section>
					<Loader view="fullscreen" />
				</section>
			) : (
				<ModalControl
					title={labels.markeddeleteconfirm}
					modalsize="small"
					showButtons={false}
					show={show}
					onOkClick={onModalOkClick}
					closeTitle={labels.close}
					onHide={hide}
					backdrop="static"
					focusTrapActive={false}
				>
					<RemoveMarkedDeleteStyle>
						<div id="message" className="delmsg-header">
							<span className="del-msg">
								{labels.markeddeleteconfirmmessage}
							</span>
							<span className="del-msg1">
								{labels.markeddeleteconfirmmessage1}
							</span>
							<section className="del-msgdescp">
								<span className="del-msg1">
									{labels.deleteengagementconfirmationmessageoneuser1}
									<TextLink
										variant="alt"
										href={env.getDocArcDocumentationUrl()}
										target="_blank"
									>
										{labels.deleteengagementconfirmationmessageoneuser2}
									</TextLink>
								</span>
								<span className="del-msg1">
									{labels.deleteengagementconfirmationmessageoneuser3}
								</span>
							</section>
						</div>
						<section className="deleng-radiogrp">
							<FormField>
								<Label>{labels.deleteengagementreasonquestion}</Label>
								<RadioButtonGroup>
									<RadioButton
										name="trainingengagement"
										id="trainingengagement"
										value={EngagementReasonType.TrainingEngagement.toString()}
										key="1"
										checked={
											deleteReasonTypeID ==
											EngagementReasonType.TrainingEngagement
										}
										onChange={handleRationalRadioButtonChange}
									>
										{labels.deletereasontrainingengagementlabel}
									</RadioButton>
									<RadioButton
										name="restoredorcopied"
										id="restoredorcopied"
										value={EngagementReasonType.RestoredOrCopiedEngagement.toString()}
										key="2"
										checked={
											deleteReasonTypeID ==
											EngagementReasonType.RestoredOrCopiedEngagement
										}
										onChange={handleRationalRadioButtonChange}
									>
										{labels.deletereasonrestoredorcopiedlabel}
									</RadioButton>
									<RadioButton
										name="createdbymistake"
										id="createdbymistake"
										value={EngagementReasonType.CreatedByMistake.toString()}
										key="3"
										checked={
											deleteReasonTypeID ==
											EngagementReasonType.CreatedByMistake
										}
										onChange={handleRationalRadioButtonChange}
									>
										{labels.deletereasoncreatedbymistakelabel}
									</RadioButton>
									{ isldcupgraded  && 
										<RadioButton
											name="terminedbyclient"
											id="terminedbyclient"
											value={EngagementReasonType.TerminatedByClient.toString()}
											key={EngagementReasonType.TerminatedByClient}
											checked={deleteReasonTypeID == EngagementReasonType.TerminatedByClient}
											onChange={handleRationalRadioButtonChange}
										>									
											{labels.deletereasonterminedbyclientlabel}
										</RadioButton>
									}
									<RadioButton
										name="other"
										id="other"
										value={EngagementReasonType.Other.toString()}
										key="5"
										checked={deleteReasonTypeID == EngagementReasonType.Other}
										onChange={handleRationalRadioButtonChange}
									>
										{labels.deletereasonotherlabel}
									</RadioButton>
								</RadioButtonGroup>
							</FormField>
						</section>
						{deleteReasonTypeID == EngagementReasonType.Other && (
							<section className="commentwrapper">
								<textarea
									placeholder={labels.deleteengagementreasonlabel}
									onChange={handleDeleteCommentChange}
									className={applyClass}
									value={deleteCommentText}
								/>

								{badTryNoCommentReasonEntered && (
									<section className="submit-error">
										<section>
											<SVGICONS styleName="small" id="icon" name="error" />
											<span>{labels.deleteengagementreasonrequired}</span>
										</section>
									</section>
								)}
							</section>
						)}

						{badTryNoReasonEntered && (
							<section className="commentwrapper">
								<section className="submit-error">
									<section>
										<SVGICONS styleName="small" id="icon" name="error" />
										<span>{labels.deleteengagementcommentreasonrequired}</span>
									</section>
								</section>
							</section>
						)}
					</RemoveMarkedDeleteStyle>
					<section className="modal-footer">
						{notification &&
							userSettings &&
							userSettings.id != notification.displayUserId && (
								<Button
									value={labels.confirmlabel}
									name={labels.confirmlabel}
									buttonType="primary"
									label={labels.confirmlabel}
									onClick={handleMarkedDeleteConfirm}
								/>
							)}
						<Button
							value={labels.reject}
							name={labels.reject}
							buttonType="secondary"
							label={labels.reject}
							onClick={handleMarkedDeleteReject}
						/>

						<Button
							value={labels.cancel}
							name={labels.cancel}
							buttonType="secondary"
							label={labels.cancel}
							onClick={hide}
						/>
					</section>
				</ModalControl>
			)}
		</div>
	);
};
export default EngagementRequestDeleteModal;

const RemoveMarkedDeleteStyle = styled.section`
	padding: var(--px-20) 0;
	.delmsg-header {
		line-height: normal;
		font-size: var(--px-14);
		.del-msg {
			font-weight: bold;
		}
		.del-msgdescp {
			margin-top: var(--px-26);
			.del-msg1 {
				font-size: var(--px-14);
			}
		}
	}
	.deleng-radiogrp {
		margin-top: var(--px-40);
		.motif-form-field {
			margin-bottom: 0;
		}
		.motif-label {
			width: 100%;
			margin-bottom: var(--px-15);
		}
		.motif-radio-button-group {
			svg {
				width: var(--px-13);
				height: var(--px-13);
				min-width: var(--px-13);
				min-height: var(--px-13);
				//margin-top: calc(var(--px-3) * -1);
			}
		}
	}
	& .commentwrapper {
		margin-top: var(--px-30);
		textarea {
			font-size: var(--px-14);
			background: var(--neutrals-100);
			border: none;
			border-bottom: var(--px-1) solid var(--neutrals-700);
			display: block;
			height: var(--px-120);
			width: 100%;
			max-width: 100%;
			margin-bottom: var(--px-10);
			resize: none;
			padding: var(--px-10);
			line-height: var(--px-17);
			&::placeholder {
				margin-left: var(--px-10);
			}
			&:hover:not(:disabled) {
				background: var(--neutrals-200);
			}
			&:focus:not(:disabled) {
				background: var(--neutrals-200);
				border-bottom-width: var(--px-2);
			}
			&.red {
				border-bottom: var(--px-1) solid var(--red-600);
			}
		}

		& .submit-error {
			font-size: var(--px-12);
			line-height: normal;
			height: var(--px-20);
			padding-bottom: var(--px-20);
			color: var(--red-600);

			& svg {
				fill: var(--red-600);
				vertical-align: text-bottom;
				margin-right: var(--px-5);
			}
		}
	}
`;
