/* eslint-disable prettier */

import {
	Addon
} from 'react-bootstrap/lib/InputGroup';

/**
 * Created by calhosh on 4/14/2017.
* ES MX resource file
 */
export const labels = {
	defaultDropdown: "Menú desplegable",
	status: "Estado",
	language: "Idioma",
	channel: "Canal",
	header: "Encabezado",
	headerName: "Nombre del encabezado",
	sectionCustomLabelLabel: "Etiqueta personalizada",
	sectionNamePlaceHolder: "Ingrese el título de la sección",
	showRelatedObjectLabel: "Mostrar objetos relacionados",
	errorBanner: "{0} Error(es)",
	engagements: "Compromisos",
	selectedengagements: "Compromiso",
	addEngagement: "Agregar compromiso ",
	archives: "Archivos",
	userInitialsTitle: "Opciones de usuario",
	clickForOptions: "Haga clic para ver más opciones",
	comingDue: "Próximo a vencer",
	whatsNew: "Qué hay de nuevo",
	auditMetrics: "Métricas de auditoría",
	id: "ID",
	archived: "Archivados",
	myarchives: "Mis archivos",
	allarchives: "Todos los archivos",
	logout: "Salir",
	norecords: "No se han encontrado registros",
	nomatches: "No se han encontrado coincidencias",
	notemplates: "No hay templates disponibles para el área/región seleccionada",
	pleasetryagain: "Inténtelo de nuevo",
	firstpage: "Primera página",
	previous: "Anterior",
	next: "Siguiente",
	prevpage: "Página anterior",
	nextpage: "Próxima página",
	lastpage: "Ultima página",
	allengagements: "Todos los compromisos",
	searchPlaceholder: "Búsqueda",
	searchEngagementsPlaceholder: "Buscar compromisos",
	clearSearch: "Limpiar búsqueda",
	searchTextPlaceHolder: "Búsqueda",
	show: "Mostrar",
	manageaccess: "Administrar el acceso",
	restore: "Restaurar",
	rollforward: "Traspaso",
	viewaccesslist: "Ver lista de acceso",
	teammembers: "Miembros del equipo",
	name: "Nombre",
	initials: "Iniciales",
	role: "Rol  ",
	usersettings: "Configuración de usuario",
	usersettingsnewengagements: "La configuración se aplica a compromisos recién creados",
	usersettingsallengagements: "La configuración se aplica a todos los espacios de trabajo y los compromisos",
	enterfirstname: "Mostrar nombre",
	enterlastname: "Mostarr apellido",
	firstname: "Nombre",
	lastname: "Apellido",
	enter: "Entrar",
	select: "Seleccionar…",
	email: "Dirección de correo electrónico",
	contact: "Número de teléfono",
	accesslist: "Lista de acceso",
	engagementsettings: "Configuración del compromiso",
	displayname: "Mostrar nombre",
	signoffinitials: "Iniciales para firma",
	primaryoffice: "Oficina primaria",
	roles: "Rol",
	phonenumber: "Número de teléfono",
	globalapplicationsettings: "Configuración global de la aplicación",
	displaylanguage: "Idioma de visualización",
	preferreddateformat: "Formato de fecha preferido",
	preferrednumberformat: "Formato de número preferido",
	save: "Salvar",
	cancel: "Cancelar",
	isRequired: "{0} es necesario",
	arcretrymessage: "EY Canvas ha retirado automaticamente su archivo, por favor espere 15 horas, desde la fecha en que este Canvas fue enviado para el proceso de recuperacion automatico, antes de contactar al Help Desk. Si el Help Desk puede resolverlo sin reactivar el compromiso, el archive continuara procesandose. si el help desk tiene que reactivar el compromiso en sustitucion del equipo para tomar acciones correctivas, la fecha del archive sera reseteada a cuando el equipo envie la solicitud de archive de nuevo. si esto va a causar una archivo tardio, el equipo debe incluir cierta documentacion en el compromiso, referenciando el numero de ticket del help desk, el problema, los pasos tomados para resolverlo de acuerdo a la politica de archive.",
	ArchiveRetryLink: "Reintento de archivo",
	workspacelist: "Lista de espacios de trabajo",
	engagementslist: "Lista de compromisos",
	accessrequested: "Acceso solicitado",
	noaccess: "Sin acceso",
	independenceOnlyNoAccessMessage: 'Su rol en el compromiso fue "Solo independencia". Póngase en contacto con un miembro del compromiso para conceder derechos para restaurar o traspasar el compromiso.',
	accessrejected: "Acceso rechazado",
	requestaccess: "Solicitar acceso",
	allarchiveinitialmsg: "Escriba el nombre del cliente, el nombre del área de trabajo o el nombre de la compromiso para empezar",
	noengagementsforusermessage: "Usted no tiene ningún compromiso activo. Haga clic en Crear compromiso para empezar.",
	noengagementstoimportto: "No tiene ningún engagement para importar.",
	favorites: "Favoritos",
	portfolioview: "Todos los compromisos v2",
	reviewengagement: "Revisar los compromisos",
	noreviewengagementsmessage: "Usted no tiene ninguna participación en la revisión.",
	noreviewengagementsmessagecontact: "No tiene ningún compromiso de revisión. Póngase en contacto con un miembro del equipo del compromiso si necesita acceso.",
	nofavoriteengagementsmsg1: "No tienes ningún compromiso favorito.",
	nofavoriteengagementsmsg2: "Para agregar compromisos a esta vista, vaya a Todos las compromisos ",
	nofavoriteengagementsmsg3: "y haga clic en el icono de favoritos en la tarjeta de compromisos.",
	markasfavorite: "Marcar como favorito",
	unmarkfromfavorites: "Eliminar de favoritos",
	selectworkspacenoclientworkspaceexists: "No tiene ningún espacio de trabajo existente. Haga clic en Volver y seleccione Nuevo espacio de trabajo en el paso 1.",
	importpkgselectworkspacenoclientworkspaceexists: "No tiene ningún espacio de trabajo existente. Haga clic hacia atrás y seleccione 'Crear nuevo espacio de trabajo' en el paso anterior.",
	expirydatelabel: "Este compromiso se eliminará en",
	expirydatetodaytomorrowlabel: "Este compromiso será eliminado",
	/*Reporting Hub*/
	reportingHub: "Informes",
	reportingHubTitle: "reportes de EY Canvas",
	selectEngagement: "Seleccione un compromiso para ver los reportes",
	connectVPN: "Solo se puede acceder a los informes cuando se conecta a la red EY o a EY Remote Connect",
	reportsNotLoaded: "No se han podido cargar los informes. Actualice la página e inténtelo de nuevo. Si el problema persiste, póngase en contacto con el servicio de asistencia.",
	searchByEngagementNameOrClientId: "Seleccionar compromiso",
	dataExportLabel: "Exportar datos",
	summaryScreensLabel: "Pantallas de resumen",
	projectManagementLabel: "Gestión de Proyectos",
	engagement: "Nombre de la compromiso",
	reportingNotAvailable: "Los informes no están disponibles en la ubicación seleccionada. Póngase en contacto con su  Champion local de EY Canvas para más detalles.",
	client: "Cliente",
	workspace: "Espacio de trabajo",
	serviceline: "Línea de servicio",
	subserviceline: "Línea de subservicio",
	engagementstartdate: "Fecha de finalización del año",
	engagementenddate: "Fecha de finalización del período cubierto",
	deleteafter: "Eliminar después de",
	auditorsreportdate: "Fecha del informe",
	createengagementquestionlabel: "Crear un compromiso",
	choosenewexistingquestionheader: "¿Crear compromiso en un espacio de trabajo nuevo o existente?",
	createengagementheader: "Crear compromiso",
	restoreengagementheader: "Restaurar el compromiso",
	createworkspacestepheader: "Crear nuevo espacio de trabajo",
	selectexistingworkspacestepheader: "Seleccione un espacio de trabajo existente",
	workspacenotelibilefornewengagement: "El espacio de trabajo no es apto para nuevas interacciones",
	choosenewexistingstepheader: "Espacio de trabajo nuevo o existente",
	editengagementwizheader: "Editar compromiso",
	removeengagementwizheader: "Eliminar el compromiso",
	editworkspacewizheader: "Editar espacio de trabajo",
	editworkspacestepfooter: "Haga clic en 'Guardar y cerrar' para guardar los cambios en el espacio de trabajo o haga clic en 'Guardar y continuar' para editar un compromiso",
	editwizheader: "Editar",
	newworkspace: "Nuevo espacio de trabajo",
	existingworkspace: "Espacio de trabajo existente",
	back: "Atrás",
	continue: "Continuar",
	saveclose: "Guardar y cerrar",
	savecontinue: "Guardar y continuar",
	finish: "Terminar",
	step01: "Paso 01",
	step02: "Paso 02",
	step03: "Paso 03",
	step04: "Paso 04",
	step05: "Paso 05",
	engagementname: "Nombre del compromiso",
	confirmdecline: "Confirmar rechazo. Esta acción no se puede deshacer.",
	confirmdeclinevalidation: "Confirma que quieres rechazar.",
	responderror: "Ya ha respondido a esta invitación, actualice la página e inténtelo de nuevo.",
	engagementcode: "Código de compromiso",
	engagementcodename: "Nombre del código de compromiso",
	selectEngagementHeader: "Seleccione un espacio de trabajo existente",

	selectworkspaceclient: "Seleccionar cliente",
	selectclient: "Seleccione un cliente para ver los espacios de trabajo relacionados",
	createnewworkspace: "Crear nuevo espacio de trabajo",
	editworkspace: "Editar espacio de trabajo",
	clientcode: "Código de cliente",
	clientname: "Nombre del cliente",
	invalidclientcode: "Código de cliente no válido",
	workspacename: "Nombre del espacio de trabajo",
	location: "Ubicación",
	workspacecodenameplaceholder: "Introduzca el código de cliente para buscar el nombre del cliente",
	engagementcodenameplaceholder: "Introduzca el código de compromiso para buscar el nombre del código",
	isprimary: "Primario",
	addengagementcode: "Añadir código de compromiso",
	engagementnamerequired: "Se requiere el nombre de la compromiso",
	primaryofficerequired: "Se requiere oficina primaria",
	languagerequired: "Se requiere idioma",
	servicelinerequired: "Se requiere una línea de servicio",
	subservicelinerequired: "Se requiere una línea de subservicio",
	engagementcodevalidationmessage: "el código de compromiso no coincide con el código de cliente",
	engagementcoderequired: "Se requiere código de compromiso",
	yearenddate: "Fecha de finalización del año",
	periodenddate: "Fecha de finalización del período",
	reportdate: "Fecha del informe",
	complete: "Completo",
	end: "Final",
	started: "Comenzado",
	notstarted: "No iniciado",
	inprogress: "En curso",
	inreview: "En revisión",
	taskstatus: "Estado de la tarea",
	confirmlabel: "Confirmar",
	submitlabel: "Enviar",
	editengagementquestionlabel: "Editar una compromiso",
	removeengagementquestionlabel: "Eliminar el compromiso",
	removeengaementconfirmationmessage1: "¿Está seguro de que desea eliminar el siguiente compromiso de su lista de compromisos? Si eliminas el compromiso, ya no tendrás acceso a el.",
	removeengagementerrormessage: "el compromiso no se puede quitar del área de trabajo, ya que es el último usuario activo. Agrega otro miembro del equipo o elimina el compromiso. ",
	deleteengagement: "Eliminar engagement",
	deletreviewcopyoption: "borrar",
	deleteengagementconfirmationmessage: "¿Está seguro de que desea enviar una solicitud para eliminar el siguiente compromiso? ",
	deleteengagementconfirmationmessage1: "Para los compromisos terminados o aquellos en los que no emitimos un informe, los equipos de compromiso deben consultar con Professional Practice para determinar si se requiere un archivo.",
	deleteengagementconfirmationmessageoneuser: "¿Está seguro de que desea eliminar el siguiente Compromiso? ",
	deleteengagementconfirmationmessageoneuser1: "Los requisitos de archiving en ",
	deleteengagementconfirmationmessageoneuser2: "DOC+ARC 3.1",
	deleteengagementconfirmationmessageoneuser3: " debe considerarse antes de la eliminación. Por ejemplo, los compromisos terminados o aquellos en los que no emitimos un informe pueden requerir consultas con Professional Practice para determinar si se requiere un archivo",
	deleteengagementmarkeddelete: "Engagement ya marcado para eliminación",
	deleteengagementoption: "Eliminar",
	deleteengagementreasonlabel: "Introduzca el motivo de la eliminación",
	deleteengagementreasonquestion: "¿Por qué se elimina este compromiso?",
	deleteengagementreasonrequired: "Se requiere justificación. Seleccione una opción o introduzca un motivo arriba.",
	deleteengagementcommentreasonrequired: "Se requiere justificación. Seleccione una razón arriba.",
	deleteengagementcommentrequiredlabel: "Por favor, introduzca el motivo para eliminar",
	deletereasontrainingengagementlabel: "Compromiso de capacitación",
	deletereasonrestoredorcopiedlabel: "Compromiso restaurado o copiado solo como referencia",
	deletereasoncreatedbymistakelabel: "Creado por error",
	deletereasonotherlabel: "Otro",
	deletereasonterminedbyclientlabel: "Compromiso terminado en el que no se realizó ningún trabajo. Si se realizó trabajo en esta interacción, entonces debe archivarse.",
	markeddeleteconfirm: "Confirmar la eliminación",
	deleterequestbannertext1: "Solicitud de eliminación de ",
	deleterequestbannertext2: " Hace",
	markeddeleteconfirmmessage: "¿Está seguro de que desea eliminar el siguiente compromiso? ",
	markeddeleteconfirmmessage1: "Si confirma la eliminación, esta acción no se puede deshacer.",
	markeddeletereasonlabel: "Motivo para eliminar",
	engagementlistplaceholder: "Seleccionar compromiso",
	engagementrequired: "Se requiere compromiso",
	restoreconfirmationstepheader: "Confirmación",
	restoreconfirmationheader: "¿Está seguro de que desea restaurar el compromiso?",
	selectarchiveheader: "Seleccionar archivo archivado",
	restoreengagementstepheader: "Restaurar compromiso",
	chooserollfwdhdr: "Roll forward a un workspace nuevo o existente",
	rollfwdstephdrext: "Roll forward a un workspace existente",
	rollfwdstephdrnew: "Roll forward a un nuevo workspace",
	rollfwdhdrqstn: "¿Roll forward un engagement en un workspace nuevo o existente?",
	rollforwardengagementstepheader: "Avanzar el compromiso",
	genericerror1: "Algo salió mal, por favor intente nuevamente o contacte al equipo de soporte",
	restoreengagementmaxcopies: "el compromiso ha superado el número máximo de copias restauradas ({0}). Solicitar acceso a una copia existente.",
	restoreengagementengcopies: "Hay {0} copias restauradas de este compromiso disponibles:",
	restoreengagementengcopy: "Hay {0} copia restaurada de este compromiso disponible:",
	restoreengagementengnocopies: "No hay otras copias restauradas de este compromiso disponibles.",
	globalexceptionrestoreengagementengcopies: "Hay {0} copias restauradas de este compromiso disponibles. Marque la casilla de arriba para restaurar con excepción global.",
	globalexception: "Excepción global",
	globalexceptioncheckerror: "Marque la opción de excepción Global para continuar.",
	restoreengagementrestoredon: "restaurado en",
	restoreengagementusers: "Usuarios",
	restoreengagementuser: "Usuario",
	at: "En",
	arcfileuserunavailable: "Usuario no disponible",

	restorehdrqstn: "¿Restaurar el compromiso en un espacio de trabajo nuevo o existente?",
	chooserestorehdr: "Restaurar en un espacio de trabajo nuevo o existente",
	restorestephdrext: "Restaurar en un espacio de trabajo existente",
	restorestephdrnew: "Restaurar en un nuevo espacio de trabajo",

	close: "Cerrar",
	confirm: "Confirmar",
	settings: "Configuración",
	expand: "Expandir",
	collapse: "Colapsar",
	contextMenuEdit: "Editar",
	contextMenuEditEngagement: "Editar compromiso",
	contextMenuRemoveEngagement: "Remover el compromiso",

	contextMenuDeleteEngagement: "Eliminar engagement",
	contextMenuEditWorkspace: "Editar espacio de trabajo",
	contextMenuDelete: "Eliminar",
	contextMenuOverrideArchiveDate: "Anular fecha límite de archive",
	na: "N/A",
	archivesubmittedby: "Presentado por",
	archivedateandtime: "Fecha y hora",
	notifications: "Notificaciones",
	nonotificationmsg: "No tiene ninguna notificación.",
	notifcenter: "Centro de notificaciones",
	notifitemspendingact: "elementos pendientes de acción",
	accept: "Aceptar",
	reject: "Rechazar",
	acceptall: "Aceptar todo",
	rejectall: "Rechazar todo",
	notifEngInvite: "Invitaciones de compromiso",
	notifengaccesreq: "Solicitud de acceso de compromiso",
	keyevents: "Eventos clave",
	milestones: "Hitos",
	importselecttemplateheader: "Seleccione la plantilla que desea utilizar",
	importtemplateheader: "Seleccione un área o región desde la que desea importar",
	import: "Importación",
	importregionstepheader: "Seleccione Area/Region",
	importtemplatestepheader: "Seleccione una plantilla",
	importtemplatemsg: "Seleccione un área o región para continuar",
	importselecttemplatemsg: "Seleccione una plantilla para continuar",
	importengagementheader: "Plantilla de importación",
	importhdrqstn: "Importar a un nuevo espacio de trabajo o espacio de trabajo existente",
	importstephdrext: "Importar a un espacio de trabajo existente",
	importstephdrnew: "Importar a un nuevo espacio de trabajo",
	chooseimporthdr: "Importar a un espacio de trabajo nuevo o existente",
	selectexistingworkspacemsg: "Seleccione un espacio de trabajo para continuar",
	selectaclienttocontinuemsg: "Seleccione un cliente para continuar",
	selectengagementmsg: "Seleccione un engagement para aceptar el paquete de datos",
	selectgaengagementmsg: "Debe seleccionar una compromiso para aceptar la solicitud de enlace",
	arearegion: "Area/Región",
	importdatapackagequestionlabel: "¿Qué te gustaría hacer?",
	sentby: "Enviado por",
	on: "En",
	by: "por",
	view: "Visión",
	sourceengagement: "Compromiso de la fuente",
	package: "Paquete",
	decline: "rechazar",
	delegate: "Delegado",
	chooseaction: "Elija una acción",
	selectworkspace: "Seleccione un espacio de trabajo",
	declinepackage: "Rechazar solicitud",
	delegatepackage: "Paquete delegado",
	selectengagement: "Seleccione un compromiso",
	datapackagerequest: "Solicitud de paquete de datos",
	respond: "haga clic aquí para responder",
	deletionrequestfor: "Solicitud de eliminación recibida para",
	invalidengagementcode: "Código de compromiso no válido",
	noNotifications: "No hay notificaciones disponibles.",
	bellIconTitle: "Centro de notificaciones",
	restoredengagement: "Engagement restaurado",
	profiledeliveryinprogress: "Entrega de perfiles en curso",
	completeprofileheader: "Perfil completo",
	completeindependenceheader: "Independencia completa",
	independencecompletedheader: "Independencia completada",
	retry: "Reintentar",
	errorDate: "Error",
	invitationDate: "Fecha de invitación",
	engCreationFailedWarning: "Error en la creación del compromiso. Actualice la página e inténtelo de nuevo. Si el problema persiste, póngase en contacto con el servicio de asistencia.",
	deletedEngagementWarning: "Si el engagement estaba en uso y se eliminó por error, soporte tecnico inmediatamente.",
	searchEngagementWarning: "Busque estos engagements para tomar medidas.",
	archiveOverrideWarning: "ARC no permite el período de anulación para el archive seleccionado. La fecha límite de archive se ha revertido al valor predeterminado para su ubicación.",
	archiveOverrideInProgress: "Anulación del archivo pendiente de aprobación de ARC",
	archiveOverrideAccepted: "La anulación del archivo se ha completado",
	archiveOverrideRejected: "ARC no permite el período de anulación de archivo seleccionado. La fecha límite del archivo se ha revertido al valor predeterminado de su ubicación.",
	scopeName: "Nombre del alcance",
	shortName: "Nombre corto",
	invited: "Invitado",
	invitedOn: "Invitado en",
	instructions: "instrucciones",
	updates: "actualizaciones",
	viewinstructions: "Ver instrucciones",
	viewinstructionsupdates: "Ver actualizaciones",
	more: "Más",
	addmembers: "Añadir miembros",
	statusChangeDate: "Estado cambiado",
	deletedBy: "Eliminado por",
	deletedDate: "Eliminado",
	deactivatedBy: "Desactivado por",
	deactivatedDate: "Desactivado",
	requestUserName: "Solicitado por",
	requestDate: "Pedido",
	archiveDate: "Archivado",
	receivedFrom: "Recibido de",
	received: "Recibido",
	clear: "Limpiar",
	clearAll: "Borrar todo",
	type: "Tipo",
	engagementhasbeendeleted: "El engagement seleccionado ha sido eliminado por otro usuario. Actualice la página e intente nuevamente.",
	independenceOnlyRejectInvite: {
		engagementIsNotAvailable: "El compromiso no está disponible. Actualice la página y vuelva a intentarlo. Si el problema persiste, póngase en contacto con el servicio de asistencia.",
		failedToRejectInvite: "Error al rechazar la invitación. Actualice la página y vuelva a intentarlo. Si el problema persiste, contacte al servicio de ayuda.",
		succesfulRejection: "Invitación rechazada con éxito.",
		loadingLabel: "Rechazando la invitación...",
	},
	manageTeam: "Administrar equipo",
	childinprogress: "Configuración del compromiso en curso ",
	// SeeMore component
	showMore: "Mostrar más",
	showLess: "Muestra menos",
	showMoreEllipsis: "Mostrar más...",
	showLessEllipsis: "Muestra menos...",
	accepted: "Aceptado",
	acceptedOn: "Aceptado en:",
	rejected: "Rechazado",
	deniedBy: "Negado por",
	engagementstatusrestored: "Restaurado",
	engagementstatusmarkedfordeletion: "Marcado para eliminación",
	delegaterequired: "Es necesario delegar",
	username: "Nombre de usuario",
	noresults: "No se encontraron resultados, por favor intente nuevamente",
	importdatapackageconcurrencymessage: "El estado de la solicitud de copia ha cambiado. Actualice la página e intente nuevamente.",
	selectdelegateuser: "Seleccione un delegado",
	eyusersearchplaceholder: "Ingrese nombre o correo electrónico para buscar",
	optional: "(Opcional)",
	engagementAlerts: "Alertas de engagement",
	engagementMetrics: "Métricas de engagement",
	overdue: "Vencido",
	/*day labels*/
	mon: "Lun",
	tues: "Martes",
	wed: "Mié",
	thurs: "Jue",
	fri: "Vie",
	sat: "Sab",
	sun: "Dom",
	canvas: 'Canvas',
	overrideArchiveDateModalHeader: "Anular la fecha límite del archivo",
	overrideArchiveDateModalMessage: "¿Está seguro de que desea anular la fecha límite del archivo?",
	overrideArchiveDateModalComboPlaceholder: "Anular la fecha límite del archivo",
	createdOn: "Creado en",
	expiresOn: "Caduca en",
	archiveDeadlineDate: "Fecha límite de archivo",
	defaultOverriden: "Anulación predeterminada",
	selectexistingworkspace: "Seleccionar espacio de trabajo existente",
	importtonewworkspace: "Importar a un nuevo espacio de trabajo",
	groupaudit: "Solicitud de enlace de auditoría de grupo",
	gaacceptdeclinedelegatequestionlabel: "¿Qué quieres hacer con esta solicitud de enlace?",
	gaacceptselectengagementquestionlabel: "Seleccione un compromiso para vincularlo",
	gadelegatequestionlabel: "Solicitud de enlace de auditoría de grupo delegado",
	gadeclinequestionlabel: "Rechazar solicitud de enlace de auditoría de grupo",
	garegioncomponent: "Región/Componente",
	gascope: "Alcance",
	gamxTransfer: "Transferido desde GAMx",
	closeNotificationCenter: "Cerrar centro de notificaciones",
	contextMenucrp: "Crear copia de revisión",
	workingoffline: "Trabajar sin conexión",
	trainingoffline: "Trabajar sin conexión (entrenamiento)",
	training: "Entrenamiento",
	crpdescription: "Crear una copia del engagement para revisores externos.",
	crpeditdescritpion: "Edite el nombre del compromiso o el vencimiento. Los compromisos caducados se eliminarán automáticamente.",
	expiry: "Expiracion",
	enteremail: "Introduzca la dirección de correo electrónico",
	create: "Crear",
	metricsPlaceholderText: "No hay datos disponibles para el período de tiempo seleccionado",
	deleteReviewCopy: "Eliminar copia de revisión",
	deleteReviewCopyMsg: "¿Está seguro de que desea eliminar esta copia de revisión? Una vez eliminada, el compromiso ya no estará disponible y no se podrá recuperar.",
	crpNotAvailable: "No se puede crear una copia de revisión para este compromiso porque el Portal de acceso externo de Canvas no está disponible en este momento. Póngase en contacto con mesa de ayuda para obtener más información.",
	externalmember: "Miembros externos",
	internalmember: "Miembros internos",
	add: "Añadir",
	crpEditMembers: "Editar",
	invalidEmail: "Introduzca una dirección de correo electrónico válida",
	emailNotFound: "Dirección de correo electrónico no encontrada",
	dupExternalUser: "Miembro externo ya añadido",
	noExternalUser: "No se han agregado miembros externos.",
	expiresIn: "Caduca en",
	days: "Días",
	today: "hoy",
	tomorrow: "mañana",
	reviewengagementname: "Revisar el nombre del compromiso",
	enternameoremail: "Introduzca el nombre o la dirección de correo electrónico",
	options: "Opciones",
	userNotFound: "Usuario no encontrado",
	remove: "eliminar",
	crpEditHeader: "Editar copia de revisión",
	globalexceptionrestore: "Excepción de restauración global",
	expired: "Expirado",
	softwareUpdate: "Actualizaciones de software",
	updateNow: "Actualizar ahora",
	updateMsg: "Las actualizaciones de software están disponibles para EY Canvas. Seleccione Actualizar ahora para descargar e instalar las actualizaciones. La página se actualizará.",
	preferredlandingpageview: "Vista de pagina incial preferida",
	preferredlandingpagefilter: "filtro de pagina inicial preferida",
	engagementlist: "Lista de compromisos",
	workspacelistusersettings: "Lista de Espacios de trabajo",
	engagementsclientstoload: "Numero de compromisos / clientes para cargar cada vez que se accese a la aplicación",
	favoriteengagements: "Compromisos favoritos",
	preferredEngagementDashboard: "Configuración del tablero del compromiso",
	preferredEngagementDashboardTeamMembers: "Miembros del equipo",
	preferredEngagementDashboardExecutives: "Ejecutivos",
	linkedHelixProjects: "Vinculado a EY Helix",
	loadingLabel: "Cargando…",
	loadingErrorText: "Ha ocurrido un error al cargar la línea de tiempo. Actualice la página e inténtelo de nuevo.",
	selectYear: "Seleccionar año (requerido)",
	clientNameOrId: "Nombre o identificación del cliente",
	engagementNameOrId: "Nombre o identificación del compromiso",
	engagementCodeOrCodeName: "Código del engagement o nombre del código",
	searchArchives: "Buscar",
	clearSearchArchives: "limpiar búsqueda",
	searchArchivesMessage: "Para empezar, seleccione el año en que se archivó el compromiso. A continuación, busque por una o más opciones presentadas a continuación:",
	titleUpdateProfile: "información",
	msgUpdateProfileLn1: "Ha actualizado el idioma de compromiso. Para recibir contenido en este idioma, vaya a Actualizaciones de contenido de compromiso y seleccione Actualización de contenido global.",
	sessionTimerMsg1: "Debido a la inactividad, la sesión de trabajo actual está a punto de expirar.",
	sessionTimerMsg2: "Por su seguridad, las sesiones de EY Canvas terminan automáticamente después de 30 minutos de inactividad.",
	sessionTimerMsg3: "Su sesión actual expirará en {0} minutos.",
	sessionTimerMsg4: "Si desea permanecer conectado, haga clic en",
	sessionTimerHeader: "Cierre automático de sesión para su protección",
	theme: "Tema",
	light: "Claro",
	dark: "oscuro",
	chooseDisplayTheme: "Elija el tema de visualización predeterminado.",
	searchByCountry: "Seleccionar país",
	primaryEngagementLabel: "Compromiso primarios",
	componentEngagementLabel: "Compromiso de componentes",
	regionalEngagementLabel: "Compromiso regional",
	linkedLabel: "Enlazado",
	eyCanvasEconomicsTooltip: "EY Canvas Economics",
	eyOversightTooltip: "EY Canvas Oversight",
	eyPrivacyNoticeTooltip: "Aviso de privacidad",
	eyTermsOfServiceTooltip: "Términos de servicio",
	eyGeneralHelp: "Ayuda general  ",

	preferrednegativenumberformat: "Formato de número negativo preferido",

	protectedEngagementMessage: "Evitar que los documentos se impriman, copien o reproduzcan de cualquier otra forma",
	documentRrestriction: "Restricción de documentos: ",
	on: "En ",
	off: "Deshabilitar",
	protectedEngagementMessageOn: "- Los documentos en este compromiso no se pueden imprimir, copiar o reproducir de otra manera",
	protectedEngagementMessageOff: "- Los documentos en este compromiso se pueden imprimir, copiar o reproducir de otra manera",

	/* Dashboard labels*/
	homeLabel: "Casa",
	manageAccessHeader: "Gestionar el acceso",
	sharepointWorkspacesHeader: "Áreas de trabajo de SharePoint",
	showInactiveUserslabel: "Mostrar usuarios inactivos",
	showExpiredMessageslabel: "Mostrar mensajes caducados",
	addUserButtonLabel: "Agregar usuario",
	searchUserText: "Buscar",
	nameHeader: "Nombre",
	dataCenterNameHeader: "Nombre del centro de datos",
	numberOfWorkSpacesHeader: "Número de áreas de trabajo asignados",
	workspaceName: "Nombre del espacio de proporcionados",
	documentCount: "Recuento de documentos",
	percentCapacity: "Porcentaje de capacidad",
	emailHeader: "Correo electrónico",
	actionHeader: "Acción",
	manageRolesLabel: "Gestionar roles",
	deactivateUserlabel: "Desactivar usuario",
	manageRolesTableModule: "Módulo",
	manageRolesTableRoles: "Papeles",
	manageRolesTableServiceLine: "Línea de servicio",
	manageRolesTableCountries: "Países",
	manageAccessAssignRole: "Asignar rol",
	manageAccessNoRolesAdded: "No se han agregado roles",
	manageAccessDeleteRoleModalHeader: "Eliminar rol",
	manageAccessDeleteRoleButton: "Borrar",
	noRoleResults: "Sin resultados",
	confirmAssignRoleButton: "Asignar",
	permissionGroupName: "Nombre del grupo",
	permissionGroupDescription: "Descripción del grupo",
	activeMembers: "Miembros activos",
	permissionGroupMembersTitle: "Título",
	permissionGroupMembersPhone: "Teléfono",
	permissionGroupMembersGui: "GUI",
	permissionGroupMembersUserId: "ID de usuario",
	permissionGroupMembersAdStatus: "Estatus de AD",
	permissionGroupAddMember: "Agregar miembro",
	permissionGroupUserDeleteLabel: "Borrar",
	permissionGroupUserDeleteHeader: "Eliminar miembro",
	permissionGroupDetails: "Detalle de los permisos de grupo",
	cache: "Clave de caché",
	cacheSelection: "Seleccionar la ubicación de la caché",
	engagementID: "ID de compromiso",
	cacheKey: "Tecla de caché",
	validationMessage: "Campo requerido",
	getCache: "Obtener Cache",
	clearResult: "Resultados claros",
	removeCache: "Eliminar caché",
	requiredField: "Campo obligatorio",
	ticketNumber: "Número de ticket (requerido)",
	confirmationMessage: "Por favor, introduzca un número de ticket para esta acción",
	confirmButtonLabel: "Confirmar",
	cancelButtonLabel: "Cancelar",
	modalHeaderLabel: "Eliminar caché",
	adminModuleinvalidEngagementId: "ID de compromiso no válido  ",
	adminModuleInvalidCacheKey: "La clave de caché contiene caracteres no válidos",
	clearResults: "Limpiar Resultados",
	getDocuments: "Obtener documentos",
	documentsHeader: "Documentos",
	validTicketNumber: "Introduce un número de ticket válido",

	egagementsPageDescription: "Búsqueda de compromisos basados ​​en uno o múltiples criterios para actualizar los datos del compromiso, invite a los nuevos miembros del equipo, actualice el estado y los permisos del miembro del equipo existente.",
	engagementSearchCriteria: "Se requiere ID de país o compromiso para realizar la búsqueda",
	engagementsHeader: "Compromiso",
	engagementIdSearchPlaceholder: "ID de compromiso",
	engagementNameSearchPlaceholder: "Nombre del compromiso",
	workSpaceSearchPlaceholder: "Nombre de espacio de trabajo o ID",
	clientSearchPlaceholder: "Nombre o código del cliente",
	engagementCodeSearchPlaceholder: "Código de compromiso o nombre de código",
	userSearchPlaceholder: "GUI de usuario, correo electrónico o ID de usuario",
	countrySearchPlaceholder: "País",
	engagementTableHeader: "Compromiso",
	nameTableHeader: "Nombre",
	workspaceIdTableHeader: "Espacio de trabajo",
	clientTableHeader: "Cliente",
	engagementCodeTableHeader: "Código de compromiso",
	yearEndTableHeader: "Fin de año",
	engagementdeleteDate: "Fecha de eliminación de compromiso",
	archivalguid: "GUID de archivo",
	archivalLogId: "ID de registro de archivo",
	archivalFileguid: "GUID del documento de archivo",
	profile: "Perfil completo",
	independence: "Independencia",
	archiveRetry: "Reintento de archivo",
	engagementdetails: "Detalles del compromiso",
	engagementsdetails: "Detalles de los compromisos",
	statusTableHeader: "Estado",
	attributesTableHeader: "Atributos",
	attributesProfileComplete: "Perfil completo",
	attributesIndpendenceApplicable: "Aplicabilidad de la independencia",
	engagementPagePlaceholder: "Búsqueda de compromisos anteriores, los resultados se mostrarán aquí.",
	noEngagementFound: "No se ha encontrado ningún compromiso",
	emptySearchFieldError: "Al menos un campo de búsqueda debe tener un valor.",
	largeCountrySelectionError: "Seleccione 10 o menos países.",
	saveEngagement: "Salvar",
	undoEditEngagement: "Restablecer",
	engagementUpdateSuccess: "Los cambios se han guardado correctamente",
	archiveRetrySuccessMessage: "El archivo se ha iniciado con éxito para este compromiso. Verifique el estado más tarde para validar si el archivado fue exitoso.",
	welcome: "Bienvenido a",
	welcomeadmin: "Herramienta de administración",
	engagementCard: "Compromisos",
	messagingCard: "Mensajería",
	templatesCard: "Plantillas",
	permissionGroupCard: "Grupos de permisos",
	manageAccessCard: "Administrar el acceso",
	sharepointWorkspacesCard: "Áreas de trabajo de SharePoint",
	databaseLocatorCard: "Localizador de bases de datos de sincronización",
	importForReviewTooltip: "El compromiso se optimizará para su revisión y algunas características y funcionalidades no estarán disponibles.",
	importForReviewChecboxText: "Importación solo con fines de revisión",
	clientCode: "Código de cliente",
	primaryEngagementCode: "Código de compromiso primario",
	invalidEngagementTitle: "Código de cliente o de contratación no válido",
	invalidEngagementMessage: "El código de cliente y/o el código de compromiso deben validarse antes de poder acceder a esta contratación. Haga clic en Continuar para actualizar y validar los códigos.",
	insufficientPermission: "Permisos insuficientes para este módulo",
	noPermission: "Sin permiso",
	noAdminRights: "No tiene derechos sobre esta herramienta.",



	//Engagement Users Lables
	engmtUserTableUserNameHeader: "Nombre de usuario",
	engmtUserTableEmailAddressHeader: "Dirección de correo electrónico",
	engmtUserTableRoleHeader: "Rol",
	engmtUserTableGUIeHeader: "GUI",
	engmtUserTableStatuseHeader: "Estado",
	engmtUserTableIndependenceStatuseHeader: "Independencia",
	engmtUserTableConfidentialityHeader: "Confidencialidad",
	engmtUserTableADStatusHeader: "Estatus de AD",
	engmtUserTablePermissionsHeader: "Permisos",
	engmtUserTableActionHeader: "Acción",
	engagementUsersTitle: "Usuarios de compromiso",
	engagementInviteMember: "Miembro invitado",
	engagementInviteUser: "Invitar al usuario",
	engagementRoles: "Rol",
	engagementRolesRequired: "Rol de compromiso requerido",
	engagementInviteMemberSuccessMessage: "El miembro ha sido invitado al compromiso con éxito",
	engagementInviteMemberDescription: "Busque usuarios por nombre, apellido o dirección de correo electrónico anterior. Los resultados se mostrarán aquí.",
	engagementUsersTitleEdit: "Editar",
	engagementUpdatePermissionToolTip: "No tiene permisos suficientes para actualizar el compromiso.",
	engagementRetryArchivePermissionToolTip: "No tiene permisos suficientes para volver a intentar archivar",
	usersEditPermissionToolTip: "No tiene permiso para realizar esta acción. Póngase en contacto con el administrador para solicitar permiso.",
	usersPermissionToolTip: "No tiene permisos suficientes para actualizar el usuario del compromiso",
	invalidArchivalGuidMessage: "GUID de archive no válido",
	invalidArchivalFileGuidMessage: "GUID de archive del archivo no válido",
	invalidArchivalLogId: "Identificador de registro de archivo no válido",
	datePickerLabel: "Seleccione una fecha",
	updateEngagement: "Actualizar el compromiso",

	invalidEngagementMessage: "El código de cliente y/o el código de compromiso deben validarse antes de poder acceder a esta contratación. Haga clic en Continuar para actualizar y validar los códigos.",
	invalidEngagementId: "El ID del compromiso no es válido",
	serverErrorMessage: "Error del servidor volver más tarde",
	engagementDetailsNoPermission: "No tiene permiso suficiente para ver los detalles del compromiso.",
	engagementNotFound: "Detalles de compromiso no encontrados",
	pageNotFound: "Página No encontrada",
	assignRoleModalMessage: "Seleccione un módulo y una función específica que se habilitará para el usuario",
	selectModule: "Seleccionar módulo",
	selectRole: "Seleccionar rol",
	serviceLine: "Línea de servicio",
	countries: "Países",
	confirmAssignRoleButton: "Asignar",
	addUser: "Agregar usuario",
	addAdminUserHeader: "Ingrese el nombre apellido o la dirección de correo electrónico del usuario y presione enter para buscar",
	addMemberDescription: "Busque usuarios por nombre, apellido o dirección de correo electrónico arriba. Los resultados se mostrarán aquí.",
	seachInputRequired: "Entrada de búsqueda requerida",
	searchUsers: "Buscar usuarios",
	noResultsFound: "No se han encontrado resultados",
	usersToBeAdded: "Usuarios que se agregarán",
	addUserSuccessMessage: "El usuario administrador se ha agregado correctamente.",
	addMemberSuccessMessage: "El miembro se ha agregado al grupo con éxito",
	deactivateWarning: "¿Está seguro de que desea desactivar el siguiente usuario?",
	deactivateButton: "Desactivar",
	deactivateModalTitle: "Desactivar usuario",
	generateReport: "Generar informe",
	generateValidationReport: "Generar informe de validación",

	templateManagement: "Formas",
	templateName: "Nombre de la forma",
	templateAreaRegion: "Área/región",
	templateSize: "Tamaño",
	templateCreatedBy: "Creado por",
	templateCreatedDate: "Fecha de creación",
	templateCountOfEngagement: "Compromisos",
	templateStatus: "Estatus",
	templatePublished: "Publicado",
	templateUnpublished: "No publicado",
	templateSearchText: "Ingrese el texto de búsqueda y presione Entrar",
	templateInsufficientPermissions: "Permisos insuficientes para este módulo",
	templateMultipleArea: "Múltiple",
	templateEdit: "Editar forma",
	templateEditSave: "Guardar",
	templateEditCancel: "Cancelar",
	templateEditSuccessMessage: "La plantilla se ha actualizado correctamente.",
	templateDeleteConfirmationMessage: "¿Está seguro de que desea eliminar la plantilla?",
	templateDelete: "Eliminar modelo",
	templateEditDelete: "Borrar",

	messagingMessageTitle: "Título del mensaje",
	messagingDescriptionTitle: "Descripción del mensaje  ",
	messagingStartsOn: "Comienza el (UTC)",
	messagingEndsOn: "Termina el (UTC)",
	messagingAffectedDataCenters: "Centros de datos afectados",
	messagingStatus: "Estado",
	messagingCreatedBy: "Creado por",
	messagingCreatedDate: "Fecha de creación",
	messagingDataCenterSystemWide: "Todo el sistema",
	messagingDataCenterMultiple: "Múltiple",
	messagingDataCenterSingle: "Unico",
	messageName: "Título del mensaje",
	messageStartsOn: "Comienza en",
	messageEndsOn: "Termina en",
	messageDateTime: "Seleccione la fecha y la hora",
	messageAllTimeInUTC: "Todos los horarios están en UTC",
	messageTargetAudience: "Público objetivo",
	messageDataCenters: "Centros de datos",
	messageSelectADate: "Seleccione una fecha",
	messageAllDataCenters: "Todos los centros de datos",
	messageKendoDescription: "El uso del formato reducirá la longitud máxima de la descripción de 4.000 caracteres",
	messageSelectDataCenter: "Seleccione un centro de datos",
	messageFieldRequired: "Campo obligatorio",
	messageStartTimeError: "La fecha y hora de inicio no puede ser inferior a la hora actual",
	messageEndTimeError1: "La fecha y hora de finalización no deben ser anteriores a la fecha y hora actuales",
	messageEndTimeError2: "La fecha y hora de finalización deben ser mayores que la fecha y hora de inicio",
	messageDataCenterError: "Seleccione al menos un centro de datos",
	messageSubmitValidationError: "Faltan campos obligatorios. Desplácese y asegúrese de que todos los campos obligatorios estén completos.",
	messageChangesValidationError: "No se han realizado cambios. Edite al menos un campo para guardar.",
	currentutc: "Fecha y hora UTC actual:",
	messageCurrentUTCTime: " {0} (en formato de 24 horas)",
	createMessage: "Crear mensaje",
	createMessagePermissions: "Permisos insuficientes para este módulo",
	createMessageSuccess: "Mensaje creado correctamente",
	editMessage: "Editar mensaje",
	editMessageSuccess: "Mensaje editado correctamente",
	editHoverText: "Editar",

	messagingModalDeleteTitle: "Eliminar mensaje",
	messagingModalDeleteMessage: "¿Está seguro de que desea eliminar el mensaje?",
	messagingModalDeleteButton: "Borrar",
	messagingModalDeleteConfirm: "Confirmar",
	messagingModalDeleteCancel: "Cancelar",
	messagingModalDeletedSuccessfully: "Mensaje eliminado correctamente",

	// databaselocator
	databaseLocatorSuccessMessage: "La sincronización del localización de la base de datos ha sido cola",

	// documents module
	docListHeaderDocId: "ID del documento  ",
	docListHeaderDocVersion: "Versión  ",
	docListHeaderDocName: "Nombre del documento  ",
	docListHeaderFileSize: "Tamaño del archivo  ",
	docListHeaderContentUri: "URL de contenido  ",
	docListHeaderFileExists: "El archivo existe  ",
	docListNoResults: "No se han encontrado documentos  ",
	docListTotalDocuments: "Total de documentos:  ",
	docListDocIdSearchLabel: "ID del documento  ",
	docListExportBtn: "Exportar",

	// Internal user roles abbr
	picRole: "PIC",
	adminRole: "Administrador",
	readOnlyRoleForThirdPartyUser: "Sólo lectura",
	legacy: "Sin restricciones",
	permissionLabel: "Permiso",
	permission: "Permisos",
	active: "Activo",
	inactive: "Inactivo",
	EngagementUserStatus: "Estado",
	IndependenceStatus: "Independencia",
	Confidentiality: "Confidencialidad",
	EditUserPermissionsMainTitle: "Puede editar sus permisos",
	EditUserPermissionsInstructions: "Siempre estará editando permisos de otras personas, no los tuyos.",
	PermissionSaveButtonText: "Guardar",
	PermissionCancelButtonText: "Cancelar",
	UnknownCountry: "Desconocido",
	engagementNoPermissionMesage: "No tiene permisos suficientes para el módulo del compromiso.",
	cacheRemoveSuccessMessage: "El caché se ha eliminado con éxito",
	cacheKeyNotFound: "No se encuentra el resultado para la tecla de caché",

	// Portfolio labels
	informationText: "Bienvenido",
	filterEngagements: {
		all: "Todos los compromisos",
		favorite: "Compromisos favoritos",
		review: "Revisar compromisos"
	},
	filterEngagementsLabel: "Filtrar compromisos",
	engv2noActiveEngagements: "No tienes ningún compromiso activo. Haga clic en el botón 'Agregar compromiso' para comenzar.",
	engv2noFavoriteEngagements: "No tienes ningún compromiso favorito. Para agregar compromisos a esta vista, vaya a Todos los compromisos y haga clic en el icono de favoritos en la esquina inferior derecha de las tarjetas de compromiso.",
	engv2noReviewEngagements: "No tiene ningún compromiso de revisión. Póngase en contacto con un miembro del equipo del compromiso si necesita acceso.",

	// Engagement Progress Labels
	engagementProgressStrategyPhaseLabel: "Fase de alcance y estrategia  ",
	engagementProgressExecutionPhaseLabel: "Fase de ejecución  ",
	engagementProgressConclusionPhaseLabel: "Fase de conclusión  ",
	engagementProgressCompletionPercentage: "completo",
	engagementProgressCompleted: "Completo",
	engagementProgressNotAvailable: "El progreso del compromiso estará disponible cuando se actualice el centro de datos local  ",

	// Engagement archive countdown indicator
	EngagementArchiveCountdownIndicator: {
		reportreleasedate: "La fecha de lanzamiento del informe es <b> {0} </b>",
		archivedeadlinedate: "La fecha de fecha límite del archivo es <b> {0} </b>",
		today: "hoy",
		tommorow: "mañana",
		overdue: "atrasado",
		reportreleasedays: "<b> {0} días </b> hasta la fecha de lanzamiento del informe",
		archivedeadlinedays: "<b> {0} días </b> hasta la fecha límite de archivo",
		days: "Días"
	},
	postArchive: "Archivo de correos",

	adminTool: "Herramienta de administración",
	characters: "Caracteres",
	placeholder: "Marcador",
	ofLabel: "de",
	kendoTextLimitMessage: "Límite máximo de caracteres excedido",
	kendoRequiredMessage: "Obligatorio",
	engv2Errors: {
		noReviewEngagementsPermission: "No tienes permiso para revisar los compromisos. Póngase en contacto con un miembro del equipo del compromiso si necesita acceso. "
	},
	timeTracking: 'Time Tracker',
	timeTrackingToolMessage: "Esta herramienta opcional fue creada específicamente para ayudarlo a administrar mejor su tiempo mientras trabaja en sus compromisos.",
	timeTrackingToolMessage1: "Una vez que comience a registrar el tiempo, puede encontrar un resumen de los datos recopilados durante un máximo de 14 días. Los datos de más de 14 días se eliminarán automáticamente.",
	timeTrackingToolUpdatedMessage: "Esta herramienta opcional ayuda a registrar cómo pasa su tiempo. El tiempo registrado se conserva durante un máximo de dos semanas y no se archivará.",
	timeTrackerErrorFallBackMessage: "No se puede cargar el resumen de tiempo. Actualice la página e inténtelo de nuevo. Si el problema persiste, póngase en contacto con el servicio de Soporte de TI.",
	serviceGateway: "Portal de Servicio",
	reportinghubDisableMessage: "Spotfire será dado de baja en junio de 2024. Se puede acceder a los nuevos informes de EY Canvas desde su compromiso EY Canvas. Después de la versión 1 de 2024, se puede acceder a ellos desde el menú Más. Asegúrese de actualizar los informes personalizados o las automatizaciones que dependan de estos informes a la nueva versión antes de junio de 2024.",
	mobileVersionLabels: {
		canvas: "Canvas",
		noEngagementFound: "No tiene ninguna interacción activa. Acceda a Canvas en su computadora para comenzar.",
		searchEngagementPlaceholder: "Buscar…",
		search: "Buscar",
		independenceIncomplete: "Independencia incompleta",
		profileIncomplete: "Perfil incompleto",
		allEngagements: "Todos los compromisos",
		noMatchesFound: "No se han encontrado coincidencias. Por favor, inténtelo de nuevo.",
		inValidUserLogin: "Acceda a su ordenador para completar la configuración de usuario",
		pagination: {
			pageLabel: "Página",
			ofLabel: "de"
		}
	},
	independenceOnlyStatus: "Solo Independencia",
	toggleView: "Cambiar vista",

	// Adobe Analytics
	aaCookieConsentTitle: "Bienvenidos a",
	aaCookieContentPrompt: "¿Desea permitir las cookies?",
	aaCookieConsentExplanation: '<p>Además de las cookies que son estrictamente necesarias para el funcionamiento de este sitio web, utilizamos los siguientes tipos de cookies para mejorar su experiencia y nuestros servicios: <cookies fuertes>funcionales</fuertes> para mejorar su experiencia (por ejemplo, recordar la configuración), <cookies > rendimiento fuertes</fuertes> para medir el rendimiento del sitio web y mejorar su experiencia,  <cookies > publicidad/segmentación</strong>, que son establecidas por terceros con los que realizamos campañas publicitarias y nos permiten ofrecerle anuncios relevantes para usted.</p><p>Revise nuestra <a target="_blank" href="https://www.ey.com/en_us/cookie-policy">política de cookies</a> para obtener más información.</p>',
	aaCookieConsentExplanationWithDoNotTrack: '<p>Además de las cookies que son estrictamente necesarias para el funcionamiento de este sitio web, utilizamos los siguientes tipos de cookies para mejorar su experiencia y nuestros servicios: <cookies fuertes>funcionales</fuertes> para mejorar su experiencia (por ejemplo, recordar la configuración), <cookies > rendimiento fuertes</fuertes> para medir el rendimiento del sitio web y mejorar su experiencia,  <cookies > fuerte/publicitarias</strong>, que son establecidas por terceros con los que ejecutamos campañas publicitarias y nos permiten ofrecerle anuncios relevantes para usted.</p><p>Hemos detectado que ha habilitado la configuración No rastrear en su navegador; como resultado, las cookies publicitarias/de segmentación se desactivan automáticamente.</p><p>Revise nuestro <a target="_blank" href="https://www.ey.com/en_us/cookie-policy">política de cookies</a> para obtener más información.</p>',
	aaCookieConsentDeclineOptionalAction: "Rechazo las cookies opcionales",
	aaCookieConsentAcceptAllAction: "Acepto todas las cookies",
	aaCookieConsentCustomizeAction: "Personalizar cookies",
	aaCookieConsentCustomizeURL: 'https://www.ey.com/en_us/cookie-settings',

	// Cookie Settings
	cookieSettings: {
		title: "Configuración de cookies",
		explanation: "Proporcione su consentimiento para el uso de cookies en ey.com y en la plataforma My EY. Seleccione uno o más de los tipos de cookies que se enumeran a continuación y, a continuación, guarde su(s) selección(es). Consulte la lista a continuación para obtener detalles sobre los tipos de cookies y su propósito.",
		emptyCookieListNotice: "Las cookies de esta categoría no se utilizan en esta aplicación",
		nameTableHeader: "Nombre de la cookie",
		providerTableHeader: "Proveedor de cookies",
		purposeTableHeader: "Finalidad de la cookie",
		typeTableHeader: "Tipo de cookie",
		durationTableHeader: "Duración de la cookie",
		formSubmit: "Guardar mi selección",
		requiredCookieListTitle: "Cookies requeridas",
		functionalCookieListTitle: "Cookies funcionales",
		functionalCookieAcceptance: "Acepto las siguientes cookies funcionales",
		functionalCookieExplanation: "Cookies de funcionalidad, que nos permiten mejorar su experiencia (por ejemplo, recordando cualquier configuración que haya seleccionado).",
		performanceCookieListTitle: "Cookies de rendimiento",
		performanceCookieAcceptance: "Acepto las siguientes cookies de rendimiento",
		performanceCookieExplanation: "Cookies de rendimiento, que nos ayudan a medir el rendimiento del sitio web y mejorar su experiencia. Al utilizar cookies de rendimiento, no almacenamos ningún dato personal y solo utilizamos la información recopilada a través de estas cookies de forma agregada y anónima.",
		advertisingCookieListTitle: "Cookies de segmentación",
		advertisingCookieAcceptance: "Acepto las cookies de publicidad/segmentación que se indican a continuación",
		advertisingCookieExplanation: "Cookies publicitarias/de segmentación, que utilizamos para rastrear la actividad y las sesiones del usuario con el fin de poder ofrecer un servicio más personalizado, y (en el caso de las cookies publicitarias) que son establecidas por los terceros con los que ejecutamos campañas publicitarias y nos permiten proporcionar anuncios relevantes para usted.",
		doNotTrackNotice: "Hemos detectado que ha habilitado la configuración No rastrear en su navegador; Como resultado, las cookies publicitarias/de segmentación se desactivan automáticamente.",
	},

	//Copyright
	copyrightMessage: "Copyright © <year> todos los derechos reservados",
	addWorkspace: 'Add workspace'
};

export const errors = {
	// GenericErrors Codes
	'0005': "Transacción pendiente",
	'0006': "Transacción fallida",
	'0008': "Descripción no puede contener ninguno de los siguientes caracteres:\\",
	'0010': "Error en la llamada de Archive",

	0: "Solicitud fallida",
	928: "Acceso no autorizado: no tiene acceso a la opción seleccionada. Póngase en contacto con el servicio de asistencia para obtener instrucciones sobre cómo obtener acceso, si está autorizado.",

	1009: "Servidor de datos no encontrado",
	1010: "Centro de datos no encontrado",
	1011: "El centro de datos para este país no se ha configurado",
	1013: "No se ha encontrado el ID de país o el ID de compromiso",
	1014: "No se ha encontrado el centro de datos del país",
	1016: "Error en la asignación del localizador de bases de datos",
	1017: "oDataQuery no puede ser nulo o vacío",
	1018: "oDataQuery no contiene ningún campo",
	1019: "No se ha encontrado un centro de datos universal",
	1020: "El usuario no existe en el sistema",
	1021: "Los informes no están disponibles en la ubicación seleccionada. Póngase en contacto con su Champion local de EY Canvas para más detalles.",

	// Scope & Strategy 1021 - 1200
	1026: "Fila no encontrada",
	1030: "Nombre del cliente no encontrado",

	// Restore Engagement
	1101: "No se puede restaurar el compromiso en el país seleccionado. Actualice la página e inténtelo de nuevo.",

	// Execution   1201-1400
	1219: "Método no encontrado",
	1234: "El ID de rol no puede ser nulo o vacío",
	1260: "El elemento en el que está trabajando ha sido eliminado por otro usuario.",
	1300: "Objeto de solicitud no válido",
	1301: "Rol de compromiso no válido",
	1302: "Idioma de usuario no válido",
	1303: "No se han encontrado los detalles del usuario de compromiso",
	1304: "Ubicación no válida",
	1305: "Nombre del compromiso no encontrada",
	1306: "El nombre de la compromiso supera la longitud máxima (255 caracteres)",
	1307: "Fecha de finalización del período cubierto no se ha encontrado",
	1308: "Fecha no válida",
	1309: "Fecha de informe de auditores no encontrada",
	1310: "No se ha encontrado la fecha límite de archivo de compromiso",
	1311: "La fecha límite del archivo de compromiso no puede ser anterior a la fecha de hoy",
	1312: "El compromiso tiene códigos de compromiso duplicados",
	1313: "Ubicación no encontrada",
	1314: "Nombre del espacio de trabajo no encontrado",
	1315: "El nombre del espacio de trabajo supera la longitud máxima (255 caracteres)",
	1316: "El código de cliente supera la longitud máxima (50 caracteres)",
	1317: "El nombre del cliente supera la longitud máxima (255 caracteres)",
	1318: "Valor no válido para la marca para indicar si el espacio de trabajo es para entrenamiento",
	1319: "Marcar para indicar que el espacio de trabajo es para entrenamiento, no encontrado",
	1320: "Marcador para indicar si el cliente está validado, no encontrado",
	1321: "Valor no válido para el indicador que indica si se valida el cliente",
	1322: "Id de cliente no válido",
	1323: "Códigos de compromiso no encontrados",
	1324: "Identificador de compromiso no encontrado",
	1325: "ID de oficina principal no válido",
	1052: "ID de línea de servicio no válido",
	1053: "ID de línea de subservicio no válido",
	1326: "No se ha encontrado el ID de país",
	1327: "IDENTIFICADOR de espacio de trabajo no válido",
	1328: "Estado de miembro del equipo de compromiso no válido",
	1329: "Estado de compromiso no válido",
	1330: "El compromiso ya ha sido eliminado por otro miembro del equipo",
	1331: "Fecha de finalizacion no encontrada",
	1339: "Se ha superado el número máximo de compromisos permitidos",
	1340: "No se han encontrado los detalles de la ubicación",
	1341: "Datos de compromiso para la actualización no recibidos correctamente",
	1342: "No se han encontrado detalles de compromiso para el compromiso actual",
	1343: "No se han encontrado datos de resumen de compromiso para el compromiso actual",
	1344: "EL compromiso no existe para el ID seleccionado",
	1359: "El identificador del centro de datos para una de los compromisos activos no es válido",
	1363: "La creación de compromiso ha fracasado en Universal",
	1364: "La creación de compromiso ha fracasado en los PMA",
	1373: "Seleccione un espacio de trabajo existente o cree un nuevo espacio de trabajo",
	1374: "El nombre del compromiso ya existe. Escriba un nuevo nombre de compromiso para importar la plantilla.",
	1375: "Los días de vencimiento incorrectos no se pueden actualizar.",
	1379: "El contenido se está actualizando. Inténtalo de nuevo más tarde. Si el problema persiste durante el tiempo prolongado, póngase en contacto con el mesa de ayuda de TI.",
	1380: "No se pudo completar el reintento de archivo. Actualice la página e inténtelo de nuevo. Si el problema persiste, póngase en contacto con el departamento de soporte técnico.",
	1381: "Error en la interacción de creación / RF / RS, debido a una falta de coincidencia en la identificación del país en el espacio de trabajo y el compromiso.",
	1382: "Error en la interacción de creación / RF / RS, debido a una inconsistencia en la oficina principal en el espacio de trabajo y el compromiso.",
	1384: "Restaurar o traspasar ya está en curso para este compromiso. Inténtalo de nuevo más tarde.",
	1386: "El espacio de trabajo no es apto para nuevos compromisos. Actualice la página, inténtelo de nuevo. Si el problema persiste, póngase en contacto con el servicio de asistencia.",
	1385: "El compromiso no se puede crear en la ubicación seleccionada. Actualice la página y vuelva a intentarlo. Si el problema persiste, comuníquese con la mesa de ayuda.",
	1389: "El compromiso de revisión caducada no se puede actualizar.",
	// Invite Team Members (1401 - 1500)
	1403: "El mensaje de bienvenida de compromiso supera la longitud máxima (200 caracteres)",
	1406: "No se han encontrado colecciones del compromiso",
	1424: "No se han encontrado los detalles del compromiso o del usuario",
	1425: "Detalles del compromiso no encontrados",

	// User details Error Codes (1426 - 1434)
	1426: "Se requiere nombre",
	1427: "Se requiere apellido",
	1428: "Se requieren iniciales",
	1429: "Se requiere oficina primaria",
	1430: "Se requiere un número de teléfono preferido",
	1431: "Se requiere idioma",
	1432: "Se requiere el formato de fecha preferido",
	1433: "Se requiere el formato de número preferido",
	1434: "Los datos del usuario no pueden ser nulos ni estar vacíos",
	1436: "Conflicto detectado. Si el problema persiste, póngase en contacto con el servicio de asistencia.",
	1438: "La colección de usuarios no puede estar vacía.",
	1448: "El país seleccionado no se ha actualizado a la última versión de EY Canvas. Consulte EY Atlas para conocer el cronograma de implementación y vuelva a intentarlo después de que se haya producido la actualización. Si el problema persiste, póngase en contacto con Help Desk.",

	1502: "No se puede completar la operación en este momento. Actualice la página e inténtelo de nuevo. Si el problema persiste, póngase en contacto con el servicio de asistencia.",
	1504: "No se puede completar la operación en este momento. Actualice la página e inténtelo de nuevo. Si el problema persiste, póngase en contacto con el servicio de asistencia.",
	1505: "Entrada no válida",

	// Common control errors (1601 - 1700)
	1601: "Porcentaje no válido",
	18000: "Error de procesamiento de ARC: la operación solicitada no se puede realizar ya que ARC está en proceso de mantenimiento. Por favor, inténtelo más tarde.",
	18306: "Error de procesamiento arc: No se puede encontrar el formulario ARC relacionado con Canvas ArchivalGUID",
	18307: "Error de procesamiento de ARC: el compromiso se ha eliminado en ARC. No puede continuar con la opción seleccionada. Póngase en contacto con su administrador de ARC si necesita más ayuda.",
	18309: "Error de procesamiento de ARC: la operación solicitada no se puede realizar ya que el compromiso archivado está bajo restricciones reglamentarias. Póngase en contacto con su administrador de ARC si necesita más ayuda.",
	18320: "Error de procesamiento de ARC: ARC Form está en estado de retención (suspendido) y no se puede modificar.",
	18327: "Error de procesamiento de ARC: ARC Form está en estado de retención para el estado de borrado y no se puede modificar.",
	18328: "Error de procesamiento de ARC: la operación solicitada no se puede realizar ya que el compromiso está marcada para su eliminación en ARC. Póngase en contacto con su administrador de ARC si necesita más ayuda.",
	18329: "Error de procesamiento de ARC: este compromiso no es accesible para los socios. Póngase en contacto con su administrador de ARC si necesita más ayuda.",
	18330: "Error de procesamiento de ARC: la operación solicitada no se puede realizar ya que el compromiso está bloqueado debido a restricciones reglamentarias. Póngase en contacto con su administrador de ARC si necesita más ayuda.",
	2027: "Petición invalida",
	2046: "Desautorizado",
	2047: "Prohibido",
	2048: "Registro no encontrado",
	2049: "Solicitar entidad demasiado grande",
	2052: "No aceptable",
	2053: "Se ha producido un error al recuperar datos. Actualice la página e inténtelo de nuevo. Si el problema persiste, póngase en contacto con el servicio de asistencia.",
	2056: "Error genérico ocurrido",
	2057: "Servicio no disponible",

	// Roll Forward Error Codes (2301-2400)
	2301: "Durante el avance no se encontró el archivo archivado",
	2302: "Durante el avance, no se pudo obtener la llamada de referencia de archivo",
	2303: "Durante el avance, el archivo archivado devuelto tiene un identificador único vacío",
	2304: "Durante el avance, el archivo archivado devuelto tiene la URL vacía del almacén de archivos",
	2305: "Durante el avance, el archivo archivado devuelto tiene un valor hash vacío",
	2306: "El usuario no está autorizado a realizar esta acción",
	2307: "Se ha superado el número permitido de copias restauradas",

	// Link Error Codes
	2568: "El vínculo ya no está disponible, ya que no es el punto de contacto actual. Por favor, actualice la página.",
	3005: "El equipo principal ya ha eliminado el componente del compromiso de grupo",
	2571: "Se ha actualizado la solicitud de vínculo. Por favor, actualice la página.",

	// Error for v1 engagement not supported features
	4001: "El compromiso seleccionado se creó en una versión anterior de EY Canvas y no se puede restaurar hasta que el centro de datos local de ubicaciones seleccionado se haya actualizado a la versión más reciente de EY Canvas. Póngase en contacto con su servicio de asistencia local para obtener más información.",
	4002: "EL compromiso seleccionado se creó en una versión anterior de EY Canvas y el acceso a solicitudes no está disponible hasta que el centro de datos local se haya actualizado a la versión más reciente. Póngase en contacto con el servicio de asistencia local para obtener más información.",
	4214: "La plantilla no se puede importar a un espacio de trabajo en la ubicación seleccionada. Seleccione una ubicación diferente e inténtelo de nuevo",

	// Common framework error
	4205: "Copiar detalles de solicitud no encontrados",
	401000: "Se detecta el cambio de red y vuelva a cargar la página para continuar",

	// CopyHub concurrency scenarios
	4217: "El estado de la solicitud de paquete de datos ha cambiado. Actualice la página e inténtelo de nuevo.",
	4219: "Las copias entre diferentes versiones de EY Canvas no son compatibles con esta versión debido a las mejoras realizadas en el proceso de copia. Revise EY Atlas para obtener detalles sobre el cronograma de implementación. Una vez que se haya actualizado su centro de datos, se puede hacer una copia.",
	4220: "La versión de la plantilla no es compatible con este país.",
	4221: "La versión de la plantilla no es compatible con este país.",
	4222: "La importación no se puede completar hasta que la ubicación de destino se haya actualizado a la última versión de EY Canvas. Seleccione otra ubicación para continuar. Póngase en contacto con el servicio de asistencia si el problema persiste.",

	// Error for Mobility API
	4501: "No hay tareas disponibles en el Compromiso",
	4502: "No hay tareas pendientes disponibles en el Compromiso",

	// Counrty API version error
	5001: "La ubicación seleccionada tiene una versión anterior de EY Canvas y un compromiso no se puede reenviar a un nuevo espacio de trabajo hasta que el centro de datos local de ubicaciones seleccionado se haya actualizado a la versión más reciente de EY Canvas. Póngase en contacto con su servicio de asistencia local para obtener más información",

	//CRP checks
	5101: "No se puede crear una copia de revisión para este compromiso porque el Portal de acceso externo de Canvas no está disponible en este momento. Póngase en contacto con mesa de ayuda para obtener más información.",
	5102: "La copia del CRP ya está en curso para este compromiso. Inténtelo de nuevo más tarde.",
	5103: "La copia de revisión no se puede editar para este compromiso porque el Portal de acceso externo de Canvas no está disponible en este momento. Póngase en contacto con mesa de ayuda para obtener más información.",
	5104: "La restricción de documentos no está disponible para este engagement. Desactive la opción e inténtelo de nuevo.",

	18100: "Línea de servicio no encontrada.",
	18101: "No se ha encontrado una línea de sub servicio",

	//RBAC realted error messages.
	935: "Permisos insuficientes para realizar esta acción. Póngase en contacto con un administrador del compromiso y solicite permiso para realizar esta acción.",
	8001: "No se puede completar la operación en este momento. Actualice la página e inténtelo de nuevo. Si el problema persiste, póngase en contacto con el servicio de asistencia.",
	336699: "No se puede cargar el resumen de tiempo. Actualice la página y vuelva a intentarlo. Si el problema persiste, comuníquese con la mesa de ayuda.",

	//Concurrency errors
	7821: "Ya has respondido a esta invitación, actualiza la página y vuelve a intentarlo.",

	/*Information Security */
	200001: "La acción tomada fue prohibida por EY Information Security. Actualice la página y vuelva a intentarlo. Si el problema persiste, póngase en contacto con el servicio de asistencia."
};

export const adminErrors = {
	1332: "Error al activar el reintento de archivo",
	1501: "El usuario está inactivo. Actualizar la página e intentarlo de nuevo",
	156: "Permisos insuficientes para este módulo",
	70740: "Los cambios no se pueden guardar. El compromiso debe tener al menos un miembro activo que tenga permisos para administrar el compromiso y haya resuelto la independencia. Si el problema persiste, póngase en contacto con el servicio de asistencia.",
	70790: "El dominio no está permitido. Inténtelo de nuevo y, si el problema persiste, póngase en contacto con el servicio de asistencia.",
	70800: "Usuario no válido para invitar a un usuario de terceros.",
	70810: "Roles RBAC no válidos/inactivos seleccionados para usuarios de terceros.",
	70820: "Seleccione un rol RBAC válido para un usuario de terceros.",
	70830: "Seleccione el rol de solo lectura para un usuario de terceros."
};

export const actionTypes = {
	1: "Mensajes del sistema",
	2: "Invitaciones de engagement",
	3: "Acceso denegado",
	4: "Solicitudes de eliminación de engagement",
	5: "Error de creación de engagement",
	6: "Solicitud de acceso al engagement",
	7: "Engagements eliminados",
	8: "Engagements archivados",
	9: "Engagements desactivados",
	10: "Anulación de la fecha límite de archivo",
	11: "Solicitudes de enlace de grupo",
	12: "Instrucciones de grupo recibidas",
	13: "Copias recibidas",
	14: "Engagement en progreso"
};
export const timephaseType = {
	'-1': "Fecha de finalización del período cubierto",
	'-2': "Fecha del informe",
	'-3': "Fecha límite de archivo",
	'1': "Alcance",
	'2': "Estrategia",
	'3': "Recorridos",
	'4': "Pruebas de control interinas",
	'5': "Pruebas sustantivas interinas",
	'6': "Recorridos (procesos de fin de año)",
	'7': "Pruebas de control de fin de año",
	'8': "Pruebas sustantivas de fin de año",
	'9': "Conclusión",
	'10': "N/A",
	'11': "Ejecución",
	'12': "Actividades de calidad de alcance y estrategia",
	'13': "Otro alcance y estrategia",
	'14': "CRA",
	'15': "Evaluación de riesgos y materialidad",
	'16': "Finalizar el alcance y la estrategia",
	'17': "Gestión de proyectos post intermedios"
};
export const dataExport = [
	"Formulario de archivo",
	"Formularios de Canvas",
	"Colaboración",
	"Formularios de control",
	"Documentos",
	"Tareas",
	"Miembros del equipo",
	"Balanza de Saldos"
];
export const dataExportReportNames = {
	'Formulario de archivo': 'Archive Form',
	'Formularios de Canvas': 'Canvas Form Report',
	'Colaboración': 'Collaboration',
	'Formularios de control': 'Control Report',
	'Documentos': 'All Documents Report',
	'Tareas': 'All Tasks Report',
	'Miembros del equipo': 'Team Members',
	'Balanza de Saldos': 'Numbers Report'
};
export const projectManagement = [
	"Detalles de la colaboración",
	"Scorecard digital",
	"Hitos",
	"Notas de revisión",
	"Comprobaciones de validación"
];
export const projectManagementReportNames = {
	'Detalles de la colaboración': 'Client Collaboration Dashboard',
	Hitos: 'Milestone Report',
	'Notas de revisión': 'Review Notes Report',
	'Comprobaciones de validación': 'Validation Checks Report'
};
export const summaryScreenDefaults = [
	"Informe del Plan de Auditoría",
	"Plan de auditoría con pruebas relacionadas",
	"Herramienta de revisión de controles (CRT)",
	"Resumen de CRA",
	"Resultados",
	"IT App & SO Resumen",
	"Resumen de SCOT",
	"Matriz de evaluación sustantiva"
];
export const summaryScreenDefaultsReportNames = {
	'Informe del Plan de Auditoría': 'Audit Plan Report',
	'Plan de auditoría con pruebas relacionadas': 'Audit Plan with Related Evidence',
	'Herramienta de revisión de controles (CRT)': 'Controls Review Tool (CRT)',
	'Resumen de CRA': 'CRA Summary',
	Resultados: 'Findings',
	'IT App & SO Resumen': 'ITSO CRT',
	'Resumen de SCOT': 'SCOT Summary',
	'Matriz de evaluación sustantiva': 'SEM'
};
export const summaryScreenInterim = [
	"Informe del plan de trabajo",
	"Plan de trabajo con pruebas relacionadas"
];
export const summaryScreenInterimReportNames = {
	'Informe del plan de trabajo': 'Audit Plan Report',
	'Plan de trabajo con pruebas relacionadas': 'Audit Plan with Related Evidence'
};
export const summaryScreenGroupAudit = [
	"Informe del Plan de Auditoría",
	"Plan de auditoría con pruebas relacionadas",
	"Herramienta de revisión de controles (CRT)",
	"Resumen de CRA",
	"hallazgos",
	"Resumen de instrucciones de grupo",
	"Informe de grupo",
	"Resumen de la estructura del grupo",
	"IT App & SO Resumen",
	"Resumen de SCOT",
	"Matriz de evaluación sustantiva"
];
export const summaryScreenGroupAuditReportNames = {
	'Informe del Plan de Auditoría': 'Audit Plan Report',
	'Plan de auditoría con pruebas relacionadas': 'Audit Plan with Related Evidence',
	'Herramienta de revisión de controles (CRT)': 'Controls Review Tool (CRT)',
	'Resumen de CRA': 'CRA Summary',
	hallazgos: 'Findings',
	'Resumen de instrucciones de grupo': 'Group Instruction Summary',
	'Informe de grupo': 'Group Report',
	'Resumen de la estructura del grupo': 'Group Structure Summary',
	'IT App & SO Resumen': 'ITSO CRT',
	'Resumen de SCOT': 'SCOT Summary',
	'Matriz de evaluación sustantiva': 'SEM'
};
export const summaryScreenGroupAuditInterim = [
	"Resumen de instrucciones de grupo",
	"Resumen de la estructura del grupo",
	"Informe del plan de trabajo",
	"Plan de trabajo con pruebas relacionadas"
];
export const summaryScreenGroupAuditInterimReportNames = {
	'Resumen de instrucciones de grupo': 'Group Instruction Summary',
	'Resumen de la estructura del grupo': 'Group Structure Summary',
	'Informe del plan de trabajo': 'Audit Plan Report',
	'Plan de trabajo con pruebas relacionadas': 'Audit Plan with Related Evidence'
};
export const rolelistitems = [{
	id: 8,
	label: "Personal"
},
{
	id: 7,
	label: "Senior"
},
{
	id: 6,
	label: "Gerente"
},
{
	id: 5,
	label: "Gerente Senior"
},
{
	id: 3,
	label: "Director Ejecutivo"
},
{
	id: 4,
	label: "Director"
},
{
	id: 11,
	label: "Otro socio"
},
{
	id: 2,
	label: "Socios de compromiso"
},
{
	id: 1,
	label: "Socio a cargo"
},
{
	id: 10,
	label: "Revisor de calidad de compromiso"
},
{
	id: 17,
	label: "Revisor de calidad de auditoría"
},
{
	id: 16,
	label: "Consejero General"
},
{
	id: 12,
	label: "GDS - Personal"
},
{
	id: 30,
	label: "GDS - Senior"
},
{
	id: 31,
	label: "GDS - Gerente"
}
];
export const roleType = {
	'11': "Otro socio",
	'24': "Otros (por ejemplo, revisión de diligencia)",
	'15': "Servicios de Apoyo Ejecutivo",
	'18': "Equipo de componentes de ML",
	'17': "Revisor de calidad de auditoría",
	'1': "Socio a cargo",
	'2': "Socios de compromiso",
	'9': "Internamente",
	'13': "Asesoramiento (FAIT, TAS, Capital Humano u Otro)",
	'14': "Impuestos",
	'27': "Industria",
	'10': "Revisor de calidad de compromiso",
	'25': "Oficina",
	'8': "Personal",
	'20': "Personal del cliente",
	'19': "Supervisor del cliente",
	'5': "Gerente Senior",
	'16': "Consejero General",
	'26': "Área",
	'23': "Regulador",
	'22': "Personal de Auditoría Interna",
	'3': "Director Ejecutivo",
	'21': "Supervisor de Auditoría Interna",
	'6': "Gerente",
	'4': "Director",
	'7': "Senior",
	'12': "Centro Global de Talento",
	'28': "Nacional",
	'29': "Global",
	'12': "GDS - Personal",
	'30': "GDS - Senior",
	'31': "GDS - Gerente"
};

export const copyObjectGroupType = {
	1: "Engagement",
	2: "Cuentas",
	3: "SCOTs",
	4: "Aplicaciones de TI y organizaciones de servicios",
	5: "Solicitudes",
	6: "Evidencia",
	7: "Números",
	8: "Solicitudes y Tareas Externas",
	9: "Archivos temporales",
	10: "Evidencia de período anterior",
	11: "Archivos eliminados recientemente",
	12: "Archivos de historial",
	13: "Plantilla de Engagement",
	14: "Restaurar copia de GCO",
	15: "Copia de revisión externa",
	16: "Revisión externa del engagement activo",
	17: "Formularios de Canvas",
	20: "Evidencia",
	21: "Compromiso",
	22: "Cuentas",
	23: "SCOTs",
	24: "Rodar hacia adelante el compromiso",
	27: "Evidencias externas",
	28: "Flujos de trabajo guiados",
	29: 'Engagement by Entity'
};

export const EngagementStatusText = [
	"Indefinido",
	"Activo",
	"Suspendido",
	"Archivado",
	"Eliminado",
	"ArchiveInProgress",
	"Reactivado",
	"Restaurado",
	"MarkedForDeletion",
	"ArchiveError",
	"RollForwardError",
	"Error al Reactivar",
	"Error al Restaurar",
	"RollForwardInProgress",
	"Reactivación en Progreso",
	"Restauración en progreso",
	"Migración GAMX en progreso",
	"Error de migración de GAMX",
	"Creación de transacción pendiente",
	"La creación de la transacción falló",
	"Transacción actualizada pendiente",
	"Error al actualizar la transacción",
	"Copia en progreso",
	"Error de copia",
	"Error en copia de plantilla",
	"Revisión externa del Engagement en progreso",
	"Error en la revisión externa del Engagement",
	"ExternalReviewEngagement"
];
export const overdueIssues = {
	partnerInvitationPending: "Invitación de socio pendiente",
	eqrInvitationPending: "Invitación EQR pendiente",
	invalidEngagementCode: "Código de engagement no válido",
	overdueArchiveDeadline: "Fecha límite de archivo vencida",
	upcomingArchiveDeadline: "Próximo plazo para archivo",
	contentDeliveryInProgress: "Entrega de contenido en progreso",
	overdueMilestones: "Milestones del equipo vencidos",
	overduePartnerTasks: "Tareas de socios vencidas",
	overdueEqrTasks: "Tareas EQR vencidas"
};
export const engagementMetricNames = [{
	propName: 'preparer',
	value: "Preparar"
},
{
	propName: 'forMyReview',
	value: "Para revisar"
},
{
	propName: 'upcomingReview',
	value: "Revisión futura"
},
{
	propName: 'reviewNotesAssigned',
	value: "Notas para mi"
},
{
	propName: 'reviewNotesAuthored',
	value: "Mis Notas"
},
{
	propName: 'clientRequests',
	value: "Solicitudes"
},
{
	propName: 'timephases',
	value: "Fases de tiempo"
},
{
	propName: 'groupTasks',
	value: "Tareas grupales"
},
{
	propName: 'groupDeliverables',
	value: "Entregables grupales"
},
{
	propName: 'automationDocuments',
	value: "Automatización"
}
];
export const metricFilterComingDue = [{
	value: 1,
	label: "Fecha limite mañana"
},
{
	value: 3,
	label: "Fecha limite en 3 dias"
},
{
	value: 5,
	label: "Fecha limite en 5 dias",
	isDefault: true
},
{
	value: 10,
	label: "Fecha limite en 10 dias"
},
{
	value: 30,
	label: "Fecha limite en 30 dias",
	isMax: true
}
];
export const metricFilterWhatsNew = [{
	value: 1,
	label: "Qué hay de nuevo ayer"
},
{
	value: 3,
	label: "Qué es lo nuevo de los últimos 3 días"
},
{
	value: 5,
	label: "Qué es lo nuevo de los últimos 5 días",
	isDefault: true
},
{
	value: 10,
	label: "Qué es lo nuevo de los últimos 10 días",
	isMax: true
}
];

export const type1Alerts = [
	"hasPartnerInvited",
	"hasEqrInvited",
	"hasValidEngagementCode",
	"overdueArchiveDeadline"
];

export const engagementStatuses = {
	1: "Activo",
	2: "Suspendido",
	3: "Archivado",
	4: "Eliminado",
	5: "Archivo en progreso",
	6: "Reactivado",
	7: "Restaurado",
	8: "Seleccionado para eliminación",
	9: "Error de archivo",
	10: "Error de Roll forward",
	11: "Error al Reactivar",
	12: "Error al Restaurar",
	13: "Roll forward en progreso",
	14: "Reactivación en progreso",
	15: "Restauración en progreso",
	16: "Migración de GAMX en progreso",
	17: "Error de migración de GAMX",
	18: "Creación de Trasacción pendiente",
	19: "La creación de trasacción fallo",
	20: "Actualización de trasacción Pendiente",
	21: "Actualización de transacción fallo",
	22: "Copia en progreso",
	23: "Error de copia",
	24: "Copiar error de plantilla",
	25: "Revisión externa del engagement en progreso",
	26: "Error en la revisión externa del engagement",
	27: "Revisión externa del Engagement",
	28: "Ubicado en el servidor Universal",
	29: "Ubicado en el servidor universal de archivado",
	30: "Entrega de contenido en progreso",
	31: "Error de entrega de contenido",
	32: "Fallo de entrega de contenido",
	33: "Portal de acceso externo de Canvas V2",
	34: "Portal de acceso externo de Canvas V2 en curso",
	35: "Portal de acceso externo de Canvas V2 Error",
	100: "División de centro de datos"
};

export const overrideArchiveDeadlineDateOptions = [{
	value: 0,
	label: "Predeterminado"
},
{
	value: 10,
	label: "10 días"
},
{
	value: 14,
	label: "14 días"
},
{
	value: 20,
	label: "20 días"
},
{
	value: 21,
	label: "21 Días"
},
{
	value: 25,
	label: "25 Días"
},
{
	value: 30,
	label: "30 días"
},
{
	value: 45,
	label: "45 días"
}
];
export const ExternalReviewCopyExpiryDays = [{
	value: 30,
	label: "30 días"
},
{
	value: 60,
	label: "60 días"
},
{
	value: 90,
	label: "90 días"
},
{
	value: 180,
	label: "180 días"
}
];

export const serviceLines = [{
	servicelineid: 1,
	servicelinename: "Auditoria"
},
{
	servicelineid: 3,
	servicelinename: "Estrategia y transacciones"
},
{
	servicelineid: 2,
	servicelinename: "Consultoría"
},
{
	servicelineid: 4,
	servicelinename: "Impuestos"
},
{
	servicelineid: 5,
	servicelinename: "GCO"
}
];

export const subServiceLines = [{
	subservicelineid: 1,
	servicelineid: 1,
	subservicelinename: "Auditoría"
},
{
	subservicelineid: 2,
	servicelineid: 1,
	subservicelinename: "FIDS"
},
{
	subservicelineid: 3,
	servicelineid: 2,
	subservicelinename: "FAIT"
},
{
	subservicelineid: 9,
	servicelineid: 1,
	subservicelinename: "FAAS"
},
{
	subservicelineid: 10,
	servicelineid: 1,
	subservicelinename: "ACR"
},
{
	subservicelineid: 11,
	servicelineid: 1,
	subservicelinename: "CcaSS"
},
{
	subservicelineid: 4,
	servicelineid: 3,
	subservicelinename: "Transformación de capital"
},
{
	subservicelineid: 5,
	servicelineid: 3,
	subservicelinename: "Soporte de transacciones"
},
{
	subservicelineid: 6,
	servicelineid: 2,
	subservicelinename: "Mejora del rendimiento"
},
{
	subservicelineid: 7,
	servicelineid: 2,
	subservicelinename: "Riesgo"
},
{
	subservicelineid: 7,
	servicelineid: 23,
	subservicelinename: "Consultoría de Negocios"
},
{
	subservicelineid: 24,
	servicelineid: 6,
	subservicelinename: "Otros"
},
{
	subservicelineid: 8,
	servicelineid: 2,
	subservicelinename: "Otros"
},
{
	subservicelineid: 12,
	servicelineid: 4,
	subservicelinename: "BTS"
},
{
	subservicelineid: 13,
	servicelineid: 4,
	subservicelinename: "Capital humano"
},
{
	subservicelineid: 14,
	servicelineid: 4,
	subservicelinename: "Ley"
},
{
	subservicelineid: 15,
	servicelineid: 4,
	subservicelinename: "Impuesto indirecto"
},
{
	subservicelineid: 16,
	servicelineid: 4,
	subservicelinename: "GCR"
},
{
	subservicelineid: 17,
	servicelineid: 4,
	subservicelinename: "Impuestos de transacciónes"
},
{
	subservicelineid: 18,
	servicelineid: 4,
	subservicelinename: "ITS"
},
{
	subservicelineid: 19,
	servicelineid: 5,
	subservicelinename: "GCO"
},
{
	subservicelineid: 20,
	servicelineid: 2,
	subservicelinename: "Consultoría de Negocios"
},
{
	subservicelineid: 21,
	servicelineid: 2,
	subservicelinename: "Consultoría de tecnologia"
},
{
	subservicelineid: 22,
	servicelineid: 3,
	subservicelinename: "Transacciones y Finanzas Corporativas"
},
{
	subservicelineid: 23,
	servicelineid: 3,
	subservicelinename: "EY Parthenon"
},
{
	subservicelineid: 25,
	servicelineid: 1,
	subservicelinename: "Riesgo tecnológico"
}
];

export const modules = [{
	id: 1,
	name: "Administrar el acceso"
},
{
	id: 2,
	name: "Compromisos"
},
{
	id: 3,
	name: "Grupos de permisos"
},
{
	id: 4,
	name: "Plantillas"
},
{
	id: 5,
	name: "Mensajería"
},
{
	id: 6,
	name: "Cache"
},
{
	id: 7,
	name: "Localizador de bases de datos"
},
{
	id: 8,
	name: "Documentos"
},
{
	id: 9,
	name: "Áreas de trabajo de SharePoint"
}
];

export const roles = [{
	id: 17,
	ModuleId: 1,
	name: "Solo lectura"
},
{
	id: 18,
	ModuleId: 1,
	name: "Agregar/quitar usuarios y permisos"
},
{
	id: 19,
	ModuleId: 2,
	name: "Solo lectura"
},
{
	id: 20,
	ModuleId: 2,
	name: "Actualizar compromiso"
},
{
	id: 21,
	ModuleId: 2,
	name: "Invitar a un miembro del equipo"
},
{
	id: 22,
	ModuleId: 2,
	name: "Actualizar usuario de interacción"
},
{
	id: 23,
	ModuleId: 3,
	name: "Solo lectura"
},
{
	id: 24,
	ModuleId: 3,
	name: "Agregar/quitar miembro"
},
{
	id: 25,
	ModuleId: 3,
	name: "Sincronizar DatabaseLocator"
},
{
	id: 26,
	ModuleId: 4,
	name: "Solo lectura"
},
{
	id: 27,
	ModuleId: 4,
	name: "Actualizar plantilla"
},
{
	id: 28,
	ModuleId: 4,
	name: "Eliminar plantilla"
},
{
	id: 29,
	ModuleId: 5,
	name: "Solo lectura"
},
{
	id: 30,
	ModuleId: 5,
	name: "Mensaje de creación/actualización"
},
{
	id: 31,
	ModuleId: 5,
	name: "Eliminar mensaje"
},
{
	id: 32,
	ModuleId: 6,
	name: "Leer y borrar Cache"
},
{
	id: 33,
	ModuleId: 8,
	name: "Acceder a documentos"
},
{
	id: 34,
	ModuleId: 2,
	name: "Reintento de archivo"
},
{
	id: 38,
	ModuleId: 9,
	name: "Solo lectura"
},
{
	id: 39,
	ModuleId: 9,
	name: "Agregar espacios de trabajo"
},
{
	id: 40,
	ModuleId: 9,
	name: "Eliminar espacios de trabajo"
}
];

export const countries = {
	1: "Mayotte",
	2: "Islas Vírgenes Británicas",
	3: "España",
	4: "Belice",
	5: "Perú",
	6: "Eslovaquia",
	7: "Venezuela",
	8: "Noruega",
	9: "Islas Malvinas (Falkland Islands)",
	10: "Mozambique",
	11: "China continental",
	12: "Sudán",
	13: "Israel",
	14: "Bélgica",
	15: "Arabia Saudí",
	16: "Gibraltar",
	17: "Guam",
	18: "Islas Norfolk",
	19: "Zambia",
	20: "Reunión",
	21: "Azerbaiyán",
	22: "Santa Elena",
	23: "Irán",
	24: "Mónaco",
	25: "San Pedro y Miquelón",
	26: "Nueva Zelanda",
	27: "Islas Cook",
	28: "Santa Lucía",
	29: "Zimbabue",
	30: "Irak",
	31: "Tonga",
	32: "Samoa Americana",
	33: "Maldivas",
	34: "Marruecos",
	35: "Normas Internacionales de Auditoría (ISA)",
	36: "Albania",
	37: "Afganistán",
	38: "Gambia",
	39: "Burkina Faso",
	40: "Tokelau",
	41: "Libia",
	42: "Canadá",
	43: "Emiratos Árabes Unidos",
	44: "Corea República Popular Democrática de",
	45: "Montserrat",
	46: "Groenlandia",
	47: "Ruanda",
	48: "Fiji",
	49: "Yibuti",
	50: "Botswana",
	51: "Kuwait",
	52: "Madagascar",
	53: "Isla de Man",
	54: "Hungría",
	55: "Namibia",
	56: "Malta",
	57: "Jersey",
	58: "Tailandia",
	59: "San Cristóbal y Nieves",
	60: "Bhután",
	61: "Panamá",
	62: "Somalia",
	63: "Bahrein",
	64: "Bosnia-Herzegovina",
	65: "Francia",
	66: "Corea República de",
	67: "Islandia",
	68: "Portugal",
	69: "Túnez",
	70: "Ghana",
	71: "Camerún",
	72: "Grecia",
	73: "Territorios del Sur de Francia",
	74: "Islas Heard y McDonald",
	75: "Andorra",
	76: "Luxemburgo",
	77: "Samoa",
	78: "Anguila",
	79: "Países Bajos",
	80: "Guinea-Bissau",
	81: "Nicaragua",
	82: "Paraguay",
	83: "Antigua y Barbuda",
	84: "Norma Internacional de Información Financiera (NIIF)",
	85: "Níger",
	86: "Egipto",
	87: "Estado de la Ciudad del Vaticano",
	88: "Letonia",
	89: "Chipre",
	90: "Islas periféricas menores de EE. UU.",
	91: "Rusia Otras entidades jurídicas",
	92: "San Vicente y las Granadinas",
	93: "Guernsey",
	94: "Burundi",
	95: "Cuba",
	96: "Guinea Ecuatorial",
	97: "Territorio Británico del Océano Índico",
	98: "Suecia",
	99: "Uganda",
	100: "Macedonia del Norte",
	101: "Swazilandia",
	102: "El Salvador",
	103: "Kirguizistán",
	104: "Irlanda",
	105: "Kazajstán",
	106: "Honduras",
	107: "Uruguay",
	108: "Georgia",
	109: "Trinidad y Tobago",
	110: "Autoridad Palestina",
	111: "Martinica",
	112: "Islas Aland",
	113: "Polinesia Francesa",
	114: "Costa de Marfil",
	115: "Montenegro",
	116: "Sudáfrica",
	117: "Islas Georgias del Sur y Sandwich del Sur",
	118: "Yemen",
	119: "Hong Kong",
	120: "Kenia",
	121: "Chad",
	122: "Colombia",
	123: "Costa Rica",
	124: "Angola",
	125: "Lituania",
	126: "Siria",
	127: "Malasia",
	128: "Sierra Leona",
	129: "Serbia",
	130: "Polonia",
	131: "Suriname",
	132: "Haití",
	133: "Nauru",
	134: "Santo Tomé y Príncipe",
	135: "Svalbard y Jan Mayen",
	136: "Singapur",
	137: "Moldavia",
	138: "Taiwán",
	139: "Senegal",
	140: "Gabón",
	141: "México",
	142: "Seychelles",
	143: "Micronesia Estados Federados de",
	144: "Argelia",
	145: "Italia",
	146: "San Marino",
	147: "Liberia",
	148: "Brasil",
	149: "Croacia",
	150: "Islas Faroe",
	151: "Palau",
	152: "Finlandia",
	153: "Filipinas",
	154: "Jamaica",
	155: "Guayana Francesa",
	156: "Cabo Verde",
	157: "Myanmar",
	158: "Lesotho",
	159: "Islas Vírgenes de los Estados Unidos",
	160: "Islas Caimán",
	161: "Niue",
	162: "Togo",
	163: "Bielorrusia",
	164: "Dominica",
	165: "Indonesio",
	166: "Uzbekistán",
	167: "Nigeria",
	168: "Wallis y Futuna",
	169: "Barbados",
	170: "Sri Lanka",
	171: "Reino Unido",
	172: "Ecuador",
	173: "Guadalupe",
	174: "Laos",
	175: "Jordania",
	176: "Islas Salomón",
	177: "Timor Oriental",
	178: "Líbano",
	179: "República Centroafricana",
	180: "India",
	181: "Isla de Navidad",
	182: "Vanuatu",
	183: "Brunéi",
	184: "Bangladesh",
	185: "Antártida",
	186: "Bolivia",
	187: "Turquía",
	188: "Bahamas",
	189: "Comoras",
	190: "Sáhara Occidental",
	191: "República Checa",
	192: "Ucrania",
	193: "Estonia",
	194: "Bulgaria",
	195: "Mauritania",
	196: "República Democrática del Congo",
	197: "Liechtenstein",
	198: "Pitcairn",
	199: "Dinamarca",
	200: "Islas Marshall",
	201: "Japón",
	202: "Austria",
	203: "Omán",
	204: "Mongolia",
	205: "Tayikistán",
	206: "Suiza",
	207: "Guatemala",
	208: "Eritrea",
	209: "Nepal",
	210: "Malí",
	211: "Eslovenia",
	212: "Islas Marianas del Norte",
	213: "(No aplicable)",
	214: "Aruba",
	215: "Congo",
	216: "Qatar",
	217: "Guinea",
	218: "Estados Unidos",
	219: "Etiopía",
	220: "Otro",
	221: "Guyana",
	222: "Alemania",
	223: "Bermudas",
	224: "Islas Turcas y Caicos",
	225: "Australia",
	226: "Kiribati",
	227: "Puerto Rico",
	228: "Pakistán",
	229: "Mauricio",
	230: "Malawi",
	231: "Turkmenistán",
	232: "Camboya",
	233: "Chile",
	234: "Nueva Caledonia",
	235: "Papúa Nueva Guinea",
	236: "Isla Bouvet",
	237: "Tuvalu",
	238: "Curacao",
	239: "República Dominicana",
	240: "Vietnam",
	241: "Islas Cocos (Keeling)",
	242: "Granada",
	243: "Tanzania",
	244: "Argentina",
	245: "Macao",
	246: "Benin",
	247: "Rumania",
	248: "Armenia",
	249: "global",
	250: "NIIF para pymes",
	251: "US GAAP",
	252: "Marco de información financiera de AICPA para pequeñas y medianas entidades",
	253: "Sudán del Sur",
	254: "Kosovo",
	255: "Estados Unidos - Gobierno",
	256: "Rusia OOO EY & OOO EY Vneshaudit",
	257: "Armenia-RusiaDC",
	258: "Bielorrusia-RusiaDC",
	259: "Uzbekistán-RusiaDC"
};

export const common = {
	selected: "Seleccionado",
	cancel: "Cancelar",
	checkbox: {
		selectAllOn: "Seleccionar todo",
		selectAllOff: "Deselecciona todo"
	},
	clear: "Claro",
	close: "Cerrado",
	pagination: {
		show: "Mostrar",
		iconHover: {
			first: "Primera página",
			previous: "Pagina anterior",
			next: "Siguiente página",
			last: "Última página"
		}
	},
	engv2Pagination: {
		show: "Mostrar",
		iconHover: {
			first: "Primera página",
			previous: "Página anterior",
			next: "Página siguiente",
			last: "Última página"
		}
	},
	search: {
		clear: "Borrar búsqueda",
		buttonTitle: "Búsqueda",
		placeholder: "Búsqueda",
		noResultsPlaceholder: "No se han encontrado resultados"
	},
	table: {
		remove: "Eliminar",
		noDataPlaceholder: "Datos no disponibles",
		noResultsPlaceholder: "No se han encontrado resultados"
	},
	toggleSwitch: {
		no: "No",
		yes: "sí"
	},
	wizard: {
		next: "Continuar",
		back: "atrás",
		finish: "Terminar"
	}
};

export const ConfidentialityTypes = [{
	id: 0,
	Name: "Predeterminado"
},
{
	id: 10,
	Name: "Bajo"
},
{
	id: 12,
	Name: "Moderado"
},
{
	id: 11,
	Name: "Alto"
}
];

export const internalUserRoles = [{
	roleKey: 3,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permisos",
	roleNameUI: "Archivar compromiso",
	displayName: "Archivar compromiso",
	roleDisplayOrder: 2,
	tooltipText: "Capacidad para archivar el compromiso"
},
{
	roleKey: 4,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permisos",
	roleNameUI: "Administrar el compromiso",
	displayName: "Administrar el compromiso",
	roleDisplayOrder: 1,
	tooltipText: "Posibilidad de invitar a miembros internos y externos y asignar permisos a miembros internos"
},
{
	roleKey: 5,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permisos",
	roleNameUI: "Actualizar contenido",
	displayName: "Actualizar contenido",
	roleDisplayOrder: 6,
	tooltipText: "Posibilidad de editar el perfil y aceptar una actualización de contenido"
},
{
	roleKey: 7,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permisos",
	roleNameUI: "Crear solicitud de servicio",
	displayName: "Administrar solicitudes de servicio",
	roleDisplayOrder: 4,
	tooltipText: "Capacidad para crear solicitudes de servicio y marcar solicitudes de servicio completas"
},
{
	roleKey: 8,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permisos",
	roleNameUI: "Administrar grupo",
	displayName: "Administrar grupo",
	roleDisplayOrder: 5,
	tooltipText: "Aceptar o rechazar las instrucciones de grupo recibidas del equipo Primario o Regional."
},
{
	roleKey: 9,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permisos",
	roleNameUI: "Copiar, Roll-forward, Restaurar compromisos",
	displayName: "Copiar, Roll-forward, Restaurar compromisos",
	roleDisplayOrder: 3,
	tooltipText: "Posibilidad de copiar el compromiso, transferirlo y restaurar su archivo"
},
{
	roleKey: 0,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "Confidencialidad",
	roleNameUI: "Predeterminado",
	displayName: "Ninguno (predeterminado)",
	roleDisplayOrder: 1,
	tooltipText: "Rol predeterminado de documentos confidenciales"
},
{
	roleKey: 10,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "Confidencialidad",
	roleNameUI: "Bajo",
	displayName: "Bajo",
	roleDisplayOrder: 8,
	tooltipText: "Rol de documentos confidenciales bajos"
},
{
	roleKey: 11,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "Confidencialidad",
	roleNameUI: "Alto",
	displayName: "Alto",
	roleDisplayOrder: 10,
	tooltipText: "Rol de documentos altamente confidenciales"
},
{
	roleKey: 12,
	sectionId: 1,
	groupDisplayOrder: 200,
	groupName: "Confidencialidad",
	roleNameUI: "Moderado",
	displayName: "Moderado",
	roleDisplayOrder: 9,
	tooltipText: "Rol de doumentos de condencialidad moderada"
},
// {
// roleKey: 13,
// sectionId:1,
// groupDisplayOrder: 200,
// groupName:"Confidentiality",
// roleNameUI: "Confidential",
// displayName: "Confidential",
// roleDisplayOrder: 11,
// tooltipText: 'Strictly Confidential Documents Role'
// },
{
	roleKey: 14,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "Restricciones",
	roleNameUI: "Sólo lectura",
	displayName: "Sólo lectura",
	roleDisplayOrder: 12,
	tooltipText: "Esta funcionalidad solo debe usarse para otorgar revisores externos a este compromiso de EY Canvas. Las aprobaciones y autorizaciones apropiadas deben adquirirse de Professional Practice."
},
{
	roleKey: 16,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "Restricciones",
	roleNameUI: "Solo revisión",
	displayName: "Solo revisión",
	roleDisplayOrder: 13,
	tooltipText: "Solo revisión ofrece a este usuario la capacidad de ver información en la interacción de Canvas, la aprobación y el uso de la funcionalidad de nota de revisión. Esto debe usarse cuando otros usuarios de EY necesiten revisar este compromiso (por ejemplo, el equipo primario de auditoría de grupo) pero no puedan realizar ediciones.",
},
{
	roleKey: 37,
	sectionId: 3,
	groupDisplayOrder: 100,
	groupName: "Restricciones",
	roleNameUI: "Solo independencia",
	displayName: "Solo independencia",
	roleDisplayOrder: 14,
	tooltipText: "La independencia solo le da a este usuario la capacidad de completar su formulario de independencia para el compromiso, pero no permitirá que el usuario acceda completamente al compromiso de EY Canvas. Esto debe usarse cuando un usuario ha realizado trabajo para el compromiso, pero no necesita tener acceso a EY Canvas.",
},
{
	roleKey: 6,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permisos",
	roleNameUI: "Deshacer ediciones",
	displayName: "Revertir documentos",
	roleDisplayOrder: 7,
	tooltipText: "Posibilidad de revertir documentos si los cambios se realizaron accidentalmente después de la fecha de publicación del informe y eliminarlos del formulario 440 Canvas."
},
{
	roleKey: 35,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permisos",
	roleNameUI: "Administrar el uso compartido de la habilitación de EY Canvas FIT",
	displayName: "Administrar flujos de trabajo guiados",
	roleDisplayOrder: 14,
	tooltipText: "Posibilidad de compartir flujos de trabajo guiados entre compromisos de EY Canvas"
},
{
	roleKey: 36,
	sectionId: 2,
	groupDisplayOrder: 150,
	groupName: "Permisos",
	roleNameUI: "Importar datos de EY Helix",
	displayName: "Importar datos de EY Helix",
	roleDisplayOrder: 15,
	tooltipText: "Posibilidad de importar datos desde EY Helix"
}
];

export const restoreengagementexpirydates = [{
	value: 3,
	label: "3 meses"
},
{
	value: 6,
	label: "6 meses"
},
{
	value: 9,
	label: "9 meses"
},
{
	value: 12,
	label: "12 meses"
}
];

export const messageStatus = {
	0: "Actual",
	1: "Programado",
	2: "Caducado"
};

export const kendoLabels = {
	alignLeft: "Justificar la izquierda",
	alignRight: "Justificar el derecho",
	alignCenter: "Justificar centro",
	alignFull: "Justificar completo",
	addComment: "Añadir comentario",
	addColumnBefore: "Agregar columna a la izquierda",
	addColumnAfter: "Agregar columna a la derecha",
	addRowAbove: "Agregar fila arriba",
	addRowBelow: "Añadir fila a continuación",
	bulletList: "Insertar lista desordenada",
	bold: "Negrita",
	backColor: "Destacar",
	createLink: "Insertar hipervínculo",
	createTable: "Crear tabla",
	cleanFormatting: "Formato limpio",
	deleteRow: "Eliminar fila",
	deleteColumn: "Eliminar columna",
	deleteTable: "Eliminar tabla",
	fontSizeInherit: "Tamaño de fuente",
	foreColor: "Color",
	format: "Formato",
	fontSize: "Tamaño de fuente",
	hyperlink: "Insertar enlace web",
	italic: "Itálico",
	indent: "Guíon",
	insertTableHint: "Crear una {0} por {1} tabla",
	"hyperlink-dialog-content-address": "Dirección web",
	"hyperlink-dialog-title": "Insertar hipervínculo",
	"hyperlink-dialog-content-title": "Título",
	"hyperlink-dialog-content-newwindow": "Abrir enlace en una ventana nueva",
	"hyperlink-dialog-cancel": "Cancelar",
	"hyperlink-dialog-insert": "Insertar",
	outdent: "fuera de servicio",
	orderedList: "Insertar lista ordenada",
	print: "Impresión",
	pdf: "exportar a pdf",
	redo: "rehacer",
	strikethrough: "Tachado",
	subscript: "Subíndice",
	superscript: "Superíndice",
	underline: "Subrayar",
	undo: "Deshacer"
};

export const kendoFormatOptions = [{
	text: "Párrafo",
	value: "p"
},
{
	text: "Rúbrica 1",
	value: "h1"
},
{
	text: "Rúbrica 2",
	value: "h2"
},
{
	text: "Rúbrica 3",
	value: "h3"
},
{
	text: "Rúbrica 4",
	value: "h4"
},
{
	text: "Rúbrica 5",
	value: "h5"
},
{
	text: "Rúbrica 6",
	value: "h6"
}
];

export const kendoFontSize = [{
	text: '8',
	value: "8px"
},
{
	text: '9',
	value: "9px"
},
{
	text: '10',
	value: "10px"
},
{
	text: '11',
	value: "11px"
},
{
	text: '12',
	value: "12px"
},
{
	text: '14',
	value: "14px"
},
{
	text: '16',
	value: "16px"
},
{
	text: '18',
	value: "18px"
},
{
	text: '20',
	value: "20px"
},
{
	text: '22',
	value: "22px"
},
{
	text: '24',
	value: "24px"
},
{
	text: '26',
	value: "26px"
},
{
	text: '28',
	value: "28px"
},
{
	text: '36',
	value: "36px"
},
{
	text: '48',
	value: "48px"
},
{
	text: '72',
	value: "72px"
}
];

export const cacheOptionValue = [{
	value: 1,
	label: "LDC"
},
{
	value: 2,
	label: "Universal"
}
]
