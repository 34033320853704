import { displayGroupings, settingsPreferences } from '../../util/uiconstants';
import { useHistory, withRouter } from 'react-router-dom';

import Button from '@ey/motif-react-wrapper/Button';
import React from 'react';
import { labels } from '../../util/uiconstants';
import { parse } from 'query-string';
import styled from 'styled-components';
import { updateGroupPrefUserSettings } from '../../actions/usersettingsaction';
import { useDispatch } from 'react-redux';
import ContentSwitcher from '@ey/motif-react-wrapper/ContentSwitcher';
import MotifIcon from '@ey/motif-react-wrapper/Icon';
import {
	IconoirList,
	IconoirRubikCube
} from '@ey-xd/motif-icon';

const Toplevelcss = styled.div`
	float: right;
	margin-top: calc(var(--px-6) * -1);
	.motif-content-switcher {
		background-color: var(--neutrals-100) !important;
		border-color: var(--neutrals-200);
	}
`;

const EngagementGrouping = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { location } = props;

	const displayGrouping = () => {
		const params = parse(location.search);
		return params.groupby ? displayGroupings.MODULE : displayGroupings.LIST;
	};

	let dynClassList =
		'' + displayGrouping() === displayGroupings.LIST
			? 'workspaceview_toggle motif-active'
			: 'workspaceview_toggle';
	let dynClassModule =
		'' + displayGrouping() === displayGroupings.MODULE
			? 'workspaceview_toggle motif-active'
			: 'workspaceview_toggle';

	const updateGroupPreferenceUserSettings = (settingPreference) => {
		dispatch(updateGroupPrefUserSettings(settingPreference));
		history.push({
			pathname: history.location.pathname,
			search: settingPreference == 'engagements' ? '' : '?groupby=clients'
		});
		props.resetPager();
	};

	return (
		<Toplevelcss className="workspaces_view">
			<ContentSwitcher>
				<Button
					className={dynClassList}
					name="view_list"
					variant="secondary"
					onClick={(e) => {
						updateGroupPreferenceUserSettings(settingsPreferences.EngagementList);
					}}
				>
					<span title={labels.engagementslist}>
						<MotifIcon src={IconoirList} />
					</span>
				</Button>
				<Button
					className={dynClassModule}
					name="view_module"
					variant="secondary"
					onClick={(e) => {
						updateGroupPreferenceUserSettings(settingsPreferences.WorkspaceList);
					}}
				>
					<span title={labels.workspacelist}>
						<MotifIcon src={IconoirRubikCube} />
					</span>
				</Button>
			</ContentSwitcher>
		</Toplevelcss>
	);
};

export default withRouter(EngagementGrouping);
