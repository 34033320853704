import React from 'react';
import {MotifNav} from '@ey/canvascoreservices';
import {labels} from '../../util/uiconstants';
import styled from 'styled-components';

export default function TopBarNavServer() {
	return (
		<StyledServerMotifNav className="server-nav">
			<MotifNav
				applicationName={labels.canvas}
				copyrightMessage={labels.copyrightMessage}
				showMoreMenuOptions={false}
				showSelectorDropdown={false}
				showIconLinks={false}
				showNotifications={false}
				showSettings={false}
				showAvatar={false}
				showSearchBox={false}					
			>
			</MotifNav>
		</StyledServerMotifNav>
	);
}

TopBarNavServer.displayName = 'TopBarNavServer';

const StyledServerMotifNav = styled.section`
.motif-header {
    display: flex;
    background: #f9f9fe;
    color: #2e2e38;
    padding: 0.4285714286rem 0.7142857143rem;
    z-index: 100;
    height: 4rem;
    border-radius: 0;
	border-bottom: 0.0625rem solid #e6e6e9;
    justify-content: space-between;
    align-items: center;
    min-width: max-content;
    max-width: 100%;
    gap: 0.5714285714rem;
}

`;
