import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {MotifNav} from '@ey/canvascoreservices';
import NotificationsCenter from '../../Notifications/NotificationsCenter';
import MoreMenuOptions from './MoreMenuOptions';
import {MenuIconOptions} from './MenuIconOptions';
import {getTheme, labels} from '../../../util/uiconstants';
import * as QueryString from 'query-string';
import _ from 'lodash';
import styled from 'styled-components';

export default function TopBarNav(props) {
	const mode = getTheme();
	const params = QueryString.parse(window.location.search);

	const userSettings = useSelector((store) => store.userSettings);
	const featureConfigurations = useSelector(
		(store) => store.featureConfiguration
	);

	const [triggerNotification, setNotificationStatus] = useState(true);
	const [showIcons, setShowIcons] = useState(true);
	const [configurationIsLoaded, setConfigurationIsLoaded] = useState(false);
	const [iconOptions, setIconOptions] = useState([]);

	useEffect(() => {
		if (!_.isEmpty(featureConfigurations)) {
			setConfigurationIsLoaded(true);
		}
	}, [featureConfigurations]);

	useEffect(() => {
		let iconOptions = [];
		if (!_.isEmpty(featureConfigurations)) {
			iconOptions = MenuIconOptions(featureConfigurations);
		}
		setIconOptions(iconOptions);
	}, [configurationIsLoaded]);

	useEffect(() => {
		let path = window.location.pathname;
		if (path === '/rejectIndependenceOnlyEngagementInvite') {
			setShowIcons(false);
		} else {
			if (!_.isEmpty(userSettings)) {
				setShowIcons(
					userSettings.data &&
						Object.keys(userSettings?.data)?.length > 0 &&
						(params.showNavIcons === 'true' ||
							params.showNavIcons === undefined)
				);
			}
		}
	}, [userSettings]);

	const notificationRender = () => {
		return (
			<section title={labels.notifications} className="notifications">
				{
					<NotificationsCenter
						key="topNavEyUserNotificationsCenter"
						id="topNavEyUserNotificationsCenter"
						{...props}
						triggerNotification={triggerNotification}
						setNotificationStatus={setNotificationStatus}
						theme={mode}
					/>
				}
			</section>
		);
	};

	return (
		<StyledMotifNav className="motif-no-padding">
			<MotifNav
				applicationName={labels.canvas}
				useMotifMenu={showIcons}
				disableLogo={false}
				showIconLinks={showIcons}
				copyrightMessage={labels.copyrightMessage}
				hideOffline={true}
				topNavLinks={iconOptions}
				showMoreMenuOptions
				showSelectorDropdown={false}
				showNotifications={
					featureConfigurations &&
					featureConfigurations.features &&
					featureConfigurations.features.enableNotifications
				}
				notifications={notificationRender}
				showSettings={false}
				showSearchBox={false}
				showAvatar={false}
			>
				<MoreMenuOptions
					featureConfiguration={featureConfigurations}
					userSettings={userSettings?.data}
				/>
			</MotifNav>
		</StyledMotifNav>
	);
}

TopBarNav.displayName = 'TopBarNav';

const StyledMotifNav = styled.section`
	.MoreMenuContainer {
		.motif-menu {
			svg {
				margin: var(--px-3) var(--px-5) 0 var(--px-5);
			}
		}
	}
`;
