import React from 'react';
import styled from 'styled-components';
import { SVGICONS } from '@ey/icons';
import MotifIcon from '@ey/motif-react-wrapper/Icon';

const Wrapper = styled.section`
	display: inline-flex;
	margin-left: auto;
	line-height: var(--px-48);

	h3 {
		margin: auto;
	}

	svg {
		height: var(--px-48);
		margin-right: var(--px-5);
		vertical-align: middle;
	}
`;
function PageHeader(props) {
	return (
		<Wrapper classname="title">
			<MotifIcon
				name="card-icon"
				className="card-icon"
				iconFunction={props.iconName}
				strokeWidth="0.5"
				fill="none"
				size={24}
			/>
			<h3>{props.moduleName}</h3>
		</Wrapper>
	);
}

export default PageHeader;
