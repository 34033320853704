import initialstate from '../../initialstate';
import * as types from '../../../actions/actiontypes';

const mockData = {
	"totalEntityCount": 20,
	"pages": 2,
	"page": 1,
	data:[
	{DataCenterName :"Test1",NumberofWorkspacesProvisioned:825,Id:1},
	{DataCenterName :"Test2",NumberofWorkspacesProvisioned:826,Id:2},
	{DataCenterName :"Test3",NumberofWorkspacesProvisioned:827,Id:3},
	{DataCenterName :"Test4",NumberofWorkspacesProvisioned:828,Id:4},
	{DataCenterName :"Test5",NumberofWorkspacesProvisioned:829,Id:5},
	{DataCenterName :"Test6",NumberofWorkspacesProvisioned:830,Id:6},
	{DataCenterName :"Test7",NumberofWorkspacesProvisioned:831,Id:7},
	{DataCenterName :"Test8",NumberofWorkspacesProvisioned:625,Id:8},
	{DataCenterName :"Test9",NumberofWorkspacesProvisioned:925,Id:9},
	{DataCenterName :"Test10",NumberofWorkspacesProvisioned:325,Id:10},
	{DataCenterName :"Test1",NumberofWorkspacesProvisioned:825,Id:11},
	{DataCenterName :"Test2",NumberofWorkspacesProvisioned:826,Id:12},
	{DataCenterName :"Test3",NumberofWorkspacesProvisioned:827,Id:13},
	{DataCenterName :"Test4",NumberofWorkspacesProvisioned:828,Id:14},
	{DataCenterName :"Test5",NumberofWorkspacesProvisioned:829,Id:15},
	{DataCenterName :"Test6",NumberofWorkspacesProvisioned:830,Id:16},
	{DataCenterName :"Test7",NumberofWorkspacesProvisioned:831,Id:17},
	{DataCenterName :"Test8",NumberofWorkspacesProvisioned:625,Id:18},
	{DataCenterName :"Test9",NumberofWorkspacesProvisioned:925,Id:19},
	{DataCenterName :"Test10",NumberofWorkspacesProvisioned:325,Id:20},
	{DataCenterName :"Test5",NumberofWorkspacesProvisioned:825,Id:21},
	{DataCenterName :"Test2",NumberofWorkspacesProvisioned:826,Id:22},
	{DataCenterName :"Test3",NumberofWorkspacesProvisioned:827,Id:23},
	{DataCenterName :"Test4",NumberofWorkspacesProvisioned:828,Id:24},
	{DataCenterName :"Test5",NumberofWorkspacesProvisioned:829,Id:25},
	{DataCenterName :"Test6",NumberofWorkspacesProvisioned:830,Id:26},
	{DataCenterName :"Test7",NumberofWorkspacesProvisioned:831,Id:27},
	{DataCenterName :"Test8",NumberofWorkspacesProvisioned:625,Id:28},
	{DataCenterName :"Test9",NumberofWorkspacesProvisioned:925,Id:29},
	{DataCenterName :"Test10",NumberofWorkspacesProvisioned:325,Id:30},
	{DataCenterName :"Test1",NumberofWorkspacesProvisioned:825,Id:31},
	{DataCenterName :"Test2",NumberofWorkspacesProvisioned:826,Id:32},
	{DataCenterName :"Test3",NumberofWorkspacesProvisioned:827,Id:33},
	{DataCenterName :"Test4",NumberofWorkspacesProvisioned:828,Id:34},
	{DataCenterName :"Test5",NumberofWorkspacesProvisioned:829,Id:35},
	{DataCenterName :"Test6",NumberofWorkspacesProvisioned:830,Id:36},
	{DataCenterName :"Test7",NumberofWorkspacesProvisioned:831,Id:37},
	{DataCenterName :"Test8",NumberofWorkspacesProvisioned:625,Id:38},
	{DataCenterName :"Test9",NumberofWorkspacesProvisioned:925,Id:39},
	{DataCenterName :"Test10",NumberofWorkspacesProvisioned:325,Id:40}
 ]
};const mockWorkspaceData = {
	"totalEntityCount": 20,
	"pages": 2,
	"page": 1,
	data:[
	{WorkspaceName :"Workspace1",DocumentCount:825,PercentageCapacity:40,Id:1,DatacenterId:1},
	{WorkspaceName :"Workspace2 Workspace2 Workspace2 Workspace2 Workspace2 Workspace2 Workspace2 Workspace2 Workspace2 Workspace2 Workspace2 Workspace2 Workspace2",DocumentCount:826,PercentageCapacity:70,Id:2,DatacenterId:1},
	{WorkspaceName :"Workspace3",DocumentCount:827,PercentageCapacity:80,Id:3,DatacenterId:1},
	{WorkspaceName :"Workspace4",DocumentCount:828,PercentageCapacity:40,Id:4,DatacenterId:1},
	{WorkspaceName :"Workspace5",DocumentCount:829,PercentageCapacity:40,Id:5,DatacenterId:1},
	{WorkspaceName :"Workspace6",DocumentCount:9030,PercentageCapacity:90,Id:6,DatacenterId:1},
	{WorkspaceName :"Workspace7",DocumentCount:831,PercentageCapacity:40,Id:7,DatacenterId:1},
	{WorkspaceName :"Workspace8",DocumentCount:625,PercentageCapacity:40,Id:8,DatacenterId:1},
	{WorkspaceName :"Workspace9",DocumentCount:925,PercentageCapacity:20,Id:9,DatacenterId:1},
	{WorkspaceName :"Workspace10",DocumentCount:3254,PercentageCapacity:40,Id:10,DatacenterId:1},
	{WorkspaceName :"Workspace11",DocumentCount:825,PercentageCapacity:10,Id:11,DatacenterId:2},
	{WorkspaceName :"Workspace12",DocumentCount:826,PercentageCapacity:40,Id:12,DatacenterId:2},
	{WorkspaceName :"Workspace13",DocumentCount:827,PercentageCapacity:30,Id:13,DatacenterId:2},
	{WorkspaceName :"Workspace14",DocumentCount:828,PercentageCapacity:40,Id:14,DatacenterId:2},
	{WorkspaceName :"Workspace15",DocumentCount:829,PercentageCapacity:40,Id:15,DatacenterId:5},
	{WorkspaceName :"Workspace16",DocumentCount:830,PercentageCapacity:40,Id:16,DatacenterId:5},
	{WorkspaceName :"Workspace17",DocumentCount:831,PercentageCapacity:75,Id:17,DatacenterId:5},
	{WorkspaceName :"Workspace18",DocumentCount:625,PercentageCapacity:48,Id:18,DatacenterId:5},
	{WorkspaceName :"Workspace19",DocumentCount:9259,PercentageCapacity:95,Id:19,DatacenterId:2},
	{WorkspaceName :"Workspace20",DocumentCount:3251,PercentageCapacity:87,Id:20,DatacenterId:2},
	{WorkspaceName :"Workspace21",DocumentCount:825,PercentageCapacity:56,Id:21,DatacenterId:3},
	{WorkspaceName :"Workspace22",DocumentCount:826,PercentageCapacity:35,Id:22,DatacenterId:3},
	{WorkspaceName :"Workspace23",DocumentCount:827,PercentageCapacity:25,Id:23,DatacenterId:3},
	{WorkspaceName :"Workspace24",DocumentCount:828,PercentageCapacity:18,Id:24,DatacenterId:7},
	{WorkspaceName :"Workspace25",DocumentCount:829,PercentageCapacity:88,Id:25,DatacenterId:7},
	{WorkspaceName :"Workspace26",DocumentCount:830,PercentageCapacity:84,Id:26,DatacenterId:7},
	{WorkspaceName :"Workspace27",DocumentCount:831,PercentageCapacity:44,Id:27,DatacenterId:3},
	{WorkspaceName :"Workspace28",DocumentCount:625,PercentageCapacity:48,Id:28,DatacenterId:3},
	{WorkspaceName :"Workspace29",DocumentCount:925,PercentageCapacity:69,Id:29,DatacenterId:3},
	{WorkspaceName :"Workspace30",DocumentCount:325,PercentageCapacity:67,Id:30,DatacenterId:3},
	{WorkspaceName :"Workspace31",DocumentCount:825,PercentageCapacity:73,Id:31,DatacenterId:6},
	{WorkspaceName :"Workspace32",DocumentCount:826,PercentageCapacity:40,Id:32,DatacenterId:6},
	{WorkspaceName :"Workspace33",DocumentCount:827,PercentageCapacity:39,Id:33,DatacenterId:6},
	{WorkspaceName :"Workspace34",DocumentCount:828,PercentageCapacity:40,Id:34,DatacenterId:6},
	{WorkspaceName :"Workspace35",DocumentCount:829,PercentageCapacity:54,Id:35,DatacenterId:6},
	{WorkspaceName :"Workspace36",DocumentCount:830,PercentageCapacity:86,Id:36,DatacenterId:6},
	{WorkspaceName :"Workspace37",DocumentCount:831,PercentageCapacity:56,Id:37,DatacenterId:4},
	{WorkspaceName :"Workspace38",DocumentCount:625,PercentageCapacity:90,Id:38,DatacenterId:4},
	{WorkspaceName :"Workspace39",DocumentCount:925,PercentageCapacity:46,Id:39,DatacenterId:4},
	{WorkspaceName :"Workspace40",DocumentCount:325,PercentageCapacity:20,Id:40,DatacenterId:8}
 ]
};
export function sharepointWorkspacesMockDataReducer(state = initialstate.dataCentersSharepointWorkspaces) {	
			state = mockData;
			return state;
}	
export function dataCenterDetailsReducer(state = initialstate.dataCentersSharepointWorkspaces, action) {
	switch (action.type) {
		case types.GET_ALL_DAAS_WORKSPACES:
			state = action.response;
			return state;
		case types.RESET_SHAREPOINT_WORKSPACES:
			return initialState.dataCentersSharepointWorkspaces;
		default:
			return state;
	}	
}
const filterData=(data,dataCenterId) =>{
	var result = data.filter(item => { 
		return item.DatacenterId == dataCenterId;
	})
	return result.length>0 ? result : mockWorkspaceData.data;
};

//Todo : need to modify this accordingly when the api is ready 
export function workSpaceDetailsReducer(state = initialstate.workSpaceDetails, action) {
	switch (action.type) {
		case types.GET_ALL_WORKSPACE_DETAILS:
			state = filterData(mockWorkspaceData.data,action.meta.api.data.dataCenterId);
			return state;
		default:
			return state;
	}	
}